import { useState } from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import style from './TenantAppearence.module.css';
import { useTranslation } from 'react-i18next';

export const TenantAppearence = () => {
  const { client, getClient } = useTenant();
  const { api } = useAuth();
  const { addToast } = useToast();
  const [logo, setLogo] = useState<Blob>();
  const { t } = useTranslation();

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLogo(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    const getExtFromMimeType = (mimeType: string) =>
    ({
      'image/svg+xml': 'svg',
    }[mimeType]);
    const formData = new FormData();
    if (!logo) {
      addToast({
        title: t('tenantPage:missingImage', { defaultValue: 'Imagem faltando' }),
        description: t('tenantPage:missingImageDescription', { defaultValue: 'Selecione uma imagem para definir como logotipo.' }),
        type: 'error',
      });
      return;
    }
    formData.append(
      'logo',
      logo,
      `${client?.tenant}.${getExtFromMimeType(logo.type)}`
    );
    await api.putFormData(
      `/api/client/${client?.tenant}/tenant/logo`,
      formData
    );
    addToast({
      title: t('tenantPage:logoSuccessfullyUpdated', { defaultValue: 'Logo atualizada com sucesso' }),
      description: t('tenantPage:logoSuccessfullyUpdatedDescription', { defaultValue: 'Próximo login exibirá o logo escolhido.' }),
      type: 'success',
    });
    getClient(client?.tenant ?? '');
  };
  return (
    <div className={style.container}>
      <h1>{t('tenantPage:systemAppearenceConfigurator', { defaultValue: 'Configurador da Aparência do sistema' })}</h1>
      <div className={style.logoArea}>
        <label htmlFor='profilePic' className={style.pic}>
          <div>{t('tenantPage:selectLogoImage', { defaultValue: 'Selecione a imagem do logotipo' })}</div>
          {logo || client?.logoPath ? (
            <img
              src={logo ? window.URL.createObjectURL(logo) : client?.logoPath}
              alt={client?.name}
            />
          ) : (
            <img src='/logo_cliente_supervis.png' alt='logo supervis' />
          )}
          <input
            type='file'
            name=''
            id='profilePic'
            onChange={handleLogoChange}
          />
        </label>
      </div>

      <Button secondary>
        <a href='https://wa.me/555133255471' target='_blank'>
          {t('tenantPage:support', { defaultValue: 'Suporte' })}
        </a>
      </Button>
      <footer className={style.footer}>
        <Button onClick={handleSave}>{t('tenantPage:save', { defaultValue: 'Salvar' })}</Button>
      </footer>
    </div>
  );
};
