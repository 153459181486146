import convert from 'convert-units';

export const ConvertValueToBestUnit = (
  val: number
): { val: number; unit: string } => {
  const convertedValue = convert(parseFloat(val.toString()))
    .from('g')
    .toBest({ exclude: ['mt', 'mcg', 'mg'], cutOffNumber: 10 });
  if (convertedValue.val > 10000) {
    if (convertedValue.unit === 'kg') {
      convertedValue.val = convertedValue.val / 1000;
      convertedValue.unit = 't';
    }
    if (convertedValue.unit === 'g') {
      convertedValue.val = convertedValue.val / 1000;
      convertedValue.unit = 'kg';
    }
  }
  return convertedValue;
};
