export type Zoom =
  | 'lst30m'
  | 'lst60m'
  | 'today'
  | 'lst24h'
  | 'yesterday'
  | 'thisweek'
  | 'lst7d'
  | 'thismonth'
  | 'lst30d'
  | 'custom';
export type FilterInterval = 'no' | '5m';
export interface FilterRules {
  equipments: 'multi' | 'single' | 'no';
  operator: boolean;
  product: boolean;
  zoomAvailables: Zoom[];
  autoRefresh: boolean;
}

export interface TimeRange {
  startTimestamp: number;
  endTimestamp: number;
}

export const DefaultFilterRules: FilterRules = {
  equipments: 'multi',
  operator: false,
  product: false,
  zoomAvailables: ['lst30m', 'lst60m', 'today', 'lst24h', 'yesterday'],
  autoRefresh: false,
};
export interface Filter {
  equipments: { id: string; name: string }[];
  operator?: { id: string; name: string; code: string };
  product?: { id: string; name: string; codeText: string, code: number };
  zoom: Zoom;
  interval: FilterInterval;
  customStartDate: Date;
  customEndDate: Date;
  dates: TimeRange[];
}
