import { startOfToday } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { FilterContext } from './FilterContext';
import { DefaultFilterRules, Filter, FilterRules } from './models';
import { computeInterval, computeStartOfZoom } from './utils';

export const FilterProvider: React.FC = ({ children }) => {
  const [filterRules, setFilterRules] = useState<FilterRules>();

  const [filter, setFilter] = useState<Filter>({
    operator: undefined,
    product: undefined,
    zoom: 'today',
    interval: '5m',
    customEndDate: new Date(
      new Date(new Date().setSeconds(0)).setMilliseconds(0)
    ),
    customStartDate: startOfToday(),
    dates: [
      {
        startTimestamp: startOfToday().getTime(),
        endTimestamp: new Date(
          new Date(new Date().setSeconds(0)).setMilliseconds(0)
        ).getTime(),
      },
    ],
    equipments: [],
  });

  const intervalZoomRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    console.log(filter.zoom);
    if (intervalZoomRef.current) {
      clearInterval(intervalZoomRef.current);
    }
    if (filter.zoom !== 'custom' && filterRules?.autoRefresh) {
      intervalZoomRef.current = setInterval(
        () => {
          const now = new Date(
            new Date(new Date().setSeconds(0)).setMilliseconds(0)
          );
          const start = computeStartOfZoom(filter, now);
          const end = filter.zoom === 'yesterday' ? startOfToday() : now;
          const interval = computeInterval(now, start);
          setFilter((prev) => ({
            ...prev,
            dates: [
              { startTimestamp: start.getTime(), endTimestamp: end.getTime() },
            ],
            customEndDate: end,
            customStartDate: start,
            interval,
          }));
        },
        filter.zoom === 'yesterday' ? 1000 * 60 * 60 : 1000 * 60 * 1 //if zoom yesterday update every hour, otherwise every 1 minute
      );
    }
    return () => {
      if (intervalZoomRef.current) {
        clearInterval(intervalZoomRef.current);
        intervalZoomRef.current = undefined;
      }
    };
  }, [setFilter, filter.zoom, filterRules]);

  const updateFilter = (newValue: Filter): void => {
    const end =
      newValue.zoom === 'custom'
        ? newValue.customEndDate
        : newValue.zoom === 'yesterday'
        ? startOfToday()
        : new Date();
    const start = computeStartOfZoom(newValue, end);
    setFilter({
      equipments: newValue.equipments,
      operator: newValue.operator,
      product: newValue.product,
      customEndDate: end,
      customStartDate: start,
      dates:
        newValue.zoom === 'custom'
          ? newValue.dates
          : [{ startTimestamp: start.getTime(), endTimestamp: end.getTime() }],
      zoom: newValue.zoom,
      interval: computeInterval(end, start),
    });
  };
  const resetFilterRules = () => {
    setFilterRules({
      equipments: 'multi',
      operator: true,
      product: true,
      autoRefresh: false,
      zoomAvailables: [
        'lst30m',
        'lst60m',
        'today',
        'lst24h',
        'yesterday',
        'thisweek',
        'lst7d',
        'thismonth',
        'lst30d',
        'custom',
      ],
    });
  };
  const updateFilterRules = (partialFilterRules: Partial<FilterRules>) => {
    setFilterRules((prev = DefaultFilterRules) => ({
      ...prev,
      ...partialFilterRules,
    }));
  };
  return (
    <FilterContext.Provider
      value={{
        filter,
        updateFilter,
        filterRules,
        resetFilterRules,
        updateFilterRules,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
