import { useCallback, useEffect, useRef, useState } from 'react';
import { useData } from '../../Controls/DataControl/UseData';
import style from './PackagesPage.module.css';
import { GroupedPackage, PackageSummary } from '../../Controls/DataControl/models';
import { useTranslation } from 'react-i18next';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import Chart from 'react-apexcharts';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { useLocale } from '../../../../LocaleControl';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { MultiSeriesChartPalette } from '../../../../../helper/ChartsColorPalette';
import { ConvertValueToBestUnit } from '../../../../../helper/ConvertValueToBestUnit';
import { ApexOptions } from 'apexcharts';
import { Filter } from '../../Controls/FilterControl/models';
import { useFilter } from '../../Controls/FilterControl';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { EmptyState } from '../../Components/Cards/EmptyState';

export const PackagesPage = ({ containerHeight, containerWidth }: { containerWidth: number; containerHeight: number; }): React.ReactElement => {
  const { fetchPackages } = useData();
  const { filter, resetFilterRules } = useFilter();
  const { t } = useTranslation();
  const [packages, setPackages] = useState<GroupedPackage[]>([]);
  const [series, setSeries] = useState<{ data: { x: string; y: number; }[] }[]>([]);
  const [options, setOptions] = useState<ApexOptions>()
  const { chartOptionsLocale } = useLocale();
  const [loading, setLoading] = useState(true);
  const abortFetchRef = useRef<() => void>();

  const localFetch = useCallback(
    async (localFilter: Filter) => {
      setLoading(true);
      try {
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }

        const { abort, fetch } = fetchPackages();
        abortFetchRef.current = abort;
        await fetch(localFilter).then((res) => {
          setPackages(res.result);
          setSeries(
            [{
              data: res.result.map(({ groupName, totalPackages }) => {
                return {
                  x: groupName,
                  y: totalPackages
                }
              })
            }]
          );
          setLoading(false);
        })

      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [abortFetchRef, fetchPackages, filter]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    localFetch(filter);
  }, [filter]);

  //setOptions();
  useEffect(() => {
    if (packages.length === 0) return;
    setOptions(
      {
        responsive: [
          {
            breakpoint: 1400,
            options: {
              xaxis: {
                tickAmount: 2,
              },
            },
          },
        ],
        ...ChartOptionsLegend,
        chart: {
          type: 'bar',
          toolbar: { show: false },
          animations: {
            enabled: false,
          },
          ...chartOptionsLocale,
        },
        xaxis: {
          type: 'category',
          tickPlacement: 'on',
          tickAmount: 4,
          categories: packages.map((item) => item.groupName),
          title: { ...AxisTitleConfiguration(t('packagesPage:packages', { defaultValue: 'Fardos' })), offsetY: 10 },
        },
        colors: MultiSeriesChartPalette,
        yaxis: {
          show: false,
          labels: {
            formatter: formatNumberToNotation,
          },
        },
        dataLabels: {
          enabled: true,
          style: { fontSize: '0.875em', colors: ['#222'] },
          textAnchor: 'start',

          formatter: function (val: any, { w, dataPointIndex }: any) {
            const convertedValue = ConvertValueToBestUnit(
              parseFloat(val.toString())
            );
            return `${w.globals.labels[dataPointIndex]}: ${convertedValue.val}`;
          },

          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderColor: 'transparent',
            opacity: 0.5,
            dropShadow: {
              enabled: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
            borderRadius: 4,
            dataLabels: {
              position: 'bottom',
              orientation: 'horizontal',
              hideOverflowingLabels: false,
            },
          },
        },
      }
    )
  }, [packages])


  return (
    <div className={style.container}>
      <SpecificChartPageTitle text={t('specificChartPage:packages', { defaultValue: 'Fardos' })} />
      {loading ? (
        <LoadingState />
      ) : (
        <>
          <div className={style.tableSection}>
            <table className={style.tableContainer}>
              <thead>
                <tr key="header">
                  <th className={style.headerTitle}>{t('packagesPage:group', { defaultValue: 'Grupo' })}</th>
                  <th className={style.headerTitle}>{t('packagesPage:totalProduced', { defaultValue: 'Quantidade Produzida' })}</th>
                  <th className={style.headerTitle}>{t('packagesPage:nominalWeight', { defaultValue: 'Peso Nominal' })}</th>
                  <th className={style.headerTitle}>{t('packagesPage:packages', { defaultValue: 'Fardos' })}</th>
                </tr>
              </thead>
              <tbody>
                {packages && packages.map((groupedPackage) => (
                  <tr key={groupedPackage.id}>
                    <td>{groupedPackage.groupName}</td>
                    <td>{groupedPackage.totalProducts}</td>
                    <td>{groupedPackage.totalGroupWeight}</td>
                    <td>{groupedPackage.totalPackages}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={style.chartSection}>
            {options && series.length > 0 ?
              <div className={style.chart}>
                <Chart
                  type="bar"
                  id="packageschart"
                  height={400}
                  series={series}
                  options={options}
                />
              </div>
              : <EmptyState />
            }
          </div>
        </>
      )}

    </div>
  )
}