export const saveProductionBlacklist = (productionCodes: string[]) => {
  localStorage.setItem(
    '@medianeira:filter:production',
    JSON.stringify(productionCodes)
  );
};

export const getProductionBlacklist = (): string[] => {
  const data = localStorage.getItem('@medianeira:filter:production');
  if (!data) return [];
  return JSON.parse(data);
};
