import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SignedInHeader } from '../Components/Header';
import { EquipmentPage } from './EquipmentsPages/EquipmentPage';
import { EquipmentsListPage } from './EquipmentsPages/EquipmentsListPage';
import { EventPage } from './EventsPages/EventPage';
import { EventsListPage } from './EventsPages/EventsListPage';
import { OperatorPage } from './OperatorsPages/OperatorPage';
import { OperatorsListPage } from './OperatorsPages/OperatorsListPage';
import { ProductPage } from './ProductsPages/ProductPage';
import { ProductsListPage } from './ProductsPages/ProductsListPage';
import { UserPage } from './UsersPages/UserPage';
import { UsersListPage } from './UsersPages/UsersListPage';
import style from './AdminPages.module.css';
import { FilterProvider } from '../Controls/FilterControl';
import { DevicesPages } from './DevicesPages';
import { CoilsListPage } from './CoilsPages/CoilsListPage';
import { CoilPage } from './CoilsPages/CoilPage';
import { TenantAppearence } from './TenantPages';
import { ProductConfigPage } from './ProductsPages/ProductConfigPage';
import { EquipmentGroupsListPage } from './EquipmentGroupsPages/EquipmentGroupsListPage';
import { EquipmentGroupPage } from './EquipmentGroupsPages/EquipmentGroupPage';
import { EquipmentGroupCreationPage } from './EquipmentGroupsPages/EquipmentGroupCreationPage';
import { OrdersListPage } from './OrdersPages/OrdersListPage';
import { OrderPage } from './OrdersPages/OrderPage';

export const AdminPages = (): React.ReactElement => {
  return (
    <FilterProvider>
      <SignedInHeader hideFilters />
      <div className={style.container}>
        <Switch>
          <Route
            exact
            path='/client/:tenant/admin/informacao'
            component={TenantAppearence}
          />
          <Route
            exact
            path='/client/:tenant/admin/usuarios'
            component={UsersListPage}
          />
          <Route
            path='/client/:tenant/admin/usuarios/novo'
            render={() => <UserPage newUser />}
          />
          <Route
            path='/client/:tenant/admin/usuarios/:id'
            component={UserPage}
          />

          <Route
            path='/client/:tenant/admin/produtos/novo'
            render={() => <ProductPage newProduct />}
          />
          <Route
            exact
            path='/client/:tenant/admin/produtos/:id'
            component={ProductPage}
          />
          <Route
            exact
            path='/client/:tenant/admin/produtos'
            component={ProductsListPage}
          />
          <Route
            exact
            path='/client/:tenant/admin/produtos/:id/config'
            component={ProductConfigPage}
          />

          <Route
            exact
            path='/client/:tenant/admin/ordens'
            component={OrdersListPage}
          />

          <Route
            path='/client/:tenant/admin/ordens/novo'
            render={() => <OrderPage newOrder />}
          />

          <Route
            exact
            path='/client/:tenant/admin/ordens/:id'
            component={OrderPage}
          />
            
          <Route 
            exact
            path='/client/:tenant/admin/grupos'
            component={EquipmentGroupsListPage}
          />
          <Route 
            exact
            path='/client/:tenant/admin/grupos/novo'
            component={EquipmentGroupCreationPage}
          />
          <Route 
            exact
            path='/client/:tenant/admin/grupos/:group'
            component={EquipmentGroupPage}
          />

          <Route
            path='/client/:tenant/admin/equipamentos/novo'
            render={() => <EquipmentPage newEquipment />}
          />
          <Route
            exact
            path='/client/:tenant/admin/equipamentos/:id'
            component={EquipmentPage}
          />
          <Route
            exact
            path='/client/:tenant/admin/equipamentos'
            component={EquipmentsListPage}
          />

          <Route
            path='/client/:tenant/admin/operadores/novo'
            render={() => <OperatorPage newOperator />}
          />
          <Route
            exact
            path='/client/:tenant/admin/operadores/:id'
            component={OperatorPage}
          />
          <Route
            exact
            path='/client/:tenant/admin/operadores'
            component={OperatorsListPage}
          />
          <Route
            path='/client/:tenant/admin/eventos/novo'
            render={() => <EventPage newEvent />}
          />
          <Route
            exact
            path='/client/:tenant/admin/eventos/:id'
            component={EventPage}
          />
          <Route
            exact
            path='/client/:tenant/admin/eventos'
            component={EventsListPage}
          />
          <Route
            path='/client/:tenant/admin/bobinas/novo'
            render={() => <CoilPage newCoil />}
          />
          <Route
            exact
            path='/client/:tenant/admin/bobinas/:id'
            component={CoilPage}
          />
          <Route
            exact
            path='/client/:tenant/admin/bobinas'
            component={CoilsListPage}
          />
          <Route
            exact
            path='/client/:tenant/admin/dispositivo'
            component={DevicesPages}
          />
        </Switch>
      </div>
    </FilterProvider>
  );
};
