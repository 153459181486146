import { ApexOptions } from 'apexcharts';

export const ChartOptionsDatetimeXaxis = (
  tickAmount: number
): Pick<ApexOptions, 'xaxis'> => ({
  xaxis: {
    type: 'datetime',
    tickAmount,
    labels: {
      style: { fontSize: '1em' },
      datetimeUTC: false,
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM / yy',
        day: 'dd MMM',
        hour: 'HH:mm:ss',
      },
    },
  },
});
