export const formatSecondsToHumanReadableTime = (
  seconds: number,
  addSpace = false,
  includeSeconds = true
): string => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  const restOfDays = seconds % (24 * 60 * 60);
  const hours = Math.floor(restOfDays / (60 * 60));
  const restOfHours = restOfDays % (60 * 60);
  const minutes = Math.floor(restOfHours / 60);
  const restOfMinutes = restOfHours % 60;
  const restOfSeconds = Math.floor(restOfMinutes);
  let message = '';
  if (days >= 1) {
    message += `${days.toFixed(0)}${addSpace ? ' ' : ''}d `;
  }
  if (hours >= 1) {
    message += `${hours.toFixed(0)}${addSpace ? ' ' : ''}h `;
  }
  if (minutes >= 1) {
    message += `${minutes.toFixed(0)}${addSpace ? ' ' : ''}min `;
  }
  if (includeSeconds) {
    if (restOfSeconds >= 1) {
      message += `${restOfSeconds.toFixed(0)}${addSpace ? ' ' : ''}s `;
    }
    if (message === '') {
      message = '0 s';
    }
  }
  return message.trim();
};
