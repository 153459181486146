import React from 'react';
import style from './DropDownMenu.module.css';
import * as DropDownMenuRadix from '@radix-ui/react-dropdown-menu';
import { FiCheck } from 'react-icons/fi';

interface Props {
  trigger: React.ReactElement;
  align?: 'start' | 'center' | 'end';
  items: { key: string; text: string; selected: boolean, disabled: boolean }[];
  closeOnClick?: boolean;
  onCheckedChange?: (checked: {
    key: string;
    text: string;
    selected: boolean;
    disabled?: boolean;
  }) => void;
}
export const DropDownMenuCheckbox = ({
  trigger,
  align = 'start',
  items,
  closeOnClick,
  onCheckedChange,
}: Props): React.ReactElement => {
  return (
    <DropDownMenuRadix.Root>
      <DropDownMenuRadix.Trigger className={style.trigger} asChild>
        {trigger}
      </DropDownMenuRadix.Trigger>
      <DropDownMenuRadix.Content
        align={align}
        sideOffset={4}
        className={style.content}
        onCloseAutoFocus={(e) => {
          if (!closeOnClick) e.preventDefault();
        }}
      >
        {items.map(({ key, text, selected, disabled }) => (
          <DropDownMenuRadix.CheckboxItem
            key={key}
            asChild
            className={!!!disabled ? style.contentCheckboxItem : style.groupName}
            disabled={disabled}
            onCheckedChange={() =>
              onCheckedChange && onCheckedChange({ key, text, selected })
            }
          >
            <div>
              <div>{text}</div>
              {selected && <FiCheck />}
            </div>
          </DropDownMenuRadix.CheckboxItem>
        ))}
      </DropDownMenuRadix.Content>
    </DropDownMenuRadix.Root>
  );
};
