import { useContext } from 'react';
import { DataContext, DataContextState } from './DataContext';

export const useData = (): DataContextState => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within DataProvider');
  }
  return context;
};
