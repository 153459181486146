import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DropDownMenu,
  DropDownMenuCheckbox,
} from '../../../../../Components/Atoms/DropDownMenu';
import style from './SignedInHeader.module.css';
import { FiMenu, FiFilter, FiClock } from 'react-icons/fi';
import { useFilter, Zoom } from '../../Controls/FilterControl';
import { useTenant } from '../../../../TenantControl';
import { MenuItems } from './MenuItems';
import { useData } from '../../Controls/DataControl/UseData';
import { Equipment } from '../../Controls/DataControl/models';
import { useAuth } from '../../../../AuthControl';
import { UserMenuItems } from './UserMenuItems';
import { AiOutlineUser } from 'react-icons/ai';
import { HiOutlineCog } from 'react-icons/hi';
import { AdministrativeMenuItems } from './AdministrativeMenuItems';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../LocaleControl';
import { useLocation } from 'react-router-dom';

interface Props {
  zoomWhitelist?: Zoom[];
  multiEquipment?: boolean;
  hideFilters?: boolean;
}

export const SignedInHeader = ({
  hideFilters,
  zoomWhitelist,
}: Props): React.ReactElement => {
  const { updateFilter, filter, filterRules } = useFilter();
  const { fetchEquipments } = useData();
  const { client } = useTenant();
  const { user, isAdmin } = useAuth();
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [groupedEquipments, setGroupedEquipments] = useState<Equipment[]>([]);
  const { t } = useTranslation();
  const { zoomOptions } = useLocale();
  const location = useLocation();

  const filterEquipmentsText = useMemo(() => {
    if (filter.equipments.length === 0)
      return t('headerPage:allEquipment', {
        defaultValue: 'Todos Equipamentos',
      });
    if (filter.equipments.length === 1)
      return `${t('headerPage:equipment', { defaultValue: 'Equipamento' })}
    ${filter.equipments[0].name}`;
    return `${filter.equipments.length} ${t('headerPage:equipmentPlural', {
      defaultValue: 'Equipamentos',
    })}`;
  }, [filter.equipments, t]);

  useEffect(() => {
    fetchEquipments().then(setEquipments);
  }, []);

  const filterZoomText = useMemo(
    () =>
      ({
        lst30m: t('headerPage:last30min', { defaultValue: 'Últimos 30 min' }),
        lst60m: t('headerPage:lastHour', { defaultValue: 'Última hora' }),
        today: t('headerPage:today', { defaultValue: 'Hoje' }),
        lst24h: t('headerPage:last24h', { defaultValue: 'Últimas 24h' }),
        yesterday: t('headerPage:yesterday', { defaultValue: 'Ontem' }),
        thisweek: t('headerPage:thisWeek', { defaultValue: 'Essa semana' }),
        lst7d: t('headerPage:last7Days', { defaultValue: 'Últimos 7 dias' }),
        thismonth: t('headerPage:thisMonth', { defaultValue: 'Esse mês' }),
        lst30d: t('headerPage:last30Days', { defaultValue: 'Últimos 30 dias' }),
        custom: t('headerPage:custom', { defaultValue: 'Personalizado' }),
      }[filter.zoom]),
    [filter.zoom, t]
  );

  const handleEquipmentsChange = useCallback(
    (item) => {
      if (item.key === 'all') {
        updateFilter({
          ...filter,
          equipments: [],
        });
        return;
      }
      const found = item.selected;
      if (found) {
        updateFilter({
          ...filter,
          equipments: filter.equipments.filter(({ id }) => id !== item.key),
        });
      } else {
        if (filterRules?.equipments === 'multi') {
          updateFilter({
            ...filter,
            equipments: [
              ...filter.equipments,
              { name: item.text, id: item.key },
            ],
          });
        } else {
          updateFilter({
            ...filter,
            equipments: [{ name: item.text, id: item.key }],
          });
        }
      }
    },
    [updateFilter, filter, filterRules]
  );

  useEffect(() => {
    const groupedArray = equipments.reduce((acc: any, eq) => {
      const groupName = eq.groupName || t('headerPage:groupless', { defaultValue: 'Sem Grupo' });
      acc[groupName] = acc[groupName] || [];
      acc[groupName].push(eq);
      return acc;
    }, {});

    const sortedGroups = Object.keys(groupedArray).sort((a, b) => a.localeCompare(b));
  
    const organizedArray: any[] = [];
    sortedGroups.forEach((groupName) => {
      organizedArray.push(groupName);
      organizedArray.push(...groupedArray[groupName].sort((a: any, b: any) => a.name.localeCompare(b.name)));
    })
  
    const resultArray = organizedArray.flatMap(item => item);

    setGroupedEquipments(resultArray);

  }, [equipments])


  return (
    <header className={style.container}>
      <div className={style.body}>
        <div className={style.desktopContainer}>
          <DropDownMenu
            trigger={
              <div className={style.menuButton}>
                <FiMenu />
                <span>{t('headerPage:menu', { defaultValue: 'Menu' })}</span>
              </div>
            }
            items={MenuItems(client?.tenant)}
          />
        </div>

        {!hideFilters && (
          <DropDownMenuCheckbox
            onCheckedChange={handleEquipmentsChange}
            trigger={
              <div className={style.menuButton}>
                <FiFilter />
                <span>{filterEquipmentsText}</span>
              </div>
            }
            items={[
              {
                text: t('headerPage:all', { defaultValue: 'Todos' }),
                key: 'all',
                selected: filter.equipments.length === 0,
                disabled: false,
              },
              ...groupedEquipments
                .map((equipment) => ({
                  text: equipment.name ?? equipment,
                  key: equipment.id,
                  selected: equipment.name ? !!filter.equipments.find(
                    ({ id }) => equipment.id === id
                  ) : false,
                  disabled: equipment.name ? false : true,
                })),
            ]}
          />
        )}
        {!hideFilters && !location.pathname.endsWith("/producao") && (
          <DropDownMenuCheckbox
            onCheckedChange={(item: any) => {
              updateFilter({ ...filter, zoom: item.key });
            }}
            trigger={
              <div className={style.menuButton}>
                <FiClock />
                <span>{filterZoomText}</span>
              </div>
            }
            items={zoomOptions
              .filter(
                ({ key }) =>
                  (zoomWhitelist?.length ?? 0 > 0
                    ? (zoomWhitelist ?? []).includes(key)
                    : true) && filterRules?.zoomAvailables.includes(key)
              )
              .map((item) => ({
                ...item,
                selected: filter.zoom === item.key,
                disabled: false
              }))}
          />
        )}
        <div className={style.menus}>
          {isAdmin() && (
            <div className={style.desktopContainer}>
              <DropDownMenu
                trigger={
                  <div className={`${style.menuButton} `}>
                    <span className={style.username}>
                      {t('headerPage:configurations', {
                        defaultValue: 'Configurações',
                      })}
                    </span>
                    <HiOutlineCog />
                  </div>
                }
                items={AdministrativeMenuItems(client?.tenant)}
              />
            </div>
          )}
          <DropDownMenu
            trigger={
              <div className={`${style.menuButton} `}>
                <span className={style.username}>{user?.name}</span>
                {!!user?.profilePicPath ? (
                  <img
                    className={style.profileImage}
                    alt={user?.name}
                    src={user?.profilePicPath}
                  />
                ) : (
                  <AiOutlineUser />
                )}
              </div>
            }
            items={UserMenuItems(client?.tenant)}
          />
        </div>
      </div>
    </header>
  );
};
