import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { ConfirmDeleteEventModal } from './ConfirmDeleteEventModal';
import style from './EventsListPage.module.css';
import { useTranslation } from 'react-i18next';

export interface Event {
  id: string;
  code: number;
  name: string;
  enabled?: boolean;
  blocked?: boolean;
  sys?: boolean;
  notificationLevel: number;
}

export const EventsListPage = (): React.ReactElement => {
  const [eventToDelete, setEventToDelete] =
    useState<Omit<Event, 'notificationLevel'>>();
  const [events, setEvents] = useState<Event[]>([]);
  const { push } = useHistory();
  const { addToast } = useToast();
  const { api } = useAuth();
  const { client } = useTenant();
  const { t } = useTranslation();
  const x = useRef<number>();
  x.current = window.setTimeout(() => { }, 1000);
  clearTimeout(x.current);

  const fetchEvents = async () => {
    try {
      const result = await api.get<Event[]>(
        `/api/client/${client?.tenant}/registrations/events`
      );
      setEvents(result);
    } catch (_) {
      addToast({ title: t('eventPage:problemListingEvents', { defaultValue: 'Problema ao listar eventos' }), type: 'error' });
    }
  };

  useEffect(() => {
    fetchEvents();
    const pollingEvents = setInterval(() => {
      fetchEvents();
    }, 10000);
    return () => {
      clearInterval(pollingEvents);
    };
  }, []);

  return (
    <div className={style.container}>
      {eventToDelete && (
        <ConfirmDeleteEventModal
          tenant={client?.tenant || ''}
          eventId={eventToDelete.id}
          eventName={eventToDelete.name}
          handleClose={(success) => {
            setEventToDelete(undefined);
            if (success) {
              fetchEvents();
            }
          }}
        />
      )}
      <Button
        onClick={() => push(`${location.pathname}/novo`)}
        icon={<FiPlus />}
      >
        {t('eventPage:occurrence', { defaultValue: 'Ocorrência' })}
      </Button>
      <ul className={`${style.eventsList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.column} ${style.nameColumn}`}>{t('eventPage:name', { defaultValue: 'Nome' })}</div>
          <div className={`${style.column} ${style.codeColumn}`}>{t('eventPage:code', { defaultValue: 'Código' })}</div>
          <div className={`${style.column} ${style.codeColumn}`}>{t('eventPage:group', { defaultValue: 'Grupo' })}</div>
          <div className={`${style.column} ${style.actionsColumn}`}></div>
        </li>
        {events.map(({ id, name, code, blocked, notificationLevel, sys }) => (
          <li key={id} className={style.row}>
            <div className={`${style.column} ${style.nameColumn}`}>{name}</div>
            <div className={`${style.column} ${style.codeColumn}`}>{code}</div>
            <div className={`${style.column} ${style.codeColumn}`}>
              {
                {
                  [0]: t('eventPage:log', { defaultValue: 'Registro' }),
                  [1]: t('eventPage:info', { defaultValue: 'Informação' }),
                  [2]: t('eventPage:warning', { defaultValue: 'Aviso' }),
                  [3]: t('eventPage:error', { defaultValue: 'Erro' }),
                  [4]: t('eventPage:fatalError', { defaultValue: 'Erro Grave' }),
                }[notificationLevel]
              }
            </div>
            <div className={`${style.column} ${style.actionsColumn}`}>
              {!blocked && (
                <>
                  <Tooltip tooltip={t('eventPage:edit', { defaultValue: 'Editar' })}>
                    <Link to={`${location.pathname}/${code}`}>
                      <AiOutlineEdit />
                    </Link>
                  </Tooltip>
                  {!sys && (
                    <Tooltip tooltip={t('eventPage:delete', { defaultValue: 'Excluir' })}>
                      <div>
                        <AiOutlineDelete
                          onClick={() => setEventToDelete({ id, name, code })}
                        />
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
