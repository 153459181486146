import { useTranslation } from "react-i18next";
import { useToast } from "../../../../../hooks/toast";
import { useAuth } from "../../../../AuthControl";
import Modal from "../../../../../Components/Modal";
import { Button } from "../../../../../Components/Atoms/Button";
import style from "./ConfirmDeleteUserModal.module.css";

export const ConfirmDeleteUserModal = ({
  tenant,
  id,
  name,
  handleClose,
}: {
  tenant: string;
  id: string;
  name: string;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { addToast } = useToast();
  const { api } = useAuth();
  const { t } = useTranslation();

  const handleDeleteUser = async () => {
    await api.delete(`/api/client/${tenant}/users/profile/${id}`);
    addToast({ title: t('userPage:userDeletedSuccessfully', { defaultValue: 'Usuário removido com sucesso.' }), type: 'success' });
    handleClose(true);
  };

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('userPage:delete', { defaultValue: 'Excluir' })} {name} ?</h1>
        <p className={style.body}>
          {t('userPage:userDeleteText', { defaultValue: 'Deseja excluir este usuário?' })}
        </p>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('userPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteUser()}>
            {t('userPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  )
}