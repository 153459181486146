import { useContext } from 'react';
import { ManagementAuthContext } from './AuthContext';
import { ManagementAuthContextState } from './Models';

export const useManagementAuth = (): ManagementAuthContextState => {
  const context = useContext(ManagementAuthContext);
  if (!context) {
    throw new Error(
      'useManagementAuth must be used within ManagementAuthProvider'
    );
  }
  return context;
};
