import React, { useState } from 'react';
import { useAuth } from '../../../../Client/AuthControl';
import { Input } from '../../../../Components/Atoms/Input';
import { Button } from '../../../../Components/Atoms/Button';
import * as Yup from 'yup';
import { ResizeImageFile } from '../../../../helper/UploadImageFile';
import style from './EditProfilePage.module.css';
import { useHistory } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import { useToast } from '../../../../hooks/toast';
import { RadioButtonGroup } from '../../../../Components/Atoms/RadioButton';
import { useTranslation } from 'react-i18next';

export const EditProfilePage = (): React.ReactElement => {
  const { user, updateUser } = useAuth();
  const { addToast } = useToast();
  const { goBack } = useHistory();
  const [name, setUserName] = useState(user?.name ?? '');
  const [profilePic, setProfilePic] = useState<Blob>();
  const [password, setPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [emailNotifications, setEmailNotifications] = useState(user?.emailNotification ?? 0);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>();
  const { t } = useTranslation();

  const updateUserSchema = Yup.object().shape({
    name: Yup.string().required(),
    profilePic: Yup.mixed(),
    emailNotifications: Yup.number().required(
      t('tenantPage:notificationsGroupIsMandatory', { defaultValue: 'Grupos de notificação é obrigatório.' })
    ),
    newPassword: Yup.string(),
    newPasswordConfirmation: Yup.string().when('newPassword', {
      is: (newPassword: string) => !!newPassword,
      then: Yup.string().required(t('tenantPage:repeatPassword', { defaultValue: 'Repita a senha' })),
    }),
    password: Yup.string().when('newPassword', {
      is: (newPassword: string) => !!newPassword,
      then: Yup.string().required(t('tenantPage:insertCurrentPasswordToUpdatePassword', { defaultValue: 'Insira senha atual para alterar a senha' })),
    }),
  });
  const handleProfilePicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      ResizeImageFile(e.target.files[0], 280, 280).then((pic) =>
        setProfilePic(pic)
      );
    }
  };
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      updateUserSchema.validate({
        name,
        profilePic,
        password,
        newPassword,
        newPasswordConfirmation,
        emailNotifications,
      });
      await updateUser({
        name,
        profilePic,
        password,
        newPassword,
        newPasswordConfirmation,
        emailNotifications,
      });
      addToast({
        title: t('tenantPage:success', { defaultValue: 'Sucesso' }),
        description: t('tenantPage:profileUpdated', { defaultValue: 'Perfil atualizado.' }),
        type: 'success',
      });
    } catch (exception) {
      console.error(exception);
      addToast({
        title: t('tenantPage:error', { defaultValue: 'Erro' }),
        description: t('tenantPage:problemUpdatingProfile', { defaultValue: 'Problema ao atualizar perfil.' }),
        type: 'error',
      });
    }
  };
  return (
    <div className={style.container}>
      <div className={style.body}>
        <form onSubmit={handleFormSubmit} className={style.form}>
          <div className={style.baseInfo}>
            <div className={style.profilePicArea}>
              <div className={style.roleBadge}>
                <div>{user?.role}</div>
              </div>
              <label htmlFor='profilePic' className={style.pic}>
                {profilePic || user?.profilePicPath ? (
                  <img
                    src={
                      profilePic
                        ? window.URL.createObjectURL(profilePic)
                        : user?.profilePicPath
                    }
                    alt={user?.name}
                  />
                ) : (
                  <AiOutlineUser />
                )}
                <input
                  type='file'
                  accept='image/*'
                  name=''
                  id='profilePic'
                  onChange={handleProfilePicChange}
                />
              </label>
            </div>
            <Input
              grow
              label={t('tenantPage:name', { defaultValue: 'Nome' })}
              id='name-input'
              value={name}
              onChange={setUserName}
            />
          </div>
          <div className={style.passwordData}>
            <div>
              <Input
                label={t('tenantPage:currentPassword', { defaultValue: 'Senha Atual' })}
                type='password'
                value={password}
                onChange={setPassword}
              />
            </div>
            <div className={style.newPasswordData}>
              <Input
                label={t('tenantPage:newPassword', { defaultValue: 'Nova Senha' })}
                type='password'
                value={newPassword}
                onChange={setNewPassword}
              />
              <Input
                label={t('tenantPage:repeatNewPassword', { defaultValue: 'Repita Nova Senha' })}
                type='password'
                value={newPasswordConfirmation}
                onChange={setNewPasswordConfirmation}
              />
            </div>
          </div>
          <RadioButtonGroup
            onValueChange={(value) => {
              setEmailNotifications(parseInt(value));
            }}
            label={t('tenantPage:emailNotificationGroups', { defaultValue: 'Grupos de notificação por email' })}
            selectedValue={emailNotifications.toString()}
            items={[
              { value: '0', label: t('tenantPage:noNotification', { defaultValue: 'Sem notificação' }) },
              { value: '1', label: t('tenantPage:fatalErrors', { defaultValue: 'Erros Graves' }) },
              { value: '2', label: t('tenantPage:fatalErrorsAndErrors', { defaultValue: 'Erros Graves e Erros' }) },
              {
                value: '3',
                label: t('tenantPage:fatalErrorsErrorsAndWarnings', { defaultValue: 'Erros Graves, Erros e Avisos' }),
              },
              {
                value: '4',
                label: t('tenantPage:fatalErrorsErrorsWarningsAndInfos', { defaultValue: 'Erros Graves, Erros, Avisos e Informações' }),
              },
            ]}
          />
          <div className={style.controlButtons}>
            <Button secondary danger onClick={() => goBack()}>
              {t('tenantPage:cancel', { defaultValue: 'Cancelar' })}
            </Button>
            <Button type='submit'>{t('tenantPage:save', { defaultValue: 'Salvar' })}</Button>
          </div>
        </form>
      </div>
    </div>
  );
};
