import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../../AuthControl";
import { Input } from "../../../../../Components/Atoms/Input";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../Components/Atoms/Button";
import { FiPlus } from "react-icons/fi";
import { Select } from "../../../../../Components/Atoms/Input/Select";
import { useToast } from "../../../../../hooks/toast";
import { EquipmentNameModel } from "./models";
import { useTenant } from "../../../../TenantControl";

export const EquipmentGroupPage = (): React.ReactElement => {
  const { group: groupName } = useParams<{ group: string }>();
  const { state: groupId } = useLocation<{ groupId: string }>();
  const { api, user } = useAuth();
  const [equipmentGroup, setEquipmentGroup] = useState<EquipmentNameModel[]>([]);
  const { t } = useTranslation();
  const [addEquipment, setAddEquipment] = useState<boolean>(false);
  const [availableEquipments, setAvailableEquipments] = useState<EquipmentNameModel[]>([]);
  const [equipmentToAdd, setEquipmentToAdd] = useState<EquipmentNameModel>();
  const { addToast } = useToast();
  const { push } = useHistory();
  const { client } = useTenant();

  useEffect(() => {
    getEquipmentGroup();
  }, []);

  useEffect(() => {
    api.get<EquipmentNameModel[]>(`/api/client/${user?.tenant}/registrations/equipments/groups/available`)
      .then((response) => setAvailableEquipments(response));
  }, [addEquipment]);

  const getEquipmentGroup = async () => {
    try {
      api.get<EquipmentNameModel[]>(`/api/client/${user?.tenant}/registrations/equipments/groups/${groupId}`)
        .then((response) => setEquipmentGroup(response));
    } catch (err) {
      addToast({ title: t('equipmentPage:errorListingEquipments', { defaultValue: 'Erro ao listar os equipamentos.' }), type: 'error' });
    }
  }

  const handleAddEquipmentToGroup = async () => {
    if (!equipmentToAdd) {
      addToast({ title: t('equipmentPage:selectEquipment', { defaultValue: 'Selecione um equipamento.' }), type: 'info' });
      return;
    }

    try {
      //TODO: investigate why is this sending EQUIPMENTS instead of EQUIPMENT or literaly anything else
      //Array of equipments is being sent as a single object so there is no need to conditionally check if it is an array or not in the backend
      await api.post(`/api/client/${user?.tenant}/registrations/equipments/groups/${groupName}`, { equipments: [equipmentToAdd?.id] })
        .then(() => {
          getEquipmentGroup();
          setAddEquipment(false);
          setEquipmentToAdd(undefined);
          addToast({ title: t('equipmentPage:equipmentAddedSuccessfully', { defaultValue: 'Equipamento adicionado ao grupo com sucesso.' }), type: 'success' });
        });
    } catch (err) {
      addToast({ title: t('equipmentPage:errorAddingEquipmentToGroup', { defaultValue: 'Erro ao adicionar equipamento ao grupo.' }), type: 'error' });
    }
  }

  return (
    <div style={{
      maxWidth: '900px',
      margin: '0 auto',
      padding: '1rem 0'
    }}>
      <div
        style={{
          marginBottom: '10px',
          fontSize: '20px',
          fontWeight: 'bolder'
        }}>
        {groupName}
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Button
          onClick={() => setAddEquipment(true)}
          icon={<FiPlus />}>
          {t('equipmentPage:equipment', { defaultValue: 'Equipamento' })}
        </Button>
      </div>
      {equipmentGroup.map((equipment) => (
        <div>
          <Input
            label={t('equipmentPage:equipment', { defaultValue: 'Equipamento' }) + " " + (equipmentGroup.indexOf(equipment) + 1).toString()}
            maxLength={4}
            value={equipment.name}
            required={true}
            disabled
            onChange={function (newValue: string): void {
              throw new Error("Function not implemented.");
            }} />
        </div>
      ))}
      {addEquipment && (
        <>
          <div>
            <Select
              id='add'
              label={t('equipmentPage:newEquipment', { defaultValue: 'Novo Equipamento' })}
              options={availableEquipments.map((equip) => equip.name)}
              value={equipmentToAdd ? equipmentToAdd.name : ''}
              onChange={(value) => {
                const selectedEquipment = availableEquipments.find((equip) => equip.name === value);
                setEquipmentToAdd((_) => selectedEquipment);
              }}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <Button
              onClick={handleAddEquipmentToGroup}>
              {t('equipmentPage:confirm', { defaultValue: 'Confirmar' })}
            </Button>
          </div>
        </>
      )}
      <div style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/grupos`)}>
          {t('equipmentPage:return', { defaultValue: 'Voltar' })}
        </Button>
      </div>
    </div>
  );
}