import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import style from './AndonPages.module.css';
import { Button } from '../../../../Components/Atoms/Button';
import { CarouselPage } from './CarouselPage';
import { useResponsiveContainer } from '../Controls/ResponsiveContainerControl/UserResponsiveContainer';
import { EquipmentSummaryPage } from './EquipmentSummaryPage';
import { useData } from '../Controls/DataControl/UseData';
import {
  Equipment,
  EquipmentStatus,
  Production,
  ProductionSummary,
} from '../Controls/DataControl/models';
import { useFilter } from '../Controls/FilterControl';
import { Filter } from '../Controls/FilterControl/models';
import { EquipmentProductionPage } from './EquipmentProductionPage';
import { OccurrencesParetoPage } from './OccurrencesParetoPage';
import { formatDistance, subMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocation } from '../../../../helper/GetDateFnsLocation';

export const AndonPages = (): React.ReactElement => {
  const andonRef = useRef<HTMLDivElement>(null);
  const [andonRunning, setAndonRunning] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [equipmentsStatus, setEquipmentsStatus] = useState<EquipmentStatus[]>(
    []
  );
  const [equipmentsProductionStatus, setEquipmentsProductionStatus] = useState<
    {
      equipment: { id: string; name: string };
      inDowntime: boolean;
      downtimeDuration?: string;
      downtimeJustificative?: string;
    }[]
  >([]);
  const [equipmentsProduction, setEquipmentsProduction] = useState<
    Production[]
  >([]);
  const [productionSummary, setProductionSummary] = useState<
    ProductionSummary[]
  >([]);
  const {
    fetchEquipments,
    fetchEquipmentStatus,
    fetchProductionSummary,
    fetchProduction,
    fetchEvents,
  } = useData();
  const { filter, resetFilterRules, updateFilterRules } = useFilter();
  const carouselAnimationIntervalRef = useRef<NodeJS.Timer>();
  const { containerHeight, containerWidth } = useResponsiveContainer();
  const intervalFetchRef = useRef<NodeJS.Timer>();
  const { t } = useTranslation();
  const { fetch: fetchStatus } = fetchEquipmentStatus();
  const { fetch: fetchSumary } = fetchProductionSummary();
  const { fetch: fetchEquipmentProduction } = fetchProduction();
  const { fetch: fetchEquipmentEvents } = fetchEvents();
  const FALLBACK_LANGUAGE = 'pt_BR';

  const localFetch = useCallback(
    (localFilter: Filter) => {
      fetchSumary(localFilter).then(setProductionSummary);
      fetchStatus(localFilter).then(setEquipmentsStatus);
      fetchEquipmentProduction(localFilter).then((result) => {
        setEquipmentsProduction(
          result
            .reduce<Production[]>((acc, curr) => {
              const found = acc.find(
                ({ equipment, unit }) =>
                  equipment.id === curr.equipment.id && unit === curr.unit
              );
              if (!found) {
                return [...acc, curr];
              }
              return [
                ...acc.filter(
                  ({ equipment, unit }) =>
                    equipment.id !== curr.equipment.id || unit !== curr.unit
                ),
                {
                  ...found,
                  sum: found.sum + curr.sum,
                  count: found.count + curr.count,
                  entries: [...found.entries, ...curr.entries],
                  max: Math.max(found.max, curr.max),
                  min: Math.min(found.min, curr.min),
                  dev:
                    found.count * found.dev +
                    (curr.count * curr.dev) / (found.count + curr.count),
                  avg:
                    found.count * found.avg +
                    (curr.count * curr.avg) / (found.count + curr.count),
                },
              ];
            }, [])
            .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
        );
      });
      fetchEquipmentEvents({
        ...localFilter,
        customStartDate: subMinutes(new Date(), 5),
        customEndDate: new Date(),
      }).then((result) => {
        setEquipmentsProductionStatus(
          result.map(({ equipment, events }) => {
            const openOcurrence = events.find(({ endDate }) => !endDate);
            return {
              equipment,
              inDowntime: !!openOcurrence,
              downtimeJustificative: openOcurrence?.description,
              downtimeDuration: !!openOcurrence
                ? formatDistance(
                  openOcurrence.startDate.getTime(),
                  new Date(),
                  { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) }
                )
                : undefined,
            };
          })
        );
      });
    },
    [
      fetchSumary,
      fetchEvents,
      fetchStatus,
      setEquipmentsStatus,
      setProductionSummary,
      setEquipmentsProductionStatus,
    ]
  );

  useEffect(() => {
    resetFilterRules();
    updateFilterRules({ autoRefresh: true });
  }, []);

  useEffect(() => {
    fetchEquipments().then(setEquipments);
    localFetch(filter);
    if (intervalFetchRef.current) {
      clearInterval(intervalFetchRef.current);
    }
    intervalFetchRef.current = setInterval(() => {
      localFetch(filter);
    }, 60 * 1000 * 1);
    localFetch(filter);
    return () => {
      if (intervalFetchRef.current) {
        clearInterval(intervalFetchRef.current);
      }
    };
  }, [filter]);

  useEffect(() => {
    if (andonRef.current) {
      andonRef.current.addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
          setCurrentPage(1);
          setAndonRunning(true);
        } else {
          setAndonRunning(false);
        }
      });
    }
  }, [andonRef.current]);

  const equipmentsPerPage = 4;
  const PagesToRender = useMemo(
    () => [
      <OccurrencesParetoPage
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />,
      ...Array.from(
        Array(Math.ceil(equipments.length / equipmentsPerPage)).keys()
      ).map((value) => (
        <EquipmentSummaryPage
          id={`sumary-${value}`}
          equipments={equipments}
          equipmentsProductionStatus={equipmentsProductionStatus}
          equipmentIds={(filter.equipments.length > 0
            ? filter.equipments
            : equipments
          )
            .slice(
              value * equipmentsPerPage,
              value * equipmentsPerPage + equipmentsPerPage
            )
            .map(({ id }) => id)}
          equipmentsStatus={equipmentsStatus}
          equipmentSummary={productionSummary}
        />
      )),
      ...Array.from(
        Array(Math.ceil(equipmentsProduction.length / equipmentsPerPage)).keys()
      ).map((value) => {
        const slice = equipmentsProduction.slice(
          value * equipmentsPerPage,
          value * equipmentsPerPage + equipmentsPerPage
        );
        return (
          <EquipmentProductionPage
            id={`production-${value}`}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            localProduction={slice}
          />
        );
      }),
    ],
    [
      equipments,
      equipmentsProduction,
      equipmentsProductionStatus,
      equipmentsStatus,
      productionSummary,
      containerHeight,
      containerWidth,
    ]
  );

  useEffect(() => {
    if (andonRunning) {
      carouselAnimationIntervalRef.current = setInterval(() => {
        setCurrentPage((prev) =>
          prev < PagesToRender.length ? ++prev : (prev = 1)
        );
      }, 20000);
      return () => {
        if (carouselAnimationIntervalRef.current) {
          clearInterval(carouselAnimationIntervalRef.current);
        }
      };
    } else {
      if (carouselAnimationIntervalRef.current) {
        clearInterval(carouselAnimationIntervalRef.current);
      }
    }
  }, [andonRunning, PagesToRender]);

  return (
    <div ref={andonRef} className={style.container}>
      {andonRunning ? (
        <div className={style.carouselContainer}>
          {PagesToRender.map((Page, index) => (
            <CarouselPage
              pageNumber={index + 1}
              currentPageNumber={currentPage}
              lastPageNumber={PagesToRender.length - 1}
            >
              {Page}
            </CarouselPage>
          ))}
        </div>
      ) : (
        <div className={style.startMessage}>
          <h3>
            {t('andonPage:startMessageText1', {
              defaultValue:
                'Pressione a tecla F11 ou clique no botão abaixo para iniciar o painel informativo.',
            })}
          </h3>
          <p>
            {t('andonPage:startMessageText2', {
              defaultValue: 'Para finalizar, basta apertar a tecla ESC.',
            })}
          </p>
          <Button
            onClick={() => {
              andonRef.current?.requestFullscreen();
            }}
          >
            {t('andonPage:start', { defaultValue: 'Iniciar' })}
          </Button>
        </div>
      )}
    </div>
  );
};
