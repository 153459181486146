import { useCallback, useEffect, useState } from 'react';
import { TenantContext } from './TenantContext';
import { TenantState, Client } from './models';
import Api from '../../helper/Api';
import { useToast } from '../../hooks/toast';
import { useHistory, useLocation } from 'react-router-dom';
import { ClientSelection } from '../ClientSelectionPage';
import { useTranslation } from 'react-i18next';

export const TenantProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const [data, setData] = useState<TenantState>();
  const { t } = useTranslation();

  useEffect(() => {
    const regexResult = /.*\/client\/([^\/]*)/.exec(pathname);
    if (regexResult?.length === 2) {
      const urlTenant = regexResult[1];
      const stringfiedClient = localStorage.getItem('@medianeira:client');
      if (stringfiedClient) {
        const parsedClient = JSON.parse(stringfiedClient);
        if (parsedClient.tenant === urlTenant) {
          setData({ client: JSON.parse(stringfiedClient) });
        } else {
          getClient(urlTenant);
        }
      } else {
        getClient(urlTenant);
      }
    } else {
      const stringfiedClient = localStorage.getItem('@medianeira:client');
      if (stringfiedClient) {
        setData({ client: JSON.parse(stringfiedClient) });
      }
    }
  }, []);

  useEffect(() => {
    if (data?.client) {
      // replace(`/client/${data.client.tenant}/login`);
    } else if (data) {
      replace('/client');
    }
  }, [data]);

  const setClient = useCallback((client: Client) => {
    setData((prev) => ({ ...prev, client }));
    localStorage.setItem('@medianeira:client', JSON.stringify(client));
  }, []);

  const getClient = useCallback(async (tenant: string) => {
    try {
      const api = new Api();
      const result = await api.get<Client>(
        `/api/management/clients/account/verify/${tenant.toLocaleLowerCase()}`
      );
      setClient(result);
    } catch (err) {
      addToast({ title: t('userConfigPage:errorFindingClient', { defaultValue: 'Erro ao encontrar cliente.' }), type: 'error' });
      clearClient();
    }
  }, []);

  const clearClient = useCallback(() => {
    setData({});
    localStorage.removeItem('@medianeira:client');
  }, []);

  const forgotPassword = useCallback(
    async ({ email, tenant }: { email: string; tenant: string }) => {
      const api = new Api();
      await api.post<{ success: boolean }, { email: string }>(
        `/api/client/${tenant.toLocaleLowerCase()}/users/access/forgot`,
        { email }
      );
    },
    []
  );
  return (
    <TenantContext.Provider
      value={{
        client: data?.client,
        clearClient,
        setClient,
        getClient,
        forgotPassword,
      }}
    >
      {data?.client?.tenant ? children : <ClientSelection />}
    </TenantContext.Provider>
  );
};
