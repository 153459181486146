import { Route, Switch, Redirect } from 'react-router';
import { Login } from '../Login';
import { SignedInPages } from '../SignedInPages';

export const Routes: React.FC = () => {
  return (
    <div>
      <div>
        <Switch>
          <Route exact path='/management'>
            <Redirect to='/management/login' />
          </Route>
          <Route exact path='/management/login'>
            <Login />
          </Route>
          <Route path='/management/s/*' component={SignedInPages} />
        </Switch>
      </div>
    </div>
  );
};
