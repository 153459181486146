import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { TenantLogin } from '../Login';
import { SignedInPages } from '../SignedIn';

interface ClientMatchProps extends RouteComponentProps<{ tenant: string }> {}

export const TenantRoutes: React.FC = () => {
  return (
    <div id='root-tenant' style={{ width: '100%' }}>
      <Switch>
        <Route
          exact
          path='/client/:tenant'
          render={({
            match: {
              params: { tenant },
            },
          }: ClientMatchProps) => <Redirect to={`/client/${tenant}/login`} />}
        />
        <Route
          path='/client/:tenant/login'
          render={({
            match: {
              params: { tenant },
            },
          }: ClientMatchProps) => {
            return <TenantLogin tenant={tenant} />;
          }}
        />
        <Route path='/client/:tenant/*' component={SignedInPages} />
        <Route path='*' render={() => <Redirect to='/client' />} />
      </Switch>
    </div>
  );
};
