import React, { useEffect } from 'react';
import './style.css';
import { useLocation } from 'react-router';
import { useToast } from '../../../hooks/toast';
import AppError from '../../../helper/AppError';
import ManagerModel from '../ManagerListPages/ManagerModel';
import { useManagementAuth } from '../../AuthControl';

const ManagerDetails: React.FC = () => {
  const { addToast } = useToast();
  const { manager, signOut, api } = useManagementAuth();
  const { pathname } = useLocation();
  const managerId = pathname.split('/').slice(-1).join('');
  useEffect(() => {
    if (managerId && managerId !== 'new') {
      api
        .get<ManagerModel>(`/api/management/managers/profile/${manager?.id}`)
        .then((manager) => {
          //TODO: show manager
        })
        .catch((error) => {
          if (error instanceof AppError) {
            if (error.statusCode === 401) {
              addToast({
                type: 'error',
                title: 'Sessão expirada',
                description: 'Faça login novamente.',
              });
              signOut();
            }
          }
        });
    }
  }, [managerId]);
  return <form id='manager-form'>{/* <Input /> */}</form>;
};

export default ManagerDetails;
