import React, { useEffect, useState } from 'react';
import style from './OrderPage.module.css';
import { Input } from '../../../../../Components/Atoms/Input';
import { Group, Order, Product, statusKey } from '../../Controls/DataControl/models';
import { Select } from '../../../../../Components/Atoms/Input/Select';
import { useTenant } from '../../../../TenantControl';
import { useAuth } from '../../../../AuthControl';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../Components/Atoms/Button';
import { useToast } from '../../../../../hooks/toast';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AppError from '../../../../../helper/AppError';
import { ConfirmDeleteOrderModal } from './ConfirmDeleteOrderModal';

interface Props {
    newOrder?: boolean;
}

interface Option {
    name: string;
    id: string;
}

function dateFormat(date: Date): string {
    const pad = (number: number) => (number < 10 ? '0' + number : number);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
    const day = pad(date.getDate());
    const hour = pad(date.getHours());
    const minute = pad(date.getMinutes());
    const second = pad(date.getSeconds());

    return `${year}${month}${day}${hour}${minute}${second}`;
}

export const OrderPage = ({ newOrder }: Props): React.ReactElement => {
    const { api } = useAuth();
    const { client } = useTenant();
    const { t } = useTranslation();
    const { push } = useHistory();
    const { addToast } = useToast();
    const [groupsList, setGroupsList] = useState<Option[]>();
    const [productsList, setProductsList] = useState<Option[]>([]);
    const [group, setGroup] = useState<string>();
    const [groupId, setGroupId] = useState<string>();
    const [product, setProduct] = useState<string>();
    const [productId, setProductId] = useState<string>();
    const [codeText, setCodeText] = useState<string>(newOrder ? dateFormat(new Date()) : '');
    const [status, setStatus] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const [waitDeleteConfirmation, setWaitDeleteConfirmation] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>();
    const [visible, setVisible] = useState<boolean>(newOrder ? true : false);
    const { params: { id: editId } } = useRouteMatch<{ id: string }>();

    const fetchGroups = async () => {
        const result = await api.get<Group[]>(
            `/api/client/${client?.tenant}/registrations/equipments/groups`
        ).then(result => result.map(group => {
            return {
                name: group.name,
                id: group.id
            }
        }));
        setGroupsList(result);
    }

    const fetchProducts = async () => {
        const result = await api.get<Product[]>(
            `/api/client/${client?.tenant}/registrations/products`
        ).then(result => result.map(product => {
            return {
                name: product.completeName,
                id: product.id
            }
        }))
        setProductsList(result);
    }

    useEffect(() => {
        if (newOrder) return;
        api.get<Order>(
            `/api/client/${client?.tenant}/registrations/orders/${editId}`
        ).then((order) => {
            setGroupId(order.group);
            setProductId(order.product);
            setProduct(order.productName);
            setGroup(order.groupName);
            setCodeText(order.codeText);
            //setPackageCount(order.packageCount);
            setStatus(order.status);
            setCount(order.count);
        }).catch((e: AppError) => {
            addToast({
                title: t('orderPage:orderNotFound', { defaultValue: 'Erro ao ler ordem' }),
                type: 'error'
            });
            push(`/client/${client?.tenant}/admin/ordens`);
        })
    }, [newOrder])

    useEffect(() => {
        fetchGroups();
        fetchProducts();
    }, []);

    useEffect(() => {
        if (productsList && product) {
            const filtered = productsList.filter(p =>
                p.name && p.name.toLowerCase().includes(product.toLowerCase())
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]);
        }
    }, [product, productsList]);

    const createOrder = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await api.post(
                `/api/client/${client?.tenant}/registrations/orders`,
                {
                    product: productId,
                    group: groupId,
                    //packageCount,
                    codeText: codeText,
                    status: status,
                    count
                }
            );
            addToast({ title: t('orderPage:orderCreated', { defaultValue: 'Ordem criada com sucesso' }), type: 'success' });
            push(`/client/${client?.tenant}/admin/ordens`);
        } catch (e) {
            addToast({ title: t('orderPage:addingOrderProblem', { defaultValue: 'Problema ao adicionar ordem' }), type: 'error' });
        }
    }

    const updateOrder = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await api.put(
                `/api/client/${client?.tenant}/registrations/orders`,
                {
                    id: editId,
                    status: status,
                }
            );
            addToast({ title: t('orderPage:orderUpdated', { defaultValue: 'Ordem atualizada com sucesso' }), type: 'success' });
            push(`/client/${client?.tenant}/admin/ordens`);
        } catch (e) {
            addToast({ title: t('orderPage:updatingOrderProblem', { defaultValue: 'Problema ao atualizar ordem' }), type: 'error' });
        }
    }

    return (
        <>
            <form className={style.container} onSubmit={(e) => newOrder ? createOrder(e) : updateOrder(e)}>
                {waitDeleteConfirmation && editId && (
                    <ConfirmDeleteOrderModal
                        handleClose={(success) => {
                            setWaitDeleteConfirmation(false);
                            if (success) {
                                push(`/client/${client?.tenant}/admin/ordens`);
                            }
                        }}
                        tenant={client?.tenant || ''}
                        orderId={editId}
                    />
                )}
                <div className={style.inputs} >
                    <Select
                        id='group'
                        disabled={!newOrder}
                        options={groupsList?.map(group => group.name) ?? []}
                        value={group ?? groupId ?? ''}
                        noEmpty={newOrder ? false : true}
                        label={t('orderPage:group', { defaultValue: 'Grupo' })}
                        onChange={(value) => {
                            const foundGroup = groupsList?.find(group => group.name === value);
                            if (foundGroup) {
                                setGroup(foundGroup.name);
                                setGroupId(foundGroup.id);
                            }
                        }}
                        required
                    />
                    <div>
                        <Input
                            label={t('orderPage:product', { defaultValue: 'Produto' })}
                            disabled={!newOrder}
                            value={product ?? productId}
                            onChange={value => {
                                const foundProduct = productsList.find(({ name }) => name === value);
                                setProduct(value);
                                setProductId(foundProduct?.id ?? '');
                            }}
                            onClick={() => setVisible(true)}
                            placeholder={t('orderPage:productPlaceholder', { defaultValue: 'Digite o nome do produto' })}
                            required
                        />
                        {visible && productsList && filteredData && filteredData.length > 0 && (
                            <ul className={style.suggestions}>
                                {filteredData.map(({ name, id }) => (
                                    <li key={id} onClick={() => {
                                        setProduct(name);
                                        setProductId(id);
                                        setVisible(false);
                                    }}>
                                        {name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <Input
                        label={t('orderPage:orderCode', { defaultValue: 'Código da Ordem' })}
                        disabled={!newOrder}
                        onChange={value => setCodeText(value)}
                        value={codeText}
                        required
                    />
                    {/* <Input
                        label={t('orderPage:packageCount', { defaultValue: 'Contagem de Pacotes' })}
                        disabled={!newOrder}
                        value={packageCount?.toString() ?? ''}
                        onChange={value => setPackageCount(parseInt(value))}
                        required
                    /> */}
                    <Input
                        label={t('orderPage:count', { defaultValue: 'Quantidade de Fardos' })}
                        disabled={!newOrder}
                        onChange={value => setCount(parseInt(value))}
                        value={count}
                        required
                    />
                    <Select
                        id='status'
                        label={t('orderPage:status', { defaultValue: 'Status' })}
                        noEmpty={true}
                        disabled={newOrder}
                        options={statusKey}
                        value={newOrder ? statusKey[1] : statusKey[status]}
                        onChange={value => setStatus(newOrder ? 1 : statusKey.indexOf(value).toString())}
                        required
                    />
                    <div className={style.buttons}>
                        <Button
                            secondary
                            danger
                            onClick={() => push(`/client/${client?.tenant}/admin/ordens`)}
                        >
                            {t('productPage:cancel', { defaultValue: 'Cancelar' })}
                        </Button>
                        {!newOrder && (
                            <>
                                <Button danger onClick={() => setWaitDeleteConfirmation(true)}>
                                    {t('productPage:delete', { defaultValue: 'Excluir' })}
                                </Button>
                            </>
                        )}
                        <Button type='submit'>
                            {!newOrder ? t('productPage:save', { defaultValue: 'Salvar' }) : t('productPage:add', { defaultValue: 'Adicionar' })}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    )
}
