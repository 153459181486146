import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useManagementAuth } from '../../AuthControl';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}
export const ManagementRoute: React.FC<RouteProps> = ({
  isPrivate,
  component: Component,
  ...rest
}) => {
  const { manager } = useManagementAuth();
  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (!isPrivate) return <Component />;
        if (isPrivate && !!manager) return <Component />;
        return <Redirect to='/management/login' />;
      }}
    />
  );
};
