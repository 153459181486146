import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from './hooks/toast';
import './index.css';
import { Routes } from './routes';
import './i18n';
import { LoadingState } from './Client/TenantPages/SignedIn/Components/Cards/LoadingState';

function App() {
  return (
    <Suspense fallback={<LoadingState />}>
      <ToastProvider>
        <Router>
          <Routes />
        </Router>
      </ToastProvider>
    </Suspense>
  );
}

export default App;
