import React, { useEffect, useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { useToast } from '../../../../../hooks/toast';
import { ConfirmDeleteOperatorModal } from './ConfirmDeleteOperatorModal';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { Operator } from '../../Controls/DataControl/models';
import style from './OperatorsListPage.module.css';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { useTranslation } from 'react-i18next';

export const OperatorsListPage = (): React.ReactElement => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const { api } = useAuth();
  const { client } = useTenant();
  const [operatorToDelete, setOperatorToDelete] = useState<Operator>();
  const [operators, setOperators] = useState<Operator[]>([]);
  const { t } = useTranslation();

  const fetchOperators = async () => {
    try {
      const result = await api.get<Operator[]>(
        `/api/client/${client?.tenant}/registrations/operators`
      );
      setOperators(result);
    } catch (_) {
      addToast({ title: t('operatorPage:problemListingOperators', { defaultValue: 'Problema ao listar operadores' }), type: 'error' });
    }
  };
  useEffect(() => {
    fetchOperators();
    const pollingOperators = setInterval(() => {
      fetchOperators();
    }, 10000);
    return () => {
      clearInterval(pollingOperators);
    };
  }, []);
  return (
    <div className={style.container}>
      {operatorToDelete && (
        <ConfirmDeleteOperatorModal
          tenant={client?.tenant || ''}
          operatorId={operatorToDelete.id}
          operatorName={operatorToDelete.name}
          handleClose={(success) => {
            setOperatorToDelete(undefined);
            if (success) {
              fetchOperators();
            }
          }}
        />
      )}
      <Button
        onClick={() => push(`${location.pathname}/novo`)}
        icon={<FiPlus />}
      >
        {t('operatorPage:operator', { defaultValue: 'Operador' })}
      </Button>
      <ul className={`${style.productsList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.column} ${style.nameColumn}`}>{t('operatorPage:name', { defaultValue: 'Nome' })}</div>
          <div className={`${style.column} ${style.codeColumn}`}>{t('operatorPage:code', { defaultValue: 'Código' })}</div>
          <div className={`${style.column} ${style.actionsColumn}`}></div>
        </li>
        {operators.map(({ id, name, code }) => (
          <li key={id} className={style.row}>
            <div className={`${style.column} ${style.nameColumn}`}>{name}</div>
            <div className={`${style.column} ${style.codeColumn}`}>{code}</div>
            <div className={`${style.column} ${style.actionsColumn}`}>
              <Tooltip tooltip={t('operatorPage:edit', { defaultValue: 'Editar' })}>
                <Link to={`${location.pathname}/${code}`}>
                  <AiOutlineEdit />
                </Link>
              </Tooltip>
              <Tooltip tooltip={t('operatorPage:delete', { defaultValue: 'Excluir' })}>
                <div>
                  <AiOutlineDelete
                    onClick={() => setOperatorToDelete({ id, name, code })}
                  />
                </div>
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
