import { useCallback, useEffect, useRef, useState } from 'react';
import style from './ProductionHeatmapChart.module.css';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import { useResponsiveContainer } from '../../../Controls/ResponsiveContainerControl/UserResponsiveContainer';
import { ChartOptionsLegend } from '../ChartOptionsLegend';
import { format, startOfYear } from 'date-fns';
import { LoadingState } from '../LoadingState';
import { EmptyState } from '../EmptyState';
import { Filter } from '../../../Controls/FilterControl/models';
import { AxisTitleConfiguration } from '../../../../../../helper/AxisTitleConfiguration';
import { formatNumberToNotation } from '../../../../../../helper/FormatNumberToNotation';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../LocaleControl';
import { getDateFnsLocation } from '../../../../../../helper/GetDateFnsLocation';

export const ProductionHeatmapChart = (): React.ReactElement => {
  const { filter } = useFilter();
  const { fetchProductionHeatmap } = useData();
  const { containerWidth } = useResponsiveContainer();
  const [options, setOptions] = useState<ApexOptions>();
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const [loading, setLoading] = useState(true);
  const abortFetchRef = useRef<() => void>();
  const { t } = useTranslation();
  const { chartOptionsLocale } = useLocale();
  const FALLBACK_LANGUAGE = 'pt_BR';

  useEffect(() => {
    setOptions({
      ...ChartOptionsLegend,

      chart: {
        ...chartOptionsLocale,
        type: 'heatmap',
        toolbar: { show: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        heatmap: {
          distributed: true,
          enableShades: true,
        },
      },
      xaxis: {
        title: AxisTitleConfiguration(t('cardsPage:day', { defaultValue: 'Dia' })),
      },
      yaxis: {
        title: AxisTitleConfiguration(t('cardsPage:month', { defaultValue: 'Mês' })),
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#27dc94'],
      tooltip: {
        y: {
          formatter: (val) => `${formatNumberToNotation(val)} un`,
        },
        x: {
          formatter: (val) => `${t('cardsPage:day', { defaultValue: 'dia' })} ${val}`,
        },
      },
    });
  }, [t]);

  const localFetch = useCallback(
    (localFilter: Filter) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      const heatMapFilter: Filter = {
        ...localFilter,
        customStartDate: startOfYear(new Date()),
      };

      const { abort, fetch } = fetchProductionHeatmap();
      abortFetchRef.current = abort;
      fetch(heatMapFilter).then((result) => {
        setSeries(
          Array.from({ length: 12 }, (_, i) => 12 - i).map((heatmapMonth) => {
            return {
              name: format(new Date(0, heatmapMonth, 0), 'MMM', {
                locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
              }),
              data: Array.from({ length: 31 }, (_, i) => i + 1).map(
                (heatmapDay) =>
                  result
                    .filter(
                      ({ day, month }) =>
                        day === heatmapDay && month === heatmapMonth
                    )
                    .reduce((acc, curr) => acc + curr.count, 0)
              ),
            };
          })
        );
        setLoading(false);
      });
    },
    [fetchProductionHeatmap, setSeries, filter]
  );

  useEffect(() => {
    localFetch(filter);
  }, [localFetch, setLoading, filter]);

  useEffect(() => {
    return () => {
      setSeries([]);
      setOptions({});
    };
  }, [setOptions, setSeries]);
  return (
    <div className={style.chartContainer}>
      <h2>{t('cardsPage:longRunProduction', { defaultValue: 'Produção de Longo Prazo' })}</h2>
      {loading ? (
        <LoadingState />
      ) : options && series.length > 0 ? (
        <Chart
          type='heatmap'
          options={options}
          series={series}
          height={containerWidth > 1200 ? 350 : 300}
        />
      ) : (
        <EmptyState />
      )}
    </div>
  );
};
