import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { EmptyState } from '../Components/Cards/EmptyState';
import { Production } from '../Controls/DataControl/models';
import { MultiSeriesChartPalette } from '../../../../helper/ChartsColorPalette';
import style from './AndonPages.module.css';
import { formatNumberToNotation } from '../../../../helper/FormatNumberToNotation';
import { ConvertValueToBestUnit } from '../../../../helper/ConvertValueToBestUnit';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../LocaleControl';

interface Props {
  containerWidth: number;
  containerHeight: number;
  localProduction: Production[];
  id: string | number;
}

export const EquipmentProductionPage = ({
  localProduction,
  id,
}: Props): React.ReactElement => {
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const { t } = useTranslation();
  const { chartOptionsLocale } = useLocale();

  useEffect(() => {
    if (localProduction) {
      if (localProduction.length) {
        setSeries([
          {
            name: t('andonPage:sumOfProduction', {
              defaultValue: 'Somatório da produção',
            }).toUpperCase(),
            data: localProduction
              .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
              .map(({ sum, unit, equipment }) => {
                return {
                  x: equipment.name,
                  y: sum,
                  meta: {
                    unit: [undefined, null, ''].includes(unit) ? 'g' : unit,
                  },
                };
              }),
          },
        ]);
      } else {
        setSeries([]);
      }
      setOptions({
        responsive: [
          {
            breakpoint: 1921,
            options: {
              xaxis: {
                title: {
                  text: t('andonPage:weight', { defaultValue: 'Peso' }),
                  offsetY: 92,
                  style: { fontSize: '1.175em', fontWeight: 400 },
                },
                labels: {
                  offsetY: 10,
                  style: { fontSize: '1.15em' },

                  formatter: (val: string) =>
                    formatNumberToNotation(parseFloat(val) / 1000),
                },
              },
              yaxis: {
                labels: {
                  align: 'left',
                  minWidth: 250,
                  maxWidth: 1000,
                  style: { fontSize: '1.35em' },
                },
              },
            },
          },
          {
            breakpoint: 1400,
            options: {
              xaxis: {
                title: {
                  text: t('andonPage:weight', { defaultValue: 'Peso' }),
                  offsetY: 48,
                  style: { fontSize: '1.175em', fontWeight: 400 },
                },
                labels: {
                  offsetY: 5,
                  style: { fontSize: '1.15em' },

                  formatter: (val: string) =>
                    formatNumberToNotation(parseFloat(val)),
                },
              },
              yaxis: {
                labels: {
                  align: 'left',
                  minWidth: 180,
                  maxWidth: 700,
                  style: { fontSize: '1.35em' },
                },
              },
            },
          },
        ],
        legend: {
          show: false,
        },
        chart: {
          type: 'bar',
          ...chartOptionsLocale,
        },
        xaxis: {
          type: 'numeric',
          tickPlacement: 'on',
          tickAmount: 4,
          title: {
            text: t('andonPage:weight', { defaultValue: 'Peso' }),
            style: { fontSize: '1.35em', fontWeight: 400 },
            offsetY: 150,
          },
          labels: {
            offsetY: 20,
            style: { fontSize: '1em' },

            formatter: (val) => formatNumberToNotation(parseFloat(val)),
          },
        },
        yaxis: {
          labels: {
            align: 'left',
            minWidth: 180,
            maxWidth: 2000,
            style: { fontSize: '1.35em' },
          },
        },
        colors: MultiSeriesChartPalette,
        dataLabels: {
          formatter: (val, { w, seriesIndex, dataPointIndex }) => {
            if (typeof val === 'number') {
              const dataPoint =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              const converted = ConvertValueToBestUnit(dataPoint.y);
              return `${formatNumberToNotation(converted.val)} ${converted.unit
                }`;
            }
            if (val) {
              return val.toString();
            }
            return '';
          },
          offsetX: 10,
          enabled: true,
          style: { fontSize: '1.75em', colors: ['#222'] },
          textAnchor: 'start',
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 12,
            borderColor: 'transparent',
            opacity: 1,
            dropShadow: {
              enabled: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            barHeight: '90%',
            dataLabels: {
              hideOverflowingLabels: false,
              position: 'bottom',
            },
          },
        },
      });
    }
  }, [localProduction]);

  return (
    <>
      <div className={style.pageHeader}>
        <div>
          <img src='/supervis_logo_andon.png' alt='supervis' />
        </div>
        <div className={style.pageTitle}>
          <h3>
            {t('andonPage:accumulatedUsefulProductionUppercase', {
              defaultValue: 'ACUMULADO DE PRODUÇÃO ÚTIL',
            })}
          </h3>
        </div>
      </div>
      {series.length ? (
        <Chart type='bar' options={options} series={series} height={'90%'} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
