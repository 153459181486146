import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import Checkbox from '../../../../../Components/Atoms/Checkbox';
import Modal from '../../../../../Components/Modal';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import {
  getOccurrencesBlacklist,
  saveOccurrencesBlacklist,
} from './FilterOccurrencesService';
import style from './OccurrencesFilterModal.module.css';
import { useTranslation } from 'react-i18next';

export interface Event {
  id: string;
  code: number;
  name: string;
  enabled?: boolean;
  blocked?: boolean;
  sys?: boolean;
}

interface Props {
  handleClose: () => void;
}

export const OccurrencesFilterModal = ({ handleClose }: Props) => {
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const [events, setEvents] = useState<Event[]>([]);
  const [occurrencesBlacklist, setOccurrencesBlacklist] = useState<string[]>([]);
  const { t } = useTranslation();

  const fetchEvents = async () => {
    try {
      const result = await api.get<Event[]>(
        `/api/client/${client?.tenant}/registrations/events`
      );
      setEvents(result);
    } catch (_) {
      addToast({ title: t('otherComponentsPage:problemListingEvents', { defaultValue: 'Problema ao listar eventos.' }), type: 'error' });
    }
  };

  const handleCloseModal = () => {
    handleClose();
  };

  useEffect(() => {
    setOccurrencesBlacklist(getOccurrencesBlacklist());
    fetchEvents();
  }, []);

  return (
    <Modal handleClose={handleCloseModal}>
      <div className={style.container}>
        <div className={style.header}>
          <h3>{t('otherComponentsPage:filterOccurrencesByType', { defaultValue: 'Filtrar Ocorrências Por Tipo' })}</h3>
          <div className={style.bulkSelection}>
            <Button
              textual
              danger
              compact
              onClick={() =>
                setOccurrencesBlacklist(
                  events.map(({ code }) => code.toString())
                )
              }
            >
              {t('otherComponentsPage:clear', { defaultValue: 'Limpar' })}
            </Button>
            <Button textual compact onClick={() => setOccurrencesBlacklist([])}>
              {t('otherComponentsPage:selectAll', { defaultValue: 'Selecionar todos' })}
            </Button>
          </div>
        </div>
        <div className={style.list}>
          {events
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ code, name }) => (
              <Checkbox
                label={name}
                inline
                tiny
                checked={!occurrencesBlacklist.includes(code.toString())}
                onChange={(checked) => {
                  if (checked)
                    setOccurrencesBlacklist((prev) =>
                      prev.filter((item) => item !== code.toString())
                    );
                  else
                    setOccurrencesBlacklist((prev) => [
                      ...prev,
                      code.toString(),
                    ]);
                }}
              />
            ))}
        </div>
        <div className={style.actions}>
          <Button
            danger
            onClick={() => {
              handleClose();
            }}
          >
            {t('otherComponentsPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button
            onClick={() => {
              saveOccurrencesBlacklist(occurrencesBlacklist);
              handleClose();
            }}
          >
            {t('otherComponentsPage:save', { defaultValue: 'Salvar' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
