import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Input } from '../../../../../Components/Atoms/Input';
import { RadioButtonGroup } from '../../../../../Components/Atoms/RadioButton';
import AppError from '../../../../../helper/AppError';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { ConfirmDeleteEventModal } from './ConfirmDeleteEventModal';
import style from './EventPage.module.css';
import { useTranslation } from 'react-i18next';

export interface Event {
  id: string;
  code: number;
  name: string;
  enabled?: boolean;
  blocked?: boolean;
  sys?: boolean;
  notificationLevel: number;
}

interface Props {
  newEvent?: boolean;
}

export const EventPage = ({ newEvent }: Props): React.ReactElement => {
  const { push } = useHistory();
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const [eventId, setEventId] = useState<string>();
  const [eventName, setEventName] = useState('');
  const [eventCode, setEventCode] = useState(0);
  const [eventSysType, setEventSysType] = useState(false);
  const [waitDeleteConfirmation, setWaitDeleteConfirmation] = useState(false);
  const [notificationLevel, setNotificationLevel] = useState(0);
  const {
    params: { id: editCode },
  } = useRouteMatch<{ id: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!newEvent) {
      api
        .get<Event>(
          `/api/client/${client?.tenant}/registrations/events/${editCode}`
        )
        .then((response) => {
          if (response.blocked) {
            addToast({
              title: t('eventPage:occurrenceBlockedForEditing', { defaultValue: 'Ocorrência bloqueada para edição.' }),
              type: 'error',
            });
            push(`/client/${client?.tenant}/admin/events`);
          }
          setEventCode(response.code);
          setEventId(response.id);
          setEventName(response.name);
          setEventSysType(response.sys ?? false);
          setNotificationLevel(response.notificationLevel ?? 0);
        })
        .catch((e: AppError) => {
          if (e.statusCode === 404) {
            addToast({ title: t('eventPage:occurrenceNotFound', { defaultValue: 'Ocorrência não encontrada.' }), type: 'error' });
          } else addToast({ title: t('eventPage:unexpectedError', { defaultValue: 'Erro inesperado.' }), type: 'error' });
          push(`/client/${client?.tenant}/admin/events`);
        });
    }
  }, [newEvent]);

  const handleCreateEvent = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.post(`/api/client/${client?.tenant}/registrations/events`, {
        name: eventName.trim(),
        code: eventCode,
        notificationLevel,
      });
      addToast({ title: t('eventPage:occurrenceSuccessfullyCreated', { defaultValue: 'Ocorrência criada com sucesso' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/eventos`);
    } catch (_) {
      addToast({ title: t('eventPage:errorAddingEvent', { defaultValue: 'Erro ao adicionar evento' }), type: 'error' });
    }
  };

  const handleUpdateEvent = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.put(`/api/client/${client?.tenant}/registrations/events`, {
        name: eventName.trim(),
        code: eventCode,
        id: eventId,
        notificationLevel,
        sys: eventSysType,
      });
      addToast({ title: t('eventPage:occurrenceSuccessfullyUpdated', { defaultValue: 'Ocorrência alterada com sucesso' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/eventos`);
    } catch (_) {
      addToast({ title: t('eventPage:errorUpdatingEvent', { defaultValue: 'Erro ao alterar evento' }), type: 'error' });
    }
  };
  return (
    <form
      className={style.container}
      onSubmit={(e) =>
        !newEvent ? handleUpdateEvent(e) : handleCreateEvent(e)
      }
    >
      {waitDeleteConfirmation && eventId && (
        <ConfirmDeleteEventModal
          tenant={client?.tenant || ''}
          eventId={eventId}
          eventName={eventName}
          handleClose={(success) => {
            setWaitDeleteConfirmation(false);
            if (success) {
              push(`/client/${client?.tenant}/admin/events`);
            }
          }}
        />
      )}
      <h1>{newEvent ? t('eventPage:addNewOccurrence', { defaultValue: 'Adicionar Nova Ocorrência' }) :
        t('eventPage:editName', { occurenceName: name, defaultValue: `Editar ${name}` })}
      </h1>

      <Input
        label={t('eventPage:name', { defaultValue: 'Nome' })}
        maxLength={48}
        disabled={eventSysType}
        value={eventName}
        required={true}
        onChange={setEventName}
        tooltip={t('eventPage:maximum48Characters', { defaultValue: 'Máximo de 48 caracteres' })}
      />
      <Input
        disabled={!newEvent}
        label={t('eventPage:code', { defaultValue: 'Código' })}
        type='number'
        min={1}
        required={true}
        value={eventCode}
        onChange={(value) => setEventCode(parseInt(value))}
      />
      <RadioButtonGroup
        onValueChange={(value) => {
          setNotificationLevel(parseInt(value));
        }}
        label={t('eventPage:group', { defaultValue: 'Grupo' })}
        selectedValue={notificationLevel.toString()}
        items={[
          { value: '0', label: t('eventPage:log', { defaultValue: 'Registro' }) },
          { value: '1', label: t('eventPage:info', { defaultValue: 'Informação' }) },
          { value: '2', label: t('eventPage:warning', { defaultValue: 'Aviso' }) },
          { value: '3', label: t('eventPage:error', { defaultValue: 'Erro' }) },
          { value: '4', label: t('eventPage:fatalError', { defaultValue: 'Erro Grave' }) },
        ]}
      />
      <div className={style.buttons}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/eventos`)}
        >
          {t('eventPage:cancel', { defaultValue: 'Cancelar' })}
        </Button>
        {!newEvent && (
          <Button
            danger
            disabled={eventSysType}
            onClick={() => {
              setWaitDeleteConfirmation(true);
            }}
          >
            {t('eventPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        )}
        <Button type='submit'>{!newEvent ? t('eventPage:save', { defaultValue: 'Salvar' }) : t('eventPage:add', { defaultValue: 'Adicionar' })}</Button>
      </div>
    </form>
  );
};
