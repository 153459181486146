import React from 'react';

interface LegendDotProps {
  color: string;
  width: string;
  height: string;
}

const LegendDot: React.FC<LegendDotProps> = ({ color, width, height }) => {
  const style = {
    width: width,
    height: height,
    backgroundColor: color,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
  };

  return <div style={style}></div>;
};

export default LegendDot;