import * as RadioGroup from '@radix-ui/react-radio-group';
import { Label } from '../Label';
import style from './RadioButton.module.css';
interface Props {
  label: string;
  selectedValue: string;
  items: { value: string; label: string }[];
  onValueChange: (value: string) => void;
}

export const RadioButtonGroup = ({
  label,
  items,
  selectedValue,
  onValueChange,
}: Props) => (
  <RadioGroup.Root value={selectedValue} onValueChange={onValueChange}>
    <Label value={label} />
    <div className={style.radioGroup}>
      {items.map(({ value, label }) => (
        <div className={style.radioItem}>
          <RadioGroup.Item className={style.radio} value={value} id={value}>
            <RadioGroup.Indicator className={style.indicator} />
          </RadioGroup.Item>
          <label className={`${style.label} ${style.overflowInherit}`} htmlFor={value}>
            {label}
          </label>
        </div>
      ))}
    </div>
  </RadioGroup.Root>
);
