import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { EquipmentListItem, EquipmentType } from '../../Controls/DataControl/models';
import { ConfirmDeleteEquipmentModal } from './ConfirmDeleteEquipmentModal';
import style from './EquipmentsListPage.module.css';
import { useTranslation } from 'react-i18next';

export const EquipmentsListPage = (): React.ReactElement => {
  const [equipments, setEquipments] = useState<EquipmentListItem[]>([]);
  const [max, setMax] = useState<number>();
  const [equipmentToDelete, setEquipmentToDelete] =
    useState<Pick<EquipmentListItem, 'id' | 'mid' | 'name' | 'type'>>();
  const { push } = useHistory();
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const fetchEquipments = async () => {
    try {
      const result = await api.get<{
        equipments: EquipmentListItem[];
        max: number;
      }>(`/api/client/${client?.tenant}/registrations/equipments`);
      setEquipments(result.equipments);
      setMax(result.max);
    } catch (e) {
      addToast({ title: t('equipmentPage:problemsListingEquipments', { defaultValue: 'Problemas ao listar equipamentos' }), type: 'error' });
    }
  };

  useEffect(() => {
    fetchEquipments();
    const pollingEquipments = setInterval(() => {
      fetchEquipments();
    }, 10000);
    return () => {
      clearInterval(pollingEquipments);
    };
  }, []);

  return (
    <div className={style.container}>
      {equipmentToDelete && (
        <ConfirmDeleteEquipmentModal
          equipmentId={equipmentToDelete.id}
          tenant={client?.tenant || ''}
          equipmentName={equipmentToDelete.name}
          handleClose={(success) => {
            setEquipmentToDelete(undefined);
            if (success) {
              fetchEquipments();
            }
          }}
        />
      )}
      <div className={style.pageHeader}>
        <Button
          onClick={() => push(`${location.pathname}/novo`)}
          icon={<FiPlus />}
          disabled={(max ?? 0) <= equipments.length}
        >
          {t('equipmentPage:equipment', { defaultValue: 'Equipamento' })}
        </Button>
        <h2 data-max={(max ?? 0) <= equipments.length}>{`${equipments.length}/${max ?? 0
          }`}</h2>
      </div>
      <ul className={`${style.equipmentList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.column} ${style.nameColumn}`}>{t('equipmentPage:name', { defaultValue: 'Nome' })}</div>
          <div className={`${style.column} ${style.typeColumn}`}>{t('equipmentPage:type', { defaultValue: 'Tipo' })}</div>
          <div className={`${style.column} ${style.actionsColumn}`}></div>
        </li>
        {equipments.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name, type, mid }) => (
          <li key={id} className={style.row}>
            <div className={`${style.column} ${style.nameColumn}`}>{name}</div>
            <div className={`${style.column} ${style.typeColumn}`}>
              {
                {
                  [EquipmentType.TCPIP]: 'TCP / IP',
                  [EquipmentType.RS485]: 'RS485',
                }[type]
              }
            </div>
            <div className={`${style.column} ${style.actionsColumn}`}>
              <Tooltip tooltip={t('equipmentPage:edit', { defaultValue: 'Editar' })}>
                <Link to={`${location.pathname}/${id}`}>
                  <AiOutlineEdit />
                </Link>
              </Tooltip>
              <Tooltip tooltip={t('equipmentPage:delete', { defaultValue: 'Excluir' })}>
                <div>
                  <AiOutlineDelete
                    onClick={() =>
                      setEquipmentToDelete({ id, name, type, mid })
                    }
                  />
                </div>
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
