import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import { EmptyState } from '../EmptyState';
import { OccurrencesTile } from './OccurrencesTile';
import style from './LastOccurrencesCard.module.css';
import { Filter } from '../../../Controls/FilterControl/models';
import { FiFilter } from 'react-icons/fi';
import { LoadingState } from '../LoadingState';
import { useTranslation } from 'react-i18next';

interface Props {
  occurrencesBlacklist: string[];
  showOccurrencesFilter: () => void;
}

export const LastOccurrencesCard = ({
  occurrencesBlacklist,
  showOccurrencesFilter,
}: Props): React.ReactElement => {
  const { filter } = useFilter();
  const { fetchEvents } = useData();
  const abortFetchRef = useRef<() => void>();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<
    {
      equipment: string;
      code: string;
      startDate: Date;
      endDate?: Date;
      description: string;
      duration: number;
      factor: number;
    }[]
  >([]);
  const { t } = useTranslation();
  const localFetch = useCallback(
    async (localFilter: Filter, localOccurrencesBlacklist: string[]) => {
      setLoading(true);
      try {
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }
        const { abort, fetch } = fetchEvents(localOccurrencesBlacklist);
        abortFetchRef.current = abort;
        const response = await fetch(localFilter);
        setEvents(
          response
            .reduce<
              {
                equipment: string;
                startDate: Date;
                endDate?: Date;
                description: string;
                duration: number;
                factor: number;
                code: string;
              }[]
            >(
              (acc, curr) => [
                ...acc,
                ...curr.events.map((event) => ({
                  equipment: curr.equipment.name,
                  ...event,
                  code: event.code?.toString() ?? '',
                })),
              ],
              []
            )
            .sort((a, b) => {
              if (a.endDate && b.endDate) {
                return b.endDate.getTime() - a.endDate.getTime();
              } else if (!a.endDate && b.endDate) {
                return -1;
              } else if (a.endDate && !b.endDate) {
                return 1;
              }
              return 0;
            })
        );
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [abortFetchRef, fetchEvents, setEvents]
  );

  useEffect(() => {
    localFetch(filter, occurrencesBlacklist);
  }, [filter, occurrencesBlacklist]);

  useEffect(() => {
    return () => {
      setEvents([]);
    };
  }, [setEvents]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2>{t('cardsPage:lastOccurrences', { defaultValue: 'Últimas Ocorrências' })}</h2>
        <FiFilter onClick={() => showOccurrencesFilter()} />
      </div>
      <div className={style.tilesContainer}>
        {loading ? (
          <LoadingState />
        ) : events.length ? (
          events.slice(0, 5).map((item, index) => {
            return (
              <OccurrencesTile
                key={index}
                equipment={item.equipment}
                label={item.description}
                startDate={item.startDate}
                endDate={item.endDate}
                code={item.code}
              />
            );
          })
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};
