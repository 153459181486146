import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProductionSummary } from '../../../Controls/DataControl/models';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import style from './ProductionSummary.module.css';
import convert from 'convert-units';
import { Filter } from '../../../Controls/FilterControl/models';
import { formatNumberToNotation } from '../../../../../../helper/FormatNumberToNotation';
import { ConvertValueToBestUnit } from '../../../../../../helper';
import { useTranslation } from 'react-i18next';

export const ProductionSummaryChart = (): React.ReactElement => {
  const { fetchEquipmentStatus, fetchProductionSummary, fetchTotalPackages } = useData();
  const { filter } = useFilter();
  const [data, setData] = useState<ProductionSummary[]>([]);
  const [packages, setPackages] = useState<number>(0);
  const abortFetchRef = useRef<() => void>();
  const abortPackageRef = useRef<() => void>();
  const { t } = useTranslation();

  const localFetch = useCallback(
    (localFilter: Filter) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }

      const { abort, fetch } = fetchProductionSummary();
      abortFetchRef.current = abort;
      fetch(localFilter).then((response) => {
        setData(response);
      });

    },
    [fetchEquipmentStatus, setData]
  );

  const fetchLocalPackages = useCallback(
    (localFilter: Filter) => {
      if (abortPackageRef.current) {
        abortPackageRef.current();
        abortPackageRef.current = undefined;
      }

      const { abort, fetch } = fetchTotalPackages();
      abortPackageRef.current = abort;
      fetch(localFilter).then(({ data }) => {
        setPackages(data.totalPackages);
      });
    },
    [fetchEquipmentStatus, setData]
  );

  useEffect(() => {
    localFetch(filter);
    fetchLocalPackages(filter);
  }, [filter]);

  useEffect(() => {
    return () => {
      setData([]);
      setPackages(0);
    };
  }, [setData]);

  let totalWeight = ConvertValueToBestUnit(
    data.reduce<number>((acc, curr) => acc + curr.totalWeight ?? 0, 0)
  );
  if (totalWeight.val > 1000 && totalWeight.unit === 'g') {
    totalWeight.val = totalWeight.val / 1000;
    totalWeight.unit = 'kg';
  }
  if (totalWeight.val > 1000 && totalWeight.unit === 'kg') {
    totalWeight.val = totalWeight.val / 1000;
    totalWeight.unit = 't';
  }
  const valids = data.reduce<number>(
    (acc, curr) => acc + (curr.approvedCount ?? 0),
    0
  );
  const rejSup = data.reduce<number>(
    (acc, curr) => acc + (curr.rejectedSuperiorCount ?? 0),
    0
  );
  const rejInf = data.reduce<number>(
    (acc, curr) => acc + (curr.rejectedInferiorCount ?? 0),
    0
  );
  const totalAmount = data.reduce<number>((acc, curr) => acc + curr.count, 0);

  const totalPackages = packages ?? 0;

  return (
    <div className={style.container}>
      <h2>{t('cardsPage:accumulators', { defaultValue: 'Acumuladores' })}</h2>
      <div className={style.dataContainer}>
        <table>
          <tbody>
            <div style={{marginBottom: "0.75rem"}}>
              <tr>
                <td>
                  <h4>{t('cardsPage:totalColon', { defaultValue: 'Total:' })}</h4>
                </td>
                <th>
                  <strong>{`${formatNumberToNotation(totalWeight.val)} ${totalWeight.unit
                    }`}</strong>
                </th>
              </tr>
              <tr>
                <td>
                  <h4>{t('cardsPage:totalPackagesColon', { defaultValue: 'Fardos:' })}</h4>
                </td>
                <th>
                  <strong>{`${totalPackages} un`}</strong>
                </th>
              </tr>
            </div>
            <tr>
              <td>
                <div className={style.leftColumn}>
                  <h4>{t('cardsPage:usefulColon', { defaultValue: 'Úteis:' })}</h4>
                  <strong className={style.percentage} data-success>
                    {`${totalAmount
                      ? ((valids / totalAmount) * 100).toFixed(2)
                      : 0
                      } %`}
                  </strong>
                </div>
              </td>
              <th data-success>
                <strong>{`${formatNumberToNotation(valids)} un`}</strong>
              </th>
            </tr>
            <tr>
              <td>
                <div className={style.leftColumn}>
                  <h4>{t('cardsPage:superiorRejColon', { defaultValue: 'Rej. Superior:' })}</h4>
                  <strong className={style.percentage} data-error>{`${totalAmount ? ((rejSup / totalAmount) * 100).toFixed(2) : 0
                    } %`}</strong>
                </div>
              </td>
              <th data-error>
                <strong>{`${formatNumberToNotation(rejSup)} un`}</strong>
              </th>
            </tr>
            <tr>
              <td>
                <div className={style.leftColumn}>
                  <h4>{t('cardsPage:inferiorRejColon', { defaultValue: 'Rej. Inferior:' })}</h4>
                  <strong className={style.percentage} data-error>{`${totalAmount ? ((rejInf / totalAmount) * 100).toFixed(2) : 0
                    } %`}</strong>
                </div>
              </td>
              <th data-error>
                <strong>{`${formatNumberToNotation(rejInf)} un`}</strong>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
