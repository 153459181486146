import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ResponsiveContainerContext } from './ResponsiveContainerContext';

export const ResponsiveContainerProvider = ({
  children,
}: PropsWithChildren<{}>): React.ReactElement => {
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      if (
        containerRef.current?.offsetWidth &&
        containerRef.current?.offsetWidth > 1200
      )
        setContainerWidth(containerRef.current?.offsetWidth);

      setContainerHeight(containerRef.current?.offsetHeight);
      const handleResize = () => {
        setContainerHeight(containerRef.current?.offsetHeight);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [containerRef.current]);

  return (
    <div ref={containerRef}>
      <ResponsiveContainerContext.Provider
        value={{
          containerWidth: containerWidth ?? 0,
          containerHeight: containerHeight ?? 0,
        }}
      >
        {children}
      </ResponsiveContainerContext.Provider>
    </div>
  );
};
