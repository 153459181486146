import React, { useState } from 'react';
import { Button } from '../../Components/Atoms/Button';
import { Input } from '../../Components/Atoms/Input';
import * as Yup from 'yup';
import { useToast } from '../../hooks/toast';
import style from './ClientSelection.module.css';
import { useTenant } from '../TenantControl';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ClientSelection: React.FC = () => {
  const [tenant, setTenant] = useState('');
  const [errors, setErrors] = useState<{ [keys: string]: string[] }>();
  const { addToast } = useToast();
  const { getClient, client } = useTenant();
  const { push } = useHistory();
  const { t, i18n } = useTranslation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const schema = Yup.object().shape({
      tenant: Yup.string().required(t('userConfigPage:clientIdRequired', { defaultValue: 'ID do cliente é obrigatório' })),
    });
    try {
      await schema.validate({ tenant }, { abortEarly: false });
      await getClient(tenant);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors(
          error.inner.reduce((acc, curr) => {
            if (curr.path) {
              return { ...acc, [curr.path]: curr.errors };
            } else return acc;
          }, {})
        );
      } else {
        addToast({
          type: 'error',
          title: t('userConfigPage:clientDoesntExist', { defaultValue: 'Cliente não existe' }),
          description: t('userConfigPage:couldntFindClient', { client: tenant, defaultValue: `Não foi possível encontrar o cliente ${tenant}.` }),
        });
      }
    }
  };

  if (client) {
    i18n.changeLanguage(client.language);
    localStorage.setItem('lng', client.language);
    push(`/client/${client.tenant}`);
  }

  return (
    <div className={style.container}>
      <section className={style.background}>
        <img src='/background.png' />
      </section>
      <section className={style.body}>
        <img src='/medianeira_logo.png' alt='medianeira' />
        <h1>{t('userConfigPage:welcome', { defaultValue: 'Bem-vindo' })}</h1>
        <form onSubmit={handleSubmit}>
          <Input
            id='tenant'
            grow
            label={t('userConfigPage:clientId', { defaultValue: 'ID do cliente' })}
            type='text'
            value={tenant}
            onChange={setTenant}
            errors={errors && errors['tenant']}
          />
          <div>
            <Button type='submit'>{t('userConfigPage:signIn', { defaultValue: "Entrar" })}</Button>
          </div>
        </form>
      </section>
    </div>
  );
};
