import { useEffect, useState } from 'react';
import { Button } from '../../../../Components/Atoms/Button';
import Checkbox from '../../../../Components/Atoms/Checkbox';
import { AdvancedFilter } from '../Components/AdvancedFilter';
import { useData } from '../Controls/DataControl/UseData';
import { useFilter } from '../Controls/FilterControl';
import style from './ReportsPage.module.css';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useToast } from '../../../../hooks/toast';
import { useTranslation } from 'react-i18next';

export const ReportsPage = () => {
  const { filter, resetFilterRules } = useFilter();
  const { addToast } = useToast();
  const { generateReport } = useData();
  const [selectedCharts, setSelectedCharts] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleClickButton = () => {
    if (selectedCharts.length === 0) {
      addToast({ title: t('tenantPage:selectAtLeastOneReport', { defaultValue: 'Selecione ao menos um relatório.' }), type: 'error' });
      return;
    }
    setLoading(true);
    generateReport({
      ...filter,
      charts: selectedCharts,
    }).then(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    resetFilterRules();
  }, []);

  const CheckboxChartItem = (chartName: string, chartLabel: string) => (
    <Checkbox
      label={chartLabel}
      inline
      alignStart
      onChange={() => {
        setSelectedCharts((prev) =>
          prev.includes(chartName)
            ? prev.filter((value) => value !== chartName)
            : [...prev, chartName]
        );
      }}
      checked={selectedCharts.includes(chartName)}
    />
  );

  const LoadingSplash = () => (
    <div className={style.loadingSplash}>
      <AiOutlineLoading3Quarters size={62} />
    </div>
  );

  return (
    <div>
      {loading && <LoadingSplash />}
      <AdvancedFilter alwaysOpen />
      <div className={style.container}>
        <div className={style.selectArea}>
          <h3>{t('tenantPage:reports', { defaultValue: 'Relatórios' })}</h3>
          <div className={style.reportsList}>
            {CheckboxChartItem('Classification', t('tenantPage:classification', { defaultValue: 'Classificação' }))}
            {CheckboxChartItem('WeightOverTime', t('tenantPage:weightXTime', { defaultValue: 'Peso x Tempo' }))}
            {CheckboxChartItem('ProductionHistogram', t('tenantPage:productionHistogram', { defaultValue: 'Histograma da Produção' }))}
            {CheckboxChartItem('Scraps', t('tenantPage:leftovers', { defaultValue: 'Sobras' }))}
            {CheckboxChartItem('Occurrences', t('tenantPage:occurrences', { defaultValue: 'Ocorrências' }))}
            {CheckboxChartItem('OperationalFactor', t('tenantPage:operationalFactor', { defaultValue: 'Fator Operacional' }))}
            {CheckboxChartItem('OccurrencesHistogram', t('tenantPage:occurrencesHistogram', { defaultValue: 'Histograma de Ocorrências' }))}
            {CheckboxChartItem('ProductionOrders', t('tenantPage:productionOrders', { defaultValue: 'Ordens de Produção' }))}
          </div>
        </div>
        <div className={style.buttonsArea}>
          <Button onClick={handleClickButton}>{t('tenantPage:generateReports', { defaultValue: 'Gerar relatórios' })}</Button>
        </div>
      </div>
    </div>
  );
};
