export const MultiSeriesChartPalette = [
  '#27dc94',
  '#FCAA67',
  '#94778B',
  '#FFC800',
  '#8292C8',
  '#20A4F3',
  '#E4C1F9',
  '#8DE4FF',
];
