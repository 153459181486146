import AppError from './AppError';
class Api {
  defaultHeaders: Headers;
  signOutFunction: VoidFunction | undefined;
  signedOut: boolean = false;

  constructor(signOut?: VoidFunction, defaultHeaders?: Headers) {
    this.defaultHeaders =
      defaultHeaders ||
      new Headers({
        'content-type': 'application/json',
        'cache-control': 'no-cache',
      });
    this.signOutFunction = signOut;
  }
  get<T>(url: string, signal?: AbortSignal): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'GET',
        headers: this.defaultHeaders,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction && !this.signedOut) {
              this.signedOut = true;
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          return resolve(response.json());
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }
  download<U>(
    url: string,
    filename: string,
    body: U,
    signal?: AbortSignal
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            link.click();
            return resolve();
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  post<T, U>(url: string, body: U, signal?: AbortSignal): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          return resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  postFormData<T>(
    url: string,
    formData: FormData,
    signal?: AbortSignal
  ): Promise<T> {
    const formDataHeaders = new Headers();
    formDataHeaders.append(
      'Authorization',
      this.defaultHeaders.get('Authorization') ?? ''
    );
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'POST',
        headers: formDataHeaders,
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            response.json().then((error: AppError) => {
              reject(new AppError(error.message, response.status, error.code));
            });
          } else {
            return resolve(response.json());
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  put<T, U>(url: string, body: U, signal?: AbortSignal): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        method: 'PUT',
        headers: this.defaultHeaders,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          return resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  putFormData<T>(
    url: string,
    formData: FormData,
    signal?: AbortSignal
  ): Promise<T> {
    const formDataHeaders = new Headers();
    formDataHeaders.append(
      'Authorization',
      this.defaultHeaders.get('Authorization') ?? ''
    );
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'PUT',
        headers: formDataHeaders,
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          return resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  patch<T, U>(url: string, body: U, signal?: AbortSignal): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'PATCH',
        headers: this.defaultHeaders,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          return resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  patchFormData<T>(
    url: string,
    formData: FormData,
    signal?: AbortSignal
  ): Promise<T> {
    const formDataHeaders = new Headers();
    formDataHeaders.append(
      'Authorization',
      this.defaultHeaders.get('Authorization') ?? ''
    );
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'PATCH',
        headers: this.defaultHeaders,
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          return resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete<T>(url: string, signal?: AbortSignal): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_END_URL}${url}`, {
        signal,
        method: 'DELETE',
        headers: this.defaultHeaders,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401 && this.signOutFunction) {
              this.signOutFunction();
            }
            reject(new AppError(response.statusText, response.status));
          }
          return resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default Api;
