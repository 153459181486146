import React, { useState } from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import { Select } from '../../../../../Components/Atoms/Input/Select';
import Modal from '../../../../../Components/Modal';
import { useAuth, User } from '../../../../AuthControl';
import style from './ChangeUserRoleModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
  user: User;
  handleClose: (refresh?: boolean) => void;
}

export const ChangeUserRoleModal = ({
  user: { role, _id, tenant, name },
  handleClose,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const mapRole = (role: string): string | undefined =>
  ({
    Admin: t('userPage:administrator', { defaultValue: 'Administrador' }),
    Operator: t('userPage:operator', { defaultValue: 'Operador' }),
    Supervisor: t('userPage:supervisor', { defaultValue: 'Supervisor' }),
  }[role]);

  const mapRoleReverse = (role: string): string | undefined =>
  ({
    Administrador: 'Admin',
    Operador: 'Operator',
    Supervisor: 'Supervisor',
  }[role]);

  const [tempRole, setTempRole] = useState<string>(mapRole(role) ?? '');
  const { api } = useAuth();


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    api
      .post(`/api/client/${tenant}/users/profile/${_id}/role`, {
        role: mapRoleReverse(tempRole),
      })
      .then(() => {
        handleClose(true);
      });
  };

  return (
    <Modal handleClose={handleClose}>
      <form onSubmit={handleSubmit} className={style.container}>
        <h2>{name}</h2>
        <Select
          customPlaceholder={t('cardsPage:select', { defaultValue: 'Selecione' })}
          options={
            [t('userPage:administrator', { defaultValue: 'Administrador' }),
            t('userPage:operator', { defaultValue: 'Operador' }),
            t('userPage:supervisor', { defaultValue: 'Supervisor' })]
          }
          id='role'
          grow
          label={t('userPage:role', { defaultValue: 'Permissão' })}
          type='text'
          value={tempRole}
          onChange={(value) => {
            setTempRole(value);
          }}
        />
        <div className={style.footer}>
          <Button danger secondary onClick={() => handleClose()}>
            {t('userPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button type='submit'>{t('userPage:save', { defaultValue: 'Salvar' })}</Button>
        </div>
      </form>
    </Modal>
  );
};
