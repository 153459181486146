import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Label } from '../../Label';
import style from './Select.module.css';
interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange'
  > {
  id: string;
  grow?: boolean;
  label?: string;
  options: string[] | { id: string; text: string }[];
  value: string;
  noEmpty?: boolean;
  inverted?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  customPlaceholder?: string;
}
export const Select = ({
  multiple = false,
  label,
  grow = false,
  options,
  id,
  inverted,
  value,
  disabled,
  noEmpty,
  onChange,
  customPlaceholder,
}: Props): React.ReactElement => {
  return (
    <div data-grow={grow} className={`${style.container}`}>
      <Label value={label ?? ''} htmlFor={`select-${id}`} inverted={inverted} />
      <div className={style.selectOutline} data-disabled={disabled}>
        <select
          id={`select-${id}`}
          value={value}
          disabled={disabled}
          multiple={multiple}
          className={style.select}
          onChange={({ target: { value } }) => {
            value === 'select' ? onChange('') : onChange(value);
          }}
        >
          {!noEmpty && (
            <option className={style.option} key='empty' value='select'>
              {customPlaceholder ?? 'Selecione'}
            </option>
          )}
          {options.map((option) => {
            if (typeof option === 'string') {
              return (
                <option className={style.option} key={option} value={option}>
                  {option}
                </option>
              );
            } else {
              return (
                <option
                  className={style.option}
                  key={option.id}
                  value={option.id}
                >
                  {option.text}
                </option>
              );
            }
          })}
        </select>
      </div>
    </div>
  );
};
