import React from 'react';
import style from './Modal.module.css';

const Modal = ({
  children,
  handleClose,
}: {
  children: React.ReactNode;
  handleClose: VoidFunction;
}): React.ReactElement => {
  return (
    <div className={style.backsplash} onClick={() => handleClose()}>
      <div
        className={style.modal}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
