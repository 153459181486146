import { Link } from 'react-router-dom';
import { useAuth } from '../../../../AuthControl';
import style from './SignedInHeader.module.css';
import { useTranslation } from 'react-i18next';

export const UserMenuItems = (
  tenant: string | undefined
): React.ReactElement[] => {
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const basePath = `/client/${tenant}`;

  return [
    <Link to={`${basePath}/eu`}>
      <div className={style.menuItem}>{t('headerPage:profile', { defaultValue: 'Perfil' })}</div>
    </Link>,
    <Link to='#'>
      <div className={style.menuItem} onClick={signOut}>
        {t('headerPage:signOut', { defaultValue: 'Sair' })}
      </div>
    </Link>,
  ];
};
