import { ApexOptions } from 'apexcharts';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EmptyState } from '../Components/Cards/EmptyState';
import { EventsHistogram } from '../Controls/DataControl/models';
import { useData } from '../Controls/DataControl/UseData';
import { useFilter } from '../Controls/FilterControl';
import Chart from 'react-apexcharts';
import { formatSecondsToHumanReadableTime } from '../../../../helper/FormatSecondsToHumanReadableTime';
import { Filter } from '../Controls/FilterControl/models';
import style from './AndonPages.module.css';
import { AxisTitleConfiguration } from '../../../../helper/AxisTitleConfiguration';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../LocaleControl';

interface Props {
  containerWidth: number;
  containerHeight: number;
}

const formatXaxisLabels = (val: string): string => {
  let roundFactor = 1;
  const intVal = parseInt(val);

  if (intVal < 60 * 60 * 12) {
    roundFactor = 60 * 60;
  } else if (intVal < 60 * 60 * 24) {
    roundFactor = 60 * 60 * 3;
  } else {
    roundFactor = 60 * 60 * 6;
  }
  const roundedVal =
    parseInt((parseInt(val) / roundFactor).toFixed(0)) * roundFactor;
  return formatSecondsToHumanReadableTime(roundedVal, false, false);
};

export const OccurrencesParetoPage = ({
  containerWidth,
}: Props): React.ReactElement => {
  const { filter } = useFilter();
  const { fetchEventsHistogram } = useData();
  const [histogramData, setHistogramData] = useState<EventsHistogram[]>([]);
  const intervalFetchRef = useRef<NodeJS.Timer>();
  const { t } = useTranslation();
  const { chartOptionsLocale } = useLocale();

  const localFetch = useCallback(
    (filter: Filter) => {
      const { fetch } = fetchEventsHistogram();
      fetch(filter).then((data) => setHistogramData(data));
    },
    [fetchEventsHistogram, setHistogramData]
  );

  useEffect(() => {
    localFetch(filter);
    if (intervalFetchRef.current) {
      clearInterval(intervalFetchRef.current);
    }
    intervalFetchRef.current = setInterval(() => {
      localFetch(filter);
    }, 60 * 1000 * 1);
    return () => {
      if (intervalFetchRef.current) {
        clearInterval(intervalFetchRef.current);
      }
    };
  }, [filter]);

  const OccurrencesOrderedList = useMemo(() => {
    const uniqueOccurrences: { [key: string]: { duration: number } } = {};
    for (const data of histogramData) {
      data.events.forEach((event) => {
        if (event.description in uniqueOccurrences) {
          uniqueOccurrences[event.description] = {
            duration:
              event.duration + uniqueOccurrences[event.description].duration,
          };
        } else {
          uniqueOccurrences[event.description ?? t('andonPage:notIdentified', { defaultValue: 'Não identificado' })] = {
            duration: event.duration,
          };
        }
      });
    }
    const orderedUniqueOccurrences = Object.keys(uniqueOccurrences)
      .map((key) => ({
        category: key,
        value: uniqueOccurrences[key].duration,
      }))
      .sort((a, b) => b.value - a.value);
    const firstFiveOccurrences = orderedUniqueOccurrences.slice(0, 5);
    const restOfOccurrencesCombined = orderedUniqueOccurrences.slice(5).reduce(
      (acc, curr) => {
        return {
          ...acc,
          value: acc.value + curr.value,
        };
      },
      { category: 'Outros', value: 0 }
    );
    if (restOfOccurrencesCombined.value === 0) {
      return firstFiveOccurrences.sort((a, b) => b.value - a.value);
    }
    return [...firstFiveOccurrences, restOfOccurrencesCombined].sort(
      (a, b) => b.value - a.value
    );
  }, [histogramData]);

  const options: ApexOptions = useMemo(
    (): ApexOptions => ({
      responsive: [
        {
          breakpoint: 1921,
          options: {
            yaxis: {
              labels: {
                align: 'left',
                minWidth: 250,
                maxWidth: 1200,
                style: { fontSize: '1.35em' },
              },
            },
            xaxis: {
              title: {
                text: t('andonPage:duration', { defaultValue: 'Duração' }),
                offsetY: 70,
                style: { fontSize: '1.175em', fontWeight: 400 },
              },
              labels: {
                formatter: formatXaxisLabels,
                offsetY: 10,

                style: { fontSize: '1.15em' },
              },
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            yaxis: {
              labels: {
                align: 'left',
                minWidth: 180,
                maxWidth: 650,
                style: { fontSize: '1.35em' },
              },
            },
            xaxis: {
              title: {
                text: t('andonPage:duration', { defaultValue: 'Duração' }),
                offsetY: 48,
                style: { fontSize: '1.175em', fontWeight: 400 },
              },
              labels: {
                formatter: formatXaxisLabels,
                offsetY: 5,
                style: { fontSize: '1.15em' },
              },
            },
          },
        },
      ],
      chart: {
        type: 'bar',
        ...chartOptionsLocale,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#27dc94'],
      dataLabels: {
        formatter: (val) => {
          if (typeof val === 'number') {
            return formatSecondsToHumanReadableTime(val);
          } else if (Array.isArray(val)) {
            return val.join('');
          }
          return val;
        },
        enabled: true,
        style: { fontSize: '1.175em', colors: ['#222'] },
        textAnchor: 'start',
        offsetX: 10,
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 12,
          borderColor: 'transparent',
          opacity: 1,
          dropShadow: {
            enabled: false,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 16,
          dataLabels: {
            position: 'bottom',
            hideOverflowingLabels: false,
          },
        },
      },
      legend: { show: false },
      xaxis: {
        type: 'category',
        tickPlacement: 'on',
        categories: OccurrencesOrderedList.map(({ category }) => category),
        tickAmount: 3,
        title: {
          text: t('andonPage:duration', { defaultValue: 'Duração' }),
          offsetY: 160,
          style: { fontSize: '1.175em', fontWeight: 400 },
        },
        labels: {
          formatter: formatXaxisLabels,
          offsetY: 24,
          style: { fontSize: '1.15em' },
        },
      },
      yaxis: {
        labels: {
          align: 'left',
          minWidth: 180,
          maxWidth: 2000,
          style: { fontSize: '1.35em' },
        },
        title: {
          text: undefined,
        },
      },
    }),
    [OccurrencesOrderedList]
  );
  const series = useMemo(
    () => [
      {
        name: t('andonPage:duration', { defaultValue: 'Duração' }),
        data: OccurrencesOrderedList.map(({ value }) => value),
      },
    ],
    [OccurrencesOrderedList]
  );

  return (
    <>
      <div className={style.pageHeader}>
        <div>
          <img src='/supervis_logo_andon.png' alt='supervis' />
        </div>
        <div className={style.pageTitle}>
          <h3>{t('andonPage:paretoOfOccurrencesUppercase', { defaultValue: 'PARETO DE OCORRÊNCIAS' })}</h3>
        </div>
      </div>
      {histogramData.length ? (
        <Chart
          type='bar'
          options={options}
          series={series}
          height={'95%'}
          width={containerWidth - 50}
        />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
