import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Input } from '../../../../../Components/Atoms/Input';
import AppError from '../../../../../helper/AppError';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { Operator } from '../../Controls/DataControl/models';
import { ConfirmDeleteOperatorModal } from './ConfirmDeleteOperatorModal';
import style from './OperatorPage.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
  newOperator?: boolean;
}

export const OperatorPage = ({ newOperator }: Props): React.ReactElement => {
  const { push } = useHistory();
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const [operatorId, setOperatorId] = useState<string>();
  const [operatorName, setOperatorName] = useState('');
  const [operatorCode, setOperatorCode] = useState(0);
  const [waitDeleteConfirmation, setWaitDeleteConfirmation] = useState(false);
  const {
    params: { id: editCode },
  } = useRouteMatch<{ id: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!newOperator) {
      api
        .get<Operator>(
          `/api/client/${client?.tenant}/registrations/operators/${editCode}`
        )
        .then((response) => {
          setOperatorCode(parseInt(response.code));
          setOperatorId(response.id);
          setOperatorName(response.name);
        })
        .catch((e: AppError) => {
          if (e.statusCode === 404) {
            addToast({ title: t('operatorPage:operatorNotFound', { defaultValue: 'Operador não encontrado' }), type: 'error' });
          } else addToast({ title: t('operatorPage:unexpectedError', { defaultValue: 'Erro inesperado' }), type: 'error' });
          push(`/client/${client?.tenant}/admin/operadores`);
        });
    }
  }, [!newOperator]);

  const handleCreateOperator = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.post(`/api/client/${client?.tenant}/registrations/operators`, {
        name: operatorName,
        code: operatorCode,
      });
      addToast({ title: t('operatorPage:operatorSuccessfullyCreated', { defaultValue: 'Operador criado com sucesso' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/operadores`);
    } catch (_) {
      addToast({ title: t('operatorPage:errorAddingOperator', { defaultValue: 'Erro ao adicionar operador' }), type: 'error' });
    }
  };

  const handleUpdateOperator = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.put(`/api/client/${client?.tenant}/registrations/operators`, {
        name: operatorName,
        code: operatorCode,
        id: operatorId,
      });
      addToast({ title: t('operatorPage:operatorSuccessfullyUpdated', { defaultValue: 'Operador alterado com sucesso' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/operadores`);
    } catch (_) {
      addToast({ title: t('operatorPage:errorUpdatingOperator', { defaultValue: 'Erro ao alterar operador' }), type: 'error' });
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) =>
        !newOperator ? handleUpdateOperator(e) : handleCreateOperator(e)
      }
    >
      {waitDeleteConfirmation && operatorId && (
        <ConfirmDeleteOperatorModal
          tenant={client?.tenant || ''}
          operatorId={operatorId}
          operatorName={operatorName}
          handleClose={(success) => {
            setWaitDeleteConfirmation(false);
            if (success) {
              push(`/client/${client?.tenant}/admin/operadores`);
            }
          }}
        />
      )}
      <h1>
        {newOperator ? t('operatorPage:addNewOperator', { defaultValue: 'Adicionar Novo Operador' }) :
          t('operatorPage:editOperatorName', { operatorName: operatorName, defaultValue: `Editar ${operatorName}` })}
      </h1>

      <Input
        label={t('operatorPage:name', { defaultValue: 'Nome' })}
        value={operatorName}
        required={true}
        onChange={setOperatorName}
      />
      <Input
        disabled={!newOperator}
        label={t('operatorPage:code', { defaultValue: 'Código' })}
        type='number'
        min={1}
        required={true}
        value={operatorCode}
        onChange={(value) => setOperatorCode(parseInt(value))}
      />
      <div className={style.buttons}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/operadores`)}
        >
          {t('operatorPage:cancel', { defaultValue: 'Cancelar' })}
        </Button>
        {!newOperator && (
          <Button
            danger
            onClick={() => {
              setWaitDeleteConfirmation(true);
            }}
          >
            {t('operatorPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        )}
        <Button type='submit'>{!newOperator ? t('operatorPage:save', { defaultValue: 'Salvar' }) :
          t('operatorPage:add', { defaultValue: 'Adicionar' })}
        </Button>
      </div>
    </form>
  );
};
