import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Api from '../../helper/Api';
import AppError from '../../helper/AppError';
import { useTenant } from '../TenantControl';
import { AuthContext } from './authContext';
import {
  AuthRequest,
  AuthResponse,
  AuthState,
  UpdateUserRequest,
  User,
} from './models';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../hooks/toast';

  let token = localStorage.getItem('@medianeira:token');
  let user = localStorage.getItem('@medianeira:user');
  let data: AuthState = {};

  if (token && user) {
    data = {
      token,
      user: JSON.parse(user),
    };
  }

export const AuthProvider: React.FC = ({ children }) => {
  const { client } = useTenant();
  const { push } = useHistory();
  const { t, i18n } = useTranslation();
  const { addToast } = useToast();

  const isAdmin = (): boolean => data?.user?.role === 'Admin';
  const isOperator = (): boolean => data?.user?.role === 'Operator';

  const signIn = useCallback(
    async ({ tenant, email, password }): Promise<User> => {
      const { token, ...user } = await api.post<AuthResponse, AuthRequest>(
        `/api/client/${tenant}/users/access`,
        { email, password }
      );
      if (!token || !user) {
        throw new AppError(t('userConfigPage:invalidCredentials', { defaultValue: 'Credenciais inválidas' }), 401);
      }
      if (token && user) {
        localStorage.setItem('@medianeira:token', token);
        localStorage.setItem('@medianeira:user', JSON.stringify(user));
        if (user.language) {
          localStorage.setItem('lng', user.language);
          i18n.changeLanguage(user.language);
        }
        data = { token, user };
        console.log(data);
      }
      return user;
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@medianeira:token');
    localStorage.removeItem('@medianeira:user');
    data = {};
    push(`/client/${client?.tenant}`);
    addToast(
      { type: 'error', title: t('userPage:sessionExpired', { defaultValue: 'Sessão expirada' })}
    );
  }, [client, push]);

  const api = useApi(signOut, data.token);

  const updateUser = useCallback(
    async ({
      name,
      profilePic,
      password,
      newPassword,
      newPasswordConfirmation,
      emailNotifications,
    }: UpdateUserRequest) => {
      const formData = new FormData();
      name && formData.append('name', name);
      formData.append('emailNotification', emailNotifications.toString());
      formData.append('pushNotification', '3');
      profilePic &&
        formData.append('profile_pic', profilePic, `${data.user?._id}.jpeg`);
      password && formData.append('password', password);
      newPassword && formData.append('newPassword', newPassword);
      newPasswordConfirmation &&
        formData.append('newPasswordConfirmation', newPasswordConfirmation);
      const user = await api.putFormData<User>(
        `/api/client/${client?.tenant}/users/profile`,
        formData
      );
      data = {}

      localStorage.setItem('@medianeira:user', JSON.stringify(user));
    },
    [api, data]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        updateUser,
        isAdmin,
        isOperator,
        api,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};



const useApi = (signOut: VoidFunction, token: string | undefined) => {
  return useMemo(() => {
    const headers = new Headers();
    headers.append('content-type', 'application/json');
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    return new Api(signOut, headers);
  }, [token]);
};
