import React, { useEffect, useState } from 'react';
import { useToast } from '../../../hooks/toast';
import { useLocation, useHistory } from 'react-router-dom';
import './style.css';
import { Input } from '../../../Components/Atoms/Input';
import { Button } from '../../../Components/Atoms/Button';
import * as Yup from 'yup';
import Checkbox from '../../../Components/Atoms/Checkbox';
import { Customer, Invoice, subscription } from '../../../Client/AuthControl';
import { Client } from '../../../Client/TenantControl';
import AppError from '../../../helper/AppError';
import { FormSeparator } from '../../../Components/Atoms/FormSeparator';
import { format, startOfDay, addYears } from 'date-fns';
import { FiCheck, FiX } from 'react-icons/fi';
import style from './ClientDetails.module.css';
import { useManagementAuth } from '../../AuthControl';
import { TbFileInvoice } from 'react-icons/tb';
import { LanguageListItemModel } from '../LanguageListPages/LanguageModel';
import { Select } from '../../../Components/Atoms/Input/Select';

interface Account {
  client: Client;
  owner: {
    name: string;
    email: string;
    verified: boolean;
  };
}

const ClientDetails: React.FC = () => {
  const { addToast } = useToast();
  const { pathname } = useLocation();
  const history = useHistory();
  const [tenantId, setTenantId] = useState<string>();
  const [tenant, setTenant] = useState<string>();
  const [name, setName] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [plan, setPlan] = useState<string>('2');
  const [pricePerEquipment, setPricePerEquipment] = useState(0);
  const [countEquipments, setCountEquipments] = useState(0);
  const [trialEnd, setTrialEnd] = useState(new Date());
  const [recurringInterval, setRecurringInterval] = useState(1);
  const [startBillingCycle, setStartBillingCycle] = useState(new Date());
  const [useBillingCycle, setUsingBillingCycle] = useState(true);
  const [useTrialEnd, setUseTrialEnd] = useState(false);
  const [rootUserEmail, setRootUserEmail] = useState<string>();
  const [existingInvoice, setExistingInvoice] = useState<Invoice>();
  const [billingPersonEmail, setBillingPersonEmail] = useState<string>();
  const [taxId, setTaxId] = useState<string>();
  const [addressLine1, setAddressLine1] = useState<string>();
  const [addressPostalCode, setAddressPostalCode] = useState<string>();
  const [addressCity, setAddressCity] = useState<string>();
  const [addressState, setAddressState] = useState<string>();
  const [addressCountry, setAddressCountry] = useState<string>();
  const [rootUserName, setRootUserName] = useState<string>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>();
  const [blocked, setBlocked] = useState(false);
  const [enableUpdateRootUser, setEnableUpdateRootUser] = useState(true);
  const { signOut, api } = useManagementAuth();
  const [availableLanguages, setAvailableLanguages] = useState<LanguageListItemModel[]>([]);
  const clientId = pathname.split('/').slice(-1).join('');
  const newClient = clientId === 'new';

  const getCreateClientSchema = () =>
    Yup.object().shape({
      name: Yup.string().trim().required('Nome e obrigatório'),
      tenant: Yup.string()
        .trim()
        .matches(/^\S+$/, { message: 'Identificador não pode conter espaços' })
        .required('Identificador da empresa é obrigatório'),
      plan: Yup.string().required('Plano é obrigatório'),
      rootUser: Yup.object()
        .required()
        .shape({
          name: Yup.string().trim().required('Nome do usuário é obrigatório'),
          email: Yup.string()
            .trim()
            .email()
            .required('Email do usuário é obrigatório'),
        }),
      pricePerEquipment: Yup.number()
        .min(0.01, 'Preço por equipamento não pode ser R$ 0,00')
        .required('Preço por equipamento é obrigatório'),
      recurringInterval: Yup.number()
        .min(1)
        .max(12)
        .required('Intervalo de recorrência é obrigatório'),
      countEquipments: Yup.number()
        .min(1, 'Assinatura deve conter ao menos um equipamento')
        .required('Nº de equipamentos é obrigatório'),
      trialEnd: Yup.date()
        .min(startOfDay(new Date()))
        .max(
          addYears(startOfDay(new Date()), 1),
          'Período de avaliação deve ser menor que 1 ano'
        ),
      // .required('Período de avaliação é obrigatório'),
      startBillingCycle: Yup.date().min(
        trialEnd,
        'Primeira cobrança deve ser após período de avaliação'
      ),
      // .required('Primeira cobrança é obrigatório'),
    });

  const getUpdateClientSchema = () =>
    Yup.object().shape({
      name: Yup.string().trim().required('Nome e obrigatório'),
      tenant: Yup.string()
        .trim()
        .matches(/^\S+$/, { message: 'ID não pode conter espaços' })
        .required('ID do cliente é obrigatório'),
      plan: Yup.string().required('Plano é obrigatório'),
      rootUser: Yup.object()
        .default(undefined)
        .optional()
        .shape({
          name: Yup.string().trim().required('Nome do usuário é obrigatório'),
          email: Yup.string()
            .trim()
            .email()
            .required('Email do usuário é obrigatório'),
        }),
      endTrialAt: Yup.date()
        .min(startOfDay(new Date()))
        .max(
          addYears(startOfDay(new Date()), 1),
          'Período de avaliação deve ser menor que 1 ano'
        ),
      equipmentAmount: Yup.number()
        .integer('Quantidade de equipamentos não pode ser fracionada')
        .min(1, 'Assinatura deve conter ao menos um equipamento')
        .required('Nº de equipamentos é obrigatório'),
      pricePerEquipment: Yup.number()
        .positive('Preço por equipamento precisa ser um valor positivo')
        .min(0.01, 'Preço por equipamento não pode ser R$ 0,00')
        .required('Preço por equipamento é obrigatório'),
      recurringInterval: Yup.number()
        .integer('Intervalo não pode ser fracionado')
        .positive('Intervalo deve ter valor positivo')
        .max(12, 'Intervalo não pode ser superior a 1 ano')
        .required('Intervalo de recorrência é obrigatório'),
      billingCycleAnchor: Yup.date().optional().min(startOfDay(new Date())),
    });

  useEffect(() => {
    if (clientId && clientId !== 'new') {
      api
        .get<Account>(`/api/management/clients/account/${clientId}`)
        .then(({ client, owner }) => {
          setEnableUpdateRootUser(!owner.verified);
          setTenantId(client._id);
          setName(client.name);
          setTenant(client.tenant);
          setLanguage(client.language);
          setPlan((prev) => client.plan ?? prev);
          setBlocked(client.status === 'Blocked');
          setRootUserEmail(owner.email);
          setRootUserName(owner.name);

          setTaxId(client.cnpj);
          setBillingPersonEmail(client.accountResponsible);
          setAddressLine1(client.address.street);
          setAddressCity(client.address.city);
          setAddressState(client.address.state);
          setAddressCountry(client.address.country);
          setAddressPostalCode(client.address.cep);
          setPricePerEquipment(
            parseFloat((client.pricePerEquipment / 100).toFixed(2))
          );
          setCountEquipments(client.equipmentAmount);
          setRecurringInterval(client.billingPeriod);
          setStartBillingCycle(
            client.billingCycleAnchor
              ? new Date(client.billingCycleAnchor)
              : new Date()
          );
          setTrialEnd(
            client.endTrialAt ? new Date(client.endTrialAt) : new Date()
          );
          setUseTrialEnd(!!client.endTrialAt);
        })
        .catch((error) => {
          console.error(error);
          if (error.statusCode === 401) {
            signOut();
          }
          addToast({ type: 'error', title: 'Erro ao carregar cliente.' });
        });
    }
  }, [clientId]);

  const createClient = async () => {
    const formData = {
      tenant,
      name,
      language,
      plan,
      rootUser: { email: rootUserEmail, name: rootUserName },
      status: blocked ? 'Blocked' : 'Active',
      pricePerEquipment: pricePerEquipment * 100,
      recurringInterval,
      countEquipments,
    };
    try {
      await getCreateClientSchema().validate(formData, { abortEarly: false });
      await api.post<{ newClient: { tenant: string } }, any>(
        `/api/management/clients/account`,
        {
          tenant,
          name,
          language,
          plan,
          rootUser: { email: rootUserEmail, name: rootUserName },
          status: blocked ? 'Blocked' : 'Active',
          equipmentAmount: countEquipments,
          pricePerEquipment: pricePerEquipment * 100,
          accountResponsible: billingPersonEmail,
          endTrialAt: trialEnd,
          billingCycleAnchor: startBillingCycle,
          billingPeriod: recurringInterval,
          cnpj: taxId,
          address: {
            street: addressLine1,
            city: addressCity,
            state: addressState,
            country: addressCountry,
            cep: addressPostalCode,
          },
        }
      );
    } catch (error) {
      if (error instanceof AppError) {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao criar cliente',
            description: 'Verifique os dados e tente novamente.',
          });
        }
      } else if (error instanceof Yup.ValidationError) {
        setErrors(
          error.inner.reduce((acc, curr) => {
            if (curr.path) {
              return { ...acc, [curr.path]: curr.errors };
            } else return acc;
          }, {})
        );
      }
      return;
    }
    try {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description:
          'Um email foi enviado para o usuário chave iniciar as configurações.',
      });
    } catch (error) {
      if (error instanceof AppError) {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        } else {
          addToast({
            type: 'error',
            title: 'Erro desconhecido',
            description: 'Tente novamente mais tarde.',
          });
        }
      } else if (error instanceof Yup.ValidationError) {
        setErrors(
          error.inner.reduce((acc, curr) => {
            if (curr.path) {
              return { ...acc, [curr.path]: curr.errors };
            } else return acc;
          }, {})
        );
      }
    } finally {
      history.push('/management/s/clients');
    }
  };

  const updateClient = async () => {
    const body = {
      tenant,
      name,
      language,
      plan,
      rootUser: undefined,
      status: blocked ? 'Blocked' : 'Active',
      pricePerEquipment: pricePerEquipment * 100,
      equipmentAmount: countEquipments,
      billingPeriod: recurringInterval,
      accountResponsible: billingPersonEmail,
      endTrialAt: trialEnd,
      billingCycleAnchor: startBillingCycle,
      cnpj: taxId,
      address: {
        street: addressLine1,
        city: addressCity,
        state: addressState,
        country: addressCountry,
        cep: addressPostalCode,
      },
    };

    if (enableUpdateRootUser) {
      Object.assign(body, {
        rootUser: { email: rootUserEmail, name: rootUserName },
      });
    }
    try {
      // await getUpdateClientSchema().validate(body); //TODO: fix me later
      await api.put(`/api/management/clients/account`, body);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Dados do cliente foram atualizados.',
      });
    } catch (error) {
      if (error instanceof AppError) {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar.',
            description:
              'Houve algum problema ao atualizar os dados do cliente.',
          });
        }
      }
      return;
    }
    try {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Dados de cobrança do cliente foram atualizados.',
      });
      history.push('/management/s/home');
    } catch (error) {
      if (error instanceof AppError) {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar dados de cobrança',
            description:
              'Houve algum problema ao atualizar os dados de cobrança do cliente.',
          });
        }
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({});
    try {
      if (!tenantId) {
        createClient();
      } else {
        updateClient();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors(
          error.inner.reduce((acc, curr) => {
            if (curr.path) {
              return { ...acc, [curr.path]: curr.errors };
            } else return acc;
          }, {})
        );
      } else {
        addToast({
          type: 'error',
          title: 'Erro',
          description: String(error),
        });
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    api.get<LanguageListItemModel[]>(`/api/management/languages`, controller.signal)
      .then((languages: LanguageListItemModel[]) => {
        setAvailableLanguages(languages);
      })
      .catch((error) => {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        }
        throw 'error';
      });
    return () => controller.abort();
  }, []);

  const selectedLanguage = availableLanguages.find((lang) => lang.language === language);

  return (
    <form id='client-form' onSubmit={handleSubmit}>
      <div className={style.header}>
        <h2>{newClient ? 'Novo cliente' : name ?? clientId}</h2>
      </div>
      <FormSeparator id='company-data' label={'Dados da empresa'} />
      <div id='client-form-identity'>
        <Input
          id='name'
          grow
          label='Nome Completo'
          type='text'
          value={name}
          onChange={setName}
          errors={errors && errors['name']}
        />
        <Input
          id='tenant'
          grow
          disabled={!newClient}
          label='Identificador'
          type='text'
          value={tenant}
          onChange={setTenant}
          errors={errors && errors['tenant']}
          tooltip='Valor usado para montar a URL de acesso do cliente à aplicação.'
        />
        <Checkbox
          label='Bloqueado'
          tooltip='Caso o cliente seja bloqueado nenhum usuário do cliente terá acesso à aplicação.'
          checked={blocked}
          onChange={setBlocked}
        />
      </div>
      <div id={`client-form-root-user`}>
        <FormSeparator id='root-user-separator' label='Usuário chave'>
          {enableUpdateRootUser ? (
            <FiX id='non-verified' />
          ) : (
            <FiCheck id='verified' />
          )}
        </FormSeparator>
        <div>
          <Input
            id='rootUserName'
            disabled={!enableUpdateRootUser}
            grow
            label='Nome'
            type='text'
            value={rootUserName}
            onChange={setRootUserName}
            errors={errors && errors['rootUser.name']}
          />
          <Input
            id='rootUserEmail'
            disabled={!enableUpdateRootUser}
            grow
            label='Email'
            type='email'
            value={rootUserEmail}
            onChange={setRootUserEmail}
            errors={errors && errors['rootUser.email']}
          />
        </div>
      </div>
      <FormSeparator id='signature' label='Assinatura' />
      <div className={style.subscriptionArea}>
        <div className={style.subscriptionPrice}>
          <Input
            id='countEquipmentsInput'
            grow
            label='nº Equipamentos'
            defaultValue={0}
            type='number'
            min={0}
            max='any'
            step={1}
            value={countEquipments}
            onChange={(value) => setCountEquipments(parseInt(value))}
            errors={errors && errors['countEquipments']}
          />
          <Input
            id='priceInput'
            grow
            label='Preço por Equipamento (R$)'
            defaultValue={1}
            type='number'
            min={1}
            max='any'
            step={0.01}
            value={pricePerEquipment}
            onChange={(value) => setPricePerEquipment(parseFloat(value))}
            errors={errors && errors['pricePerEquipment']}
          />
          <Input
            id='recurringInput'
            grow
            label='Intervalo da recorrência (meses)'
            type='number'
            min={1}
            max={12}
            step={1}
            value={recurringInterval}
            onChange={(value) => setRecurringInterval(parseInt(value))}
            errors={errors && errors['recurringInterval']}
          />
        </div>
        <label id='price-summary'>{`R$${(
          countEquipments * pricePerEquipment
        ).toFixed(2)} a cada ${recurringInterval} mes${recurringInterval > 1 ? 'es' : ''
          }`}</label>

        <div className={style.billingCycle}>
          <div className={style.dateArea}>
            <Checkbox
              label='Definir período de avaliação'
              tooltip='A assinatura do cliente só começará a ser cobrada após o fim do período de avaliação.'
              checked={useTrialEnd}
              onChange={setUseTrialEnd}
            />
            <Input
              type='date'
              id='trialEndInput'
              grow
              disabled={!useTrialEnd}
              label='Fim do período de avaliação'
              min={format(new Date(), 'yyyy-MM-dd')}
              value={format(trialEnd ? trialEnd : new Date(), 'yyyy-MM-dd')}
              onChange={(value) => {
                try {
                  if (value === '') return;
                  const newDate = new Date(value);
                  if (isNaN(newDate.getTime())) return;
                  newDate.setDate(newDate.getDate() + 1);
                  setTrialEnd(newDate);
                } catch (err) {
                  console.warn(`${value} is not a valid date}`);
                }
              }}
              errors={errors && errors['trialEnd']}
            />
          </div>
          <div className={style.dateArea}>
            <Checkbox
              disabled={!newClient}
              label='Definir ciclo da assinatura'
              tooltip='Essa data define o dia do mês em que um novo ciclo da assinatura é gerado. Se não for definido será usado a data atual.'
              checked={useBillingCycle}
              onChange={setUsingBillingCycle}
            />
            <Input
              type='date'
              id='startBillingCycleDateInput'
              grow
              disabled={!useBillingCycle || !newClient}
              label='Início do ciclo de assinatura'
              min={format(new Date(), 'yyyy-MM-dd')}
              value={format(startBillingCycle, 'yyyy-MM-dd')}
              onChange={(value) => {
                try {
                  if (value === '') return;
                  const newDate = new Date(value);
                  if (isNaN(newDate.getTime())) return;
                  newDate.setDate(newDate.getDate() + 1);
                  setStartBillingCycle(newDate);
                } catch (err) {
                  console.warn(`${value} is not a valid date}`);
                }
              }}
              errors={errors && errors['startBillingCycle']}
            />
          </div>
          {!newClient && useTrialEnd && (
            <p>
              Alterar o fim do período de avaliação pode alterar a data da
              assinatura.
            </p>
          )}
          {useBillingCycle && useTrialEnd && (
            <p>
              Será gerada uma fatura parcial entre o fim do período de avaliação
              e o início da assinatura.
            </p>
          )}
          {!newClient && useBillingCycle && !useTrialEnd && (
            <p>
              Será gerada uma fatura parcial entre o dia de hoje e o início da
              assinatura.
            </p>
          )}
          <p>
            Os boletos expiram sempre 3 dias úteis após a data de ciclo da
            assinatura.
          </p>
          <p>
            O intervalo de recorrência começa a contar a partir da data de
            início do ciclo de assinatura (ou hoje, se essa não for definida)
          </p>
        </div>
        <Input
          id='taxId'
          grow
          label='CNPJ'
          type='text'
          value={taxId}
          onChange={setTaxId}
          errors={errors && errors['taxId']}
        />
        <FormSeparator id='signature-address' label='Endereço' />
        <Input
          id='line1'
          grow
          label='Logradouro'
          type='text'
          value={addressLine1}
          onChange={setAddressLine1}
          errors={errors && errors['line1']}
        />
        <Input
          id='city'
          grow
          label='Cidade'
          type='text'
          value={addressCity}
          onChange={setAddressCity}
          errors={errors && errors['city']}
        />
        <Input
          id='state'
          grow
          label='Estado'
          placeholder='rs'
          type='text'
          value={addressState}
          onChange={setAddressState}
          errors={errors && errors['state']}
        />
        <Input
          id='country'
          grow
          label='País'
          placeholder='br'
          type='text'
          value={addressCountry}
          onChange={setAddressCountry}
          errors={errors && errors['country']}
        />
        <Input
          id='postalCode'
          grow
          label='CEP'
          type='text'
          value={addressPostalCode}
          onChange={setAddressPostalCode}
          errors={errors && errors['postalCode']}
        />
        <FormSeparator id='signature-responsible' label='Responsável' />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '1rem',
          }}
        >
          <Input
            id='billingPersonEmail'
            grow
            label='Email'
            type='email'
            value={billingPersonEmail}
            onChange={setBillingPersonEmail}
            errors={errors && errors['billingPerson.email']}
          />
        </div>
      </div>
      <FormSeparator id='language' label='Idioma' />
      <div>
        <Select
          id='name'
          grow
          label='Idioma do cliente'
          options={availableLanguages ? availableLanguages.map((lang) => lang.language + ' - ' + lang.description) : []}
          value={selectedLanguage ? selectedLanguage.language + ' - ' + selectedLanguage.description : ''}
          onChange={(value) => {
            const selectedLanguage = availableLanguages.find(
              (lang) => lang.language + ' - ' + lang.description === value
            );
            setLanguage(selectedLanguage?.language);
          }}
        />
      </div>

      <Button type='submit'>{newClient ? 'Adicionar' : 'Salvar'}</Button>
    </form>
  );
};

export default ClientDetails;
