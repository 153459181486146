import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { getOccurrencesBlacklist } from '../../Components/OccurrencesFilterModal/FilterOccurrencesService';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import { useData } from '../../Controls/DataControl/UseData';
import { Event } from '../../Controls/DataControl/models';
import { useFilter } from '../../Controls/FilterControl';
import style from './OccurrencesOverTimePage.module.css';
import { EmptyState } from '../../Components/Cards/EmptyState';
import { Filter } from '../../Controls/FilterControl/models';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { formatSecondsToHumanReadableTime } from '../../../../../helper/FormatSecondsToHumanReadableTime';
import { OccurrencesFilterModal } from '../../Components/OccurrencesFilterModal';
import { ChartOptionsDatetimeXaxis } from '../../Components/Cards/ChartOptionsDatetimeXaxis';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { useLocale } from '../../../../LocaleControl';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocation } from '../../../../../helper/GetDateFnsLocation';

interface Props {
  containerWidth: number;
  containerHeight: number;
  events?: Event[];
}

export const OccurrencesOverTimePage = ({
  containerHeight,
  containerWidth,
}: Props): React.ReactElement => {
  const [events, setEvents] = useState<Event[]>();
  const { filter, resetFilterRules } = useFilter();
  const [occurrencesBlacklist, setOccurrencesBlacklist] = useState<string[]>(getOccurrencesBlacklist());
  const { fetchEvents } = useData();
  const abortFetchRef = useRef<() => void>();
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState<ApexAxisChartSeries>();
  const [showFilter, setShowFilter] = useState(false);
  const { chartOptionsLocale } = useLocale();
  const { t } = useTranslation();
  const FALLBACK_LANGUAGE = 'pt_BR';

  const labels: any = {
    Desconectado: t('cardsPage:disconnected', { defaultValue: 'Desconectado' }),
    ['Ocorrência não informada']: t('cardsPage:occurrenceNotInformed', { defaultValue: 'Ocorrência não informada' }),
    Conectado: t('cardsPage:connected', { defaultValue: 'Conectado' }),
  };

  const localFetch = useCallback(
    async (localFetchFilter: Filter, localOccurrencesBlacklist) => {
      setLoading(true);
      try {
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }
        const { abort, fetch } = fetchEvents(localOccurrencesBlacklist);
        abortFetchRef.current = abort;
        const response = await fetch(localFetchFilter);

        setEvents(response);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [abortFetchRef, fetchEvents, setEvents]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    setEvents(undefined);
    localFetch(filter, occurrencesBlacklist);
  }, [filter, setLoading, occurrencesBlacklist]);

  var options: ApexOptions = {
    chart: {
      id: 'OccurrencesChart',
      animations: {
        enabled: false,
      },
      type: 'rangeBar',
      ...chartOptionsLocale,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        right: 50,
      },
    },
    responsive: [
      {
        breakpoint: 900,
        options: {
          dataLabels: {
            style: { fontSize: '1.175em', colors: ['#222'] },
          },

          ...ChartOptionsDatetimeXaxis(3),
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          dataLabels: {
            style: { fontSize: '1.175em', colors: ['#222'] },
          },
          ...ChartOptionsDatetimeXaxis(3),
          yaxis: {
            labels: {
              minWidth: 70,
              maxWidth: 7000,
              style: {
                fontSize: '1em',
              },
              formatter: (val: string) => val.toString().trim(),
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '95%',
        columnWidth: '100%',
      },
    },
    ...ChartOptionsDatetimeXaxis(14),
    yaxis: {
      title: { ...AxisTitleConfiguration(t('specificChartPage:occurrence', { defaultValue: 'Ocorrência' })), offsetX: 10 },
      labels: {
        minWidth: 150,
        maxWidth: 1000,
        align: 'left',
        style: {
          fontSize: '1em',
        },
        formatter: (val) => labels[val.toString().trim()] ?? val.toString().trim(),
      },
    },

    stroke: {
      width: 1,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    ...ChartOptionsLegend,
    tooltip: {
      shared: false,
      intersect: true,
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const serie = w.globals.initialSeries[seriesIndex];

        const dataPoint = serie.data[dataPointIndex];
        const totalTime = serie.data.reduce(
          (acc: any, curr: { y: number[] }) => acc + curr.y[1] - curr.y[0],
          0
        );
        const currentOccurrenceTime = dataPoint.y[1] - dataPoint.y[0];
        const percentage = ((currentOccurrenceTime / totalTime) * 100).toFixed(
          0
        );
        return `<div style="padding: 0.5rem 1rem">
        <div style="padding: 0.25rem 0; border-bottom: 1px solid #ddd; display:flex; flex-direction: column">
        <span>${serie.name}</span>
        <span>${labels[dataPoint.x] ?? dataPoint.x}</span>
        <div style="width:100%; display: flex; justify-content:space-between;">${formatSecondsToHumanReadableTime(
          currentOccurrenceTime / 1000
        )} ${percentage}%</div></div>
        <div style="display: flex; flex-direction: column; line-height: 1.25rem"><span>${t('specificChartPage:startColon', { defaultValue: 'inicio:' })} ${format(
          new Date(dataPoint.y[0]),
          'HH:mm:ss dd/MMM',
          { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) }
        )}</span><span>${t('specificChartPage:endColon', { defaultValue: 'fim:' })} ${format(
          new Date(dataPoint.y[1]),
          'HH:mm:ss dd/MMM',
          { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) }
        )}</span></div>
        </div>`;
      },
    },
  };

  const chartHeight = useMemo(() => {
    const tempCategories = (events ?? []).reduce<string[]>((acc, curr) => {
      return [...acc, ...curr.events.map(({ description }) => description)];
    }, []);
    return (
      Array.from(new Set(tempCategories)).length * (events ?? []).length * 40
    );
  }, [events]);

  useEffect(() => {
    if (!events) return;
    setSeries(
      events
        .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
        .map((event) => {
          return {
            name: event.equipment.name,
            data: event.events
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((curr) => {
                return {
                  x: curr?.description?.trim() ?? t('specificChartPage:eventNotFound', { defaultValue: 'evento não encontrado' }),
                  y: [
                    Math.max(
                      curr.startDate.getTime(),
                      curr.windowStart.getTime()
                    ),
                    Math.min(
                      (curr.endDate ? curr.endDate : new Date()).getTime(),
                      curr.windowEnd.getTime()
                    ),
                  ],
                };
              }),
          };
        })
    );
    setLoading(false);
  }, [events, setSeries, setLoading]);

  useEffect(() => {
    setEvents(undefined);
    setSeries(undefined);
  }, [setEvents, setSeries]);

  return (
    <>
      {showFilter && (
        <OccurrencesFilterModal
          handleClose={() => {
            setOccurrencesBlacklist(getOccurrencesBlacklist());
            setShowFilter(false);
          }}
        />
      )}
      <div className={style.container}>
        <SpecificChartPageTitle
          text={t('specificChartPage:occurrences', { defaultValue: 'Ocorrências' })}
          onFilterClick={() => {
            setShowFilter(true);
          }}
        />
        <div className={style.chartsArea}>
          {loading ? (
            <LoadingState />
          ) : series && series.some(({ data }) => data.length) ? (
            options && (
              <Chart
                type='rangeBar'
                series={series}
                options={options}
                height={Math.max(chartHeight, containerHeight - 200)}
                width={
                  containerWidth <= 1600
                    ? containerWidth - 100
                    : containerWidth - 90
                }
              />
            )
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
    </>
  );
};
