import * as React from 'react';
import { Tooltip } from '../Tooltip';
import style from './Label.module.css';

export const Label = React.forwardRef<
  HTMLLabelElement,
  { value: string; htmlFor?: string; inverted?: boolean; tooltip?: string, optionalStyle?: React.CSSProperties }
>(({ value, htmlFor, inverted, tooltip, optionalStyle }, ref) => {
  return (
    <Tooltip tooltip={tooltip ?? value}>
      <label
        htmlFor={htmlFor}
        ref={ref}
        className={style.label}
        data-inverted={inverted}
        style={optionalStyle}
      >
        {value}
      </label>
    </Tooltip>
  );
});
