import React, { useEffect, useState } from 'react';
import { BiLock, BiRefresh } from 'react-icons/bi';
import { TbLockOpen, TbLock } from 'react-icons/tb';
import { FiPlus, FiUser, FiUserCheck, FiUserX } from 'react-icons/fi';
import { GrUserAdmin } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { useToast } from '../../../../../hooks/toast';
import { useAuth, User } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { ChangeUserRoleModal } from './ChangeUserRoleModal';
import style from './UsersListPage.module.css';
import { useTranslation } from 'react-i18next';
import { FiUserMinus } from 'react-icons/fi';
import { ConfirmDeleteUserModal } from './ConfirmDeleteUserModal';

export const UsersListPage = (): React.ReactElement => {
  const { location, push } = useHistory();
  const { addToast } = useToast();
  const [users, setUsers] = useState<User[]>([]);
  const { api, signOut, user } = useAuth();
  const { client } = useTenant();
  const [showRoleModalOfUser, setShowRoleModalOfUser] = useState<User | null>();
  const { t } = useTranslation();
  const userRoleMap = {
    Admin: t('userPage:administrator', { defaultValue: 'Administrador' }),
    Operator: t('userPage:operator', { defaultValue: 'Operador' }),
    Supervisor: t('userPage:supervisor', { defaultValue: 'Supervisor' }),
  };

  const [userToDelete, setUserToDelete] = useState<Pick<User, '_id' | 'name'>>();

  useEffect(() => {
    fetchUsersList();
    const pollingUsers = setInterval(() => {
      fetchUsersList();
    }, 10000);
    return () => {
      clearInterval(pollingUsers);
    };
  }, []);

  const fetchUsersList = async () => {
    api
      .get<User[]>(`/api/client/${client?.tenant}/users/profile`)
      .then((users) => setUsers(users))
      .catch((error) => {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: t('userPage:sessionExpired', { defaultValue: 'Sessão expirada' }),
            description: t('userPage:loginAgain', { defaultValue: 'Faça login novamente.' }),
          });
          signOut();
        }
        throw 'error';
      });
  };

  const UpdateUserRoleModal = ({
    user,
  }: {
    user: User;
  }): React.ReactElement => {
    return (
      <ChangeUserRoleModal
        user={user}
        handleClose={(refresh) => {
          setShowRoleModalOfUser(null);
          if (refresh) {
            addToast({
              title: t('userPage:success', { defaultValue: 'Sucesso' }),
              description: t('userPage:updatedUserNamePermissions', { userName: user.name, defaultValue: `Permissões de ${user.name} alteradas.` }),
              type: 'success',
            });
            fetchUsersList();
          }
        }}
      />
    );
  };

  const handleBlockUser = async ({
    _id,
    name,
    blocked,
  }: Pick<User, '_id' | 'name' | 'blocked'>) => {
    try {
      const response = await api.post<User, { block: boolean }>(
        `/api/client/${client?.tenant}/users/profile/${_id}/block`,
        { block: !blocked }
      );
      if (response.blocked !== blocked) {
        addToast({
          title: t('userPage:success', { defaultValue: 'Sucesso' }),
          description: response.blocked ?
            t('userPage:userNameBlocked', { userName: name, defaultValue: `Usuário ${name} foi bloqueado e não pode mais acessar o sistema.` }) :
            t('userPage:userNameUnblocked', { userName: name, defaultValue: `Usuário ${name} desbloqueado e pode voltar a utilizar o sistema.` }),
          type: 'success',
        });
        fetchUsersList();
      }
    } catch (error) { }
  };

  const handleResetPassword = async ({ email }: Pick<User, 'email'>) => {
    try {
      const response = await api.post<{ success: boolean }, { email: string }>(
        `/api/client/${client?.tenant}/users/access/forgot`,
        { email }
      );
      if (response.success) {
        addToast({
          title: t('userPage:requestedSuccessfully', { defaultValue: 'Solicitado com sucesso.' }),
          description: t('userPage:emailSentToResetPassword', { email: email, defaultValue: `Um email foi enviado para ${email} para redefinir a senha.` }),
          type: 'success',
        });
      }
    } catch (error) {
      addToast({
        title: 'Erro',
        description: t('userPage:problemRequestingPasswordReset', { defaultValue: 'Houve um problema ao solicitar a redefinição da senha.' }),
        type: 'error',
      });
    }
  };

  return (
    <div className={style.container}>
      {showRoleModalOfUser && (
        <UpdateUserRoleModal user={showRoleModalOfUser} />
      )}
      {userToDelete && (
        <ConfirmDeleteUserModal
          tenant={client?.tenant || ''}
          id={userToDelete._id}
          name={userToDelete.name}
          handleClose={(success) => {
            setUserToDelete(undefined);
            if (success) {
              fetchUsersList();
            }
          }}
        />
      )}
      <Button
        onClick={() => push(`${location.pathname}/novo`)}
        icon={<FiPlus />}
      >
        {t('userPage:user', { defaultValue: 'Usuário' })}
      </Button>
      <ul className={`${style.usersList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.column} ${style.statusColumn}`}></div>
          <div className={`${style.column} ${style.nameColumn}`}>{t('userPage:name', { defaultValue: 'Nome' })}</div>
          <div className={`${style.column} ${style.emailColumn}`}>{t('userPage:email', { defaultValue: 'Email' })}</div>
          <div className={`${style.column} ${style.roleColumn}`}>{t('userPage:role', { defaultValue: 'Permissão' })}</div>
          <div className={`${style.column} ${style.actionsColumn}`}></div>
        </li>
        {users.map(
          ({
            _id,
            blocked,
            tenantOwner,
            name,
            email,
            role,
            profilePicPath,
            emailNotification,
          }) => (
            <li key={_id} className={style.row}>
              <div
                className={`${style.column} ${style.statusColumn} ${blocked ? style.blocked : style.unblocked
                  } ${tenantOwner ? style.tenantOwner : ''}`}
              >
                {tenantOwner ? (
                  <FiUser />
                ) : blocked ? (
                  <FiUserX />
                ) : (
                  <FiUserCheck />
                )}
              </div>
              <div className={`${style.column} ${style.nameColumn}`}>
                {name}
              </div>
              <div className={`${style.column} ${style.emailColumn}`}>
                {email}
              </div>
              <div className={`${style.column} ${style.roleColumn}`}>
                {userRoleMap[role]}
              </div>
              <div className={`${style.column} ${style.actionsColumn}`}>
                <Tooltip tooltip={t('userPage:deleteUser', { defaultValue: 'Remover Usuário' })}>
                  <div>
                    <FiUserMinus onClick={() => {
                      setUserToDelete({ _id, name });
                    }}></FiUserMinus>
                  </div>
                </Tooltip>
                <Tooltip tooltip={t('userPage:updateRole', { defaultValue: 'Alterar Permissão' })}>
                  <div>
                    <GrUserAdmin
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowRoleModalOfUser({
                          _id,
                          blocked,
                          tenantOwner,
                          name,
                          email,
                          role,
                          tenant: user?.tenant ?? '',
                          profilePicPath,
                          emailNotification,
                        });
                      }}
                    />
                  </div>
                </Tooltip>
                <Tooltip tooltip={blocked ? t('userPage:unblock', { defaultValue: 'Desbloquear' }) : t('userPage:block', { defaultValue: 'Bloquear' })}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleBlockUser({ blocked, name, _id });
                    }}
                  >
                    {blocked ? <TbLockOpen /> : <TbLock />}
                  </div>
                </Tooltip>
                <Tooltip tooltip={t('userPage:resetPassword', { defaultValue: 'Redefinir senha' })}>
                  <div
                    className={style.resetPasswordIcon}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleResetPassword({ email });
                    }}
                  >
                    <BiLock />
                    <BiRefresh className={style.refreshIcon} />
                  </div>
                </Tooltip>
              </div>
            </li>
          )
        )}
      </ul>
    </div >
  );
};
