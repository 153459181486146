import React from 'react';
import { FiCheck } from 'react-icons/fi';
import style from './Checkbox.module.css';
import { Label } from '../Label';
interface Props {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  alignStart?: boolean;
  invertColorLabel?: boolean;
  tooltip?: string;
  inline?: boolean;
  tiny?: boolean;
  labelStyle?: React.CSSProperties;
}
const Checkbox = ({
  label,
  checked,
  onChange,
  disabled,
  invertColorLabel,
  alignStart,
  tooltip,
  inline,
  tiny,
  labelStyle
}: Props): React.ReactElement => {
  return (
    <div
      data-disabled={disabled}
      data-color-inverted={invertColorLabel}
      data-align-left={alignStart}
      data-inline={inline}
      key={`checkbox-${label}`}
      className={style.container}
      onClick={() => {
        !disabled && onChange(!checked);
      }}
    >
      <Label tooltip={tooltip} value={label} optionalStyle={labelStyle} />
      <div data-disabled={disabled} data-tiny={tiny} className={style.checkbox}>
        {checked ? <FiCheck /> : ''}
      </div>
    </div>
  );
};

export default Checkbox;
