import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Input } from '../../../../../Components/Atoms/Input';
import AppError from '../../../../../helper/AppError';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { Coil } from './CoilsListPage';
import style from './CoilsListPage.module.css';
import { ConfirmDeleteCoilModal } from './ConfirmDeleteCoilModal';
import { useTranslation } from 'react-i18next';

export interface Props {
  newCoil?: boolean;
}

export const CoilPage = ({ newCoil }: Props): React.ReactElement => {
  const [id, setId] = useState<string>();
  const [name, setName] = useState('');
  const [code, setCode] = useState<number>();
  const [weight, setWeight] = useState<number>();
  const [estimate, setEstimate] = useState<number>();
  const [enabled, setEnabled] = useState(true);
  const [waitDeleteConfirmation, setWaitDeleteConfirmation] = useState(false);
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const { push } = useHistory();
  const { t } = useTranslation();

  const {
    params: { id: editCode },
  } = useRouteMatch<{ id: string }>();

  useEffect(() => {
    if (!newCoil) {
      api
        .get<Coil>(
          `/api/client/${client?.tenant}/registrations/coils/${editCode}`
        )
        .then((response) => {
          setCode(response.code);
          setId(response.id);
          setName(response.name);
          setWeight(response.weight);
          setEstimate(response.estimate);
          setEnabled(response.enabled);
        })
        .catch((e: AppError) => {
          if (e.statusCode === 404) {
            addToast({ title: t('coilPage:coilNotFound', { defaultValue: 'Bobina não encontrada.' }), type: 'error' });
          } else addToast({ title: t('coilPage:unexpectedError', { defaultValue: 'Erro inesperado.' }), type: 'error' });
          push(`/client/${client?.tenant}/admin/bobinas`);
        });
    }
  }, [newCoil]);

  const handleCreateCoil = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.post(`/api/client/${client?.tenant}/registrations/coils`, {
        name: name.trim(),
        code,
        weight,
        estimate,
      });
      addToast({ title: t('coilPage:coilCreatedSuccessfully', { defaultValue: 'Bobina criada com sucesso.' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/bobinas`);
    } catch (_) {
      addToast({ title: t('coilPage:errorCreatingCoil', { defaultValue: 'Erro ao adicionar bobina.' }), type: 'error' });
    }
  };

  const handleUpdateCoil = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.put(`/api/client/${client?.tenant}/registrations/coils`, {
        name: name.trim(),
        id,
        weight,
        estimate,
      });
      addToast({ title: t('coilPage:coilUpdatedSuccessfully', { defaultValue: 'Bobina alterada com sucesso.' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/bobinas`);
    } catch (_) {
      addToast({ title: t('coilPage:errorUpdatingCoil', { defaultValue: 'Erro ao alterar bobina.' }), type: 'error' });
    }
  };

  const validateFormFields = (): boolean => {
    if (!name) {
      addToast({ title: t('coilPage:fieldNameIsMandatory', { defaultValue: 'O campo "Nome" é obrigatório' }), type: 'error' });
      return false;
    }
    if (!code) {
      addToast({ title: t('coilPage:fieldCodeIsMandatory', { defaultValue: 'O campo "Código" é obrigatório' }), type: 'error' });
      return false;
    }
    if (!weight) {
      addToast({ title: t('coilPage:fieldWeightIsMandatory', { defaultValue: 'O campo "Peso" é obrigatório' }), type: 'error' });
      return false;
    }
    if (!estimate) {
      addToast({ title: t('coilPage:fieldEstimateIsMandatory', { defaultValue: 'O campo "Estimativa" é obrigatório' }), type: 'error' });
      return false;
    }
    return true;
  }

  return (
    <form
      className={style.container}
      onSubmit={(e) => {
        e.preventDefault();
        if (validateFormFields()) {
          !newCoil ? handleUpdateCoil(e) : handleCreateCoil(e);
        }
      }}
    >
      {waitDeleteConfirmation && id && (
        <ConfirmDeleteCoilModal
          tenant={client?.tenant || ''}
          id={id}
          name={name}
          handleClose={(success) => {
            setWaitDeleteConfirmation(false);
            if (success) {
              push(`/client/${client?.tenant}/admin/bobinas`);
            }
          }}
        />
      )}
      <Input
        label={t('coilPage:name', { defaultValue: 'Nome' })}
        maxLength={48}
        value={name}
        disabled={!enabled}
        onChange={setName}
        tooltip='Máximo de 48 caracteres'
      />
      <Input
        disabled={!newCoil}
        label={t('coilPage:code', { defaultValue: 'Código' })}
        type='number'
        min={0}
        defaultValue={0}
        value={code}
        onChange={(value) => setCode(parseInt(value))}
      />
      <Input
        label={t('coilPage:weight', { defaultValue: 'Peso' })}
        disabled={!enabled}
        type='number'
        value={weight}
        onChange={(value) => setWeight(parseFloat(value))}
      />
      <Input
        label={t('coilPage:estimation', { defaultValue: 'Estimativa' })}
        disabled={!enabled}
        type='number'
        maxLength={48}
        value={estimate}
        onChange={(value) => setEstimate(parseInt(value))}
      />
      <div className={style.buttons}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/bobinas`)}
        >
          {t('coilPage:cancel', { defaultValue: 'Cancelar' })}
        </Button>
        {enabled && !newCoil && (
          <Button
            danger
            disabled={newCoil}
            onClick={() => {
              setWaitDeleteConfirmation(true);
            }}
          >
            {t('coilPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        )}
        <Button type='submit'>{!newCoil ?
          t('coilPage:save', { defaultValue: 'Salvar' }) :
          t('coilPage:add', { defaultValue: 'Adicionar' })}
        </Button>
      </div>
    </form>
  );
};