import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import style from './OccurrencesHistogramBubbleChart.module.css';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useResponsiveContainer } from '../../../Controls/ResponsiveContainerControl/UserResponsiveContainer';
import { Event } from '../../../Controls/DataControl/models';
import { EmptyState } from '../EmptyState';
import { formatSecondsToHumanReadableTime } from '../../../../../../helper/FormatSecondsToHumanReadableTime';
import { Filter } from '../../../Controls/FilterControl/models';
import { LoadingState } from '../LoadingState';
import { FiFilter } from 'react-icons/fi';
import { AxisTitleConfiguration } from '../../../../../../helper/AxisTitleConfiguration';
import { ChartOptionsLegend } from '../ChartOptionsLegend';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../LocaleControl';

interface Props {
  occurrencesBlacklist: string[];
  showOccurrencesFilter: () => void;
}

export const EventsHistogramBubbleChart = ({
  occurrencesBlacklist,
  showOccurrencesFilter,
}: Props): React.ReactElement => {
  const { filter } = useFilter();
  const { fetchEvents } = useData();
  const { containerWidth } = useResponsiveContainer();
  const [options, setOptions] = useState<ApexOptions>();
  const [series, setSeries] = useState<
    { name: string; data: { x: string; y: number; z: number }[] }[]
  >([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const abortFetchRef = useRef<() => void>();
  const { t } = useTranslation();
  const { chartOptionsLocale } = useLocale();

  const labels: any = {
    Desconectado: t('cardsPage:disconnected', { defaultValue: 'Desconectado' }),
    ['Ocorrência não informada']: t('cardsPage:occurrenceNotInformed', { defaultValue: 'Ocorrência não informada' }),
    Conectado: t('cardsPage:connected', { defaultValue: 'Conectado' }),
  };

  const localFetch = useCallback(
    async (localFilter: Filter, localOccurrencesBlacklist: string[]) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      const { abort, fetch } = fetchEvents(localOccurrencesBlacklist);
      abortFetchRef.current = abort;
      const result = await fetch(localFilter);
      setEvents(result);
      setLoading(false);
    },
    [abortFetchRef, fetchEvents, setEvents]
  );

  useEffect(() => {
    setLoading(true);
    localFetch(filter, occurrencesBlacklist);
  }, [filter, occurrencesBlacklist]);

  useEffect(() => {
    const occurrences = events.map(({ events }) => events).flat();
    const setOfOccurrences = new Set(
      occurrences.map(({ description }) => description ?? t('cardsPage:notSpecified', { defaultValue: 'Não especificado' }))
    );
    setSeries([
      {
        name: 'serie1',
        data: [
          { x: '', y: -10, z: 0 },
          ...Array.from(setOfOccurrences)
            .map((occurrenceDescription) =>
              occurrences
                .filter(
                  ({ description }) => description === occurrenceDescription
                )
                .reduce(
                  (acc, curr) => ({
                    ...acc,
                    y: acc.y + 1,
                    z: acc.z + curr.duration,
                  }),
                  {
                    x: occurrenceDescription,
                    y: 0,
                    z: 0,
                  }
                )
            )
            .sort((a, b) => b.z - a.z)
            .filter(({ y, z }) => y > 0 && z > 0)
            .slice(0, 10)
            .map(({ x, ...rest }, index) => ({
              x: `${index + 1}-${x}`,
              ...rest,
            })),
        ],
      },
    ]);
  }, [events]);
  useEffect(() => {
    if (series.length === 0) return;
    setOptions({
      legend: {
        show: false,
      },
      plotOptions: {
        bubble: {
          maxBubbleRadius: 50,
          minBubbleRadius: 5,
        },
      },
      colors: ['#27dc94'],
      chart: {
        ...chartOptionsLocale,
        type: 'bubble',
        zoom: {
          enabled: true,
          type: 'xy',
          autoScaleYaxis: true,
        },
        animations: {
          enabled: false,
        },
        id: 'EventsHistogramBubbleChart',
        toolbar: { show: false },
      },
      stroke: {
        curve: 'smooth',
      },

      dataLabels: { enabled: false },
      tooltip: {
        custom: ({ w, seriesIndex, dataPointIndex }) => {
          const dataPoint: any =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          if (!dataPoint) return 'no datapoint found';
          return `<div style="">
    <div style="padding: 2px 8px; border-bottom: 1px solid #ddd">${dataPoint.x.slice(0,2) + labels[dataPoint.x.slice(2, dataPoint.x.length)] ?? dataPoint.x}</div>
    <div style=" padding: 2px 8px">
    <div style="display: flex; justify-content: space-between; gap: 1rem;"><span>${t('cardsPage:count', { defaultValue: 'Contagem' })}</span> <span>${dataPoint.y.toFixed()}</span></div>
    <div style="display: flex; justify-content: space-between; gap: 1rem;"><span>${t('cardsPage:duration', { defaultValue: 'Duração' })}</span> <span>${formatSecondsToHumanReadableTime(
              dataPoint.z
            )}</span></div>
    </div>`;
        },
      },
      xaxis: {
        type: 'category',
        tickPlacement: 'on',
        tooltip: { enabled: false },
        max: series[0].data.length + 1,
        offsetX: -1,
        title: AxisTitleConfiguration(t('cardsPage:occurrence', { defaultValue: 'Ocorrência' })),
        labels: {
          formatter: (val) => val?.split('-')[0],
        },
      },

      yaxis: {
        max: Math.max(...series[0].data.map(({ y }) => y)) * 1.1,
        min: 0,
        tickAmount: 3,
        title: AxisTitleConfiguration(t('cardsPage:quantity', { defaultValue: 'Quantidade' })),
        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
    });
  }, [series, t]);

  useEffect(() => {
    return () => {
      setOptions(undefined);
      setEvents([]);
      setSeries([]);
    };
  }, [setOptions, setSeries, setEvents]);
  return (
    <div className={style.chartContainer}>
      <div className={style.header}>
        <h2>{t('cardsPage:mainOccurrences', { defaultValue: 'Principais Ocorrências' })} </h2>
        <FiFilter onClick={() => showOccurrencesFilter()} />
      </div>
      <div className={style.xaxisLegend}>
        {series.length &&
          series[0].data
            .filter(({ x }) => !!x)
            .map(({ x }) => (
              <div key={x} className={style.xaxisLegendItem}>
                {x.slice(0,2) + labels[x.slice(2, x.length)] ?? x}
              </div>
            ))}
      </div>
      {loading ? (
        <LoadingState />
      ) : series[0]?.data?.length > 1 ? (
        <Chart
          type='bubble'
          options={options}
          series={series}
          height={containerWidth > 767 ? 330 : 280}
        />
      ) : (
        <EmptyState />
      )}
    </div>
  );
};
