import React from 'react';
import { LocaleContext } from './LocaleContext';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Zoom } from '../TenantPages/SignedIn/Controls/FilterControl';

const getZoomOptions = (
  t: TFunction<'translation', undefined>
): { key: Zoom; text: string }[] => {
  return [
    {
      key: 'lst30m',
      text: t('headerPage:last30min', { defaultValue: 'Últimos 30 min' }),
    },
    {
      key: 'lst60m',
      text: t('headerPage:lastHour', { defaultValue: 'Última hora' }),
    },
    { key: 'today', text: t('headerPage:today', { defaultValue: 'Hoje' }) },
    {
      key: 'lst24h',
      text: t('headerPage:last24h', { defaultValue: 'Últimas 24h' }),
    },
    {
      key: 'yesterday',
      text: t('headerPage:yesterday', { defaultValue: 'Ontem' }),
    },
    {
      key: 'thisweek',
      text: t('headerPage:thisWeek', { defaultValue: 'Essa semana' }),
    },
    {
      key: 'lst7d',
      text: t('headerPage:last7Days', { defaultValue: 'Últimos 7 dias' }),
    },
    {
      key: 'thismonth',
      text: t('headerPage:thisMonth', { defaultValue: 'Esse mês' }),
    },
    {
      key: 'lst30d',
      text: t('headerPage:last30Days', { defaultValue: 'Últimos 30 dias' }),
    },
    {
      key: 'custom',
      text: t('headerPage:custom', { defaultValue: 'Personalizado' }),
    },
  ];
};

const getCalendarLocale = (t: TFunction<any, any>) => {
  return {
    name: 'br_th',
    months: [
      [
        t('shared:january', { defaultValue: 'Janeiro' }),
        t('shared:januaryAbbreviation', { defaultValue: 'Jan' }),
      ],
      [
        t('shared:february', { defaultValue: 'Fevereiro' }),
        t('shared:februaryAbbreviation', { defaultValue: 'Fev' }),
      ],
      [
        t('shared:march', { defaultValue: 'Março' }),
        t('shared:marchAbbreviation', { defaultValue: 'Mar' }),
      ],
      [
        t('shared:april', { defaultValue: 'Abril' }),
        t('shared:aprilAbbreviation', { defaultValue: 'Abr' }),
      ],
      [
        t('shared:may', { defaultValue: 'Maio' }),
        t('shared:mayAbbreviation', { defaultValue: 'Mai' }),
      ],
      [
        t('shared:june', { defaultValue: 'Junho' }),
        t('shared:juneAbbreviation', { defaultValue: 'Jun' }),
      ],
      [
        t('shared:july', { defaultValue: 'Julho' }),
        t('shared:julyAbbreviation', { defaultValue: 'Jul' }),
      ],
      [
        t('shared:august', { defaultValue: 'Agosto' }),
        t('shared:augustAbbreviation', { defaultValue: 'Ago' }),
      ],
      [
        t('shared:september', { defaultValue: 'Setembro' }),
        t('shared:septemberAbbreviation', { defaultValue: 'Set' }),
      ],
      [
        t('shared:october', { defaultValue: 'Outubro' }),
        t('shared:octoberAbbreviation', { defaultValue: 'Out' }),
      ],
      [
        t('shared:november', { defaultValue: 'Novembro' }),
        t('shared:novemberAbbreviation', { defaultValue: 'Nov' }),
      ],
      [
        t('shared:december', { defaultValue: 'Dezembro' }),
        t('shared:decemberAbbreviation', { defaultValue: 'Dez' }),
      ],
    ],
    weekDays: [
      [
        t('shared:saturday', { defaultValue: 'Sabado' }),
        t('shared:saturdayAbbreviation', { defaultValue: 'sab' }),
      ],
      [
        t('shared:sunday', { defaultValue: 'Domingo' }),
        t('shared:sundayAbbreviation', { defaultValue: 'dom' }),
      ],
      [
        t('shared:monday', { defaultValue: 'Segunda-feira' }),
        t('shared:mondayAbbreviation', { defaultValue: 'seg' }),
      ],
      [
        t('shared:tuesday', { defaultValue: 'Terça-feira' }),
        t('shared:tuesdayAbbreviation', { defaultValue: 'ter' }),
      ],
      [
        t('shared:wednesday', { defaultValue: 'Quarta-feira' }),
        t('shared:wednesdayAbbreviation', { defaultValue: 'qua' }),
      ],
      [
        t('shared:thursday', { defaultValue: 'Quinta-feira' }),
        t('shared:thursdayAbbreviation', { defaultValue: 'qui' }),
      ],
      [
        t('shared:friday', { defaultValue: 'Sexta-feira' }),
        t('shared:fridayAbbreviation', { defaultValue: 'sex' }),
      ],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
      ['ก่อนเที่ยง', 'เอเอ็ม'],
      ['หลังเที่ยง', 'พีเอ็ม'],
    ],
  };
};

const getChartOptionsLocale = (t: TFunction<any, any>) => {
  return {
    defaultLocale: 'ptBR',
    locales: [
      {
        name: 'ptBR',
        options: {
          months: [
            t('shared:january', { defaultValue: 'Janeiro' }),
            t('shared:february', { defaultValue: 'Fevereiro' }),
            t('shared:march', { defaultValue: 'Março' }),
            t('shared:april', { defaultValue: 'Abril' }),
            t('shared:may', { defaultValue: 'Maio' }),
            t('shared:june', { defaultValue: 'Junho' }),
            t('shared:july', { defaultValue: 'Julho' }),
            t('shared:august', { defaultValue: 'Agosto' }),
            t('shared:september', { defaultValue: 'Setembro' }),
            t('shared:october', { defaultValue: 'Outubro' }),
            t('shared:november', { defaultValue: 'Novembro' }),
            t('shared:december', { defaultValue: 'Dezembro' }),
          ],
          shortMonths: [
            t('shared:januaryAbbreviation', { defaultValue: 'Jan' }),
            t('shared:februaryAbbreviation', { defaultValue: 'Fev' }),
            t('shared:marchAbbreviation', { defaultValue: 'Mar' }),
            t('shared:aprilAbbreviation', { defaultValue: 'Abr' }),
            t('shared:mayAbbreviation', { defaultValue: 'Mai' }),
            t('shared:juneAbbreviation', { defaultValue: 'Jun' }),
            t('shared:julyAbbreviation', { defaultValue: 'Jul' }),
            t('shared:augustAbbreviation', { defaultValue: 'Ago' }),
            t('shared:septemberAbbreviation', { defaultValue: 'Set' }),
            t('shared:octoberAbbreviation', { defaultValue: 'Out' }),
            t('shared:novemberAbbreviation', { defaultValue: 'Nov' }),
            t('shared:decemberAbbreviation', { defaultValue: 'Dez' }),
          ],
          days: [
            t('shared:sunday', { defaultValue: 'Domingo' }),
            t('shared:mondayShort', { defaultValue: 'Segunda' }),
            t('shared:tuesdayShort', { defaultValue: 'Terça' }),
            t('shared:wednesdayShort', { defaultValue: 'Quarta' }),
            t('shared:thursdayShort', { defaultValue: 'Quinta' }),
            t('shared:fridayShort', { defaultValue: 'Sexta' }),
            t('shared:saturday', { defaultValue: 'Sabado' }),
          ],
          shortDays: [
            t('shared:sundayAbbreviation', { defaultValue: 'Dom' }),
            t('shared:mondayAbbreviation', { defaultValue: 'Seg' }),
            t('shared:tuesdayAbbreviation', { defaultValue: 'Ter' }),
            t('shared:wednesdayAbbreviation', { defaultValue: 'Qua' }),
            t('shared:thursdayAbbreviation', { defaultValue: 'Qui' }),
            t('shared:fridayAbbreviation', { defaultValue: 'Sex' }),
            t('shared:saturdayAbbreviation', { defaultValue: 'Sab' }),
          ],
          toolbar: {
            download: t('cardsPage:downloadSvg', {
              defaultValue: 'Baixar SVG',
            }),
            selection: t('cardsPage:select', { defaultValue: 'Selecionar' }),
            selectionZoom: t('cardsPage:zoom', { defaultValue: 'Zoom' }),
            zoomIn: t('cardsPage:zoomIn', { defaultValue: 'Mais zoom' }),
            zoomOut: t('cardsPage:zoomOut', { defaultValue: 'Menos zoom' }),
            pan: t('cardsPage:move', { defaultValue: 'Mover' }),
            reset: t('cardsPage:reset', { defaultValue: 'Início' }),
          },
        },
      },
    ],
  };
};

export const LocaleProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const calendarLocale = getCalendarLocale(t);
  const chartOptions = getChartOptionsLocale(t);
  const zoomOptions = getZoomOptions(t);

  return (
    <LocaleContext.Provider
      value={{
        calendarLocale,
        chartOptionsLocale: chartOptions,
        zoomOptions,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
