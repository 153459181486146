import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { getDateFnsLocation } from '../../../../../helper/GetDateFnsLocation';

export const ConformityChartTooltip = (max: string, min: string, avg: string, criterion: string, avgCriterion: string, samplesAmount: string): Pick<ApexOptions, 'tooltip'> => ({
  tooltip: {
    shared: false,
    intersect: true,
    followCursor: true,
    custom: ({ seriesIndex, dataPointIndex, w }) => {
      const dataPoint =
        w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
      const grouped = dataPoint?.metadata?.samples.length > 1;
      const FALLBACK_LANGUAGE = 'pt_BR';
      if (!dataPoint) return 'no datapoint found';
      if (!Array.isArray(dataPoint.y)) {
        return `<div style="font-size: 1em">
          <div style="padding: 2px 8px; border-bottom: 1px solid #ddd">${format(
          new Date(dataPoint.x),
          'cccccc dd MMM yy HH:mm:ss',
          {
            locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
          }
        )}</div>
          <div style=" padding: 2px 8px">
          <div style="display: flex; justify-content: space-between; gap: 1rem;"><span>${w.globals.initialSeries[seriesIndex].name
          }</span> <span>${formatNumberToNotation(dataPoint.y)} ${dataPoint?.metadata?.unit ?? ''
          }</span></div>
          <div style="display: flex; justify-content: space-between; gap: 1rem;"><span>${samplesAmount}</span> <span>${formatNumberToNotation(
            dataPoint?.metadata?.samples.length ?? 0
          )} un</span></div>
          <div style="display: flex; justify-content: space-between;"><span>${max}</span><span>${formatNumberToNotation(
            dataPoint?.metadata?.max ?? 0
          )} ${dataPoint?.metadata?.unit ?? ''}</span></div>
          <div style="display: flex; justify-content: space-between; gap: 1rem;"><span>${avg}</span> <span>${formatNumberToNotation(
            dataPoint?.metadata?.avg ?? 0
          )} ${dataPoint?.metadata?.unit ?? ''}</span></div>
          <div style="display: flex; justify-content: space-between;"><span>${min}</span><span>${formatNumberToNotation(
            dataPoint.metadata?.min ?? 0
          )} ${dataPoint?.metadata?.unit ?? ''}</span></div>
          <div style="display: flex; justify-content: space-between;"><span>${grouped ? avgCriterion : criterion}</span><span>${formatNumberToNotation(
            dataPoint.metadata?.criteria ?? ''
          )} ${dataPoint.metadata?.unit ?? ''}</span></div>
          </div></div>`;
      }
    },
  },
});
