import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import { Input } from '../../../../../Components/Atoms/Input';
import AppError from '../../../../../helper/AppError';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import style from './DevicesPages.module.css';
import { useTranslation } from 'react-i18next';

interface Device {
  description: string;
  key?: string;
  id?: string;
}

export const DevicesPages = (): React.ReactElement => {
  const { client } = useTenant();
  const { api } = useAuth();
  const { addToast } = useToast();
  const [key, setKey] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const getDevice = async () => {
    try {
      const { device } = await api.get<{ device: { appKey: string } }>(
        `/api/client/${client?.tenant}/devices`
      );
      setKey(device.appKey);
    } catch (err) {
      if (!(err instanceof AppError)) {
        return console.error(err);
      }
      console.error(err.message);
      if (err.statusCode !== 404) {
        addToast({
          type: 'error',
          title: t('devicesPage:errorFindingKey', { defaultValue: 'Erro ao buscar chave.' }),
          description: t('devicesPage:tryAgainLater', { defaultValue: 'Tente novamente mais tarde.' }),
        });
        return;
      }
      await api.post(`/api/client/${client?.tenant}/devices`, {
        description: `${client?.tenant}01`,
      });
      getDevice();
    }
  };
  const copyToClipboard = () => {
    const input = inputRef.current;
    if (input) {
      input.select();
      input.setSelectionRange(0, 9999999);
      navigator.clipboard.writeText(input.value);
      addToast({
        title: t('devicesPage:keyCopied', { defaultValue: 'Chave copiada' }),
        type: 'success',
        description: t('devicesPage:mustUseThisKeyToConfigureDevice', { defaultValue: 'Você deve usar essa chave para configurar o dispositivo.' }),
      });
    } else {
      addToast({ type: 'error', title: '' });
    }
  };

  useEffect(() => {
    getDevice();
  }, []);

  return (
    <div className={style.container}>
      <h2>{t('devicesPage:deviceConfiguration', { defaultValue: 'Configuração do dispositivo' })}</h2>
      <div className={style.data}>
        <Input
          inputRef={inputRef}
          disabled
          label={t('devicesPage:key', { defaultValue: 'Chave' })}
          onChange={() => { }}
          value={key}
        />
        <Button onClick={copyToClipboard}>{t('devicesPage:copy', { defaultValue: 'Copiar' })}</Button>
      </div>
    </div>
  );
};
