import React, { PropsWithChildren } from 'react';

import style from './AndonPages.module.css';

interface Props {
  isFirstPage?: boolean;
  pageNumber: number;
  currentPageNumber: number;
  lastPageNumber: number;
}

export const CarouselPage = ({
  isFirstPage,
  pageNumber,
  currentPageNumber,
  lastPageNumber,
  children,
}: PropsWithChildren<Props>): React.ReactElement => {
  return (
    <div
      className={style.page}
      data-current={currentPageNumber === pageNumber}
      data-previous={currentPageNumber > pageNumber}
      data-next={
        isFirstPage
          ? currentPageNumber === lastPageNumber
          : currentPageNumber < pageNumber
      }
    >
      {children}
    </div>
  );
};
