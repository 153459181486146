import { ApexOptions } from 'apexcharts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Inspection,
  Production,
  Event,
} from '../../Controls/DataControl/models';

import style from '../SpecificChartPage.module.css';
import uniqueStyle from './ProductionConformityPage.module.css';
import { useFilter } from '../../Controls/FilterControl';
import { useData } from '../../Controls/DataControl/UseData';
import { Filter } from '../../Controls/FilterControl/models';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { EmptyState } from '../../Components/Cards/EmptyState';
import { ScatterSerie, SelectedData } from './models';
import { SideMenuComponent } from './SideMenuComponent';
import { BadFilterMessageComponent } from './BadFilterMessageComponent';
import { ConformityChartTooltip } from './ConformityChartTooltip';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { ChartOptionsDatetimeXaxis } from '../../Components/Cards/ChartOptionsDatetimeXaxis';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { addHours } from 'date-fns';
import { MainChart } from './MainChart';
import { BrushChart } from './BrushChart';
import { useLocale } from '../../../../LocaleControl';
import { useTranslation } from 'react-i18next';

interface Props {
  containerHeight: number;
  containerWidth: number;
}

const handleChartItemClick = (
  unit: string,
  inspection: Inspection,
  index: number,
  seriesIndex: number,
  sampleSize: number,
  series?: any[]
) => {
  const samples: {
    criterion: number;
    max: number;
    min: number;
    dev: number;
    avg: number;
    startDate: Date;
    unit: string;
  }[] = series ? series[seriesIndex]?.data[index].metadata.samples : [];
  const tolerance = inspection.tolerance;
  const individualAcceptanceCriterium =
    inspection.individualAcceptanceCriterium;
  const productName = inspection.productName;
  const productWeight = inspection.productWeight;
  const packageWeight = inspection.packageWeight;
  const productUnit = inspection.productUnit;
  const multiplierFactor = inspection.multiplierFactor;
  const sample = inspection.samples[index];
  if (!sample) {
    console.error(`sample not found with index ${index}`);
    return undefined;
  }
  const values = inspection.items.slice(
    samples.length * index,
    samples.length * index + sampleSize + samples.length
  );
  return {
    product: {
      name: productName,
      weight: productWeight,
      packageWeight,
      unit: [undefined, null, ''].includes(productUnit) ? 'g' : productUnit,
    },
    sumary: {
      multiplierFactor,
      tolerance,
      individualAcceptanceCriterium,
      samples: samples.map((sample) => ({
        unit,
        date: sample.startDate,
        avg: sample.avg,
        packagesBelowIndividualTolerance: values.filter(
          ({ weight }) => weight < sample.avg - tolerance
        ).length,
        dev: parseFloat(sample.dev.toFixed(2)),
        criterion: sample.criterion,
        approvedAvg: sample.avg > sample.criterion,
        approvedIndividual:
          tolerance >
          values.filter(({ weight }) => weight < sample.avg - tolerance).length,
      })),
    },
    values,
  };
};

const MAX_SAMPLES = 500;

export const ProductionConformityPage = ({
  containerWidth,
  containerHeight,
}: Props): React.ReactElement => {
  const [sampleSize, setSampleSize] = useState<number>(20);
  const [inspections, setInspections] = useState<Inspection[]>([]);
  const [events, setEvents] = useState<Event[]>();
  const [eventsBrush, setEventsBrush] = useState<Event[]>();
  const [options, setOptions] = useState<ApexOptions>();
  const [brushOptions, setBrushOptions] = useState<ApexOptions>();
  const [loading, setLoading] = useState(false);
  const [loadingBrush, setLoadingBrush] = useState(false);
  const [selectedData, setSelectedData] = useState<SelectedData>();
  const [production, setProduction] = useState<Production[]>([]);
  const [series, setSeries] = useState<ApexAxisChartSeries>();
  const [brushSeries, setBrushSeries] = useState<ApexAxisChartSeries>();
  const [brushX, setBrushX] = useState<{ start: number; end: number }>();
  const { chartOptionsLocale } = useLocale();
  const { t } = useTranslation();

  const {
    filter,
    updateFilterRules,
    filterRules,
    updateFilter,
    resetFilterRules,
  } = useFilter();
  const { fetchConformity, fetchProduction, fetchEvents } = useData();

  const abortFetchRef = useRef<() => void>();
  const abortFetchEventsRef = useRef<() => void>();
  const abortFetchEventsBrushRef = useRef<() => void>();
  const abortFetchProductionRef = useRef<() => void>();
  const throttleBrushRef = useRef<NodeJS.Timer>();

  const localFetchInspection = useCallback(
    async (localFilter: Filter) => {
      setLoading(true);
      if (localFilter.equipments.length === 0) {
        setLoading(false);
        return;
      }
      try {
        let inspectionFilter: Omit<
          Filter,
          'equipments' | 'interval' | 'operator'
        > & {
          equipment: string;
          sampleSize: number;
        } = {
          customEndDate: localFilter.customEndDate,
          customStartDate: localFilter.customStartDate,
          dates: localFilter.dates,
          zoom: localFilter.zoom,
          product: localFilter.product,
          equipment: localFilter.equipments[0].id,
          sampleSize,
        };
        const { abort, fetch } = fetchConformity();
        if (abortFetchRef.current) {
          abortFetchRef.current();
        }
        abortFetchRef.current = abort;
        const response = await fetch(inspectionFilter);
        abortFetchRef.current = undefined;
        setInspections(response);
        setSelectedData(undefined);
      } catch (err) {
        setLoading(false);
        console.error('Error on fetching:', err);
      }
    },
    [sampleSize, fetchConformity, setInspections]
  );

  const localFetchEvents = useCallback(
    async (localFilter: Filter) => {
      setLoading(true);
      try {
        if (localFilter.equipments.length === 0) {
          setLoading(false);
          return;
        }
        if (abortFetchEventsRef.current) {
          abortFetchEventsRef.current();
        }
        const { abort, fetch } = fetchEvents();
        abortFetchEventsRef.current = abort;
        const response = await fetch(localFilter);
        abortFetchEventsRef.current = undefined;
        setEvents(response);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    },
    [fetchEvents]
  );

  const localFetchEventsBrush = useCallback(
    async (localFilter: Filter) => {
      setLoadingBrush(true);
      try {
        if (localFilter.equipments.length === 0) {
          setLoadingBrush(false);
          return;
        }
        if (abortFetchEventsBrushRef.current) {
          abortFetchEventsBrushRef.current();
        }
        const { abort, fetch } = fetchEvents();
        abortFetchEventsBrushRef.current = abort;
        const response = await fetch(localFilter);
        abortFetchEventsBrushRef.current = undefined;
        setEventsBrush(response);
      } catch (err) {
        setLoadingBrush(false);
      }
    },
    [fetchEvents]
  );

  const localFetchProduction = useCallback(
    async (localFilter: Filter) => {
      setLoadingBrush(true);
      if (abortFetchProductionRef.current) {
        abortFetchProductionRef.current();
        abortFetchProductionRef.current = undefined;
      }
      if (localFilter.equipments.length === 0) {
        setLoadingBrush(false);
        return;
      }
      try {
        const { abort, fetch } = fetchProduction();
        abortFetchProductionRef.current = abort;
        const productionResponse = await fetch({
          ...localFilter,
          interval: '5m',
        });
        setProduction(productionResponse);
      } catch (err) {
        console.error('Error on fetch production: ', err);
        setLoadingBrush(false);
      }
    },
    [setProduction, fetchProduction]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    if (filter.equipments.length > 1) {
      updateFilter({ ...filter, equipments: [] });
    }
  }, [filter.equipments]);

  useEffect(() => {
    if (filter.equipments.length !== 1) {
      setInspections([]);
      setSelectedData(undefined);
      return;
    }
    localFetchEventsBrush(filter);
    localFetchProduction(filter);
  }, [filter]);

  useEffect(() => {
    if (!brushX) return;
    const customFilter = {
      ...filter,
      dates: [
        {
          endTimestamp: new Date(brushX.end).getTime(),
          startTimestamp: new Date(brushX.start).getTime(),
        },
      ],
    };
    localFetchEvents(customFilter);
    localFetchInspection(customFilter);
  }, [filter, brushX, sampleSize]);

  useEffect(() => {
    if (
      filterRules &&
      (filterRules.equipments !== 'single' || filterRules.operator !== false)
    ) {
      updateFilterRules({ equipments: 'single', operator: false });
    }
  }, [updateFilterRules]);

  const dataPointSelectionHandler = useCallback(
    (event, chart, { w, dataPointIndex, seriesIndex }) => {
      const dataPoint =
        w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      if (!dataPoint) return;
      const inspectionClicked = inspections.find(({ productId }) => {
        return dataPoint.metadata.productId === productId;
      });
      if (!inspectionClicked) return;
      const newSelectedData = handleChartItemClick(
        dataPoint.metadata.unit.toString(),
        inspectionClicked,
        dataPointIndex,
        seriesIndex,
        sampleSize,
        w.globals.initialSeries
      );
      setSelectedData(newSelectedData);
    },
    [setSelectedData, sampleSize, inspections]
  );

  useEffect(() => {
    const firstDate =
      production && production.length
        ? production
          .map(({ entries }) => entries)
          .flat()
          .sort((a, b) => a.date.getTime() - b.date.getTime())[0]
          .date.getTime()
        : filter.dates.length > 0 ? filter.dates[0].startTimestamp : new Date().getTime();
    setBrushOptions({
      chart: {
        id: 'brushChart',
        brush: {
          autoScaleYaxis: false,
          target: 'conformity-chart',
          enabled: true,
        },
        selection: {
          enabled: true,
          xaxis: {
            min: firstDate,
            max: addHours(
              firstDate ?? (filter.dates.length > 0 ? filter.dates[0].startTimestamp : new Date().getTime()),
              1
            ).getTime(),
          },
        },

        events: {
          brushScrolled: (_, { xaxis }) => {
            if (throttleBrushRef.current) {
              clearTimeout(throttleBrushRef.current);
              throttleBrushRef.current = undefined;
            }
            throttleBrushRef.current = setTimeout(() => {
              setLoading(true);
              setBrushX({ start: xaxis.min, end: xaxis.max });
            }, 1000);
          },
        },
      },
      yaxis: {
        forceNiceScale: true,
        decimalsInFloat: 0,
      },
      ...ChartOptionsDatetimeXaxis(14),
    });

    let newBrushSeries:
      | {
        name: string;
        data: { x: number; y: number | null; metadata: unknown }[];
      }[]
      | undefined =
      production && production.length > 0
        ? [
          {
            name: 'brush',
            data: production
              .map(({ entries }) =>
                entries.map(({ avg, sum, date }) => ({
                  x: date.getTime(),
                  y: avg ?? sum,
                  metadata: undefined,
                }))
              )
              .flat()
              .sort((a, b) => a.x - b.x),
          },
        ]
        : undefined;
    if (newBrushSeries && eventsBrush?.length === 1) {
      newBrushSeries = newBrushSeries.map((serie, index) => {
        if ([2, 3].includes(index)) {
          return serie;
        }
        return {
          ...serie,
          data: [
            ...serie.data,
            ...eventsBrush[0].events.map(({ windowStart }) => ({
              x: windowStart.getTime(),
              y: null,
              metadata: undefined,
            })),
          ].sort((a, b) => a.x - b.x),
        };
      });
    }
    setBrushSeries(newBrushSeries?.filter(({ data }) => data.length > 0));
    setLoadingBrush(false);
    setBrushX({
      start: firstDate,
      end: filter.dates.length > 0
      ? Math.min(filter.dates[0].endTimestamp,addHours(firstDate, 1).getTime())
      : addHours(new Date(), 1).getTime(),
    });
  }, [production, eventsBrush, setBrushSeries, setBrushOptions, setLoading]);

  useEffect(() => {
    if (!inspections) return;

    const [max, min] = inspections
      .map(({ samples }) => samples)
      .flat()
      .reduce(
        (acc, curr) => [
          Math.max(acc[0], curr.max, curr.criterion),
          Math.min(acc[1], curr.min, curr.criterion),
        ],
        [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
      );
    setOptions({
      responsive: [
        {
          breakpoint: 900,
          options: {
            xaxis: { tickAmount: inspections.length > 0 ? 3 : 0 },
          },
        },
        {
          breakpoint: 1400,
          options: {
            xaxis: { tickAmount: inspections.length > 0 ? 7 : 0 },
          },
        },
      ],
      ...ChartOptionsLegend,
      chart: {
        id: 'conformity-chart',
        toolbar: {
          show: false,
          autoSelected: 'pan',
        },
        animations: {
          enabled: false,
        },
        ...chartOptionsLocale,

        events: {
          dataPointSelection: dataPointSelectionHandler,
        },
      },
      yaxis: {
        forceNiceScale: true,
        min,
        max,
        title: {
          text: t('specificChartPage:weight', { defaultValue: 'Peso' }),
          style: { fontSize: '1em' },
        },
        labels: {
          style: { fontSize: '1em' },
          formatter: formatNumberToNotation,
          minWidth: 50,
        },
      },
      markers: {
        size: Array.from(Array(inspections.length))
          .map(() => [0, 0, 5, 5])
          .flat(),
      },
      ...ChartOptionsDatetimeXaxis(14),
      fill: {
        opacity: 1,
      },
      stroke: { width: 0 },
      colors: [
        function ({
          seriesIndex,
          w,
        }: {
          value: any;
          seriesIndex: number;
          dataPointIndex: number;
          w: any;
        }) {
          if (w.globals.seriesNames[seriesIndex].includes(t('specificChartPage:max', { defaultValue: 'Max.' }))) {
            return '#008FFB33';
          }
          if (w.globals.seriesNames[seriesIndex].includes(t('specificChartPage:min', { defaultValue: 'Min.' }))) {
            return '#f0f5f8';
          }
          if (w.globals.seriesNames[seriesIndex].includes(t('specificChartPage:criterion', { defaultValue: 'Critério' }))) {
            return '#e12222';
          }
          if (w.globals.seriesNames[seriesIndex].includes(t('specificChartPage:average', { defaultValue: 'Média' }))) {
            return '#22e15e';
          }
          return '#ee2e2e2';
        },
      ],
      ...ConformityChartTooltip(
        t('specificChartPage:max', { defaultValue: 'Max.' }),
        t('specificChartPage:min', { defaultValue: 'Min.' }),
        t('specificChartPage:average', { defaultValue: 'Média' }),
        t('specificChartPage:criterion', { defaultValue: 'Critério' }),
        t('specificChartPage:avgCriterion', { defaultValue: 'Critério médio' }),
        t('specificChartPage:samples', { defaultValue: 'Qtd. Amostras' }),
      ),
    });
  }, [inspections, dataPointSelectionHandler]);

  const createConformitySeries =
    (groupSize: number, inspections: Inspection[]) =>
      (
        serieName: string,
        yValue: 'avg' | 'criterion' | 'min' | 'max',
        type: 'scatter' | 'area'
      ): ScatterSerie[] => {
        return inspections.map(
          ({ samples, productName, productUnit, productId, productCodeText }) => ({
            name: `${serieName} - ${productCodeText} - ${productName ? productName.substring(0, 30) : t('specificChartPage:productNotSpecified', { defaultValue: 'Produto não informado' })}`,
            type,
            productId,
            data: samples
              .reduce<
                {
                  x: number;
                  y: number;
                  metadata: Pick<Inspection, 'samples'> & {
                    unit: string;
                    product: string;
                    productId: string;
                    max: number;
                    min: number;
                    avg: number;
                    criteria: number;
                  };
                }[]
              >((acc, curr, currIndex) => {
                const groupItemIndex = Math.floor(currIndex % groupSize);
                const groupIndex = Math.floor(currIndex / groupSize);

                if (groupItemIndex === 0) {
                  acc = [
                    ...acc,
                    {
                      x: curr.startDate.getTime(),
                      y: curr[yValue],
                      metadata: {
                        samples: [curr],
                        unit: [undefined, null, ''].includes(productUnit)
                          ? 'g'
                          : productUnit,
                        product: productName,
                        productId,
                        min: curr.min,
                        max: curr.max,
                        avg: curr.avg,
                        criteria: curr.criterion,
                      },
                    },
                  ];
                } else {
                  const newAvg =
                    [...acc[groupIndex].metadata.samples, curr]
                      .map(({ avg }) => avg)
                      .reduce((acc, curr) => acc + curr, 0) /
                    (acc[groupIndex].metadata.samples.length + 1);
                  const newCriterion =
                    [...acc[groupIndex].metadata.samples, curr]
                      .map(({ criterion }) => criterion)
                      .reduce((acc, curr) => acc + curr, 0) /
                    (acc[groupIndex].metadata.samples.length + 1);
                  const newMin = Math.min(acc[groupIndex].metadata.min, curr.min);
                  const newMax = Math.max(acc[groupIndex].metadata.max, curr.max);

                  const updatedGroup = {
                    x: acc[groupIndex].x,
                    y: {
                      avg: newAvg,
                      criterion: newCriterion,
                      min: newMin,
                      max: newMax,
                    }[yValue],
                    metadata: {
                      ...acc[groupIndex].metadata,
                      product: productName,
                      avg: newAvg,
                      max: newMax,
                      min: newMin,
                      criteria: newCriterion,
                      productId,
                      samples: [...acc[groupIndex].metadata.samples, curr],
                    },
                  };
                  acc = [...acc.slice(0, -1), updatedGroup];
                }
                return acc;
              }, [])
              .sort((a, b) => a.x - b.x),
          })
        );
      };


  useEffect(() => {
    setSelectedData(undefined);

    const numberOfSamples = inspections
      .map(({ samples }) => samples)
      .flat().length;
    const groupSize = Math.ceil(numberOfSamples / MAX_SAMPLES);
    const createCurrentConformitySerie = createConformitySeries(
      groupSize,
      inspections
    );
    let newSeries: {
      name: string;
      productId: string;
      type: string;
      data: { x: number; y: number | null; metadata: unknown }[];
    }[] =
      inspections.length < 1
        ? []
        : [
          ...createCurrentConformitySerie(t('specificChartPage:max', { defaultValue: 'Max.' }), 'max', 'area'),
          ...createCurrentConformitySerie(t('specificChartPage:min', { defaultValue: 'Min.' }), 'min', 'area'),
          ...createCurrentConformitySerie(t('specificChartPage:criterion', { defaultValue: 'Critério' }), 'criterion', 'scatter'),
          ...createCurrentConformitySerie(t('specificChartPage:average', { defaultValue: 'Média' }), 'avg', 'scatter'),
        ];
    if (events?.length === 1) {
      newSeries = newSeries
        .sort((a, b) =>
          `${a.name.split('-').slice(1).join().trim()}-${a.productId
            }`.localeCompare(
              `${b.name.split('-').slice(1).join().trim()}-${b.productId}`
            )
        )
        .map((serie, index) => {
          if ([2, 3].includes(index % 4)) {
            return serie;
          }
          return {
            ...serie,
            data: [
              ...serie.data,
              ...events[0].events.map(({ windowStart }) => ({
                x: windowStart.getTime(),
                y: null,
                metadata: undefined,
              })),
            ].sort((a, b) => a.x - b.x),
          };
        });
    }
    setLoading(false);
    setSeries(newSeries);
  }, [inspections, events]);

  useEffect(() => {
    return () => {
      setInspections([]);
      setEvents(undefined);
      setEventsBrush(undefined);
      setOptions(undefined);
      setBrushOptions(undefined);
      setSeries(undefined);
      setBrushSeries(undefined);
    };
  }, [
    setInspections,
    setEvents,
    setEventsBrush,
    setOptions,
    setBrushOptions,
    setBrushSeries,
    setSeries,
    setInspections,
  ]);
  return (
    <div className={style.container}>
      <div className={uniqueStyle.container}>
        <div className={uniqueStyle.body}>
          <div className={uniqueStyle.chart}>
            <SpecificChartPageTitle text={t('specificChartPage:conformity', { defaultValue: 'Conformidade' })} />
            {filter.equipments.length !== 1 && <BadFilterMessageComponent />}
            {filter.equipments.length === 1 &&
              !loading &&
              !loadingBrush &&
              !brushSeries?.length && <EmptyState />}
            {filter.equipments.length === 1 && (
              <>
                {loading && <LoadingState />}
                {!loading && series && options && (
                  <MainChart
                    series={series}
                    options={options}
                    height={containerHeight - 350}
                    width={
                      containerWidth > 900
                        ? containerWidth - 300
                        : containerWidth - 50
                    }
                  />
                )}
                {loadingBrush && <LoadingState />}
                {!loadingBrush &&
                  brushSeries &&
                  brushSeries.length > 0 &&
                  brushSeries[0].data.length > 0 &&
                  brushOptions && (
                    <BrushChart
                      series={brushSeries}
                      options={brushOptions}
                      width={
                        containerWidth > 900
                          ? containerWidth - 300
                          : containerWidth - 50
                      }
                    />
                  )}
              </>
            )}
          </div>
          <SideMenuComponent
            selectedData={selectedData}
            setSampleSize={setSampleSize}
            sampleSize={sampleSize}
          />
        </div>
      </div>
    </div>
  );
};
