import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.css';
const MenuList = ({
  closeMobileMenu,
}: {
  closeMobileMenu: () => void;
}): React.ReactElement => {
  const { pathname } = useLocation();
  return (
    <div id='management-menu-list'>
      <hr />
      <ul>
        <li onClick={closeMobileMenu}>
          <Link
            className={pathname === '/management/s/home' ? 'active' : ''}
            to='/management/s/home'
          >
            <div>Clientes</div>
          </Link>
        </li>
        <li onClick={closeMobileMenu}>
          <Link
            className={pathname === '/management/s/users' ? 'active' : ''}
            to='/management/s/users'
          >
            <div>Usuários</div>
          </Link>
        </li>
        <li onClick={closeMobileMenu}>
          <Link
            className={pathname === '/management/s/languages' ? 'active' : ''}
            to='/management/s/languages'
          >
            <div>Idiomas</div>
          </Link>
        </li>
      </ul>
      <hr />
    </div>
  );
};

export default MenuList;
