import { createContext } from 'react';
import Api from '../../helper/Api';
import { Client } from './models';

export interface TenantContextState {
  client?: Client;
  setClient: (client: Client) => void;
  getClient: (tenant: string) => void;
  clearClient: () => void;
  forgotPassword: (payload: { email: string; tenant: string }) => Promise<void>;
}

export const TenantContext = createContext<TenantContextState | undefined>(
  undefined
);
