import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useData } from '../../../Controls/DataControl/UseData';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import {
  Equipment,
  EquipmentConnectionStatus,
  EquipmentStatus,
} from '../../../Controls/DataControl/models';
import style from './EquipmentStatusChart.module.css';
import { Filter } from '../../../Controls/FilterControl/models';
import { LoadingState } from '../LoadingState';
import { EmptyState } from '../EmptyState';
import { useFilter } from '../../../Controls/FilterControl';
import { ChartOptionsLegend } from '../ChartOptionsLegend';
import { useTranslation } from 'react-i18next';

export const EquipmentsStatusChart = (): React.ReactElement => {
  const { fetchEquipmentStatus, fetchEquipments } = useData();
  const { filter } = useFilter();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<{
    [key: string]: EquipmentStatus[];
  }>({});
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexNonAxisChartSeries>([]);
  const abortFetchRef = useRef<() => void>();
  const { t } = useTranslation();

  const getEquipmentsWithoutStatus = useCallback(
    (equipments: Equipment[], equipmentsStatus: EquipmentStatus[]) => {
      const equipmentsWithout = equipments?.filter(
        ({ id }) =>
          !equipmentsStatus.map(({ equipment }) => equipment.id).includes(id)
      );
      return equipmentsWithout;
    },
    []
  );

  const localFetch = useCallback(
    async (localFilter: Pick<Filter, 'equipments'>) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      const { abort, fetch } = fetchEquipmentStatus();
      abortFetchRef.current = abort;
      const equipmentsPromise = fetchEquipments();
      const equipmentsStatusPromise = fetch(localFilter);

      const [equipmentsResponse, equipmentsStatusResponse] = await Promise.all([
        equipmentsPromise,
        equipmentsStatusPromise,
      ]);
      const unconnected = [
        ...(getEquipmentsWithoutStatus(
          equipmentsResponse,
          equipmentsStatusResponse
        ).map<EquipmentStatus>((eq) => ({
          equipment: eq,
          status: 'Desconectado',
        })) ?? []),
        ...equipmentsStatusResponse.filter(
          ({ status }) =>
            status !==
            EquipmentConnectionStatus[EquipmentConnectionStatus.Conectado]
        ),
      ];

      setChartData({
        [t('cardsPage:producing', { defaultValue: 'Produzindo' })]: equipmentsStatusResponse.filter(
          ({ status, speed }) =>
            status === EquipmentConnectionStatus[EquipmentConnectionStatus.Conectado] && (speed !== 0 || speed === null || speed === undefined)
        ),
        [t('cardsPage:stopped', { defaultValue: 'Parado' })]: equipmentsStatusResponse.filter(
          ({ status, speed }) =>
            status === EquipmentConnectionStatus[EquipmentConnectionStatus.Conectado] && speed === 0
        ),
        [t('cardsPage:disconnected', { defaultValue: 'Desconectado' })]: unconnected
      });

      setLoading(false);
    },
    [fetchEquipmentStatus, fetchEquipments]
  );

  useEffect(() => {
    setLoading(true);
    localFetch({ equipments: [] });
  }, [filter]);

  useEffect(() => {
    if (chartData && Object.keys(chartData).length > 0) {
      setOptions({
        chart: {
          type: 'donut',
          id: 'EquipmentStatusChart',
          animations: {
            enabled: false,
          },
        },
        legend: {
          show: true,
          position: 'right',
        },
        labels: Object.keys(chartData),
        colors: Object.keys(chartData).map(
          (key) =>
          ({
            [t('cardsPage:disconnected', { defaultValue: 'Desconectado' })]: 'rgb(236, 89, 89)',
            [t('cardsPage:producing', { defaultValue: 'Produzindo' })]: 'rgb(24, 169, 242)',
            [t('cardsPage:stopped', { defaultValue: 'Parado' })]: 'rgb(238, 204, 85)',
          }[key])
        ),
        dataLabels: {
          enabled: false,
          style: { fontSize: '1.175em', colors: ['#222'] },
          textAnchor: 'middle',
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderColor: 'transparent',
            opacity: 0.5,
            dropShadow: {
              enabled: false,
            },
          },
        },
        ...ChartOptionsLegend,
        responsive: [
          {
            breakpoint: 900,
            options: {
              plotOptions: {
                pie: {
                  customScale: 0.9,
                },
              },
            },
          },
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                pie: {
                  customScale: 0.9,
                },
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: false,
            customScale: 1,
            donut: {
              labels: {
                show: true,

                total: {
                  formatter: (w) => {
                    const series = w.globals.series;
                    if (series.length > 0) {
                      const total = series.reduce(
                        (acc: number, curr: number) => acc + curr,
                        0
                      );
                      const connected = series[0];
                      return `${connected ?? 0} / ${total}`;
                    }
                    return '';
                  },
                  showAlways: true,
                  show: true,
                  label: t('cardsPage:producing', { defaultValue: 'Produzindo' }),
                },
              },
            },
          },
        },
      });
      setSeries(Object.keys(chartData).map((key) => chartData[key].length));
    }
  }, [chartData, t]);

  useEffect(() => {
    return () => {
      setChartData({});
      setSeries([]);
      setOptions({});
    };
  }, [setChartData, setSeries, setOptions]);

  return (
    <div className={style.chartContainer}>
      <h2>{t('cardsPage:equipments', { defaultValue: 'Equipamentos' })}</h2>
      <div className={style.donut}>
        {loading ? (
          <LoadingState />
        ) : options && series && series.length > 0 ? (
          <Chart
            id='equipmentStatusChart'
            type='donut'
            options={options}
            series={series}
            height={400}
          />
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};
