import { createContext } from 'react';
import { Filter, FilterInterval, FilterRules } from './models';

export interface FilterContextState {
  filter: Filter;
  updateFilter: (newValue: Filter) => void;
  filterRules?: FilterRules;
  resetFilterRules: () => void;
  updateFilterRules: (partialFilterRules: Partial<FilterRules>) => void;
}
export const FilterContext = createContext<FilterContextState | undefined>(
  undefined
);
