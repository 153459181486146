import { createContext } from 'react';
import { Filter } from '../FilterControl/models';
import {
  Event,
  Inspection,
  Production,
  EquipmentStatus,
  EquipmentOperationDetails,
  ProductionClassification,
  ProductionHistogram,
  Scrap,
  EventsHistogram,
  Equipment,
  Operator,
  Product,
  ProductionHeatmap,
  ProductionSummary,
  CoilConsumption,
  PackageSummary,
  ProductionOrder,
  EquipmentProduction,
} from './models';

export interface DataRequest<T, U> {
  abort: () => void;
  fetch: (filter: T) => Promise<U>;
}

export interface DataContextState {
  fetchEquipments: () => Promise<Equipment[]>;
  fetchOperators: () => Promise<Operator[]>;
  fetchProducts: () => Promise<Product[]>;
  fetchPackages: () => DataRequest<
    Omit<Filter, 'interval'>,
    PackageSummary
  >;
  fetchTotalPackages: () => DataRequest<
    Omit<Filter, 'interval'>,
    { data: { totalPackages: number } }
  >;
  fetchProductionOrders: () => DataRequest<
    Omit<Filter, 'interval'>,
    ProductionOrder[]
  >;
  generateReport: (
    filter: Omit<Filter, 'interval'> & {
      eventsCode?: string[];
      charts: string[];
    }
  ) => Promise<void>;
  fetchCoilConsumption: () => DataRequest<
    Omit<Filter, 'interval' | 'zoom'>,
    CoilConsumption[]
  >;
  fetchProductionHistogram: (productionBlackList?: string[]) => DataRequest<
    Omit<Filter, 'interval'>,
    ProductionHistogram
  >;
  fetchEventsHistogram: (
    eventsCodeBlacklist?: string[]
  ) => DataRequest<Omit<Filter, 'interval' | 'product'>, EventsHistogram[]>;
  fetchProduction: (weightOverTimeBlacklist?: string[]) => DataRequest<Filter, Production[]>;
  fetchEvents: (
    eventsCodeBlacklist?: string[]
  ) => DataRequest<Omit<Filter, 'interval' | 'product'>, Event[]>;

  fetchProductionScraps: () => DataRequest<Omit<Filter, 'interval'>, Scrap[]>;

  fetchProductionHeatmap: () => DataRequest<
    Omit<Filter, 'interval'>,
    ProductionHeatmap[]
  >;

  fetchProductionClassification: () => DataRequest<
    Omit<Filter, 'interval'>,
    ProductionClassification
  >;

  fetchConformity: () => DataRequest<
    Omit<Filter, 'interval' | 'equipments' | 'operator'> & {
      equipment: string;
      sampleSize: number;
    },
    Inspection[]
  >;

  fetchEquipmentStatus: () => DataRequest<
    Pick<Filter, 'equipments'>,
    EquipmentStatus[]
  >;

  fetchProductionSummary: () => DataRequest<
    Omit<Filter, 'useShiftHours' | 'interval'>,
    ProductionSummary[]
  >;

  fetchEquipmentProduction: () => DataRequest<
    Omit<Filter, 'interval'>,
    EquipmentProduction[]
  >;
}

export const DataContext = createContext<DataContextState | undefined>(
  undefined
);
