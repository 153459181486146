import { ApexOptions } from 'apexcharts';
import { memo } from 'react';
import Chart from 'react-apexcharts';

interface Props {
  series: ApexAxisChartSeries;
  options: ApexOptions;
  height: number;
  width: number;
}

export const MainChart = memo(({ width, height, options, series }: Props) => {
  return (
    <Chart
      id='conformity-chart'
      series={series}
      width={width}
      type='line'
      options={options}
      height={height}
    />
  );
});
