import { useTranslation } from 'react-i18next';
import { formatNumberToNotation } from '../../../../../helper';
import uniqueStyle from './ProductionHistogramPage.module.css';
export const ProductionHistogramTable = ({
  serie,
  key,
  unit,
}: {
  serie: ApexAxisChartSeries;
  key: string;
  unit: string;
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={uniqueStyle.tableContainer}>
      <table>
        <thead>
          <tr>
            <th>
              <span>
                {t('specificChartPage:weight', { defaultValue: 'Peso' })} ({[undefined, null, ''].includes(unit) ? 'g' : unit ?? 'g'}
                )
              </span>
            </th>
            {serie
              .map<any>(({ data }) => data)
              .flat()
              .sort((a, b) => a.x - b.x)
              .map(({ x, meta }) =>
              (!meta.invalid && !meta.rejected ?
                (
                  <td className={uniqueStyle.dataOk}>
                    <span>{formatNumberToNotation(x)}</span>
                  </td>) :
                (!meta.invalid && meta.rejected ? (
                  <td className={uniqueStyle.dataRejected}>
                    <span>{formatNumberToNotation(x)}</span>
                  </td>) : (<td className={uniqueStyle.dataInvalid}>
                    <span>{formatNumberToNotation(x)}</span>
                  </td>)
                )
              ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <span>{t('specificChartPage:quantity', { defaultValue: 'Quantidade' })}</span>
            </th>
            {serie
              .map<any>(({ data }) => data)
              .flat()
              .sort((a, b) => a.x - b.x)
              .map(({ y }) => (
                <td>
                  <span>{formatNumberToNotation(y)}</span>
                </td>
              ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
