import React from 'react';
import { Label } from '../Label';
import style from './FormSeparator.module.css';
interface Props {
  label?: string;
  id: string;
}
export const FormSeparator = ({
  children,
  id,
  label,
}: React.PropsWithChildren<Props>) => {
  return (
    <div id={id} className={style.container}>
      <div className={style.content}>
        {label && <Label value={label} />}
        {children}
      </div>
    </div>
  );
};
