import { useContext } from 'react';
import { TenantContext, TenantContextState } from './TenantContext';

export const useTenant = (): TenantContextState => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within TenantProvider');
  }
  return context;
};
