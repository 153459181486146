import {
  subMinutes,
  startOfDay,
  subHours,
  startOfWeek,
  subDays,
  differenceInDays,
  differenceInMinutes,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import { Filter, FilterInterval, Zoom } from './models';
import { getDateFnsLocation } from '../../../../../helper/GetDateFnsLocation';

export const computeStartOfZoom = (filter: Filter, now: Date): Date => {
  const FALLBACK_LANGUAGE = 'pt_BR';
  if (filter.zoom === 'custom') return filter.customStartDate;
  return {
    lst30m: subMinutes(now, 30),
    lst60m: subMinutes(now, 60),
    today: startOfDay(now),
    lst24h: subHours(now, 24),
    yesterday: startOfDay(subHours(now, 24)),
    thisweek: startOfWeek(now, { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE), weekStartsOn: 0 }),
    lst7d: subDays(now, 7),
    thismonth: startOfMonth(now),
    lst30d: subDays(now, 30),
    custom: null,
  }[filter.zoom];
};

export const computeEndOfZoom = (filter: Filter): Date => {
  if (filter.zoom === 'yesterday') return startOfToday();
  if (filter.zoom === 'custom') return filter.customEndDate;
  return new Date(new Date(new Date().setSeconds(0)).setMilliseconds(0));
};
//TODO: check if this is still relevant
export const computeInterval = (end: Date, start: Date): FilterInterval => {
  let timespanInMinutes = Number.POSITIVE_INFINITY;
  // if (useShiftHours) {
  //   const numberOfDays = differenceInDays(end, start);
  //   const hoursEachDay =
  //     end.getHours() - start.getHours() > 0
  //       ? end.getHours() - start.getHours()
  //       : 24 + (end.getHours() - start.getHours());
  //   timespanInMinutes = numberOfDays * hoursEachDay * 60;
  // } else {
  timespanInMinutes = differenceInMinutes(end, start);
  // }
  const groups = timespanInMinutes / 60;
  let localAggregationInterval: FilterInterval = '5m';
  if (groups > 6) {
    localAggregationInterval = '5m';
  }
  return localAggregationInterval;
};
