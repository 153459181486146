import { formatDistance } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { HiOutlineRefresh } from 'react-icons/hi';
import { useFilter } from '../../Controls/FilterControl';
import {
  computeEndOfZoom,
  computeStartOfZoom,
} from '../../Controls/FilterControl/utils';
import style from './SpecificChartPageTitle.module.css';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocation } from '../../../../../helper/GetDateFnsLocation';

export const SpecificChartPageTitle = ({
  text,
  onFilterClick,
}: {
  text: string;
  onFilterClick?: () => void;
}): React.ReactElement => {
  const [distanceFromRefresh, setDistanceFromRefresh] = useState<string>();
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());
  const refDistanceFromRefreshInterval = useRef<NodeJS.Timer>();
  const { updateFilter, filter } = useFilter();
  const { t } = useTranslation();
  const FALLBACK_LANGUAGE = 'pt_BR';

  useEffect(() => {
    if (refDistanceFromRefreshInterval.current) {
      clearInterval(refDistanceFromRefreshInterval.current);
    }
    setDistanceFromRefresh(
      `${t('otherComponentsPage:intervalSinceLastUpdate', { defaultValue: 'Atualizado há' })} ${formatDistance(lastUpdatedAt, new Date(), {
        locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
      })}`
    );
    refDistanceFromRefreshInterval.current = setInterval(() => {
      setDistanceFromRefresh(
        `${t('otherComponentsPage:intervalSinceLastUpdate', { defaultValue: 'Atualizado há' })} ${formatDistance(lastUpdatedAt, new Date(), {
          locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
        })}`
      );
    }, 10000);
    return () => {
      if (refDistanceFromRefreshInterval.current) {
        clearInterval(refDistanceFromRefreshInterval.current);
        refDistanceFromRefreshInterval.current = undefined;
      }
    };
  }, [lastUpdatedAt]);
  return (
    <div className={style.title}>
      <div className={style.titleGroup} >
      <h3>{text}</h3>
      {!!onFilterClick && <FiFilter onClick={onFilterClick} />}
      </div>
      <p>{distanceFromRefresh}</p>
      <div>
        <HiOutlineRefresh
          role='button'
          onClick={() => {
            const start = computeStartOfZoom(filter, new Date());
            const end = computeEndOfZoom(filter);
            updateFilter({
              ...filter,
              customEndDate: end,
              customStartDate: start,
            });
            setLastUpdatedAt(new Date());
          }}
        />
      </div>
    </div>
  );
};
