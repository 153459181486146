import { format } from 'date-fns';
import React from 'react';
import AppError from '../../../../../helper/AppError';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { Filter } from '../FilterControl/models';
import { DataContext } from './DataContext';
import {
  CoilConsumption,
  Equipment,
  EquipmentConnectionStatus,
  EquipmentOperationDetails,
  EquipmentProduction,
  EquipmentStatus,
  Event,
  EventsHistogram,
  GroupedPackage,
  Inspection,
  Operator,
  Package,
  PackageSummary,
  Product,
  Production,
  ProductionClassification,
  ProductionHeatmap,
  ProductionHistogram,
  ProductionOrder,
  ProductionSummary,
  Scrap,
} from './models';
export const DataProvider: React.FC = ({ children }) => {
  const { api } = useAuth();
  const { client } = useTenant();

  const formatDatesFilterQuery = (filter: Pick<Filter, 'dates'>): string => {
    return encodeURIComponent(JSON.stringify(filter.dates));
  };
  const generateReport = async (
    filter: Omit<Filter, 'interval'> & {
      eventsCode?: string[];
      charts: string[];
    }
  ): Promise<void> => {
    try {
      await api.download(
        `/api/client/${client?.tenant}/reports`,
        `relatório-${format(new Date(), 'dd-MM-yyyy-hh-mm-ss')}.xlsx`,
        {
          charts: filter.charts,
          dates: formatDatesFilterQuery(filter),

          equipments: filter.equipments.length
            ? filter.equipments.map(({ id }) => id)
            : undefined,
          operator: filter.operator?.id,
          product: filter.product?.codeText.toString(),
          eventsCode: filter.eventsCode,
        }
      );
    } catch (err) {
      throw new AppError(`Error on listing equipments: ${err}`);
    }
  };
  const fetchEquipmentStatus = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch equipment status request');
        controller.abort();
      },
      fetch: async (
        filter: Pick<Filter, 'equipments'>
      ): Promise<EquipmentStatus[]> => {
        const params = new URLSearchParams();
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        try {
          const { result } = await api.get<{
            result: (Omit<EquipmentStatus, 'status'> & { status: number })[];
          }>(
            `/api/client/${client?.tenant
            }/dashboard/equipment-status?${params.toString()}`,
            signal
          );
          return result.map(({ status, ...rest }) => ({
            status: EquipmentConnectionStatus[status],
            ...rest,
          }));
        } catch (error) {
          console.error('error on fetch equipment status data: ', error);
          return [];
        }
      },
    };
  };
  const fetchEquipments = async (
    retriesCount?: number
  ): Promise<Equipment[]> => {
    try {
      const { equipments } = await api.get<{
        max: number;
        equipments: (Omit<Equipment, 'bitmap'> & {
          mid: string;
          bitmapStatusCode: string;
        })[];
      }>(`/api/client/${client?.tenant}/registrations/equipments`);
      return equipments.map(({ mid, ...rest }) => ({
        ...rest,
        id: mid,
      }));
    } catch (err) {
      if (!retriesCount) {
        return fetchEquipments(1);
      }
      if (retriesCount && retriesCount >= 3) {
        return fetchEquipments(retriesCount + 1);
      } else {
        throw new AppError(`Error on listing equipments: ${err}`);
      }
    }
  };
  const fetchOperators = async (): Promise<Operator[]> => {
    try {
      return api.get<Operator[]>(
        `/api/client/${client?.tenant}/registrations/operators`
      );
    } catch (error) {
      throw new AppError(`Error on listing operators: ${error}`);
    }
  };
  const fetchProducts = async (): Promise<Product[]> => {
    try {
      return api.get<Product[]>(
        `/api/client/${client?.tenant}/registrations/products`
      );
    } catch (err) {
      throw new AppError(`Error on listing products: ${err}`);
    }
  };
  const fetchProductionHistogram = (productionBlackList: string[] = []) => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch production histogram request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'interval'>
      ): Promise<ProductionHistogram> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        productionBlackList.forEach((type) => {
          params.append('typeCode', type);
        });
        try {
          const {
            data: { result },
          } = await api.get<{
            data: { result: ProductionHistogram };
          }>(
            `/api/client/${client?.tenant
            }/dashboard/production/histogram?${params.toString()}`,
            signal
          );
          return result;
        } catch (error) {
          console.error('error on fetch production histogram data: ', error);
          return [];
        }
      },
    };
  };
  const fetchEventsHistogram = (ocurrencesBlacklist: string[] = []) => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch events histogram request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'interval' | 'product'>
      ): Promise<EventsHistogram[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        ocurrencesBlacklist.forEach((ocurrence) => {
          params.append('eventsCode', ocurrence);
        });
        try {
          const { result } = await api.get<{
            result: EventsHistogram[];
          }>(
            `/api/client/${client?.tenant
            }/dashboard/events/histogram?${params.toString()}`,
            signal
          );
          return result;
        } catch (error) {
          console.error('error on fetch events histogram data: ', error);
          return [];
        }
      },
    };
  };
  const fetchProductionHeatmap = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch production heatmap request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'interval'>
      ): Promise<ProductionHeatmap[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        //get client-side timezone
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        params.append('timezone', timezone);
        try {
          const {
            data: { result },
          } = await api.get<{ data: { result: ProductionHeatmap[] } }>(
            `/api/client/${client?.tenant
            }/dashboard/production/heatmap?${params.toString()}`,
            signal
          );
          return result;
        } catch (error) {
          console.error(
            'error on fetch inicio production heatmap data: ',
            error
          );
          return [];
        }
      },
    };
  };
  const fetchProduction = (weightOverTimeBlacklist: string[] = []) => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch production request');
        controller.abort();
      },
      fetch: async (filter: Filter): Promise<Production[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        params.append('interval', filter.interval);
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        weightOverTimeBlacklist.forEach((type) => {
          params.append('typeCode', type);
        });
        try {
          const {
            data: { result },
          } = await api.get<{ data: { result: Production[] }; query: Filter }>(
            `/api/client/${client?.tenant
            }/dashboard/production?${params.toString()}`,
            signal
          );
          return result.map((item) => ({
            ...item,
            unit: [undefined, null, ''].includes(item.unit) ? 'g' : item.unit,
            entries: item.entries.map(({ date, ...rest }) => ({
              date: new Date(date),
              ...rest,
            })),
          }));
        } catch (err) {
          console.error(err);
          return [];
        }
      },
    };
  };
  const fetchEvents = (eventsCodeBlacklist: string[] = []) => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch events request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'interval' | 'product'>
      ): Promise<Event[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        eventsCodeBlacklist.forEach((eventCode) => {
          params.append('eventsCode', eventCode);
        });
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });

        try {
          const {
            data: { result },
          } = await api.get<{ data: { result: Event[] } }>(
            `/api/client/${client?.tenant
            }/dashboard/events/occurrences?${params.toString()}`,
            signal
          );
          return result.map((event) => ({
            ...event,
            equipment: {
              ...event.equipment,
              createdAt: new Date(event.equipment.createdAt),
              removedAt: event.equipment.removedAt
                ? new Date(event.equipment.removedAt)
                : undefined,
            },
            events: event.events.map(
              ({ startDate, endDate, windowEnd, windowStart, ...rest }) => ({
                startDate: new Date(startDate),
                endDate: endDate ? new Date(endDate) : undefined,
                windowEnd: new Date(windowEnd),
                windowStart: new Date(windowStart),
                ...rest,
              })
            ),
          }));
        } catch (error) {
          console.error('error on fetch inicio data: ', error);
          return [];
        }
      },
    };
  };
  const fetchProductionScraps = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch production scraps request');
        controller.abort();
      },
      fetch: async (filter: Omit<Filter, 'interval'>): Promise<Scrap[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        try {
          const {
            data: { result },
          } = await api.get<{ data: { result: Scrap[] } }>(
            `/api/client/${client?.tenant
            }/dashboard/production/scraps?${params.toString()}`,
            signal
          );
          return result.map(({ date, ...rest }) => ({
            date: new Date(date),
            ...rest,
          }));
        } catch (error) {
          console.error('error on fetch production scraps: ', error);
          return [];
        }
      },
    };
  };
  const fetchProductionClassification = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch production classification request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'interval'>
      ): Promise<ProductionClassification> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        try {
          const {
            data: { result },
          } = await api.get<{
            data: { result: ProductionClassification };
          }>(
            `/api/client/${client?.tenant
            }/dashboard/production/classification?${params.toString()}`,
            signal
          );
          return result;
        } catch (error) {
          console.error(
            'error on fetch production classification data: ',
            error
          );
          throw error;
        }
      },
    };
  };

  const fetchConformity = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'interval' | 'equipments' | 'operator'> & {
          equipment: string;
          sampleSize: number;
        }
      ): Promise<Inspection[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        params.append('equipment', filter.equipment);
        params.append('sampleSize', filter.sampleSize.toString());
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }

        const {
          data: { result },
        } = await api.get<{ data: { result: Inspection[] } }>(
          `/api/client/${client?.tenant
          }/dashboard/production/conformity?${params.toString()}`,
          signal
        );
        return result.map(({ samples, items, ...rest }) => ({
          ...rest,
          items: items.map(({ date, weight }) => ({
            weight,
            date: new Date(date),
          })),
          samples: samples.map(({ startDate, ...sampleRest }) => ({
            ...sampleRest,
            startDate: new Date(startDate),
          })),
        }));
      },
    };
  };
  const fetchProductionSummary = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch equipment operation details request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'useShiftHours' | 'interval'>
      ): Promise<ProductionSummary[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        try {
          const {
            data: { result },
          } = await api.get<{
            data: { result: ProductionSummary[] };
          }>(
            `/api/client/${client?.tenant
            }/dashboard/production/summary?${params.toString()}`,
            signal
          );
          return result;
        } catch (error) {
          console.error(
            'error on fetch inicio operation details data: ',
            error
          );
          return [];
        }
      },
    };
  };
  const fetchCoilConsumption = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch coil consumption request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'zoom' | 'interval' | 'operator'>
      ): Promise<CoilConsumption[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        try {
          const {
            data: { result },
          } = await api.get<{
            data: { result: CoilConsumption[] };
          }>(
            `/api/client/${client?.tenant
            }/dashboard/production/coil?${params.toString()}`,
            signal
          );
          return result;
        } catch (error) {
          console.error('error on fetch coil consumption data: ', error);
          return [];
        }
      },
    };
  };
  const fetchTotalPackages = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch total packages request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'zoom' | 'interval'>
      ): Promise<{ data: { totalPackages: number } }> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        try {
          const {
            data: { totalPackages },
          } = await api.get<{
            data: { totalPackages: number }
          }>(`/api/client/${client?.tenant}/registrations/packages/total?${params.toString()}`,
            signal
          );
          return { data: { totalPackages } };
        } catch (error) {
          console.error('error on fetch total packages data: ', error);
          return { data: { totalPackages: 0 } };
        }
      },
    };
  }
  const fetchPackages = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch packages request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'zoom' | 'interval' | 'operator'>
      ): Promise<PackageSummary> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        })
        try {
          const {
            data: result,
          } = await api.get<any>(
            `/api/client/${client?.tenant}/registrations/packages/grouped?${params.toString()}`,
            signal
          )
          return result;
        } catch (error) {
          console.error('error on fetch packages data: ', error);
          return { result: [], total: 0 };
        }
      },
    };
  };
  const fetchProductionOrders = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch packages request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'zoom' | 'interval' | 'operator'>
      ): Promise<ProductionOrder[]>  => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        })
        console.log('eqs', params.get('equipments'))
        try {
          const result = await api.get<ProductionOrder[]>(
            `/api/client/${client?.tenant}/registrations/orders/opdata?${params.toString()}`,
            signal
          );
          return result;
        } catch (error) {
          console.error('error on fetch packages data: ', error);
          return []
        }
      },
    };
  };
  const fetchEquipmentProduction = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
      abort: () => {
        console.warn('Aborting fetch equipment production request');
        controller.abort();
      },
      fetch: async (
        filter: Omit<Filter, 'interval'>
      ): Promise<EquipmentProduction[]> => {
        const params = new URLSearchParams();
        params.append('dates', formatDatesFilterQuery(filter));
        if (filter.operator) {
          params.append('operator', filter.operator.id);
        }
        if (filter.product) {
          params.append('product', filter.product.codeText || filter.product.code.toString());
        }
        filter.equipments.forEach((equipment) => {
          params.append('equipments', equipment.id);
        });
        try {
          const { data } = await api.get<{ data: EquipmentProduction[] }>(
            `/api/client/${client?.tenant
            }/dashboard/production/equipments?${params.toString()}`,
            signal
          );
          return data;
        } catch (error) {
          console.error(
            'error on fetch equipment production data: ',
            error
          );
          throw error;
        }
      },
    };
  };
  return (
    <DataContext.Provider
      value={{
        generateReport,
        fetchProductionSummary,
        fetchProductionHeatmap,
        fetchEquipments,
        fetchOperators,
        fetchProducts,
        fetchEvents,
        fetchProduction,
        fetchEventsHistogram,
        fetchProductionClassification,
        fetchProductionHistogram,
        fetchProductionScraps,
        fetchConformity,
        fetchEquipmentStatus,
        fetchCoilConsumption,
        fetchPackages,
        fetchTotalPackages,
        fetchProductionOrders,
        fetchEquipmentProduction
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
