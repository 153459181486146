import { useCallback, useEffect, useState } from 'react';
import { useFilter } from '../../Controls/FilterControl';
import style from './AdvancedFilter.module.css';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import {
  DateObject,
  Calendar,
  getAllDatesInRange,
} from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { Time } from '../../../../../Components/Atoms/Input/Time';
import { format, startOfToday } from 'date-fns';
import Checkbox from '../../../../../Components/Atoms/Checkbox';
import { Select } from '../../../../../Components/Atoms/Input/Select';
import { Operator, Product } from '../../Controls/DataControl/models';
import { useData } from '../../Controls/DataControl/UseData';
import { TimeRange } from '../../Controls/FilterControl/models';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../LocaleControl';

interface Props {
  alwaysOpen?: boolean;
}

export const AdvancedFilter = ({ alwaysOpen = false }: Props) => {
  const { filter, updateFilter } = useFilter();
  const { fetchOperators, fetchProducts } = useData();
  const [dateRanges, setDateRanges] = useState([
    [
      new DateObject(filter.customStartDate.setHours(0, 0, 0, 0)),
      new DateObject(filter.customEndDate.setHours(0, 0, 0, 0)),
    ],
  ]);
  const [open, setOpen] = useState(alwaysOpen);
  const [operatorsOptions, setOperatorsOptions] = useState<Operator[]>([]);
  const [productsOptions, setProductsOptions] = useState<Product[]>([]);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(new Date().getTime() - startOfToday().getTime());
  const [treatEachDateIndividually, setTreatDatesIndividually] = useState(false);
  const { t } = useTranslation();
  const { calendarLocale } = useLocale();

  useEffect(() => {
    if (filter.zoom !== 'custom') {
      setDateRanges([
        [
          new DateObject(filter.dates[0].startTimestamp),
          new DateObject(filter.dates[0].endTimestamp),
        ],
      ]);
    }
  }, [filter.zoom, filter.dates]);

  useEffect(() => {
    if (open) {
      fetchOperators().then(setOperatorsOptions);
      fetchProducts().then(setProductsOptions);
    }
  }, [open]);

  const handleCalendarChange = useCallback(
    ({
      localTreatEachDateIndividually,
      localStartTime,
      localEndTime,
    }: {
      localTreatEachDateIndividually: boolean;
      localStartTime: number;
      localEndTime: number;
    }) =>
      (selectedDates: DateObject[][]) => {
        setDateRanges(selectedDates);
        if (selectedDates.some((range) => range.length === 1)) return;
        const dates = selectedDates.reduce<TimeRange[]>((ranges, currRange) => {
          if (localTreatEachDateIndividually) {
            const datesInRange = getAllDatesInRange(currRange);
            return [
              ...ranges,
              ...datesInRange.map((range) => {
                if (range instanceof DateObject) {
                  const start = new Date(
                    range.year,
                    range.monthIndex,
                    range.day,
                    0,
                    0,
                    0,
                    0
                  );
                  const end = new Date(
                    range.year,
                    range.monthIndex,
                    range.day,
                    0,
                    0,
                    0,
                    0
                  );
                  return {
                    startTimestamp: start.getTime() + localStartTime,
                    endTimestamp: end.getTime() + localEndTime,
                  };
                } else if (range instanceof Date) {
                  const start = new Date(
                    range.getFullYear(),
                    range.getMonth(),
                    range.getDay(),
                    0,
                    0,
                    0,
                    0
                  );
                  const end = new Date(
                    range.getFullYear(),
                    range.getMonth(),
                    range.getDay(),
                    0,
                    0,
                    0,
                    0
                  );
                  return {
                    startTimestamp: start.getTime() + localStartTime,
                    endTimestamp: end.getTime() + localEndTime,
                  };
                } else {
                  throw new Error();
                }
              }),
            ];
          } else {
            const start = new Date(
              currRange[0].year,
              currRange[0].monthIndex,
              currRange[0].day
            );
            const end = new Date(
              currRange[1].year,
              currRange[1].monthIndex,
              currRange[1].day
            );
            return [
              ...ranges,
              {
                startTimestamp: start.getTime() + localStartTime,
                endTimestamp: end.getTime() + localEndTime,
              },
            ];
          }
        }, []);
        updateFilter({
          ...filter,
          dates: dates.sort((a, b) => a.startTimestamp - b.startTimestamp),
          zoom: 'custom',
        });
      },
    [updateFilter, setDateRanges]
  );

  useEffect(() => {
    if (filter.zoom === 'custom') {
      setOpen(true);
    }
  }, [filter.zoom]);

  useEffect(() => {
    console.log(filter.dates);
  }, [filter.dates]);

  return (
    <div className={style.container}>
      <div className={style.body}>
        <main data-open={open}>
          <div className={style.filterPeriod}>
            <Calendar
              multiple
              format='DD/MM/YYYY'
              range
              weekStartDayIndex={7}
              locale={calendarLocale}
              //@ts-ignore
              value={dateRanges}
              //@ts-ignore
              onChange={handleCalendarChange({
                localTreatEachDateIndividually: treatEachDateIndividually,
                localStartTime: startTime,
                localEndTime: endTime,
              })}
              plugins={[<DatePanel sort='date' />]}
            />
            <div>
              <div className={style.datetimeContainer}>
                <Time
                  inverted
                  required
                  id='datetime-start'
                  label={t('advancedFilterPage:dateTimeStart', { defaultValue: 'Hora inicial' })}
                  value={format(
                    startOfToday().getTime() + startTime,
                    "HH':'mm"
                  )}
                  onChange={(value) => {
                    const [startHour, startMinutes] = value.split(':');
                    if (
                      isNaN(parseInt(startHour)) ||
                      isNaN(parseInt(startMinutes))
                    ) {
                      return;
                    }
                    const timeInMs =
                      (parseInt(startHour) * 60 * 60 +
                        parseInt(startMinutes) * 60) *
                      1000;
                    setStartTime(timeInMs);
                    handleCalendarChange({
                      localTreatEachDateIndividually: treatEachDateIndividually,
                      localStartTime: timeInMs,
                      localEndTime: endTime,
                    })(dateRanges);
                  }}
                />
              </div>
              <div className={style.datetimeContainer}>
                <Time
                  inverted
                  required
                  id='datetime-start'
                  label={t('advancedFilterPage:dateTimeEnd', { defaultValue: 'Hora final' })}
                  value={format(startOfToday().getTime() + endTime, "HH':'mm")}
                  onChange={(value) => {
                    const [endHour, endMinutes] = value.split(':');
                    if (
                      isNaN(parseInt(endHour)) ||
                      isNaN(parseInt(endMinutes))
                    ) {
                      return;
                    }
                    const timeInMs =
                      (parseInt(endHour) * 60 * 60 +
                        parseInt(endMinutes) * 60) *
                      1000;
                    setEndTime(timeInMs);
                    handleCalendarChange({
                      localTreatEachDateIndividually: treatEachDateIndividually,
                      localStartTime: startTime,
                      localEndTime: timeInMs,
                    })(dateRanges);
                  }}
                />
              </div>
              <Checkbox
                invertColorLabel
                alignStart
                checked={treatEachDateIndividually}
                label={t('advancedFilterPage:dailyRepetition', { defaultValue: 'Repetição Diária' })}
                onChange={(checked) => {
                  setTreatDatesIndividually(checked);
                  handleCalendarChange({
                    localTreatEachDateIndividually: checked,
                    localEndTime: endTime,
                    localStartTime: startTime,
                  })(dateRanges);
                }}
              />
            </div>
          </div>
          <div>
            <Select
              customPlaceholder={t('advancedFilterPage:all', { defaultValue: 'Todos' })}
              inverted
              options={operatorsOptions.map(
                ({ code, name }) => `${code}-${name}`
              )}
              label={t('advancedFilterPage:operator', { defaultValue: 'Operador' })}
              id='operator'
              value={`${filter.operator?.code}-${filter.operator?.name}` || ''}
              onChange={(value) =>
                updateFilter({
                  ...filter,
                  operator: operatorsOptions.find(
                    ({ code }) => code.toString() === value.split('-')[0]
                  ),
                })
              }
            />
            <span
              style={{
                color: 'white',
                marginBottom: '0.5rem',
                visibility: filter.operator ? 'visible' : 'hidden',
              }}
            >
              {filter.operator?.code}
            </span>
            <Select
              customPlaceholder={t('advancedFilterPage:all', { defaultValue: 'Todos' })}
              inverted
              options={productsOptions.map(
                ({ name, codeText, code }) => `${codeText ?? code}-${name}`
              )}
              label={t('advancedFilterPage:product', { defaultValue: 'Produto' })}
              id='product'
              value={`${filter.product?.codeText ?? filter.product?.code}-${filter.product?.name}` || ''}
              onChange={(value) =>
                updateFilter({
                  ...filter,
                  product: productsOptions.find(
                    ({ code, codeText }) => code.toString() === value.split('-')[0] || codeText === value.split('-')[0]
                  ),
                })
              }
            />
            <span
              style={{
                color: 'white',
                visibility: !!filter.product ? 'visible' : 'hidden',
              }}
            >
              {filter.product?.codeText || filter.product?.code}
            </span>
          </div>
        </main>
        <footer onClick={!location.pathname.endsWith('/producao') ? () => setOpen((prev) => (alwaysOpen ? true : !prev)) : () => null}>
          {!alwaysOpen && (
            <>{open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</>
          )}
        </footer>
      </div>
    </div>
  );
};
