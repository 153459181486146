import { useContext } from 'react';
import { FilterContext, FilterContextState } from './FilterContext';

export const useFilter = (): FilterContextState => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within FilterProvider');
  }
  return context;
};
