import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Input } from '../../../../../Components/Atoms/Input';
import { Select } from '../../../../../Components/Atoms/Input/Select';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import { EquipmentType } from '../../Controls/DataControl/models';
import { CPNetEquipmentFormComponent } from './Components/CPNetEquipmentFormComponent';
import { ModbusEquipmentFormComponent } from './Components/ModbusEquipmentFormComponent';
import { ConfirmDeleteEquipmentModal } from './ConfirmDeleteEquipmentModal';
import style from './EquipmentPage.module.css';
import { useTranslation } from 'react-i18next';
import { ConfirmDeleteAllEquipmentConfigModal } from './ConfirmDeleteAllEquipmentConfigModal';

interface bitmapOption {
  label: string;
  code: number;
}

export const EquipmentPage = ({
  newEquipment,
}: {
  newEquipment?: boolean;
}): React.ReactElement => {
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const { push } = useHistory();
  const [waitDeleteConfirmation, setWaitDeleteConfirmation] = useState(false);
  const [waitDeleteEquipmentConfigsConfirmation, setWaitDeleteEquipmentConfigsConfirmation] = useState(false);
  const [name, setName] = useState('');
  const [ip, setIp] = useState('');
  const [port, setPort] = useState(502);
  const [type, setType] = useState<0 | 1>(1);
  const [bitmapOptions, setBitpmapOptions] = useState<bitmapOption[]>([]);
  const [selectedBitmap, setSelectedBitmap] = useState<number>();
  const [mid, setMid] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [cpnetFilePath, setCpnetFilePath] = useState('');
  const [cpnetConfigFilePath, setCpnetConfigFilePath] = useState('');
  const [cpnetEquipmentReference, setCpnetEquipmentReference] = useState('');
  const [cpnetShouldSyncOldFiles, setCpnetShouldSyncOldFiles] = useState(false);
  const { t } = useTranslation();

  const {
    params: { id: editCode },
  } = useRouteMatch<{ id: string }>();

  const handleTypeChange = (value: string) => {
    setType(value === 'CpNet' ? 0 : 1);
  };
  const fetchBitmapOptions = async () => {
    const response = await api.get<{ label: string; code: string }[]>(
      `/api/client/${client?.tenant}/registrations/equipments/bitmaps`
    );
    setBitpmapOptions(
      response.map(({ code, label }) => ({ code: parseInt(code), label }))
    );
    setSelectedBitmap(parseInt(response[0].code));
  };

  useEffect(() => {
    fetchBitmapOptions();
  }, []);

  useEffect(() => {
    if (!newEquipment && bitmapOptions.length > 0) {
      api
        .get<{
          name: string;
          ip: string;
          port: number;
          type: EquipmentType;
          bitmap: number;
          dataDirectory: string;
          configDirectory: string;
          cpNetReferenceName: string;
          syncOldData: boolean;
          mid: string;
          group?: string;
        }>(`/api/client/${client?.tenant}/registrations/equipments/${editCode}`)
        .then((response) => {
          setName(response.name);
          setIp(response.ip);
          setPort(response.port);
          setType(response.type);
          setSelectedBitmap(response.bitmap);
          setCpnetFilePath(response.dataDirectory);
          setCpnetConfigFilePath(response.configDirectory);
          setCpnetEquipmentReference(response.cpNetReferenceName);
          setCpnetShouldSyncOldFiles(response.syncOldData);
          setMid(response.mid);
          setGroupId(response.group);
        });
    }
  }, [newEquipment, editCode, bitmapOptions]);

  const handleUpdateEquipment = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      if (!mid) {
        addToast({
          title: t('equipmentPage:problemUpdatingEquipment', { defaultValue: 'Problema ao atualizar equipamento' }),
          description: t('equipmentPage:reloadPageAndTryAgain', { defaultValue: 'Recarregue a página e tente novamente.' }),
          type: 'error',
        });
      }
      e.preventDefault();
      let body: any = {
        id: editCode,
        name,
        mid,
        ip,
        port,
        type,
        bitmap: selectedBitmap ?? 65535,
        group: groupId,
      };
      if (type === EquipmentType.RS485) {
        body = {
          ...body,
          dataDirectory: cpnetFilePath,
          configDirectory: cpnetConfigFilePath,
          cpNetReferenceName: cpnetEquipmentReference,
          syncOldData: cpnetShouldSyncOldFiles,
        };
      }
      await api.put(
        `/api/client/${client?.tenant}/registrations/equipments`,
        body
      );
      addToast({
        title: t('equipmentPage:equipmentUpdatedSuccessfully', { defaultValue: 'Equipamento atualizado com sucesso.' }),
        type: 'success',
      });
      push(`/client/${client?.tenant}/admin/equipamentos`);
    } catch (e) {
      addToast({ title: t('equipmentPage:errorUpdatingEquipment', { defaultValue: 'Erro ao atualizar Equipamento.' }), type: 'error' });
    }
  };

  const handleCreateEquipment = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      let body: any = {
        name,
        ip,
        port,
        type,
        bitmap: selectedBitmap ?? 65535,
      };
      if (type === EquipmentType.RS485) {
        body = {
          ...body,
          dataDirectory: cpnetFilePath,
          configDirectory: cpnetConfigFilePath,
          cpNetReferenceName: cpnetEquipmentReference,
          syncOldData: cpnetShouldSyncOldFiles,
        };
      }
      await api.post(
        `/api/client/${client?.tenant}/registrations/equipments`,
        body
      );
      addToast({ title: t('equipmentPage:equipmentCreatedSuccessfully', { defaultValue: 'Equipamento criado com sucesso.' }), type: 'success' });
      push(`/client/${client?.tenant}/admin/equipamentos`);
    } catch (e) {
      addToast({ title: t('equipmentPage:errorAddingEquipment', { defaultValue: 'Erro ao adicionar Equipamento.' }), type: 'error' });
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) =>
        !newEquipment ? handleUpdateEquipment(e) : handleCreateEquipment(e)
      }
    >
      {waitDeleteConfirmation && editCode !== 'new' && (
        <ConfirmDeleteEquipmentModal
          equipmentId={editCode}
          equipmentName={name}
          tenant={client?.tenant || ''}
          handleClose={(success) => {
            setWaitDeleteConfirmation(false);
            if (success) {
              push(`/client/${client?.tenant}/admin/equipamentos`);
            }
          }}
        />
      )}
      {waitDeleteEquipmentConfigsConfirmation && editCode !== 'new' && (
        <ConfirmDeleteAllEquipmentConfigModal
          tenant={client?.tenant || ''}
          equipmentId={editCode}
          equipmentName={name}
          handleClose={(success) => {
            setWaitDeleteEquipmentConfigsConfirmation(false);
            if (success) {
              push(`/client/${client?.tenant}/admin/equipamentos`);
            }
          }}
        />
      )}

      <h1>{newEquipment ? t('equipmentPage:addNewEquipment', { defaultValue: 'Adicionar Novo Equipamento' }) :
        t('equipmentPage:editEquipment', { name: name, defaultValue: `Editar ${name}` })}
      </h1>

      <Input
        label={t('equipmentPage:name', { defaultValue: 'Nome' })}
        maxLength={4}
        value={name}
        required={true}
        onChange={setName}
      />
      {type === EquipmentType.TCPIP && (
        <div className={style.formHorizontalGroup}>
          <Input
            label='IP'
            value={ip}
            placeholder='xxx.xxx.xxx.xxx'
            required
            onChange={setIp}
          />
          <Input
            label={t('equipmentPage:port', { defaultValue: 'Porta' })}
            disabled
            value={port}
            type='number'
            required={true}
            onChange={(value) => setPort(parseInt(value))}
          />
        </div>
      )}
      <Select
        id='Tipo'
        label={t('equipmentPage:type', { defaultValue: 'Tipo' })}
        disabled
        value={EquipmentType[type]}
        required={true}
        onChange={(value) => {
          if (value in EquipmentType) {
            handleTypeChange(value);
          }
        }}
        options={[EquipmentType[0], EquipmentType[1]]}
      />
      {type === EquipmentType.RS485 ? (
        <CPNetEquipmentFormComponent
          filePath={cpnetFilePath}
          onFilePathChange={setCpnetFilePath}
          configFilePath={cpnetConfigFilePath}
          onConfigFilePathChange={setCpnetConfigFilePath}
          equipmentReference={cpnetEquipmentReference}
          onEquipmentReferecenChange={setCpnetEquipmentReference}
          shouldSyncOldFiles={cpnetShouldSyncOldFiles}
          onShouldSyncOldFilesChange={setCpnetShouldSyncOldFiles}
        />
      ) : (
        <ModbusEquipmentFormComponent />
      )}
      <div className={style.buttons}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/equipamentos`)}
        >
          {t('equipmentPage:cancel', { defaultValue: 'Cancelar' })}
        </Button>
        <Button danger onClick={() => setWaitDeleteEquipmentConfigsConfirmation(true)} >{t('productPage:deleteAll', { defaultValue: 'Excluir tudo' })}</Button>
        {!newEquipment && (
          <Button danger onClick={() => setWaitDeleteConfirmation(true)}>
            {t('equipmentPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        )}
        <Button type='submit'>{newEquipment ? t('equipmentPage:add', { defaultValue: 'Adicionar' }) :
          t('equipmentPage:save', { defaultValue: 'Salvar' })}
        </Button>
      </div>
    </form>
  );
};
