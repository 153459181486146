import { useTranslation } from 'react-i18next';
import uniqueStyle from './ProductionConformityPage.module.css';

export const BadFilterMessageComponent = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={uniqueStyle.badFilterMessage}>
      <div className={uniqueStyle.badfilterMessageContent}>
        <h3>{t('specificChartPage:badFilterTitle', {
          defaultValue: 'Escolha somente 1 (um) equipamento para exibir conformidade'
        })}
        </h3>
        <span>
          {t('specificChartPage:badFilterDescription', {
            defaultValue: 'Gráfico de conformidade não pode ser exibido se o filtro estiver configurado para diversos equipamentos.'
          })}
        </span>
      </div>
    </div>
  );
}
