import { Button } from '../../../../../Components/Atoms/Button';
import Modal from '../../../../../Components/Modal';
import AppError from '../../../../../helper/AppError';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import style from './ConfirmDeleteProductModal.module.css';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteProductConfigModal = ({
  tenant,
  productId,
  equipmentId,
  handleClose,
}: {
  tenant: string;
  productId: string;
  equipmentId: string;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { t } = useTranslation();
  const { api } = useAuth();
  const { addToast } = useToast();

  const handleDeleteProductConfig = async () => {
    try {
      await api.delete(`/api/client/${tenant}/registrations/products/${productId}/equipments/${equipmentId}/config`);
      addToast({ title: t('productPage:configSuccessfullyDeleted', { defaultValue: 'Configurações excluídas com sucesso' }), type: 'success' });
    } catch (error) {
      throw new AppError("Error deleting product configuration");
    }
    handleClose(true);
  };

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('productPage:deleteConfiguration', { defaultValue: 'Excluir Configuração?' })}</h1>
        <p>
          {t('productPage:product', { defaultValue: 'Produto' })}: {productId}
          <br />
          {t('productPage:equipment', { defaultValue: 'Equipamento' })}: {equipmentId}
        </p>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('productPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteProductConfig()}>
            {t('productPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
}