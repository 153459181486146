import React, { useState } from 'react';
import { useToast } from '../../hooks/toast';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from '../../Components/Atoms/Button';
import { Input } from '../../Components/Atoms/Input';
import * as Yup from 'yup';
import './style.css';
import AppError from '../../helper/AppError';
import { useManagementAuth } from '../AuthControl';
export const Login: React.FC = () => {
  const { signIn, manager } = useManagementAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setErrors({});
    e.preventDefault();
    const schema = Yup.object().shape({
      email: Yup.string().lowercase().email().required('Email é obrigatório'),
      password: Yup.string().required('Senha é obrigatório'),
    });
    try {
      await schema.validate({ email, password }, { abortEarly: false });
      const manager = await signIn({ email, password });
      history.push('home');
      addToast({
        type: 'success',
        title: 'Bem-vindo',
        description: manager.name,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors(
          error.inner.reduce((acc, curr) => {
            if (curr.path) {
              return { ...acc, [curr.path]: curr.errors };
            } else return acc;
          }, {})
        );
      } else {
        if (error instanceof AppError) {
          if (error.statusCode === 401) {
            addToast({
              title: 'Falha ao autenticar',
              type: 'error',
              description: 'Credenciais inválidas.',
            });
            return;
          }
        }
        addToast({
          title: 'Falha ao autenticar',
          type: 'error',
          description: 'Tente novamente mais tarde.',
        });
      }
    }
  };
  return manager ? (
    <Redirect to='/management/s/home' />
  ) : (
    <div id='management-login-page'>
      <section id='management-login-image'>
        <img src='/background.png' />
      </section>
      <section id='management-login-form'>
        <img src='/medianeira_logo.png' alt='medianeira' />
        <div id='welcome'>
          <h3>Administração</h3>
          <h1>Bem-vindo</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <Input
            id='manager'
            grow
            label='E-mail'
            type='email'
            value={email}
            onChange={setEmail}
            errors={errors && errors['email']}
          />
          <Input
            id='password'
            grow
            label='Senha'
            type='password'
            value={password}
            onChange={setPassword}
            errors={errors && errors['password']}
          />
          <Button type='submit'>Entrar</Button>
        </form>
      </section>
    </div>
  );
};
