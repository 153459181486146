import { ApexOptions } from 'apexcharts';
import { memo } from 'react';
import Chart from 'react-apexcharts';

interface Props {
  series: ApexAxisChartSeries;
  options: ApexOptions;
  width: number;
}

export const BrushChart = memo(({ options, series, width }: Props) => {
  return (
    <Chart
      id='conformity-chart-brush'
      series={series}
      type='line'
      options={options}
      height={150}
      width={width}
    />
  );
});
