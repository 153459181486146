export const formatNumberToNotation = (
  number: number,
  decimals = 2
): string => {
  const formatted =
    parseInt((number * Math.pow(10, decimals)).toFixed(0)) /
    Math.pow(10, decimals);
  const response = new Intl.NumberFormat('de-DE').format(formatted);
  if (isNaN(parseFloat(response))) {
    return '0';
  }
  return response;
};
