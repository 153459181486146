import { useContext } from 'react';
import { LocaleContext, LocaleContextState } from './LocaleContext';

export const useLocale = (): LocaleContextState => {
  const context = useContext(LocaleContext);

  if (!context) {
    throw new Error(); // TODO: add error message!!
  }

  return context;
};
