import { useAuth } from "../../../../AuthControl";
import { useToast } from '../../../../../hooks/toast';
import { useTenant } from "../../../../TenantControl";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import style from '../EquipmentsPages/EquipmentsListPage.module.css';
import { Button } from "../../../../../Components/Atoms/Button";
import { FiPlus } from "react-icons/fi";
import { Tooltip } from "../../../../../Components/Atoms/Tooltip";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { ConfirmDeleteEquipmentGroupModal } from "./ConfirmDeleteEquipmentGroupModal";
import { EquipmentGroup, EquipmentListItem } from "../../Controls/DataControl/models";

interface EquipmentGroupModel {
  groupId: string;
  name: string;
  machinesAmount: number;
}

export const EquipmentGroupsListPage = (): React.ReactElement => {
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const { t } = useTranslation();
  const [groups, setGroups] = useState<EquipmentGroup[]>([]);
  const [equipmentGroups, setEquipmentGroups] = useState<EquipmentGroupModel[]>([]);
  const [groupToDelete, setGroupToDelete] = useState<EquipmentGroup>();
  const { push } = useHistory();

  useEffect(() => {
    listEquipmentGroups();
  }, []);

  const listEquipmentGroups = async () => {
    setEquipmentGroups([...equipmentGroups.splice(0, equipmentGroups.length)]);
    try {
      await api.get<EquipmentGroup[]>(`/api/client/${client?.tenant}/registrations/equipments/groups`)
        .then(response => {
          setGroups(response)
        });
    } catch (e) {
      addToast({ title: t('equipmentPage:problemsListingEquipments', { defaultValue: 'Problemas ao listar equipamentos' }), type: 'error' });
    }
  };

  return (
    <div className={style.container}>
      {groupToDelete && (
        <ConfirmDeleteEquipmentGroupModal
          tenant={client?.tenant || ''}
          group={groupToDelete}
          handleClose={(success) => {
            setGroupToDelete(undefined);
            if (success) {
              listEquipmentGroups();
            }
          }}
        />
      )}
      <div className={style.pageHeader}>
        <Button
          onClick={() => push(`${location.pathname}/novo`)}
          icon={<FiPlus />}
        >
          {t('equipmentPage:equipmentGroup', { defaultValue: 'Grupo de Equipamentos' })}
        </Button>
      </div>
      <ul className={`${style.equipmentList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.column} ${style.nameColumn}`}>{t('equipmentPage:name', { defaultValue: 'Nome' })}</div>
          <div className={`${style.column} ${style.typeColumn}`}>{t('equipmentPage:machinesAmount', { defaultValue: 'Qtd. Máquinas' })}</div>
          <div className={`${style.column} ${style.actionsColumn}`}></div>
        </li>
        {groups.map((group, i) => (
          <li key={group.name + i} className={style.row}>
            <div className={`${style.column} ${style.nameColumn}`}>{group.name.length > 0 ? group.name : "Sem Grupo"}</div>
            <div className={`${style.column} ${style.typeColumn}`}>{Array.isArray(group.equipments) ? group.equipments.length : 1}</div>
            <div className={`${style.column} ${style.actionsColumn}`}>
              <Tooltip tooltip={t('equipmentPage:edit', { defaultValue: 'Editar' })}>
                {group.name ?
                  <Link to={{
                    pathname: `${location.pathname}/${group.name}`,
                    state: group.id
                  }}  >
                    <AiOutlineEdit />
                  </Link>
                  :
                  <div style={{ opacity: 0.4 }} >
                    <AiOutlineEdit />
                  </div>
                }
              </Tooltip>
              <Tooltip tooltip={t('equipmentPage:delete', { defaultValue: 'Excluir' })}>
                {group.name ?
                  <div onClick={() => setGroupToDelete(group)}>
                    <AiOutlineDelete />
                  </div>
                  :
                  <div style={{ opacity: 0.4 }} >
                    <AiOutlineDelete />
                  </div>
                }
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}