import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../../AuthControl";
import { useTenant } from "../../../../TenantControl";
import { Button } from "../../../../../Components/Atoms/Button";
import { FiArrowLeft } from "react-icons/fi";
import style from "./ProductConfigPage.module.css";
import { Product, ProductConfigurationModel } from "../../Controls/DataControl/models";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../../../../Components/Atoms/Tooltip";
import { AiOutlineDelete, AiOutlineDownload, AiOutlineUpload } from "react-icons/ai";
import { useToast } from "../../../../../hooks/toast";
import AppError from "../../../../../helper/AppError";
import { ConfirmDeleteProductConfigModal } from "./ConfirmDeleteProductConfigModal";
import { ConfirmDeleteAllProductConfigModal } from "./ConfirmDeleteAllProductConfigModal";

export type ProductEquipmentModel = {
  product: string;
  equipment: string;
}

export const ProductConfigPage = (): React.ReactElement => {
  const { id: productId } = useParams<{ id: string }>();
  const { api } = useAuth();
  const { client } = useTenant();
  const { push } = useHistory();
  const [product, setProduct] = useState<Product>();
  const [productConfigurations, setProductConfigurations] = useState<ProductConfigurationModel[]>([]);
  const { t } = useTranslation();
  const { addToast } = useToast();
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>();
  const [productConfigurationToDelete, setProductConfigurationToDelete] = useState<ProductEquipmentModel>();
  const [configurationsToDelete, setConfigurationsToDelete] = useState<boolean>(false);

  useEffect(() => {
    const productLocalStorage = localStorage.getItem('@medianeira:selectedproduct');
    if (productLocalStorage) {
      setProduct(JSON.parse(productLocalStorage));
    }
  }, []);

  useEffect(() => {
    listProductConfigurations();
  }, []);

  const listProductConfigurations = async () => {
    api.get<ProductConfigurationModel[]>(`/api/client/${client?.tenant}/registrations/products/${productId}/equipments/config`)
      .then(productConfigurations => {
        setProductConfigurations(productConfigurations);
      })
      .catch(err => {
        addToast({ title: t('productPage:errorListingProductConfigurations', { defaultValue: 'Houve um erro ao obter as configurações do produto.' }), type: 'error' });
        console.log('error', err);
      });
  }

  const downloadProductConfigurationFile = async (productId: string, equipmentId: string, equipmentName: string): Promise<void> => {
    try {
      await api.download(`/api/client/${client?.tenant}/registrations/products/${productId}/equipments/${equipmentId}/config`,
        `productconf-${client?.tenant}-${product?.codeText ?? product?.code}-${product?.name}-${equipmentName}-.csv`, {});
    } catch (err) {
      throw new AppError("Error generating file");
    }
  }

  const uploadProductConfigurationFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file || file.type !== 'text/csv') {
      addToast({ title: t('productPage:invalidFileSelecetTextCsvFile', { defaultValue: 'Arquivo inválido. Selecione um arquivo do tipo text/csv para enviar.' }), type: 'error' });
      return;
    }

    const formData: FormData = new FormData();
    formData.append('productconfig', file);

    try {
      await api.putFormData(`/api/client/${client?.tenant}/registrations/products/${productId}/equipments/${selectedEquipmentId}/config`, formData);
      addToast({ title: 'Configuração atualizada', type: 'success' });
      listProductConfigurations();
    } catch (err) {
      addToast({ title: t('productPage:errorUploadingFile', { defaultValue: 'Ocorreu um erro ao fazer o upload do arquivo.' }), type: 'error' });
    }

    if (event.target.files) {
      event.target.value = '';
    }
  };

  return (
    <div className={style.container}>
      {productConfigurationToDelete && (
        <ConfirmDeleteProductConfigModal
          tenant={client?.tenant || ''}
          productId={productConfigurationToDelete.product}
          equipmentId={productConfigurationToDelete.equipment}
          handleClose={(success) => {
            setProductConfigurationToDelete(undefined);
            if (success) {
              listProductConfigurations();
            }
          }} />
      )}

      {configurationsToDelete && (
        <ConfirmDeleteAllProductConfigModal
          tenant={client?.tenant || ''}
          productId={productId || ''}
          productName={product?.name || ''}
          handleClose={(success) => {
            setConfigurationsToDelete(false);
            if (success) {
              listProductConfigurations();
            }
          }} />
      )}

      <div style={{ display: 'flex' }}>
        <div>
          <Button
            onClick={() => push(`/client/${client?.tenant}/admin/produtos`)}
            icon={<FiArrowLeft />}>
            {t('productPage:return', { defaultValue: 'Retornar' })}
          </Button>
        </div>
        <div style={{ marginLeft: '20px', marginTop: '10px' }}>
          {t('productPage:product', { defaultValue: 'Produto' })}: {product?.name} - {product?.code}
        </div>
      </div>

      {!!productConfigurations.length ? (
        <ul className={style.productConfList}>
          <li className={style.header} key='header'>
            <div className={style.nameColumn}>{t('equipmentPage:name', { defaultValue: 'Nome' })}</div>
            <div className={style.fileSizeColumn}>{t('productPage:fileSize', { defaultValue: 'Tamanho Arquivo' })}</div>
            <div className={style.actionsColumn}></div>
          </li>

          {productConfigurations.sort((a, b) => a.equipmentName.localeCompare(b.equipmentName)).map(({ _id, equipmentName, fileSize, p, eq }) => (
            <li key={_id} className={style.row}>
              <div className={style.nameColumn}>{equipmentName}</div>
              <div className={style.fileSizeColumn}>{fileSize}</div>
              <div className={style.actionsColumn}>
                <Tooltip tooltip={'Excluir'}>
                  <div>
                    <AiOutlineDelete onClick={() => setProductConfigurationToDelete({ product: p, equipment: eq })} className={style.actionButton} />
                  </div>
                </Tooltip>
                <Tooltip tooltip={'Download'}>
                  <div>
                    <AiOutlineDownload onClick={() => downloadProductConfigurationFile(p, eq, equipmentName)} className={style.actionButton} />
                  </div>
                </Tooltip>
                <Tooltip tooltip={'Upload'}>
                  <div>
                    <label htmlFor="upload-input">
                      <AiOutlineUpload onClick={() => setSelectedEquipmentId(eq)} className={style.actionButton} />
                    </label>
                    <input
                      id="upload-input"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={uploadProductConfigurationFile}
                    />
                  </div>
                </Tooltip>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div style={{ marginTop: '10px' }}>{t('productPage:noConfigurationFound', { defaultValue: 'Nenhuma configuração encontrada' })}</div>
      )}
      <div className={style.buttonSection}>
        <Button style={{float: 'right'}} danger onClick={() => setConfigurationsToDelete(true)}>
          {t('productPage:deleteAllConfig', { defaultValue: 'Excluir tudo' })}
        </Button>
      </div>

    </div>
  );
}