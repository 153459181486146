import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import './style.css';
interface CustomButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  textual?: boolean;
  secondary?: boolean;
  danger?: boolean;
  compact?: boolean;
  icon?: React.ReactElement;
}

export const Button: React.FC<CustomButtonProps> = ({
  children,
  type = 'button',
  disabled,
  secondary,
  danger,
  compact,
  icon,
  textual,
  ...rest
}) => {
  return (
    <button
      {...rest}
      data-disabled={disabled}
      disabled={disabled}
      data-textual={textual}
      className={`button ${secondary ? 'secondary' : ''} ${
        danger ? 'danger' : ''
      } ${compact ? '' : 'big'} `}
      type={type}
    >
      {icon}
      {children}
    </button>
  );
};
