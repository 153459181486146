import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './ProductionPage.module.css';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { Filter } from '../../Controls/FilterControl/models';
import { useData } from '../../Controls/DataControl/UseData';
import { useFilter } from '../../Controls/FilterControl';
import convert from 'convert-units';
import { formatNumberToNotation } from '../../../../../helper';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { EmptyState } from '../../Components/Cards/EmptyState';
import { EquipmentProduction } from '../../Controls/DataControl/models';

interface EquipmentProductionData extends EquipmentProduction {
  data: {
    totalCount: number;
    status: number;
    a: {
      c: number;
      s: number;
      s_converted: number;
      s_unit: string;
      max: number;
      min: number;
      p: number;
    };
    ro: {
      c: number;
      s: number;
      s_converted: number;
      s_unit: string;
      p: number;
    };
    ru: {
      c: number;
      s: number;
      s_converted: number;
      s_unit: string;
      p: number;
    };
    invalid: {
      c: number;
      p: number;
    }
  }
}

const normalizedUnit = (unitString: string) => {
  switch (unitString) {
    case 'g':
    case 'kg':
    case 'lb':
      return unitString;
    default:
      return 'g';
  }
};

function minsDiff(startTimestamp: number, endTimestamp: number): number {
  const differenceInMilliseconds = endTimestamp - startTimestamp;
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  return differenceInMinutes;
}

export const ProductionPage = ({
  containerHeight,
  containerWidth
}: {
  containerHeight: number,
  containerWidth: number
}): React.ReactElement => {
  const { t } = useTranslation();
  const { filter, resetFilterRules } = useFilter();
  const { fetchEquipmentProduction } = useData();
  const abortFetchRef = useRef<() => void>();
  const [loading, setLoading] = useState<boolean>(true);
  const [equipmentProduction, setEquipmentProduction] = useState<EquipmentProductionData[]>([]);

  const localFetch = useCallback(
    async (filter: Filter) => {
      const minutesProducing = minsDiff(filter.dates[0].startTimestamp, filter.dates[0].endTimestamp);
      setLoading(true);
      try {
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }

        const { abort, fetch } = fetchEquipmentProduction();
        abortFetchRef.current = abort;
        await fetch(filter).then((res) => {
          setEquipmentProduction(
            res.map(({ equipment, product, op, data }) => {
              let convertedA = convert(
                convert(data.a.s).from('g').to(normalizedUnit(equipment.unit))
              )
                .from(normalizedUnit(equipment.unit))
                .toBest({ exclude: ['mt', 'mg', 'mcg'] });
              if (convertedA.val > 1000) {
                if (convertedA.unit === 'kg') {
                  convertedA = {
                    val: convertedA.val / 1000,
                    unit: 't',
                    singular: 't',
                    plural: 't',
                  };
                } else if (convertedA.unit === 'g') {
                  convertedA = {
                    val: convertedA.val / 1000,
                    unit: 'kg',
                    singular: 'kg',
                    plural: 'kg',
                  };
                }
              };

              let convertedRO = convert(
                convert(data.ro.s).from('g').to(normalizedUnit(equipment.unit))
              )
                .from(normalizedUnit(equipment.unit))
                .toBest({ exclude: ['mt', 'mg', 'mcg'] });
              if (convertedRO.val > 1000) {
                if (convertedRO.unit === 'kg') {
                  convertedRO = {
                    val: convertedRO.val / 1000,
                    unit: 't',
                    singular: 't',
                    plural: 't',
                  };
                } else if (convertedRO.unit === 'g') {
                  convertedRO = {
                    val: convertedRO.val / 1000,
                    unit: 'kg',
                    singular: 'kg',
                    plural: 'kg',
                  };
                }
              };

              let convertedRU = convert(
                convert(data.ru.s).from('g').to(normalizedUnit(equipment.unit))
              )
                .from(normalizedUnit(equipment.unit))
                .toBest({ exclude: ['mt', 'mg', 'mcg'] });
              if (convertedRU.val > 1000) {
                if (convertedRU.unit === 'kg') {
                  convertedRU = {
                    val: convertedRU.val / 1000,
                    unit: 't',
                    singular: 't',
                    plural: 't',
                  };
                } else if (convertedRU.unit === 'g') {
                  convertedRU = {
                    val: convertedRU.val / 1000,
                    unit: 'kg',
                    singular: 'kg',
                    plural: 'kg',
                  };
                }
              };

              let totalCount = data.a.c + data.ro.c + data.ru.c + data.invalid.c;

              return {
                equipment,
                product,
                op,
                data: {
                  totalCount: totalCount,
                  status: (totalCount / minutesProducing),
                  a: {
                    c: data.a.c,
                    s: data.a.s,
                    s_converted: convertedA.val,
                    s_unit: convertedA.unit,
                    max: data.a.max,
                    min: data.a.min,
                    p: (data.a.c / totalCount) * 100
                  },
                  ro: {
                    c: data.ro.c,
                    s: data.ro.s,
                    s_converted: convertedRO.val,
                    s_unit: convertedRO.unit,
                    p: (data.ro.c / totalCount) * 100
                  },
                  ru: {
                    c: data.ru.c,
                    s: data.ru.s,
                    s_converted: convertedRU.val,
                    s_unit: convertedRU.unit,
                    p: (data.ru.c / totalCount) * 100
                  },
                  invalid: {
                    c: data.invalid.c,
                    p: (data.invalid.c / totalCount) * 100
                  }
                }
              }
            })
          );
          setLoading(false);
        })
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
      setLoading(false);
    },
    [abortFetchRef, fetchEquipmentProduction, filter]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    localFetch(filter);
  }, [filter]);

  const options = (producedPackages: number, projectedPackages: number) => {
    const packagesLeft = projectedPackages - producedPackages;

    const options: ApexOptions = {
      series: [
        {
          data: [producedPackages]
        },
        {
          data: [packagesLeft]
        }
      ],
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        offsetX: -5,
        offsetY: -20,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      fill: {
        opacity: 1
      },
      xaxis: {
        title: { ...AxisTitleConfiguration(`${producedPackages} / ${projectedPackages} (${(producedPackages / projectedPackages * 100).toFixed(2)}%)`, "0.65rem", "normal"), offsetY: -10 },
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      colors: ['#21dc92', '#ec5757'],
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: false
      },
    };

    return {
      options
    }
  }

  return (
    <div className={style.container}>
      <SpecificChartPageTitle text={t('specificChartPage:production', { defaultValue: 'Produção' })} />

      {loading ? (
        <LoadingState />
      ) : (
        <>
          <div className={style.content}>
            <div className={style.cardsSection}>
              {equipmentProduction.length > 0 ? equipmentProduction.sort((a: any, b: any) => a.equipment?.name.localeCompare(b.equipment?.name))
                .map(({ equipment, product, op, data }: any, i: number) => (
                  <div key={i} className={style.card}>
                    <div className={style.equipment}>
                      <h2>{equipment.name}</h2>
                      <div>{equipment.groupName}</div>
                      <div>{t('productionPage:status', { defaultValue: 'Status' })}: {data.status.toFixed(0)} un/min</div>
                    </div>

                    <div className={style.product}>
                      <div>{t('productionPage:code', { defaultValue: 'Código' })}: {product.codeText}</div>
                      <Tooltip tooltip={product.name}>
                        <div>{t('productionPage:name', { defaultValue: 'Nome' })}: {product.name}</div>
                      </Tooltip>
                      <Tooltip tooltip={product.brand}>
                        <div>{t('productionPage:brand', { defaultValue: 'Marca' })}: {product.brand}</div>
                      </Tooltip>
                      <div>{t('productionPage:weight', { defaultValue: 'Peso' })}: {product.netWeight} g</div>
                    </div>

                    <div className={style.productionData}>
                      <div className={style.metricsSection}>

                        <div className={style.metrics}>
                          <div>{t('productionPage:useful', { defaultValue: 'Úteis' })}:</div>
                          <div className={style.greenText} >{data.a.c} un</div>
                        </div>
                        <div className={style.metrics}>
                          <div className={style.greenText} >{data.a.p.toFixed(2)} %</div>
                          <div className={style.greenText} >{`${formatNumberToNotation(data.a.s_converted)} ${data.a.s_unit}`}</div>
                        </div>
                        <div className={style.metrics}>
                          <div className={style.greenText} >{t('productionPage:average', { defaultValue: 'Média' })}: {(data.a.s / data.a.c).toFixed(2)} g</div>
                          <div className={style.greenText} >{`(${data.a.min} g - ${data.a.max} g)`}</div>
                        </div>

                      </div>

                      <div className={style.metricsSection}>
                        <div className={style.metrics}>
                          <div>{t('productionPage:rejectedOverDot', { defaultValue: 'Rej. Superior' })}:</div>
                          <div className={style.redText} >{data.ro.c} un</div>
                        </div>
                        <div className={style.metrics}>
                          <div className={style.redText} >{data.ro.p.toFixed(2)} %</div>
                          <div className={style.redText} >{`${formatNumberToNotation(data.ro.s_converted)} ${data.ro.s_unit}`}</div>
                        </div>
                      </div>

                      <div className={style.metricsSection}>
                        <div className={style.metrics}>
                          <div>{t('productionPage:rejectedUnderDot', { defaultValue: 'Rej. Inferior' })}:</div>
                          <div className={style.redText} >{data.ru.c} un</div>
                        </div>
                        <div className={style.metrics}>
                          <div className={style.redText} >{data.ru.p.toFixed(2)} %</div>
                          <div className={style.redText} >{`${formatNumberToNotation(data.ru.s_converted)} ${data.ru.s_unit}`}</div>
                        </div>
                      </div>

                      <div className={style.metricsSection}>
                        <div className={style.metrics}>
                          <div>{t('productionPage:invalidDot', { defaultValue: 'Rej. Inválido' })}:</div>
                          <div className={style.redText} >{`${data.invalid.c} un (${data.invalid.p.toFixed(2)}%)`}</div>
                        </div>
                      </div>

                      <div className={style.metricsSection}>
                        <div className={style.metrics}>
                          <div>{t('productionPage:operationalFactor', { defaultValue: 'Fator operacional' })}:</div>
                          <div>{((1 - equipment.factor) * 100).toFixed(2)} %</div>
                        </div>
                      </div>

                      <div className={style.metricsSection}>
                        <div className={style.metrics}>
                          <div>{t('productionPage:productionOrder', { defaultValue: 'Ordem de produção' })}:</div>
                          <div>{op.codeText}</div>
                        </div>
                      </div>

                    </div>

                    <div>
                      {op.projectedPackages > 0 ? (
                        <Chart
                          type="bar"
                          id="prd-chart"
                          height={80}
                          options={options(op.producedPackages, op.projectedPackages).options}
                          series={options(op.producedPackages, op.projectedPackages).options.series}
                        />
                      ) : (
                        <div className={style.noData}>
                          <div>{t('productionPage:noData', { defaultValue: 'Não há dados para o período selecionado.' })}</div>
                        </div>
                      )}

                    </div>

                  </div>
                )) : <EmptyState />}
            </div>
          </div>
        </>
      )}
    </div>
  )
}