import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { formatSecondsToHumanReadableTime } from '../../../../../../helper/FormatSecondsToHumanReadableTime';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import { Filter } from '../../../Controls/FilterControl/models';
import style from './OperationalFactorCard.module.css';
import { useTranslation } from 'react-i18next';

export const OperationalFactorCard = ({
  occurrencesBlacklist,
  showOccurrencesFilter,
}: {
  occurrencesBlacklist: string[];
  showOccurrencesFilter: () => void;
}): React.ReactElement => {
  const { fetchEvents } = useData();
  const { filter } = useFilter();
  const [data, setData] = useState<{
    producing: number;
    stopped: number;
    operationalFactor: number;
    timeAvailable: number;
  }>({ producing: 0, stopped: 0, operationalFactor: 0, timeAvailable: 0 });
  const abortFetchRef = useRef<() => void>();
  const { t } = useTranslation();

  const localFetch = useCallback(
    async (localFilter: Filter, localOccurrencesBlacklist) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      const { abort, fetch } = fetchEvents([]);
      abortFetchRef.current = abort;
      const result = await fetch(localFilter);
      const stopped = parseInt(
        result
          .reduce(
            (acc, curr) =>
              acc +
              curr.duration -
              curr.events
                .filter(({ code }) =>
                  localOccurrencesBlacklist?.includes(code?.toString())
                )
                .reduce((acc, curr) => acc + curr.duration, 0),
            0
          )
          .toFixed(0)
      );
      const timeAvailable = parseInt(
        result
          .reduce(
            (acc, { timeAvailable, ...curr }) =>
              acc +
              timeAvailable -
              curr.events
                .filter(({ code }) =>
                  localOccurrencesBlacklist?.includes(code?.toString())
                )
                .reduce((acc, curr) => acc + curr.duration, 0),
            0
          )
          .toFixed(0)
      );
      setData({
        timeAvailable,
        stopped,
        producing: Math.max(0, timeAvailable - stopped),
        operationalFactor: Math.max(
          0,
          ((timeAvailable - stopped) / timeAvailable) * 100
        ),
      });
    },
    [abortFetchRef, fetchEvents, setData]
  );

  useEffect(() => {
    localFetch(filter, occurrencesBlacklist);
  }, [filter, occurrencesBlacklist]);

  useEffect(() => {
    return () => {
      setData({
        producing: 0,
        stopped: 0,
        operationalFactor: 0,
        timeAvailable: 0,
      });
    };
  }, [setData]);
  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2>{t('cardsPage:operationalFactor', { defaultValue: 'Fator Operacional' })}</h2>
        <FiFilter onClick={() => showOccurrencesFilter()} />
      </div>
      <div className={style.dataContainer}>
        <div className={style.data}>
          <h4 className={style.dataLabel}>{t('cardsPage:availableColon', { defaultValue: 'Disponível:' })}</h4>
          <strong className={style.dataValue}>
            {formatSecondsToHumanReadableTime(data.timeAvailable)}
          </strong>
        </div>
        <div className={style.data}>
          <h4 className={style.dataLabel}>{t('cardsPage:stoppedColon', { defaultValue: 'Parado:' })}</h4>
          <strong className={style.dataValue}>
            {formatSecondsToHumanReadableTime(data.stopped)}
          </strong>
        </div>
        <div className={style.data}>
          <h4 className={style.dataLabel}>{t('cardsPage:producingColon', { defaultValue: 'Produzindo:' })}</h4>
          <strong className={style.dataValue}>
            {formatSecondsToHumanReadableTime(data.producing)}
          </strong>
        </div>
        <div className={style.data}>
          <h4 className={style.dataLabel}>{t('cardsPage:operationalFactorColon', { defaultValue: 'Fator Operacional:' })}</h4>
          <strong className={style.dataValue}>
            {`${([
              Number.POSITIVE_INFINITY,
              Number.NEGATIVE_INFINITY,
              undefined,
              null,
            ].includes(data.operationalFactor) ||
              isNaN(data.operationalFactor ?? 0)
              ? 0
              : Math.max(
                parseFloat((data.operationalFactor ?? 0).toFixed(2)),
                0
              )
            ).toFixed(2)} %`}
          </strong>
        </div>
      </div>
    </div>
  );
};
