import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Production,
  ProductionEntry,
} from '../../../Controls/DataControl/models';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import { ChartOptionsLegend } from '../ChartOptionsLegend';
import { ValuesType } from 'utility-types';
import style from './AggregateProductionChart.module.css';
import { useResponsiveContainer } from '../../../Controls/ResponsiveContainerControl/UserResponsiveContainer';
import { EmptyState } from '../EmptyState';
import { Filter, FilterInterval } from '../../../Controls/FilterControl/models';
import {
  MultiSeriesChartPalette,
  formatNumberToNotation,
  AxisTitleConfiguration,
  ConvertValueToBestUnit,
} from '../../../../../../helper';
import { LoadingState } from '../LoadingState';
import convert from 'convert-units';
import ReactApexChart from 'react-apexcharts';
import { ChartOptionsDatetimeXaxis } from '../ChartOptionsDatetimeXaxis';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../LocaleControl';
import { getDateFnsLocation } from '../../../../../../helper/GetDateFnsLocation';

const normalizedUnit = (unitString: string) => {
  switch (unitString) {
    case 'g':
    case 'kg':
    case 'lb':
      return unitString;
    default:
      return 'g';
  }
};

const formatIntervalLabel = (intervalInSeconds: number) => {
  const days = Math.floor(intervalInSeconds / (24 * 60 * 60));
  const restOfDays = intervalInSeconds % (24 * 60 * 60);
  const hours = Math.floor(restOfDays / (60 * 60));
  const restOfHours = restOfDays % (60 * 60);
  const minutes = Math.floor(restOfHours / 60);
  const restOfMinutes = restOfHours % 60;
  const restOfSeconds = Math.floor(restOfMinutes);
  let message = '';
  if (days >= 1) {
    message += `${days.toFixed(0)} d `;
  }
  if (hours >= 1) {
    message += `${hours.toFixed(0)} h `;
  }
  if (minutes >= 1) {
    message += `${minutes.toFixed(0)} min `;
  }
  if (restOfSeconds >= 1) {
    message += `${restOfSeconds.toFixed(0)} s `;
  }
  if (message === '') {
    message = '0 s';
  }
  return message.trim();
};

export const AggregateProductionChart = (): React.ReactElement => {
  const [localProduction, setLocalProduction] = useState<Production[]>([]);
  const { fetchProduction } = useData();
  const { filter } = useFilter();
  const { containerWidth } = useResponsiveContainer();
  const [localInterval, setLocalInterval] = useState<FilterInterval>(
    filter.interval
  );
  const [localStartDate, setLocalStartDate] = useState<Date>(
    filter.customStartDate
  );
  const [localEndDate, setLocalEndDate] = useState<Date>(filter.customEndDate);
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const [loading, setLoading] = useState(true);
  const abortFetchRef = useRef<() => void>();
  const chartRef = useRef<ReactApexChart>(null);
  const { t } = useTranslation();
  const { chartOptionsLocale } = useLocale();
  const FALLBACK_LANGUAGE = 'pt_BR';
  const computeSerieOfEquipment = useCallback(
    ({
      unit,
      equipment,
      entries,
    }: Production): ValuesType<ApexAxisChartSeries> => {
      const name = `${equipment.name}`;
      const dictOfEntries: { [key: string]: Partial<ProductionEntry> } = entries
        .filter(({ sum, avg }) => !!sum || !!avg)
        .reduce(
          (acc, curr) => ({ ...acc, [curr.date.getTime().toString()]: curr }),
          {}
        );

      const step = filter.interval === '5m' ? 1000 * 60 * 5 : 1000;

      const sortedKeys = Object.keys(dictOfEntries).sort(
        (a, b) => parseInt(a) - parseInt(b)
      );

      const data = sortedKeys.map((key, index) => {
        let computedY = 0;
        let counter = 0;

        while (counter <= index) {
          computedY += dictOfEntries[sortedKeys[counter]].sum ?? 0;
          counter++;
        }
        return {
          x: parseInt(key),
          y: computedY,
          meta: {
            ...dictOfEntries[key],
            unit,
            interval: step,
          },
        };
      });

      const convertedData = data.map(({ y, meta, ...rest }) => {
        return {
          ...rest,
          y: y,
          meta: {
            ...meta,
            max: convert(meta.max ?? 0)
              .from('g')
              .to(normalizedUnit(unit)),
            min: convert(meta.min ?? 0)
              .from('g')
              .to(normalizedUnit(unit)),
            avg: convert(meta.avg ?? 0)
              .from('g')
              .to(normalizedUnit(unit)),
            sum: meta.sum,
            dev: convert(meta.dev ?? 0)
              .from('g')
              .to(normalizedUnit(unit)),
            unit,
          },
        };
      });
      return { name, data: convertedData };
    },
    [localEndDate, localStartDate, localInterval]
  );

  const localFetch = useCallback(
    async (filter: Filter) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      const { abort, fetch } = fetchProduction();
      abortFetchRef.current = abort;
      const response = await fetch({
        ...filter,
        interval: '5m',
      });
      setLocalProduction(response);
      setLoading(false);
    },
    [abortFetchRef, fetchProduction, setLocalProduction]
  );

  useEffect(() => {
    setLoading(true);
    localFetch(filter);
  }, [filter, fetchProduction, setLocalProduction]);

  useEffect(() => {
    setLocalInterval(filter.interval);
    setLocalStartDate(filter.customStartDate);
    setLocalEndDate(filter.customEndDate);
  }, [filter.interval, filter.customStartDate, filter.customEndDate]);

  useEffect(() => {
    setSeries(
      localProduction
        .reduce<Production[]>((acc, curr) => {
          if (
            !acc.some(
              ({ equipment, unit }) =>
                equipment.name === curr.equipment.name && unit === curr.unit
            )
          ) {
            return [...acc, curr];
          } else {
            const found = acc.find(
              ({ equipment, unit }) =>
                equipment.name === curr.equipment.name && unit === curr.unit
            );
            if (found) {
              return [
                ...acc.filter(
                  ({ equipment, unit }) =>
                    equipment.name !== curr.equipment.name || unit !== curr.unit
                ),
                {
                  ...found,
                  sum: found.sum + curr.sum,
                  entries: [...found.entries, ...curr.entries],
                },
              ];
            }
            return [...acc, curr];
          }
        }, [])
        .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
        .sort((a, b) => b.sum - a.sum)
        .map(computeSerieOfEquipment)
    );
  }, [localProduction, computeSerieOfEquipment]);

  useEffect(() => {
    setOptions({
      responsive: [
        {
          breakpoint: 900,
          options: {
            ...ChartOptionsDatetimeXaxis(3),
          },
        },
      ],
      ...ChartOptionsLegend,
      chart: {
        ...chartOptionsLocale,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        id: 'aggregateProduction',
        toolbar: { show: false },
      },
      ...ChartOptionsDatetimeXaxis(14),
      yaxis: {
        labels: {
          formatter: (val) => formatNumberToNotation(val / 1000),
        },
        title: AxisTitleConfiguration(t('cardsPage:weight', { defaultValue: 'Peso' })),
      },
      tooltip: {
        custom: ({
          w,
          seriesIndex,
          dataPointIndex,
        }: {
          w: any;
          seriesIndex: number;
          dataPointIndex: number;
        }) => {
          const dataPoint: any =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          if (!dataPoint) return 'no datapoint found';
          if (!dataPoint.meta.sum) return '';
          const sumConverted = ConvertValueToBestUnit(dataPoint.meta.sum);
          const acumulatedConverted = ConvertValueToBestUnit(dataPoint.y);
          return `<div style="">
      <div style="padding: 2px 8px;">${format(
            new Date(dataPoint.x),
            'cccccc dd MMM yy HH:mm:ss',
            {
              locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
            }
          )}</div>
      <div style="padding: 2px 8px; border-bottom: 1px solid #ddd">${format(
            new Date(dataPoint.x + dataPoint.meta.interval),
            'cccccc dd MMM yy HH:mm:ss',
            {
              locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
            }
          )}</div>
      <div style=" padding: 2px 8px">
      <div style="display: flex; justify-content: space-between; gap: 2rem;"><span>${t('cardsPage:interval', { defaultValue: 'Intervalo' })}</span> <span>
        ${formatIntervalLabel(dataPoint.meta.interval / 1000)}</span></div>
      <div style="display: flex; justify-content: space-between; gap: 2rem;"><span>${t('cardsPage:average', { defaultValue: 'Média' })}</span> <span>${formatNumberToNotation(
            dataPoint.meta.avg ?? dataPoint.y
          )} ${dataPoint.meta.unit}</span></div>
      <div style="display: flex;  justify-content: space-between;"><span>${t('cardsPage:max', { defaultValue: 'Máx.' })}</span><span>${formatNumberToNotation(
            dataPoint.meta.max ?? dataPoint.y
          )} ${dataPoint.meta.unit}</span></div>
      <div style="display: flex;  justify-content: space-between;"><span>${t('cardsPage:min', { defaultValue: 'Min.' })}</span><span>${formatNumberToNotation(
            dataPoint.meta.min ?? dataPoint.y
          )} ${dataPoint.meta.unit}</span></div>
      <div style="display: flex;  justify-content: space-between;"><span>${t('cardsPage:standardDeviation', { defaultValue: 'Desvio p.' })}</span><span>${formatNumberToNotation(
            dataPoint.meta.dev ?? 0
          )} ${dataPoint.meta.unit}</span></div>
      <div style="display: flex;  justify-content: space-between;"><span>${t('cardsPage:quant', { defaultValue: 'Quant.' })}</span><span>${dataPoint.meta.count ?? 0
            } un</span></div>
      <div style="display: flex;  justify-content: space-between;"><span>${t('cardsPage:sum', { defaultValue: 'Somatório' })}</span><span>${formatNumberToNotation(
              sumConverted.val
            )} ${sumConverted.unit}</span></div>
      <div style="display: flex;  justify-content: space-between; gap: 1rem;"><span>${t('cardsPage:accumulated', { defaultValue: 'Acumulado' })}</span><span>${formatNumberToNotation(
              acumulatedConverted.val
            )} ${acumulatedConverted.unit}</span></div>
      <div>
      </div>`;
        },
      },
      colors: MultiSeriesChartPalette,
      stroke: {
        curve: 'straight',
      },
      dataLabels: {
        enabled: false,
      },
    });
  }, [filter]);

  useEffect(() => {
    return () => {
      setOptions({});
      setSeries([]);
    };
  }, [setOptions, setSeries]);
  return (
    <div className={style.chartContainer}>
      <h2>{t('cardsPage:aggregateProduction', { defaultValue: 'Produção Acumulada' })}</h2>
      {loading ? (
        <LoadingState />
      ) : options && series.length ? (
        <Chart
          ref={chartRef}
          type='area'
          id='aggregateproductionchart'
          options={options}
          series={series}
          height={containerWidth > 767 ? 300 : 240}
        />
      ) : (
        <EmptyState />
      )}
    </div>
  );
};
