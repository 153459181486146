import { createContext } from 'react';
import { Zoom } from '../TenantPages/SignedIn/Controls/FilterControl';

export type ChartOptions = {
  defaultLocale: string;
  locales: {
    name: string;
    options: {
      months: string[];
      shortMonths: string[];
      days: string[];
      shortDays: string[];
      toolbar: {
        download: string;
        selection: string;
        selectionZoom: string;
        zoomIn: string;
        zoomOut: string;
        pan: string;
        reset: string;
      };
    };
  }[];
};

export type CalendarLocale = {
  name: string;
  months: string[][];
  weekDays: string[][];
  digits: string[];
  meridiems: string[][];
};

export type ZoomOptions = { key: Zoom; text: string }[];

export interface LocaleContextState {
  chartOptionsLocale: ChartOptions;
  calendarLocale: CalendarLocale;
  zoomOptions: ZoomOptions;
}

export const LocaleContext = createContext<LocaleContextState | undefined>(
  undefined
);
