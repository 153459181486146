import React from 'react';
import { ManagementAuthProvider } from './AuthControl';
import { Routes } from './Routes';
export const Management: React.FC = () => {
  return (
    <div>
      <ManagementAuthProvider>
        <Routes />
      </ManagementAuthProvider>
    </div>
  );
};
