import React from 'react';
import style from './EmptyState.module.css';
import { useTranslation } from 'react-i18next';

export const EmptyState = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <h3>{t('cardsPage:noDataForTheSelectedPeriod', { defaultValue: 'Não há dados para o período selecionado.' })}</h3>
    </div>
  );
};
