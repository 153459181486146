import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  RefObject,
} from 'react';
import { Label } from '../Label';
import style from './Input.module.css';
interface CustomInputProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange'
  > {
  label: string;
  onChange: (newValue: string) => void;
  grow?: boolean;
  width?: number;
  compact?: boolean;
  errors?: string[];
  inverted?: boolean;
  tooltip?: string;
  inputRef?: RefObject<HTMLInputElement>;
}
export const Input = ({
  id,
  value,
  type,
  label,
  onChange,
  grow = false,
  width,
  compact,
  errors,
  inverted,
  tooltip,
  inputRef,
  ...rest
}: CustomInputProps) => {
  return (
    <div id={id} className={`${style.container} `} data-grow={grow}>
      <Label
        tooltip={tooltip}
        value={label}
        inverted={inverted}
        htmlFor={`${id}-input`}
      />
      <div
        className={`${style.inputOutline}`}
        data-onerror={errors?.length}
        data-disabled={rest.disabled}
      >
        <input
          className={`${style.input}`}
          value={value}
          disabled={rest.disabled}
          onChange={(e) => onChange(e.target.value)}
          id={`${id}-input`}
          type={type}
          data-grow={grow}
          {...rest}
          ref={inputRef}
        />
      </div>
      <div className={` ${errors ? style.error : ''}`}>
        <span>{errors?.join(', ')}</span>
      </div>
    </div>
  );
};
