import { useEffect, useState } from 'react';
import { FormSeparator } from '../../../Components/Atoms/FormSeparator';
import style from './LanguageCreation.module.css'
import { Select } from '../../../Components/Atoms/Input/Select';
import { Button } from '../../../Components/Atoms/Button';
import { LanguageListItemModel } from '../LanguageListPages/LanguageModel';
import { useManagementAuth } from '../../AuthControl';
import { useToast } from "../../../hooks/toast";
import { useHistory } from 'react-router-dom';

export const LanguageCreation = (): React.ReactElement => {
  const [language, setLanguage] = useState<LanguageListItemModel>();
  const [possibleLanguages, setPossibleLanguages] = useState<LanguageListItemModel[]>([]);
  const { token, api, signOut } = useManagementAuth();
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    const controller = new AbortController();
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    api.get<LanguageListItemModel[]>(`/api/management/languages/possible-languages`, controller.signal)
      .then((possibleLanguages: LanguageListItemModel[]) => {
        setPossibleLanguages(possibleLanguages);
      })
      .catch((error) => {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        }
        throw 'error';
      });
    return () => controller.abort();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!language) {
      addToast({ type: 'error', title: 'Atenção', description: 'Selecione uma linguagem.' });
      return;
    }
    const body = { language: language.language };

    try {
      await api.post(`/api/management/languages`, body)
        .then(() => addToast({ type: 'success', title: 'Sucesso', description: 'Linguagem cadastrada com sucesso.' }));
      history.push('/management/s/languages');
    } catch (err) {
      addToast({ type: 'error', title: 'Erro', description: 'Houve um erro ao tentar adicionar o novo idioma.' });
    }
  }

  return (
    <form id="language-form" className={style.languageForm} onSubmit={handleSubmit}>
      <div className={style.header}>
        <h2>
          Lista de traduções - Adição de novo idioma
        </h2>
      </div>
      <FormSeparator id={'language-select'} label='Idioma' />
      <div>
        <Select
          id='name'
          grow
          label='Novo Idioma'
          options={possibleLanguages ? possibleLanguages.map((lang) => `(${lang.language}) ${lang.description}`) : []}
          value={language ? `(${language.language}) ${language.description}` : ''}
          onChange={(value) => {
            const selectedLanguage = possibleLanguages.find(
              (lang) => `(${lang.language}) ${lang.description}` === value
            );
            setLanguage((_) => selectedLanguage);
          }}
        />
      </div>
      <div className={style.saveButton}>
        <Button type='submit' disabled={!language}>Salvar</Button>
      </div>
    </form>
  );
}