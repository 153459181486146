import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { FilterProvider } from './Controls/FilterControl';
import { SignedInHeader } from './Components/Header';
import { MainDashboardPage } from './MainDashboardPage';
import { SpecificChartPage } from './SpecificChartPage';
import { DataProvider } from './Controls/DataControl/DataProvider';
import style from './SignedIn.module.css';
import { useAuth } from '../../AuthControl';
import { AdminPages } from './AdminPages';
import { EditProfilePage } from './EditProfilePage';
import { ResponsiveContainerProvider } from './Controls/ResponsiveContainerControl/ResponsiveContainerProvider';
import { AndonPages } from './AndonPages';
import { AdvancedFilter } from './Components/AdvancedFilter';
import { ReportsPage } from './ReportsPage';
import { LocaleProvider } from '../../LocaleControl';
interface ChartsPageMatchProps extends RouteComponentProps<{ chart: string }> {}

export const SignedInPages = () => {
  const { isAdmin } = useAuth();
  return (
    <ResponsiveContainerProvider>
      <DataProvider>
        <LocaleProvider>
          <div className={style.container}>
            <Switch>
              <Route
                path='/client/:tenant/painel-informativo'
                render={() => (
                  <FilterProvider key='andonFilter'>
                    <SignedInHeader
                      zoomWhitelist={[
                        'lst30m',
                        'lst60m',
                        'today',
                        'lst24h',
                        'yesterday',
                        'thisweek',
                        'lst7d',
                        'thismonth',
                        'lst30d',
                      ]}
                    />
                    <AdvancedFilter />
                    <AndonPages />
                  </FilterProvider>
                )}
              />
              <Route
                path='/client/:tenant/admin'
                render={() =>
                  isAdmin() ? (
                    <AdminPages />
                  ) : (
                    <Redirect to='/client/:tenant/inicio' />
                  )
                }
              />
              <Route
                path='/client/:tenant/eu'
                render={() => (
                  <FilterProvider key='editProfile'>
                    <SignedInHeader hideFilters />
                    <EditProfilePage />
                  </FilterProvider>
                )}
              />
              <Route
                path='/client/:tenant/inicio'
                render={() => (
                  <FilterProvider key='dashboardFilter'>
                    <SignedInHeader
                      zoomWhitelist={[
                        'lst30m',
                        'lst60m',
                        'today',
                        'lst24h',
                        'yesterday',
                        'thisweek',
                        'lst7d',
                      ]}
                    />
                    <MainDashboardPage />
                  </FilterProvider>
                )}
              />
              <Route
                path='/client/:tenant/relatorio'
                render={() => (
                  <FilterProvider key='specificChartFilter'>
                    <SignedInHeader />
                    <ReportsPage />
                  </FilterProvider>
                )}
              />
              <Route
                path='/client/:tenant/:chart'
                render={({
                  match: {
                    params: { chart },
                  },
                }: ChartsPageMatchProps) => (
                  <FilterProvider key='specificChartFilter'>
                    <SignedInHeader />
                    <SpecificChartPage chartName={chart} />
                  </FilterProvider>
                )}
              />
            </Switch>
          </div>
        </LocaleProvider>
      </DataProvider>
    </ResponsiveContainerProvider>
  );
};
