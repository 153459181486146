import React, { useState } from 'react';
import MenuList from './MenuList';
import Profile from './Profile';
import './style.css';
import style from './Menu.module.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useManagementAuth } from '../../../AuthControl';

const Menu: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { signOut, manager } = useManagementAuth();
  return (
    <div
      id='management-menu'
      className={`${style.menu} ${
        mobileMenuOpen ? style.menuMobileVisible : ''
      }`}
    >
      <div>
        <Profile manager={manager} signOut={signOut} />
        <MenuList closeMobileMenu={() => setMobileMenuOpen(false)} />
      </div>
      <div
        className={style.menuMobileButton}
        onClick={() => {
          setMobileMenuOpen((prev) => !prev);
        }}
      >
        <GiHamburgerMenu />
      </div>
    </div>
  );
};

export default Menu;
