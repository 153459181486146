import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ClientPages } from './Client';
import { Management } from './Management';
import { Welcome } from './welcome';

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Welcome />
      </Route>
      <Route path='/management'>
        <Management />
      </Route>
      <Route path='/client'>
        <ClientPages />
      </Route>
    </Switch>
  );
};
