import { useEffect, useState } from 'react';
import { Select } from '../../../../../Components/Atoms/Input/Select';
import { SamplesModal } from './SamplesModal';
import style from '../SpecificChartPage.module.css';
import uniqueStyle from './ProductionConformityPage.module.css';
import { SelectedData } from './models';
import { Button } from '../../../../../Components/Atoms/Button';
import { format } from 'date-fns';
import { Label } from '../../../../../Components/Atoms/Label';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocation } from '../../../../../helper/GetDateFnsLocation';

interface Props {
  setSampleSize: (value: number) => void;
  sampleSize: number;
  selectedData?: SelectedData;
}

export const SideMenuComponent = ({
  setSampleSize,
  sampleSize,
  selectedData,
}: Props): React.ReactElement => {
  const [openSamplesModal, setOpenSamplesModal] = useState(false);
  const [hideSampleSelection, setHideSampleSelection] = useState(false);
  const [selectedDataSample, setSelectedDataSample] = useState<{
    index: number;
    date: Date;
    packagesBelowIndividualTolerance: number;
    dev: number;
    avg: number;
    unit?: string;
    criterion: number;
    approvedAvg: boolean;
    approvedIndividual: boolean;
  }>();
  const { t } = useTranslation();
  const FALLBACK_LANGUAGE = 'pt_BR';

  useEffect(() => {
    if (!selectedData) return;
    if (selectedData.sumary.samples.length === 1) {
      setHideSampleSelection(true);
      setSelectedDataSample({ index: 0, ...selectedData.sumary.samples[0] });
    } else {
      setSelectedDataSample(undefined);
    }
  }, [selectedData]);

  return (
    <aside className={uniqueStyle.aside}>
      <Select
        id='sampleSizeSelector'
        label={t('specificChartPage:sampleSize', { defaultValue: 'Tamanho da Amostra' })}
        noEmpty
        options={['5 un', '13 un', '20 un', '32 un', '80 un']}
        onChange={(value) => setSampleSize(parseInt(value.replace(' un', '')))}
        value={`${sampleSize.toString()} un`}
      />
      {!!selectedData && !hideSampleSelection && (
        <div>
          <Label value={t('specificChartPage:samples', { defaultValue: 'Amostras' })} />
          <div
            className={`${uniqueStyle.samplesList} ${uniqueStyle.chartDetailsArea}`}
          >
            {selectedData.sumary.samples.map(
              ({ avg, date, unit, ...rest }, index) => (
                <div
                  key={Math.random()}
                  className={uniqueStyle.sampleItem}
                  data-selected={selectedDataSample?.index === index}
                  onClick={() => {
                    setSelectedDataSample({ index, avg, date, ...rest });
                  }}
                >
                  <div>
                    <h3>{index + 1}</h3>
                  </div>
                  <div>
                    <div>
                      {format(new Date(date), 'cccccc dd MMM yy HH:mm:ss', {
                        locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
                      })}
                    </div>
                    <div>{`${t('specificChartPage:average', { defaultValue: 'média' })} ${avg.toFixed(2)} ${unit ?? ''}`}</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
      <div className={uniqueStyle.chartDetailsArea}>
        {!!selectedData && !!selectedDataSample ? (
          <>
            {openSamplesModal && (
              <SamplesModal
                samples={selectedData.values
                  .slice(
                    selectedDataSample.index,
                    selectedDataSample.index + sampleSize
                  )
                  .map((data) => ({
                    ...data,
                    unit: selectedData.product.unit,
                  }))}
                handleClose={() => setOpenSamplesModal(false)}
              />
            )}

            <div className={uniqueStyle.criteriumReport}>
              <div className={uniqueStyle.individualCriteriumReport}>
                <div className={style.criteriumItem}>
                  <span>{t('specificChartPage:individualCriterion', { defaultValue: 'Critério individual' })}</span>
                  <h4>
                    {selectedDataSample?.approvedIndividual
                      ? t('specificChartPage:approved', { defaultValue: 'Aprovado' })
                      : t('specificChartPage:rejected', { defaultValue: 'Rejeitado' })}
                  </h4>
                </div>
              </div>
              {!!selectedDataSample && (
                <div className={uniqueStyle.averageCriteriumReport}>
                  <div className={style.criteriumItem}>
                    <span>{t('specificChartPage:averageGrossWeight', { defaultValue: 'Peso médio bruto' })}</span>
                    <h4>
                      {`${formatNumberToNotation(selectedDataSample?.avg)} ${selectedData.product.unit ?? ''
                        }` ?? t('specificChartPage:unavailable', { defaultValue: 'Indisponível' })}
                    </h4>
                  </div>
                  <div className={style.criteriumItem}>
                    <span>{t('specificChartPage:averageNetWeight', { defaultValue: 'Peso médio líquido' })}</span>
                    <h4>
                      {isNaN(
                        selectedDataSample?.avg -
                        selectedData.product.packageWeight
                      )
                        ? t('specificChartPage:unavailable', { defaultValue: 'Indisponível' })
                        : `${formatNumberToNotation(
                          selectedDataSample?.avg -
                          selectedData.product.packageWeight
                        )} ${selectedData.product.unit ?? ''}`}
                    </h4>
                  </div>
                  <div className={style.criteriumItem}>
                    <span>{t('specificChartPage:packageWeight', { defaultValue: 'Peso da embalagem' })}</span>
                    <h4>
                      {`${formatNumberToNotation(
                        selectedData.product.packageWeight
                      )} ${selectedData.product.unit ?? ''}` ?? t('specificChartPage:unavailable', { defaultValue: 'Indisponível' })}
                    </h4>
                  </div>
                  <div className={style.criteriumItem}>
                    <span>{t('specificChartPage:sampleStandardDeviation', { defaultValue: 'Desvio padrão da amostra' })}</span>
                    <h4>
                      {`${formatNumberToNotation(selectedDataSample?.dev)} ${selectedData.product.unit ?? ''
                        }` ?? t('specificChartPage:unavailable', { defaultValue: 'Indisponível' })}
                    </h4>
                  </div>
                  <div className={style.criteriumItem}>
                    <span>{t('specificChartPage:multiplierFactor', { defaultValue: 'Fator multiplicador' })}</span>
                    <h4>
                      {selectedData.sumary.multiplierFactor ?? t('specificChartPage:unavailable', { defaultValue: 'Indisponível' })}
                    </h4>
                  </div>
                  <div className={style.criteriumItem}>
                    <span>{t('specificChartPage:criterion', { defaultValue: 'Critério' })}</span>
                    <h4>
                      {`${formatNumberToNotation(
                        selectedDataSample.criterion
                      )} ${selectedData.product.unit ?? ''}` ?? ''}
                    </h4>
                  </div>
                  <div className={style.criteriumItem}>
                    <span>{t('specificChartPage:averageCriterion', { defaultValue: 'Critério da média' })}</span>
                    <h4>
                      {selectedDataSample?.approvedAvg
                        ? t('specificChartPage:approved', { defaultValue: 'Aprovado' })
                        : t('specificChartPage:disapproved', { defaultValue: 'Reprovado' })}
                    </h4>
                  </div>
                </div>
              )}
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  setOpenSamplesModal(true);
                }}
              >
                {t('specificChartPage:viewSamples', { defaultValue: 'Ver Amostras' })}
              </Button>
            </div>
          </>
        ) : (
          <div>
            <h4>{t('specificChartPage:selectASampleToSeeMoreDetails', { defaultValue: 'Selecione uma amostra para ver mais detalhes' })}</h4>
          </div>
        )}
      </div>
    </aside>
  );
};
