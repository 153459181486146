import { useState, useCallback, useMemo } from 'react';
import Api from '../../helper/Api';
import { ManagementAuthContext } from './AuthContext';
import {
  Credentials,
  ManagementAuthState,
  Manager,
  SignInResponse,
} from './Models';
import { useHistory } from 'react-router-dom';

export const ManagementAuthProvider: React.FC = ({ children }) => {
  const { push } = useHistory();
  const [data, setData] = useState<ManagementAuthState>(() => {
    const token = localStorage.getItem('@medianeira:token');
    const stringfiedManager = localStorage.getItem('@medianeira:manager');
    if (token && stringfiedManager) {
      return { token, manager: JSON.parse(stringfiedManager) };
    }
    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    const headers = new Headers();
    headers.append('content-type', 'application/json');
    const { token: newToken, ...newManager } = await api.post<
      SignInResponse,
      Credentials
    >(`/api/management/managers/access`, {
      email,
      password,
    });
    if (!newToken || !newManager) {
      throw 'Invalid credentials';
    }
    if (newToken && newManager) {
      localStorage.setItem('@medianeira:token', newToken);
      localStorage.setItem('@medianeira:manager', JSON.stringify(newManager));
      setData({ token: newToken, manager: newManager });
    }
    return newManager;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@medianeira:token');
    localStorage.removeItem('@medianeira:manager');
    push(`/management`);
    setData({});
  }, []);

  const updateManager = useCallback((manager: Manager) => {
    setData((prev) => ({
      token: prev.token,
      manager,
    }));
    localStorage.setItem('@medianeira:manager', JSON.stringify(manager));
  }, []);

  let api: Api = useMemo(() => {
    const headers = new Headers();
    headers.append('content-type', 'application/json');
    if (data?.token) {
      headers.append('Authorization', `Bearer ${data.token}`);
    }
    return new Api(signOut, headers);
  }, [data.token]);
  return (
    <ManagementAuthContext.Provider
      value={{
        manager: data.manager,
        token: data.token,
        signIn,
        signOut,
        updateManager,
        api,
      }}
    >
      {children}
    </ManagementAuthContext.Provider>
  );
};
