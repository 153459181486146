import { useContext } from 'react';
import { ResponsiveContainerContextState } from './models';
import { ResponsiveContainerContext } from './ResponsiveContainerContext';

export const useResponsiveContainer = (): ResponsiveContainerContextState => {
  const context = useContext(ResponsiveContainerContext);
  if (!context) {
    throw new Error(
      'useResponsiveContainerContext must be used inside ResponsiveContainerContextProvider'
    );
  }
  return context;
};
