import { ApexOptions } from 'apexcharts';

export const ChartOptionsLegend: Pick<ApexOptions, 'legend'> = {
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    markers: { radius: 6, width: 12, height: 12 },
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    show: true,
    onItemClick: {
      toggleDataSeries: false,
    },
  },
};
