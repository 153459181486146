import { Button } from '../../../../../Components/Atoms/Button';
import Modal from '../../../../../Components/Modal';
import AppError from '../../../../../helper/AppError';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import style from './ConfirmDeleteAllEquipmentConfigModal.module.css';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteAllEquipmentConfigModal = ({
  tenant,
  equipmentId,
  equipmentName,
  handleClose,
}: {
  tenant: string;
  equipmentId: string;
  equipmentName: string;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { t } = useTranslation();
  const { api } = useAuth();
  const { addToast } = useToast();

  const handleDeleteAllEquipmentConfig = async () => {
    try {
      const response = await api.delete(`/api/client/${tenant}/registrations/equipments/${equipmentId}/products/config`);
        if (response === true) {
            addToast({ title: t('productPage:configSuccessfullyDeleted', { defaultValue: 'Configurações excluídas com sucesso' }), type: 'success' });
        } else {
            addToast({ title: t('productPage:errorDeletingConfig', { defaultValue: 'Houve um erro ao excluir as configurações.' }), type: 'error' });
        }
    } catch (error) {
        throw new AppError("Error deleting product configuration");
    }
    handleClose(true);
  }

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('productPage:deleteAllConfig', { defaultValue: 'Excluir todas configurações?' })}</h1>
        <p>
          {t('equipmentPage:equipment', { defaultValue: 'Equipamento' })}: {equipmentName}
        </p>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('productPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteAllEquipmentConfig()}>
            {t('productPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
}