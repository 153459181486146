import React from 'react';
import Checkbox from '../../../../../../Components/Atoms/Checkbox';
import { Input } from '../../../../../../Components/Atoms/Input';
import style from '../EquipmentPage.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
  filePath: string;
  onFilePathChange: (value: string) => void;
  configFilePath: string;
  onConfigFilePathChange: (value: string) => void;
  equipmentReference: string;
  onEquipmentReferecenChange: (value: string) => void;
  shouldSyncOldFiles: boolean;
  onShouldSyncOldFilesChange: (value: boolean) => void;
}

export const CPNetEquipmentFormComponent = ({
  filePath,
  onFilePathChange,
  configFilePath,
  onConfigFilePathChange,
  equipmentReference,
  onEquipmentReferecenChange,
  shouldSyncOldFiles,
  onShouldSyncOldFilesChange,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        required
        disabled
        label={t('equipmentPage:dataFilePath', { defaultValue: 'Local do arquivo dos dados' })}
        placeholder='c:/'
        value={filePath}
        onChange={onFilePathChange}
      />
      <Input
        required
        disabled
        label={t('equipmentPage:configurationFilePath', { defaultValue: 'Local do arquivo de configuração' })}
        placeholder='c:/'
        value={configFilePath}
        onChange={onConfigFilePathChange}
      />
      <div className={style.formHorizontalGroup}>
        <Input
          required
          disabled
          label={t('equipmentPage:equipmentReference', { defaultValue: 'Referência do equipamento' })}
          placeholder='cp'
          value={equipmentReference}
          onChange={onEquipmentReferecenChange}
        />
        <Checkbox
          disabled
          label={t('equipmentPage:syncOldFiles', { defaultValue: 'Sincronizar dados antigos' })}
          checked={shouldSyncOldFiles}
          onChange={onShouldSyncOldFilesChange}
        />
      </div>
    </>
  );
};
