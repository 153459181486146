import { useTranslation } from "react-i18next";
import { useToast } from "../../../../../hooks/toast";
import { useAuth } from "../../../../AuthControl";
import Modal from "../../../../../Components/Modal";
import style from './ConfirmDeleteEquipmentGroupModal.module.css';
import { Button } from "../../../../../Components/Atoms/Button";
import { EquipmentGroup } from "../../Controls/DataControl/models";

export const ConfirmDeleteEquipmentGroupModal = ({
  tenant,
  group,
  handleClose,
}: {
  tenant: string;
  group: EquipmentGroup;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { addToast } = useToast();
  const { api } = useAuth();
  const { t } = useTranslation();

  const handleDeleteEquipmentGroup = async () => {
    try {
      await api.delete(
        `/api/client/${tenant}/registrations/equipments/groups/${group.id}`
      );
      addToast({
        title: t('equipmentPage:equipmentGroupDeletedSuccessfully', {
          defaultValue: 'Grupo de Equipamentos excluído com sucesso.'
        }), type: 'success'
      });
      handleClose(true);
    } catch (err) {
      addToast({
        title: t('equipmentPage:errorDeletingEquipmentGroup', {
          defaultValue: 'Erro ao excluir grupo de equipamentos.'
        }), type: 'error'
      });
    }
  };

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('equipmentPage:delete', { defaultValue: 'Excluir' })} {group.name} ?</h1>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('equipmentPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteEquipmentGroup()}>
            {t('equipmentPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
}