import { useHistory, useLocation } from "react-router-dom";
import style from './LanguageTextEdit.module.css';
import { FormSeparator } from "../../../Components/Atoms/FormSeparator";
import { Input } from "../../../Components/Atoms/Input";
import { useEffect, useState } from "react";
import { Button } from "../../../Components/Atoms/Button";
import { useManagementAuth } from "../../AuthControl";
import { useToast } from "../../../hooks/toast";
import { Language, LanguageModule, LanguageModuleText } from "../LanguageListPages/LanguageModel";

export const LanguageTextEdit = (): React.ReactElement => {
  const { pathname } = useLocation();
  const language = pathname.split('/').slice(-3, -2).toString();
  const module = pathname.split('/').slice(-2, -1).toString();
  const code = pathname.split('/').slice(-1).toString();
  const [newText, setNewText] = useState<string>('');
  const { api } = useManagementAuth();
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    api.get(`/api/management/languages/${language}`)
      .then((language: Language | any) => {
        if (language) {
          const moduleFound = language.modules.find((moduleFound: LanguageModule | any) => moduleFound.name === module);
          if (!moduleFound) {
            return;
          }
          const textFound = moduleFound.texts.find((textFound: LanguageModuleText | any) => textFound.code === code);
          if (!textFound) {
            return;
          }
          setNewText(textFound.text);
        }
      });
  }, []);

  const handleUpdateText = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      code: code,
      text: newText
    }
    try {
      await api.put(`/api/management/languages/${language}/${module}`, body)
        .then(() => addToast({ type: 'success', title: 'Sucesso', description: 'Texto atualizado com sucesso.' }));
      history.push(`/management/s/languages/${language}`);
    } catch (err) {
      addToast({ type: 'error', title: 'Erro', description: 'Houve um erro ao tentar atualizar o texto.' });
    }
  }

  return (
    <form onSubmit={handleUpdateText}>
      <div className={style.header}>
        <h2>
          Lista de traduções - {language} - {code}
        </h2>
      </div>
      <FormSeparator id={'text-adjust'} label='Ajuste Texto' />
      <div className={style.textInput}>
        <div>
          <Input
            id="new-text"
            grow
            label="Novo texto"
            type="text"
            value={newText}
            onChange={setNewText} />
        </div>
      </div>
      <div className={style.saveButton}>
        <Button type='submit' disabled={!newText}>Salvar</Button>
      </div>
    </form>
  );
}