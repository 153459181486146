import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { format, formatDistance } from 'date-fns';
import { useFilter } from '../Controls/FilterControl';
import { useData } from '../Controls/DataControl/UseData';
import style from './SpecificChartPage.module.css';
import { EmptyState } from '../Components/Cards/EmptyState';
import { ChartOptionsLegend } from '../Components/Cards/ChartOptionsLegend';
import { Scrap } from '../Controls/DataControl/models';
import { Filter } from '../Controls/FilterControl/models';
import { LoadingState } from '../Components/Cards/LoadingState';
import convert from 'convert-units';
import { ChartOptionsDatetimeXaxis } from '../Components/Cards/ChartOptionsDatetimeXaxis';
import { SpecificChartPageTitle } from '../Components/SpecificChartPageTitle';
import { formatNumberToNotation } from '../../../../helper/FormatNumberToNotation';
import { AxisTitleConfiguration } from '../../../../helper/AxisTitleConfiguration';
import { useLocale } from '../../../LocaleControl';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocation } from '../../../../helper/GetDateFnsLocation';

interface Props {
  containerWidth: number;
  containerHeight: number;
}

const unit = (unitString: string) => {
  switch (unitString) {
    case 'g':
    case 'kg':
    case 'lb':
      return unitString;
    default:
      return 'g';
  }
};
export const ScrapsPage = ({ containerHeight }: Props): React.ReactElement => {
  const { filter, resetFilterRules } = useFilter();
  const { fetchProductionScraps } = useData();
  const [scraps, setScraps] = useState<Scrap[]>([]);
  const abortFetchRef = useRef<() => void>();
  const [loading, setLoading] = useState(true);
  const isZooming = useRef(false);
  const { t } = useTranslation();
  const localFetch = useCallback(
    async (localFetchFilter: Filter) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      const { abort, fetch } = fetchProductionScraps();
      abortFetchRef.current = abort;
      const response = await fetch(localFetchFilter);
      setScraps(response);
      setLoading(false);
    },
    [fetchProductionScraps, setScraps]
  );
  const { chartOptionsLocale } = useLocale();
  const FALLBACK_LANGUAGE = 'pt_BR';

  useEffect(() => {
    resetFilterRules();
    setScraps([]);
  }, []);

  useEffect(() => {
    if (isZooming.current) {
      return;
    }
    setLoading(true);
    localFetch(filter);
  }, [filter, isZooming]);

  const options: ApexOptions = useMemo(
    () => ({
      responsive: [
        {
          breakpoint: 900,
          options: { ...ChartOptionsDatetimeXaxis(3) },
        },
        {
          breakpoint: 1400,
          options: { ...ChartOptionsDatetimeXaxis(7) },
        },
      ],
      ...ChartOptionsLegend,
      chart: {
        animations: {
          enabled: false,
        },
        type: 'bar',
        ...chartOptionsLocale,
      },
      yaxis: {
        labels: {
          formatter: formatNumberToNotation,
          style: { fontSize: '1em' },
        },
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: '95%',
          columnWidth: '100%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      ...ChartOptionsDatetimeXaxis(14),
      events: {
        zoomed: () => {
          isZooming.current = true;
        },
        beforeResetZoom: () => {
          isZooming.current = false;
        },
      },
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const dataPoint =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return `<div style="width:220px;">
          <div style="padding: 2px 8px; border-bottom: 1px solid #ddd">${format(
            new Date(dataPoint.x),
            'cccccc dd MMM yy HH:mm',
            {
              locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
            }
          )}</div>
          <div style=" padding: 2px 8px">
          <div style="display: flex; justify-content: space-between; line-height:1.5rem;"><span>${t('specificChartPage:equipment', { defaultValue: 'Equipamento' })}</span> <span>${dataPoint.metadata.equipment.name ?? 'Não informado'
            }</span></div>
          <div style="display: flex; justify-content: space-between; line-height:1.5rem;"><span>${t('specificChartPage:lastOperator', { defaultValue: 'Último operador' })}</span> <span>${dataPoint.metadata.operator.name ?? 'Não informado'
            }</span></div>
          <div style="display: flex; justify-content: space-between; line-height:1.5rem;"><span>${t('specificChartPage:product', { defaultValue: 'Produto' })}</span> <span>${dataPoint.metadata.product.name
            }</span></div>
          <div style="display: flex; justify-content: space-between; line-height:1.5rem;"><span>${w.globals.initialSeries[seriesIndex].name
            }</span> <span>${typeof dataPoint.y === 'number'
              ? formatNumberToNotation(dataPoint.y)
              : dataPoint.y
            } ${dataPoint.metadata.convertedUnit ?? dataPoint.metadata.product.unit
            }</span></div>

          </div>`;
        },
      },
    }),
    [isZooming]
  );
  const series: ApexAxisChartSeries = useMemo(() => {
    if (!scraps.length) return [];
    return [
      {
        name: 'Aparas',
        type: 'column',
        data: scraps
          .reduce<{ x: number; y: number; metadata: any }[]>((acc, curr) => {
            const found = acc.find(({ x }) => x === curr.date.getTime());

            if (!found) {
              return [
                ...acc,
                {
                  x: curr.date.getTime(),
                  metadata: curr,
                  y: convert(curr.n).from('g').to(unit(curr.product.unit)),
                },
              ];
            }

            return [
              ...acc.filter(({ x }) => x !== curr.date.getTime()),
              {
                x: found.x,
                metadata: curr,
                y:
                  found.y +
                  convert(curr.n).from('g').to(unit(curr.product.unit)),
              },
            ];
          }, [])
          .map((dataItem) => {
            const convertedValue = convert(dataItem.y)
              .from(unit(dataItem.metadata.product.unit))
              .toBest({ exclude: ['mt', 'mg', 'mcg'] });
            return {
              x: dataItem.x,
              y: convertedValue.val,
              metadata: {
                ...dataItem.metadata,
                convertedUnit: convertedValue.unit,
              },
            };
          }),
      },
      {
        name: t('specificChartPage:reprocess', { defaultValue: 'Reprocesso' }),
        type: 'column',
        data: scraps
          .reduce<{ x: number; y: number; metadata: any }[]>((acc, curr) => {
            const found: { x: number; y: number } | undefined = acc.find(
              ({ x }) => x === curr.date.getTime()
            );
            if (!found) {
              return [
                ...acc,
                {
                  x: curr.date.getTime(),
                  metadata: curr,
                  y: convert(curr.r).from('g').to(unit(curr.product.unit)),
                },
              ];
            }
            return [
              ...acc.filter(({ x }) => x !== curr.date.getTime()),
              {
                x: found.x,
                metadata: curr,
                y:
                  found.y +
                  convert(curr.r).from('g').to(unit(curr.product.unit)),
              },
            ];
          }, [])
          .map((dataItem) => {
            const convertedValue = convert(dataItem.y)
              .from(unit(dataItem.metadata.product.unit))
              .toBest({ exclude: ['mt', 'mg', 'mcg'] });
            return {
              x: dataItem.x,
              y: convertedValue.val,
              metadata: {
                ...dataItem.metadata,
                convertedUnit: convertedValue.unit,
              },
            };
          }),
      },
      {
        name: t('specificChartPage:chargebacks', { defaultValue: 'Estornos' }),
        type: 'column',
        data: scraps
          .reduce<{ x: number; y: number; metadata: any }[]>((acc, curr) => {
            const found = acc.find(({ x }) => x === curr.date.getTime());

            if (!found) {
              return [
                ...acc,
                {
                  x: curr.date.getTime(),
                  metadata: curr,
                  y: curr.c,
                },
              ];
            }

            return [
              ...acc.filter(({ x }) => x !== curr.date.getTime()),
              {
                x: found.x,
                metadata: curr,
                y: found.y + curr.c,
              },
            ];
          }, [])
          .map((dataItem) => {
            return {
              x: dataItem.x,
              y: dataItem.y,
              metadata: {
                ...dataItem.metadata,
                convertedUnit: 'un',
              },
            };
          }),
      },
    ];
  }, [scraps]);

  return (
    <div className={style.container}>
      <SpecificChartPageTitle text={t('specificChartPage:leftovers', { defaultValue: 'Sobras' })} />
      <div className={style.chartsArea}>
        {loading ? (
          <LoadingState />
        ) : series.length ? (
          <>
            <Chart
              type='bar'
              options={{
                ...options,
                yaxis: {
                  ...options.yaxis,
                  title: AxisTitleConfiguration(t('specificChartPage:weight', { defaultValue: 'Peso' })),
                },
              }}
              series={[series[0]]}
              height={containerHeight - 180}
            />
            <Chart
              type='bar'
              options={{
                ...options,
                yaxis: {
                  ...options.yaxis,
                  title: AxisTitleConfiguration(t('specificChartPage:weight', { defaultValue: 'Peso' })),
                },
              }}
              series={[series[1]]}
              height={containerHeight - 180}
            />
            <Chart
              type='bar'
              options={{
                ...options,
                yaxis: {
                  ...options.yaxis,
                  title: AxisTitleConfiguration(t('specificChartPage:units', { defaultValue: 'Unidades' })),
                },
              }}
              series={[series[2]]}
              height={containerHeight - 180}
            />
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};
