import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useManagementAuth } from "../../AuthControl";
import { LanguageModel } from "../LanguageListPages/LanguageModel";
import style from './LanguageDetails.module.css';
import { Button } from "../../../Components/Atoms/Button";
import { FiPlus } from "react-icons/fi";
import { TranslationCodeModel, TranslationModel } from "./TranslationModel";
import { useToast } from "../../../hooks/toast";

export const LanguageDetails = (): React.ReactElement => {
  const { pathname } = useLocation();
  const languageCode = pathname.split('/').slice(-1).toString();
  const { api } = useManagementAuth();
  const [language, setLanguage] = useState<LanguageModel>();
  const [translations, setTranslations] = useState<TranslationModel[]>([]);
  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    getLanguage(languageCode);
  }, []);

  const getLanguage = (language: string) => {
    const controller = new AbortController();
    api.get<LanguageModel>(`/api/management/languages/${language}`).then((res: LanguageModel) => {
      setLanguage(res);
      if (res.modules) {
        const translations = res.modules.flatMap((module) =>
          module.texts.map((text) => ({
            module: module.name,
            code: text.code,
            text: text.text,
          }))
        );
        setTranslations(translations);
      }
      return () => controller.abort();
    });
  }

  const listMissingCodes = async (language: LanguageModel | undefined) => {
    if (!language) {
      return;
    }
    const missingCodes = await api.get<TranslationCodeModel[]>(`/api/management/languages/${language?.language}/missing-codes`);
    if (!missingCodes.length) {
      addToast({ type: 'success', title: 'Sucesso', description: 'Não existem códigos faltantes.' });
    }
    const newTranslations = missingCodes.map(code => {
      return { ...code, text: "" };
    });
    setTranslations([...translations, ...newTranslations].sort((a, b) => {
      if (!(a.text.length) && b.text.length) return -1;
      if (a.text.length && !b.text.length) return 1;
      return 0;
    }));
  }

  return (
    <div>
      <div className={style.header}>
        <h2>
          Lista de traduções - {language?.language} - {language?.description}
        </h2>
      </div>
      <div className={style.addButton}>
        <Button icon={<FiPlus />} onClick={async () => await listMissingCodes(language)}>
          Adicionar Códigos Faltantes
        </Button>
      </div>
      <ul className={style.list}>
        <li className={style.listHeader} key='header'>
          <div className={style.column}>Módulo</div>
          <div className={style.column}>Código</div>
          <div className={style.column}>Texto</div>
        </li>
        {translations?.map((translation) => (
          <li className={`${style.row} ${translation.text.length ? "" : style.missingText}`} key={translation.module + translation.code}
            onClick={() => {
              history.push(`${pathname}/${translation.module}/${translation.code}`);
            }}
          >
            <div className={style.column}>{translation.module}</div>
            <div className={style.column}>{translation.code}</div>
            <div className={style.column}>{translation.text}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}