import { Link } from 'react-router-dom';
import { useAuth } from '../../../../AuthControl';
import style from './SignedInHeader.module.css';
import { useTranslation } from 'react-i18next';

export const MenuItems = (tenant: string | undefined): React.ReactElement[] => {
  const { isOperator } = useAuth();
  const { t } = useTranslation();
  const basePath = `/client/${tenant}`;

  if (!isOperator || isOperator()) {
    return [
      <Link to={`${basePath}/inicio`}>
        <div className={style.menuItem}>{t('headerPage:home', { defaultValue: 'Início' })}</div>
      </Link>,
      <Link to={`${basePath}/painel-informativo`}>
        <div className={style.menuItem}>{t('headerPage:informationPanel', { defaultValue: 'Painel Informativo' })}</div>
      </Link>,
    ];
  }

  return [
    <Link to={`${basePath}/inicio`}>
      <div className={style.menuItem}>{t('headerPage:home', { defaultValue: 'Início' })}</div>
    </Link>,
    <Link to={`${basePath}/fardos`}>
      <div className={style.menuItem}>{t('headerPage:packages', { defaultValue: 'Fardos' })}</div>
    </Link>,
    <Link to={`${basePath}/classificacao`}>
      <div className={style.menuItem}>{t('headerPage:classification', { defaultValue: 'Classificação' })}</div>
    </Link>,
    <Link to={`${basePath}/histograma-producao`}>
      <div className={style.menuItem}>{t('headerPage:productionHistogram', { defaultValue: 'Histograma Produção' })}</div>
    </Link>,
    <Link to={`${basePath}/conformidade`}>
      <div className={style.menuItem}>{t('headerPage:conformity', { defaultValue: 'Conformidade' })}</div>
    </Link>,
    // <Link to={`${basePath}/peso-tempo`}>
    //   <div className={style.menuItem}>{t('headerPage:weightXTime', { defaultValue: 'Peso x Tempo' })}</div>
    // </Link>,
    <Link to={`${basePath}/fator-operacional`}>
      <div className={style.menuItem}>{t('headerPage:operationalFactor', { defaultValue: 'Fator Operacional' })}</div>
    </Link>,
    <Link to={`${basePath}/ocorrencias`}>
      <div className={style.menuItem}>{t('headerPage:occurrences', { defaultValue: 'Ocorrências' })}</div>
    </Link>,
    <Link to={`${basePath}/ordens-producao`}>
      <div className={style.menuItem}>{t('headerPage:ProductionOrders', { defaultValue: 'Ordens de Produção' })}</div>
    </Link>,
    <Link to={`${basePath}/histograma-ocorrencias`}>
      <div className={style.menuItem}>{t('headerPage:occurrencesHistogram', { defaultValue: 'Histograma Ocorrências' })}</div>
    </Link>,
    <Link to={`${basePath}/producao`}>
      <div className={style.menuItem}>{t('headerPage:production', { defaultValue: 'Produção' })}</div>
    </Link>,
    <Link to={`${basePath}/sobras`}>
      <div className={style.menuItem}>{t('headerPage:leftovers', { defaultValue: 'Sobras' })}</div>
    </Link>,
    <Link to={`${basePath}/bobinas`}>
      <div className={style.menuItem}>{t('headerPage:coilConsumption', { defaultValue: 'Consumo de Bobinas' })}</div>
    </Link>,
    <Link to={`${basePath}/relatorio`}>
      <div className={style.menuItem}>{t('headerPage:exportReports', { defaultValue: 'Exportar Relatórios' })}</div>
    </Link>,
    <Link to={`${basePath}/painel-informativo`}>
      <div className={style.menuItem}>{t('headerPage:informationPanel', { defaultValue: 'Painel Informativo' })}</div>
    </Link>,
  ];
};
