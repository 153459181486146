import { ApexOptions } from 'apexcharts';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { EmptyState } from '../../Components/Cards/EmptyState';
import uniqueStyle from './ProductionClassificationPage.module.css';
import { useData } from '../../Controls/DataControl/UseData';
import { useFilter } from '../../Controls/FilterControl';
import style from '../SpecificChartPage.module.css';
import { ProductionClassification } from '../../Controls/DataControl/models';
import { Filter } from '../../Controls/FilterControl/models';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { MultiSeriesChartPalette } from '../../../../../helper/ChartsColorPalette';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { ProductionClassificationSerie } from './intefaces/ProductionClassificationSerie';
import { ProductionClassificationTable } from './ProductionClassificationTable';
import { useLocale } from '../../../../LocaleControl';
import { useTranslation } from 'react-i18next';
import { HiEye, HiOutlineSortDescending } from 'react-icons/hi';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';

export const ProductionClassificationPage = ({
  containerHeight,
}: {
  containerWidth: number;
  containerHeight: number;
}): React.ReactElement => {
  const [productionClassification, setProductionClassification] = useState<ProductionClassification>([]);
  const [loading, setLoading] = useState(true);
  const { filter, resetFilterRules } = useFilter();
  const { fetchProductionClassification } = useData();
  const abortFetchRef = useRef<() => void>();
  const { chartOptionsLocale } = useLocale();
  const { t } = useTranslation();
  const [drilldown, setDrilldown] = useState(false);
  const [showCharts, setShowCharts] = useState(true);

  const labels: any = {
    ['Pesagens úteis']: t('specificChartPage:usefulWeighings', { defaultValue: 'Pesagens úteis' }),
    ['Pesagens inválidas']: t('specificChartPage:invalidWeighings', { defaultValue: 'Pesagens inválidas' }),
    ['Pesagens rejeitadas por peso superior']: t('specificChartPage:superiorRejectedWeighings', { defaultValue: 'Pesagens rejeitadas por peso superior' }),
    ['Pesagens rejeitadas por peso inferior']: t('specificChartPage:inferiorRejectedWeighings', { defaultValue: 'Pesagens rejeitadas por peso inferior' }),
    ['Pesagens úteis fora da conformidade']: t('specificChartPage:usefulWeighingsOutCompliance', { defaultValue: 'Pesagens úteis fora da conformidade' }),
    ['Pesagens inspecionadas']: t('specificChartPage:inspectedWeighings', { defaultValue: 'Pesagens inspecionadas' }),
    ['Pesagens úteis na faixa de correção do dosador']: t('specificChartPage:weighingsInRange', { defaultValue: 'Pesagens úteis na faixa de correção do dosador' }),
    ['Pesagens úteis abaixo da correção do dosador']: t('specificChartPage:weighingsBelowRange', { defaultValue: 'Pesagens úteis abaixo da correção do dosador' }),
    ['Pesagens úteis acima da correção do dosador']: t('specificChartPage:weighingsAboveRange', { defaultValue: 'Pesagens úteis acima da correção do dosador' }),
    ['Pesagens não inspecionadas']: t('specificChartPage:uninspectedWeighings', { defaultValue: 'Pesagens não inspecionadas' }),
  };

  const codes: any = {
    'a': 'Pesagens úteis',
    'ar': 'Pesagens úteis na faixa de correção do dosador',
    'aru': 'Pesagens úteis abaixo da correção do dosador',
    'aro': 'Pesagens úteis acima da correção do dosador',
    'pi':'Pesagens inválidas',
    'ro': 'Pesagens rejeitadas por peso superior',
    'ru': 'Pesagens rejeitadas por peso inferior',
    'rc': 'Pesagens úteis fora da conformidade',
    'ni': 'Pesagens não inspecionadas',
    'i': 'Pesagens inspecionadas',
  };

  const localFetch = useCallback(
    async (filter: Filter) => {
      setLoading(true);
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      try {
        const { abort, fetch } = fetchProductionClassification();
        abortFetchRef.current = abort;
        const response = await fetch(filter);
        setProductionClassification(response);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [fetchProductionClassification, setProductionClassification, setLoading]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    localFetch(filter);
    return () => {
      setProductionClassification([]);
    };
  }, [filter]);

  const series = useMemo(() => {
    const categories = Array.from(
      new Set(
        productionClassification
          .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
          .map(({ data }) => data)
          .flat()
          .map((data) =>
            Object.keys(data).map((key) => ({ key, order: data[key].order }))
          )
          .flat()
          .sort((a, b) => a.order - b.order)
          .map(({ key }) => key)
      )
    );
    const returnSeries: ProductionClassificationSerie[] =
      productionClassification
        .filter((equipment) => {
          return Object.values(equipment.data).some(({ count }) => count > 0);
        })
        .reduce<ProductionClassification>(
          (arrayOfEquipments, currentEquipment) => {
            if (
              !arrayOfEquipments.some(
                ({ equipment }) =>
                  equipment.name === currentEquipment.equipment.name &&
                  equipment.unit === currentEquipment.equipment.unit
              )
            ) {
              return [...arrayOfEquipments, currentEquipment];
            } else {
              const foundEquipment = arrayOfEquipments.find(
                ({ equipment }) =>
                  equipment.name === currentEquipment.equipment.name &&
                  equipment.unit === currentEquipment.equipment.unit
              );
              if (foundEquipment) {
                return [
                  ...arrayOfEquipments.filter(
                    ({ equipment }) =>
                      equipment.name !== currentEquipment.equipment.name ||
                      equipment.unit !== currentEquipment.equipment.unit
                  ),
                  {
                    ...foundEquipment,
                    data: Array.from(
                      new Set([
                        ...Object.keys(foundEquipment.data),
                        ...Object.keys(currentEquipment.data),
                      ])
                    ).reduce((equipment, category) => {
                      return {
                        ...equipment,
                        [category]: {
                          ...foundEquipment.data[category],
                          count:
                            foundEquipment.data[category].count +
                            currentEquipment.data[category].count,
                          sum:
                            foundEquipment.data[category].sum +
                            currentEquipment.data[category].sum,
                        },
                      };
                    }, {}),
                  },
                ];
              }
              return [...arrayOfEquipments, currentEquipment];
            }
          },
          []
        )
        .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
        .map(({ equipment, data }) => ({
          name: equipment.name,
          data: categories.map((category) => {
            if (!data[category]) {
              return {
                x: category.trim(),
                y: 0,
                metadata: {
                  order: 0,
                  [equipment.name]: 0,
                  sum: 0,
                  unit: 'un',
                  weightUnit: equipment.unit,
                },
              };
            }
            return {
              x: category.trim(),
              y: data[category].count,
              metadata: {
                order: data[category].order,
                [equipment.name]: data[category].count,
                sum: data[category].sum,
                avg: data[category].sum / data[category].count,
                perc: (data[category].sum / data[category].overall) * 100.0,
                unit: 'un',
                weightUnit: equipment.unit,
              },
            };
          }),
        }));

    let totalData: any[] = [];
    returnSeries.forEach(returnSerie => {
      returnSerie.data.forEach(data => {
        const initialTotal = { x: data.x, y: 0, metadata: {} };
        if (!totalData.some(t => t.x === initialTotal.x)) {
          totalData.push(initialTotal);
        }
        const rowToUpdate = totalData.find(tabela => tabela.x === data.x);
        rowToUpdate.y = rowToUpdate.y + data.y;
        rowToUpdate.metadata.sum = rowToUpdate.metadata.sum ? rowToUpdate.metadata.sum + data.metadata.sum : data.metadata.sum;
        const dataAvg = isNaN(data.metadata.avg) ? 0 : data.metadata.avg;
        rowToUpdate.metadata.avg = rowToUpdate.metadata.avg ? rowToUpdate.metadata.avg + dataAvg : dataAvg;
        rowToUpdate.metadata.unit = 'un';
        rowToUpdate.metadata.Total = rowToUpdate.y;
      });
    });
    totalData.forEach(d => {
      d.metadata.avg = d.metadata.avg / returnSeries.length;
    });
    const prodClassTotal: ProductionClassificationSerie = { name: 'Total', data: totalData };
    returnSeries.push(prodClassTotal);


    returnSeries.forEach(rs => {
      rs.data.forEach(data => {
        const total = returnSeries.find(rs => rs.name === 'Total')?.data.find(d => d.x === data.x)?.metadata.sum;
        data.metadata.perc = rs.data.find(d => d.x === data.x)?.metadata.sum / total * 100;
      });
    });

    return returnSeries;
  }, [productionClassification]);

  const seriesByProduct = useMemo(() => {
    const categories = Array.from(
      new Set(
        productionClassification
          .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
          .map(({ data }) => data)
          .flat()
          .map((data) =>
            Object.keys(data).map((key) => ({ key, order: data[key].order }))
          )
          .flat()
          .sort((a, b) => a.order - b.order)
          .map(({ key }) => key)
      )
    );
    const returnSeries: ProductionClassificationSerie[] =
      productionClassification
        .filter((equipment) => {
          return Object.values(equipment.data).some(({ count }) => count > 0);
        })
        .reduce<ProductionClassification>(
          (arrayOfEquipments, currentEquipment) => {
            if (
              !arrayOfEquipments.some(
                ({ equipment }) =>
                  equipment.name === currentEquipment.equipment.name &&
                  equipment.unit === currentEquipment.equipment.unit &&
                  equipment.productCode === currentEquipment.equipment.productCode
              )
            ) {
              return [...arrayOfEquipments, currentEquipment];
            } else {
              const foundEquipment = arrayOfEquipments.find(
                ({ equipment }) =>
                  equipment.name === currentEquipment.equipment.name &&
                  equipment.unit === currentEquipment.equipment.unit &&
                  equipment.productCode === currentEquipment.equipment.productCode
              );
              if (foundEquipment) {
                return [
                  ...arrayOfEquipments.filter(
                    ({ equipment }) =>
                      equipment.name !== currentEquipment.equipment.name ||
                      equipment.unit !== currentEquipment.equipment.unit ||
                      equipment.productCode !== currentEquipment.equipment.productCode
                  ),
                  {
                    ...foundEquipment,
                    data: Array.from(
                      new Set([
                        ...Object.keys(foundEquipment.data),
                        ...Object.keys(currentEquipment.data),
                      ])
                    ).reduce((equipment, category) => {
                      return {
                        ...equipment,
                        [category]: {
                          ...foundEquipment.data[category],
                          count:
                            foundEquipment.data[category].count +
                            currentEquipment.data[category].count,
                          sum:
                            foundEquipment.data[category].sum +
                            currentEquipment.data[category].sum,
                        },
                      };
                    }, {}),
                  },
                ];
              }
              return [...arrayOfEquipments, currentEquipment];
            }
          },
          []
        )
        .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
        .map(({ equipment, data }) => ({
          name: equipment.name,
          productCode: equipment.productCode,
          productName: equipment.productName,
          data: categories.map((category) => {
            if (!data[category]) {
              return {
                x: category.trim(),
                y: 0,
                metadata: {
                  order: 0,
                  [equipment.name]: 0,
                  sum: 0,
                  unit: 'un',
                  dev: 0,
                  productCode: equipment.productCode,
                  productName: equipment.productName,
                  weightUnit: equipment.unit,
                },
              };
            }
            return {
              x: category.trim(),
              y: data[category].count,
              metadata: {
                order: data[category].order,
                [equipment.name]: data[category].count,
                sum: data[category].sum,
                avg: data[category].sum / data[category].count,
                perc: (data[category].sum / data[category].overall) * 100.0,
                dev: data[category].dev,
                unit: 'un',
                productCode: equipment.productCode,
                productName: equipment.productName,
                weightUnit: equipment.unit,
              },
            };
          }),
        }));

    returnSeries.map(serie => serie.productCode)
      .filter((value, index, array) => array.indexOf(value) === index)
      .forEach(product => {
        let totalData: any[] = [];
        returnSeries.filter(serie => serie.productCode === product).forEach(returnSerie => {
          returnSerie.data.forEach(data => {
            const initialTotal = { x: data.x, y: 0, metadata: {} };
            if (!totalData.some(t => t.x === initialTotal.x)) {
              totalData.push(initialTotal);
            }
            const rowToUpdate = totalData.find(tabela => tabela.x === data.x);
            rowToUpdate.y = rowToUpdate.y + data.y;
            rowToUpdate.metadata.sum = rowToUpdate.metadata.sum ? rowToUpdate.metadata.sum + data.metadata.sum : data.metadata.sum;
            const dataAvg = isNaN(data.metadata.avg) ? 0 : data.metadata.avg;
            rowToUpdate.metadata.avg = rowToUpdate.metadata.avg ? rowToUpdate.metadata.avg + dataAvg : dataAvg;
            rowToUpdate.metadata.unit = 'un';
            rowToUpdate.metadata.Total = rowToUpdate.y;
          });
        });
        totalData.forEach(d => {
          d.metadata.avg = d.metadata.avg / returnSeries.filter(s => s.productCode === product).length;
        });
        const prodClassTotal: ProductionClassificationSerie = { name: 'Total', data: totalData, productCode: product };
        returnSeries.push(prodClassTotal);
      });

    returnSeries.forEach(rs => {
      rs.data.forEach(data => {
        const total = returnSeries.filter(s => s.productCode === rs.productCode).find(rs => rs.name === 'Total')?.data.find(d => d.x === data.x)?.metadata.sum;
        data.metadata.perc = rs.data.find(d => d.x === data.x)?.metadata.sum / total * 100;
      });
    });

    return returnSeries;
  }, [productionClassification]);

  const options: ApexOptions = {
    responsive: [
      {
        breakpoint: 900,
        options: {
          xaxis: {
            tickAmount: 3,
            labels: { offsetY: 0 },
          },
          yaxis: {
            labels: {
              minWidth: 30,
              maxWidth: 450,
              align: 'left',
              title: {
                ...AxisTitleConfiguration(t('specificChartPage:classification', { defaultValue: 'Classificação' })),
                offsetX: 10,
              },
              style: {
                fontSize: '1em',
              },
            },
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          yaxis: {
            labels: {
              minWidth: 90,
              maxWidth: 700,
              align: 'left',
              title: {
                ...AxisTitleConfiguration(t('specificChartPage:classification', { defaultValue: 'Classificação' })),
                offsetX: 10,
              },
              style: {
                fontSize: '1em',
              },
            },
          },
        },
      },
    ],
    chart: {
      animations: {
        enabled: false,
      },
      type: 'bar',
      ...chartOptionsLocale,
      stacked: false,
      height: 220,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        barHeight: '100%',
        dataLabels: {
          position: 'bottom',
          hideOverflowingLabels: false,
        },
      },
    },

    colors: MultiSeriesChartPalette,
    fill: {
      type: 'solid',
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
      style: { fontSize: '0.75rem', colors: ['#222'] },
      offsetX: 10,
      offsetY: 5,
      textAnchor: 'start',
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 4,
        borderColor: 'transparent',
        opacity: 1,
        dropShadow: {
          enabled: false,
        },
      },
      formatter: (val, { w, seriesIndex, dataPointIndex }) => {
        const dataPoint =
          w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
        if (typeof val === 'number') {
          return `${w.globals.initialSeries[seriesIndex]?.name
            } - ${formatNumberToNotation(val)} ${dataPoint.metadata.unit}`;
        } else return formatNumberToNotation(parseFloat(val.toString()));
      },
    },
    xaxis: {
      type: 'category',
      tickPlacement: 'on',
      title: { ...AxisTitleConfiguration(t('specificChartPage:quantity', { defaultValue: 'Quantidade' })), offsetY: 40 },
      labels: {
        rotateAlways: false,
        rotate: 0,
        show: true,
        style: { fontSize: '1em' },
        formatter: (val) => formatNumberToNotation(parseFloat(val)),
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      title: {
        ...AxisTitleConfiguration(t('specificChartPage:classification', { defaultValue: 'Classificação' })),
        offsetX: 10,
      },
      labels: {
        minWidth: 120,
        maxWidth: 1200,
        align: 'left',
        style: {
          fontSize: '1em',
        },
      formatter: (value) => labels[codes[value]] ?? value,
      },
    },
    tooltip: {
      followCursor: true,
      custom: ({ w, seriesIndex, dataPointIndex }) => {
        const dataPoint =
          w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
        const metadataEquipments = Object.keys(dataPoint.metadata).filter(
          (meta) =>
            meta !== 'unit' &&
            meta !== 'sum' &&
            meta !== 'weightUnit' &&
            meta !== 'order' &&
            meta !== 'avg' &&
            meta !== 'perc' &&
            meta !== 'productCode' &&
            meta !== 'productName'
        );
        return `<div style="padding: 0.5rem; border-radius: var(--rounded);">
          <h2>${w.globals.initialSeries[seriesIndex].name}</h2>
            <table style="border-collapse: collapse; padding: 0.5rem;">
            <thead style="padding: 0.25rem 0.5rem;">
              <th style="padding: 0.25rem 0.5rem;">${t('specificChartPage:equipment', { defaultValue: 'Equipamento' })}</th>
              <th style="padding: 0.25rem 0.5rem;">${dataPoint.metadata.unit === 'un'
            ? t('specificChartPage:units', { defaultValue: 'Unidades' })
            : dataPoint.metadata.unit
          }</th>
              ${metadataEquipments.length > 1
            ? '<th style="padding: 0.25rem 0.5rem;">%</th>'
            : ''
          }
            </thead>
            <tbody>
            ${metadataEquipments.reduce((acc, curr) => {
            return `${acc}<tr style="padding: 0.25rem 0.5rem;">
                    <td style="padding: 0.25rem 0.5rem;">${curr}</td>
                    <td style="padding: 0.25rem 0.5rem;">${formatNumberToNotation(
              dataPoint.metadata[curr]
            )}</td>
                    ${metadataEquipments.length > 1
                ? `<td style="padding: 0.25rem 0.5rem;">${(
                  (parseInt(dataPoint.metadata[curr]) / dataPoint.y) *
                  100
                ).toFixed(2)}</td>`
                : ''
              }
                  </tr>`;
          }, '')}
              </tbody>
          </table>
        </div>`;
      },
    },
    ...ChartOptionsLegend,
  };

  const chartHeight = useMemo(() => {
    let minHeight = Math.max(containerHeight + 50, 120);
    if (series.length) {
      const amountOfData = Math.max(...series.map(({ data }) => data.length));
      return Math.max(
        minHeight,
        amountOfData * (series.length === 1 ? 120 : series.length * 80)
      );
    }
    return minHeight;
  }, [series]);

  const buildSeries = (pc: ProductionClassification) => {
    const categories = Array.from(
      new Set(
        pc
          .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
          .map(({ data }) => data)
          .flat()
          .map((data) =>
            Object.keys(data).map((key) => ({ key, order: data[key].order }))
          )
          .flat()
          .sort((a, b) => a.order - b.order)
          .map(({ key }) => key)
      )
    );
    const returnSeries: ProductionClassificationSerie[] =
      pc
        .filter((equipment) => {
          return Object.values(equipment.data).some(({ count }) => count > 0);
        })
        .reduce<ProductionClassification>(
          (arrayOfEquipments, currentEquipment) => {
            if (
              !arrayOfEquipments.some(
                ({ equipment }) =>
                  equipment.name === currentEquipment.equipment.name &&
                  equipment.unit === currentEquipment.equipment.unit
              )
            ) {
              return [...arrayOfEquipments, currentEquipment];
            } else {
              const foundEquipment = arrayOfEquipments.find(
                ({ equipment }) =>
                  equipment.name === currentEquipment.equipment.name &&
                  equipment.unit === currentEquipment.equipment.unit
              );
              if (foundEquipment) {
                return [
                  ...arrayOfEquipments.filter(
                    ({ equipment }) =>
                      equipment.name !== currentEquipment.equipment.name ||
                      equipment.unit !== currentEquipment.equipment.unit
                  ),
                  {
                    ...foundEquipment,
                    data: Array.from(
                      new Set([
                        ...Object.keys(foundEquipment.data),
                        ...Object.keys(currentEquipment.data),
                      ])
                    ).reduce((equipment, category) => {
                      return {
                        ...equipment,
                        [category]: {
                          ...foundEquipment.data[category],
                          count:
                            foundEquipment.data[category].count +
                            currentEquipment.data[category].count,
                          sum:
                            foundEquipment.data[category].sum +
                            currentEquipment.data[category].sum,
                        },
                      };
                    }, {}),
                  },
                ];
              }
              return [...arrayOfEquipments, currentEquipment];
            }
          },
          []
        )
        .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
        .map(({ equipment, data }) => ({
          name: equipment.name,
          data: categories.map((category) => {
            if (!data[category]) {
              return {
                x: category.trim(),
                y: 0,
                metadata: {
                  order: 0,
                  [equipment.name]: 0,
                  sum: 0,
                  dev: 0,
                  unit: 'un',
                  weightUnit: equipment.unit,
                },
              };
            }
            return {
              x: category.trim(),
              y: data[category].count,
              metadata: {
                order: data[category].order,
                [equipment.name]: data[category].count,
                sum: data[category].sum,
                avg: data[category].sum / data[category].count,
                dev: data[category].dev,
                perc: (data[category].sum / data[category].overall) * 100.0,
                unit: 'un',
                weightUnit: equipment.unit,
              },
            };
          }),
        }));

    let totalData: any[] = [];
    returnSeries.forEach(returnSerie => {
      returnSerie.data.forEach(data => {
        const initialTotal = { x: data.x, y: 0, metadata: {} };
        if (!totalData.some(t => t.x === initialTotal.x)) {
          totalData.push(initialTotal);
        }
        const rowToUpdate = totalData.find(tabela => tabela.x === data.x);
        rowToUpdate.y = rowToUpdate.y + data.y;
        rowToUpdate.metadata.sum = rowToUpdate.metadata.sum ? rowToUpdate.metadata.sum + data.metadata.sum : data.metadata.sum;
        const dataAvg = isNaN(data.metadata.avg) ? 0 : data.metadata.avg;
        rowToUpdate.metadata.avg = rowToUpdate.metadata.avg ? rowToUpdate.metadata.avg + dataAvg : dataAvg;
        rowToUpdate.metadata.unit = 'un';
        rowToUpdate.metadata.Total = rowToUpdate.y;
      });
    });
    totalData.forEach(d => {
      d.metadata.avg = d.metadata.avg / returnSeries.length;
    });
    const prodClassTotal: ProductionClassificationSerie = { name: 'Total', data: totalData };
    returnSeries.push(prodClassTotal);


    returnSeries.forEach(rs => {
      rs.data.forEach(data => {
        const total = returnSeries.find(rs => rs.name === 'Total')?.data.find(d => d.x === data.x)?.metadata.sum;
        data.metadata.perc = rs.data.find(d => d.x === data.x)?.metadata.sum / total * 100;
      });
    });

    return returnSeries;
  }

  return (
    <div className={style.container} >
      <div style={{ display: 'flex' }}>
        <SpecificChartPageTitle text={t('specificChartPage:productionClassification', { defaultValue: 'Classificação da Produção' })} />
        <Tooltip tooltip={drilldown ? t('specificChartPage:hideProducts', { defaultValue: 'Ocultar produtos' })
          : t('specificChartPage:showProducts', { defaultValue: 'Exibir produtos' })}>
          <div>
            <HiOutlineSortDescending style={{ marginTop: '13px', cursor: 'pointer', color: drilldown ? 'green' : 'red' }} onClick={() => setDrilldown(!drilldown)} />
          </div>
        </Tooltip>
        <Tooltip tooltip={showCharts ? t('specificChartPage:hideCharts', { defaultValue: 'Ocultar gráficos' }) :
          t('specificChartPage:showCharts', { defaultValue: 'Exibir gráficos' })}>
          <div>
            <HiEye style={{ marginTop: '11px', marginLeft: '7px', cursor: 'pointer', color: showCharts ? 'green' : 'red' }} onClick={() => setShowCharts(!showCharts)} />
          </div>
        </Tooltip>
      </div>
      <div className={uniqueStyle.chartsArea}>
        {loading ? (
          <LoadingState />
        ) : drilldown ?
          <>
            {seriesByProduct
              .sort((a, b) => a.productCode && b.productCode ? Number(a.productCode) - Number(b.productCode) : 0)
              .map(serie => serie.productCode)
              .filter((value, index, array) => array.indexOf(value) === index)
              .map(productCode => {
                return (
                  <>
                    <div style={{ fontWeight: 'bold' }}>{productCode} - {seriesByProduct.find(s => s.productCode === productCode)?.productName} </div>
                    <div>
                      <div ><ProductionClassificationTable series={seriesByProduct.filter(serie => serie.productCode === productCode)} /> </div>
                      {showCharts === true &&
                        (<div >
                          <div className={uniqueStyle.chartContent}>
                            <Chart
                              type='bar'
                              options={options}
                              series={seriesByProduct.filter(serie => serie.productCode === productCode && serie.name != 'Total')}
                            />
                          </div>
                        </div>)}
                    </div>
                  </>
                )
              })}
          </>
          : (
            <div>
              <div>{!!productionClassification.length && <ProductionClassificationTable series={buildSeries(productionClassification)} />}</div>
              {!!productionClassification.length && showCharts === true && (
                <div>
                  <div className={uniqueStyle.chartContent}>
                    <Chart
                      type='bar'
                      options={options}
                      series={series.filter(serie => serie.name != 'Total')}
                      height={chartHeight}
                    />
                  </div>
                </div>
              )}
              {!productionClassification.length && <EmptyState />}
            </div>
          )}
      </div>
    </div>
  );
};