import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import style from './Tooltip.module.css';

const Root = TooltipPrimitive.Root;
const Trigger = TooltipPrimitive.Trigger;
const Content = TooltipPrimitive.Content;
const Arrow = TooltipPrimitive.Arrow;
interface Props {
  tooltip: string;
}
export const Tooltip = ({
  children,
  tooltip,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  return (
    <Root>
      <Trigger asChild>{children}</Trigger>
      <Content className={style.container}>
        {tooltip}
        <Arrow className={style.arrow} />
      </Content>
    </Root>
  );
};
