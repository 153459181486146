import React, { useEffect, useState } from 'react';
import style from './MainDashboardPage.module.css';
import { AggregateProductionChart } from '../Components/Cards/AggregateProductionChart';
import { EquipmentsStatusChart } from '../Components/Cards/EquipmentStatusChart';
import { ProductionSummaryChart } from '../Components/Cards/ProductionSummary';
import { LastOccurrencesCard } from '../Components/Cards/LastOccurrencesCard';
import { useFilter } from '../Controls/FilterControl';
import { ScrapsCard } from '../Components/Cards/ScrapsCard';
import { EventsHistogramBubbleChart } from '../Components/Cards/OccurrencesHistogramBubbleChart';
import { OperationalFactorCard } from '../Components/Cards/OperationalFactorCard';
import { ProductionChart } from '../Components/Cards/ProductionChart';
import { ProductionHeatmapChart } from '../Components/Cards/ProductionHeatmapChart';
import { OccurrencesFilterModal } from '../Components/OccurrencesFilterModal';
import { getOccurrencesBlacklist } from '../Components/OccurrencesFilterModal/FilterOccurrencesService';
import { DefaultFilterRules, Zoom } from '../Controls/FilterControl/models';

export const MainDashboardPage = (): React.ReactElement => {
  const { updateFilterRules } = useFilter();
  const [showOccurrencesFilter, setShowOccurrencesFilter] = useState(false);
  const [occurrencesBlacklist, setOccurrencesBlacklist] = useState<string[]>(
    getOccurrencesBlacklist()
  );
  useEffect(() => {
    const currentZoomAvailables: Zoom[] = [
      ...DefaultFilterRules.zoomAvailables,
      'thisweek',
      'lst7d',
    ];
    updateFilterRules({
      ...DefaultFilterRules,
      zoomAvailables: currentZoomAvailables,
      autoRefresh: true,
    });
  }, []);

  useEffect(() => {
    //timeout to reload the page everyday at 2 AM
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(2, 0, 0, 0,); // Set time 2 AM

    const timeUntilTomorrow2AM = tomorrow.getTime() - now.getTime();

    const timeout = setTimeout(() => {
      window.location.reload();
    }, timeUntilTomorrow2AM)

    return () => clearTimeout(timeout);
  }, [])

  return (
    <div className={style.container}>
      {showOccurrencesFilter && (
        <OccurrencesFilterModal
          handleClose={() => {
            setOccurrencesBlacklist(getOccurrencesBlacklist());
            setShowOccurrencesFilter(false);
          }}
        />
      )}
      <div className={style.body}>
        <div className={`${style.bodyGrid} ${style.mobileContainer}`}>
          <EquipmentsStatusChart />
          <ProductionSummaryChart />
          <ProductionChart />
          <AggregateProductionChart />
          <OperationalFactorCard
            occurrencesBlacklist={occurrencesBlacklist}
            showOccurrencesFilter={() => setShowOccurrencesFilter(true)}
          />
          <LastOccurrencesCard
            occurrencesBlacklist={occurrencesBlacklist}
            showOccurrencesFilter={() => setShowOccurrencesFilter(true)}
          />
          <ScrapsCard />
        </div>
        <div className={`${style.bodyGrid} ${style.desktopContainer}`}>
          <ProductionSummaryChart />
          <AggregateProductionChart />
          <EquipmentsStatusChart />
          <ProductionChart />
          <ProductionHeatmapChart />
          <ScrapsCard />
          <LastOccurrencesCard
            occurrencesBlacklist={occurrencesBlacklist}
            showOccurrencesFilter={() => setShowOccurrencesFilter(true)}
          />
          <EventsHistogramBubbleChart
            occurrencesBlacklist={occurrencesBlacklist}
            showOccurrencesFilter={() => setShowOccurrencesFilter(true)}
          />
          <OperationalFactorCard
            occurrencesBlacklist={occurrencesBlacklist}
            showOccurrencesFilter={() => setShowOccurrencesFilter(true)}
          />
        </div>
      </div>
    </div>
  );
};
