import React from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import Modal from '../../../../../Components/Modal';
import { useAuth } from '../../../../../Client/AuthControl';
import { useToast } from '../../../../../hooks/toast';
import style from './ConfirmDeleteProductModal.module.css';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteProductModal = ({
  tenant,
  productId,
  productName,
  handleClose,
}: {
  tenant: string;
  productId: string;
  productName: string;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { addToast } = useToast();
  const { api } = useAuth();
  const { t } = useTranslation();

  const handleDeleteProduct = async () => {
    await api.delete(
      `/api/client/${tenant}/registrations/products/${productId}`
    );
    addToast({ title: t('productPage:productSuccessfullyDeleted', { defaultValue: 'Produto excluído com sucesso!' }), type: 'success' });
    handleClose(true);
  };

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('productPage:deleteProductName', { productName: productName, defaultValue: `Excluir ${productName} ?` })}</h1>
        <p>
          {t('productPage:deleteProductText1', { defaultValue: 'Dados existentes não serão perdidos,' })}
          <br />
          {t('productPage:deleteProductText2', { defaultValue: 'mas não serão geradas novas medições com esse produto.' })}
        </p>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('productPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteProduct()}>
            {t('productPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
