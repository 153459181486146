import React from 'react';
import style from './LoadingState.module.css';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
export const LoadingState = (): React.ReactElement => {
  return (
    <div className={style.container}>
      <AiOutlineLoading3Quarters />
    </div>
  );
};
