import React from 'react';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router';
import { AuthProvider } from './AuthControl';
import { ClientSelection } from './ClientSelectionPage';
import { ResetPassword } from './TenantPages/ResetPassword';
import { TenantPages } from './TenantPages';
import { TenantProvider } from './TenantControl';

interface ClientMatchProps extends RouteComponentProps<{ tenant: string }> {}

export const ClientPages: React.FC = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <TenantProvider>
          <Switch>
            <Route
              path='/client/:tenant/reset-password'
              render={() => (
                <AuthProvider>
                  <ResetPassword />
                </AuthProvider>
              )}
            />
            <Route
              path='/client/:tenant'
              render={({
                match: {
                  params: { tenant },
                },
              }: ClientMatchProps) => {
                return (
                  <AuthProvider>
                    <TenantPages tenant={tenant} />
                  </AuthProvider>
                );
              }}
            />
            <Route path='/client'>
              <ClientSelection />
            </Route>
          </Switch>
        </TenantProvider>
      </div>
    </div>
  );
};
