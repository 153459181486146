import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import AppError from '../../../helper/AppError';
import ManagerModel from './ManagerModel';
import style from './managerList.module.css';
import { useManagementAuth } from '../../AuthControl';

const ManagerList: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { signOut, api } = useManagementAuth();
  const [managers, setManagers] = useState<ManagerModel[]>();

  useEffect(() => {
    const loadManagers = async () => {
      try {
        const result = await api.get<ManagerModel[]>(
          '/api/management/managers/profile'
        );
        setManagers(result);
      } catch (error) {
        if (error instanceof AppError) {
          if (error.statusCode === 401) {
            addToast({
              type: 'error',
              title: 'Sessão expirada',
              description: 'Faça login novamente.',
            });
            signOut();
          }
        }
      }
    };
    loadManagers();
  }, []);
  return (
    <div id='managers-area'>
      <ul className={style.list}>
        <li className={style.header} key='header'>
          <div className={`${style.column} ${style.nameColumn}`}>Nome</div>
          <div className={`${style.column} ${style.idColumn}`}>email</div>
          <div className={`${style.column} ${style.verifiedColumn}`}>
            Verificado
          </div>
          <div className={`${style.column} ${style.blockedColumn}`}>
            Bloqueado
          </div>
        </li>
        {managers?.map((manager) => (
          <li className={style.row} key={manager._id}>
            <div className={`${style.column} ${style.nameColumn}`}>
              {manager.name}
            </div>
            <div className={`${style.column} ${style.idColumn}`}>
              {manager.email}
            </div>
            <div className={`${style.column} ${style.verifiedColumn}`}>
              {manager.blocked}
            </div>
            <div className={`${style.column} ${style.blockedColumn}`}>
              {manager.blocked}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManagerList;
