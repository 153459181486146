export const ValidateImageFileType = (file: File): boolean => {
  return !!file.type.match(/image.*/);
};

export const ResizeImageFile = async (
  file: File,
  width: number,
  height: number,
  type?: string
): Promise<Blob> => {
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  return new Promise((resolve) => {
    img.onload = async () => {
      const imgRatio = img.width / img.height;
      if (imgRatio > 1) {
        canvas.width = Math.min(img.width, width);
        canvas.height = canvas.width / imgRatio;
      } else {
        canvas.height = Math.min(img.height, height);
        canvas.width = imgRatio * canvas.height;
      }

      var ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, width, height);
      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          }
          reject();
        }, type ?? 'image/png');
      });
      resolve(blob);
    };
    img.src = window.URL.createObjectURL(file);
  });
};
