import React from 'react'
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../Components/Modal';
import style from './ConfirmDeleteOrderModal.module.css';
import { Button } from '../../../../../Components/Atoms/Button';

export const ConfirmDeleteOrderModal = ({
    tenant,
    orderId,
    handleClose
}: {
    tenant: string;
    orderId: string;
    handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
    const { addToast } = useToast();
    const { api } = useAuth();
    const { t } = useTranslation();

    const deleteOrder = async () => {
        await api.delete(
            `/api/client/${tenant}/registrations/orders/${orderId}`
        );
        addToast({ title: t('orderPage:orderSuccessfullyDeleted', { defaultValue: 'Ordem excluída com sucesso!' }), type: 'success' });
        handleClose(true);
    }
    return (
        <Modal handleClose={handleClose}>
            <div className={style.container}>
                <h1>{t('productPage:deleteProductName', { productName: orderId, defaultValue: `Excluir ordem ${orderId} ?` })}</h1>
                <p>{t('productPage:deleteProductText1', { defaultValue: 'Dados existentes não serão perdidos' })}</p>
                <div className={style.buttons}>
                    <Button secondary onClick={() => handleClose(false)}>
                        {t('productPage:cancel', { defaultValue: 'Cancelar' })}
                    </Button>
                    <Button danger onClick={() => deleteOrder()}>
                        {t('productPage:delete', { defaultValue: 'Excluir' })}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
