import React from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import Modal from '../../../../../Components/Modal';
import { useAuth } from '../../../../../Client/AuthControl';
import { useToast } from '../../../../../hooks/toast';
import style from './ConfirmDeleteEquipmentModal.module.css';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteEquipmentModal = ({
  tenant,
  equipmentId,
  equipmentName,
  handleClose,
}: {
  tenant: string;
  equipmentId: string;
  equipmentName: string;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { addToast } = useToast();
  const { api } = useAuth();
  const { t } = useTranslation();

  const handleDeleteEquipment = async () => {
    await api.delete(
      `/api/client/${tenant}/registrations/equipments/${equipmentId}`
    );
    addToast({ title: t('equipmentPage:equipmentDeletedSuccessfully', { defaultValue: 'Equipamento excluído com sucesso.' }), type: 'success' });
    handleClose(true);
  };

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('equipmentPage:delete', { defaultValue: 'Excluir' })} {equipmentName} ?</h1>
        <p>
          {t('equipmentPage:equipmentDeleteText1', { defaultValue: 'Dados existentes não serão perdidos,' })}
          <br />
          {t('equipmentPage:equipmentDeleteText2', { defaultValue: 'mas não serão gerados novos registros desse equipamento.' })}
        </p>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('equipmentPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteEquipment()}>
            {t('equipmentPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
