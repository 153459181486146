import React from 'react';
import {
  FiAlertCircle,
  FiInfo,
  FiCheckCircle,
  FiXCircle,
} from 'react-icons/fi';
import { useToast } from '../../hooks/toast';
import style from './ToastContainer.module.css';
interface ToastContainerProps {
  messages: Message[];
}

interface Message {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
}
const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const { removeToast } = useToast();
  const toastIcon = {
    success: <FiCheckCircle size={24} />,
    error: <FiAlertCircle size={24} />,
    info: <FiInfo size={24} />,
  };

  const messageTypeStyle = (messageType?: string) =>
    ({
      success: style.success,
      error: style.error,
      info: style.info,
      default: style.info,
    }[messageType || 'info']);

  return (
    <div className={style.container}>
      {messages.map((message) => (
        <div
          className={`${
            !!message.description && style.withDescription
          } ${messageTypeStyle(message.type)} ${style.toast}`}
          key={message.id}
        >
          {toastIcon[message.type || 'info']}
          <div className={style.toastMessage}>
            <strong>{message.title}</strong>
            {message.description && <p>{message.description}</p>}
          </div>
          <button type='button' onClick={() => removeToast(message.id)}>
            <FiXCircle size={18} />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ToastContainer;
