export interface Product {
  id: string;
  name: string;
  code: number;
  codeText: string;
  imagePath: string;
  brand: string;
  type: string;
  netWeight: number;
  packageWeight: number;
  packSize: number;
  correctionBelow: number;
  correctionAbove: number;
  invalidBelow: number;
  invalidAbove: number;
  barCode: string;
  invalidDevianceAbove: number;
  factoryDefault: boolean;
  rejectUnder: number;
  rejectOver: number;
  completeName: string;
  unit: {
    name: string;
    resolution: number;
    index: number;
  };
}

export enum EquipmentConnectionStatus {
  Inicializando,
  Conectando,
  Conectado,
  Desconectado,
  Teste,
  Erro,
  Sincronizando,
}

export interface ProductionHeatmap {
  day: number;
  month: number;
  year: number;
  count: number;
}

export interface CoilConsumption {
  start: Date;
  end: Date;
  product: { id: string; name: string };
  equipment: { name: string; id: string };
  coil: {
    id: string;
    name: string;
    weight: number;
    estimate: number;
    code: string;
  };
  weightStart: number;
  weightEnd: number;
  count: number;
}

export interface Event {
  equipment: {
    name: string;
    id: string;
    createdAt: Date;
    removedAt?: Date;
  };
  duration: number;
  timeAvailable: number;
  factor: number;
  events: {
    operator: { name: string; id: string };
    startDate: Date;
    endDate?: Date;
    windowEnd: Date;
    windowStart: Date;
    description: string;
    code: number;
    duration: number;
    factor: number;
  }[];
}

export interface Inspection {
  individualAcceptanceCriterium: number;
  productId: string;
  productCode: string;
  productCodeText: string;
  productName: string;
  productUnit: string;
  productWeight: number;
  packageWeight: number;
  sampleSize: number;
  totalItems: number;
  multiplierFactor: number;
  items: { weight: number; date: Date }[];
  tolerance: number;
  samples: {
    criterion: number;
    max: number;
    min: number;
    dev: number;
    avg: number;
    startDate: Date;
  }[];
}

export type EventsHistogram = {
  equipment: { n: string; mid: string };
  events: {
    code: string;
    description: string;
    count: number;
    duration: number;
  }[];
};

export interface Production {
  equipment: Equipment;
  unit: string;
  count: number;
  avg: number;
  dev: number;
  max: number;
  min: number;
  sum: number;
  entries: ProductionEntry[];
  product: { id: string; name: string; code: string; codeText: string; };
}
export interface EquipmentOperationDetails {
  equipment: Equipment;
  data: {
    date: string;
    op: { id: string; name?: string };
    prod: { id: string; name?: string };
  }[];
}
export interface ProductionSummary {
  equipment: {
    id: string;
    name: string;
  };
  product: {
    name: string;
    brand: string;
    imagePath: string;
    unit: string;
    resolution: number;
    newWeight: number;
    packageWeight: number;
  };
  latestProduction: number;
  totalWeight: number;
  count: number;
  approvedCount: number;
  rejectedInferiorCount: number;
  rejectedSuperiorCount: number;
  totalPackages: number;
}

export interface Operator {
  id: string;
  name: string;
  code: string;
}

export interface EquipmentStatus {
  equipment: { id: string; name: string };
  unit?: string;
  information?: string;
  status: string;
  nonconformities?: number;
  invalids?: number;
  belowWeight?: number;
  belowTreshold?: number;
  speed?: number;
  totalWeight?: number;
  aboveWeight?: number;
  aboveTreshold?: number;
  valids?: number;
  validsThreshold?: number;
}
export interface ProductionEntry {
  date: Date;
  avg: number;
  count: number;
  dev: number;
  max: number;
  min: number;
  sum: number;
  status?: number;
  statusArray?: number[];
}
export type ProductionClassification = {
  equipment: Equipment & { unit: string, productCode?: string, productName?: string };
  data: { [key: string]: { count: number; sum: number; order: number, overall: number, dev: number } };
}[];

export type ProductionHistogram = {
  product: { name: string; id: string; unit: string; code: string; codeText: string; };
  equipment: { name: string; id: string };
  data: {
    weight: number;
    amount: number;
    invalid: boolean;
    rejected: boolean;
  }[];
}[];

export interface Scrap {
  equipment: {
    id: string;
    name: string;
  };
  operator: {
    id: string;
    code: string;
    name: string;
  };
  product: {
    id: string;
    name: string;
    code: string;
    codeText: string;
    unit: string;
  };
  date: Date;
  c: number;
  n: number;
  r: number;
}
export enum EquipmentType {
  RS485,
  TCPIP,
}
export interface Equipment {
  id: string;
  name: string;
  type: EquipmentType;
  bitmap?: number;
  createdAt?: Date;
  removedAt?: Date;
  group?: string;
  groupName?: string;
}

export type ProductConfigurationModel = {
  _id: string,
  l: string,
  p: string,
  eq: string,
  equipmentName: string,
  fileSize: string,
}
export interface Package {
  id: string;
  group: string;
  start?: Date;
  end?: Date;
  product: string;
  count: number;
}
export interface GroupedPackage {
  id: string;
  groupName: string;
  totalPackages: number;
  totalProducts: number;
  totalGroupWeight: number;
}
export interface PackageSummary {
  result: GroupedPackage[];
  total: number;
}

export interface Order {
  id: string;
  product: string;
  group: string;
  codeText: string;
  packageCount?: number;
  productsQuantity: number;
  count: number;
  status: number;
  groupName?: string;
  productName?: string;
  productPackageSize?: number;
}

export interface Group {
  id: string;
  name: string;
  equipments: string[];
}
export const statusKey: any = [
  'Status 0',
  'Status 1',
  'Status 2',
  'Status 3',
  'Status 4',
  'Status 5',
  'Status 6',
]

export interface ProductionOrder {
  id: string;
  order: string;
  product: string;
  group: string;
  totalCount: number;
  producedPackages: number;
  status: number;
  start: Date;
  end: Date;
}

export interface EquipmentListItem {
  id: string;
  mid: string;
  name: string;
  type: EquipmentType;
  bitmap: number;
  group: string;
  groupName?: string;
}

export interface EquipmentGroup {
  id: string;
  name: string;
  equipments: string[];
}

export interface EquipmentProduction {
  equipment: {
    id: string;
    name: string;
    groupId: string;
    groupName: string;
    unit: string;
    factor: number;
  };
  product: {
    codeText: string;
    name: string;
    brand: string;
    netWeight: number;
  };
  op: {
    producedPackages: number;
    projectedPackages: number;
    codeText: string;
  };
  data: {
    a: {
      c: number;
      s: number;
      max: number;
      min: number;
    };
    ru: {
      c: number;
      s: number;
    };
    ro: {
      c: number;
      s: number;
    };
    invalid: {
      c: number;
    }
  };
}