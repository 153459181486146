import React from 'react';
import style from './DropDownMenu.module.css';
import * as DropDownMenuRadix from '@radix-ui/react-dropdown-menu';

interface Props {
  trigger: React.ReactElement;
  align?: 'start' | 'center' | 'end';
  items: React.ReactElement[];
}
export const DropDownMenu = ({
  trigger,
  align = 'start',
  items,
}: Props): React.ReactElement => {
  return (
    <DropDownMenuRadix.Root>
      <DropDownMenuRadix.Trigger className={style.trigger} asChild>
        {trigger}
      </DropDownMenuRadix.Trigger>
      <DropDownMenuRadix.Content
        align={align}
        sideOffset={4}
        className={style.content}
      >
        {items.map((item, index) => (
          <DropDownMenuRadix.Item
            key={index}
            asChild
            className={style.contentMenuItem}
          >
            {item}
          </DropDownMenuRadix.Item>
        ))}
      </DropDownMenuRadix.Content>
    </DropDownMenuRadix.Root>
  );
};
