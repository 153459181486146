import React, { useEffect, useState } from 'react';
import { TenantRoutes } from './Routes/TenantRoutes';
import { useTenant } from '../TenantControl';
import { Redirect } from 'react-router-dom';

interface Props {
  tenant: string;
}

export const TenantPages = ({ tenant }: Props): React.ReactElement => {
  const [RenderElement, setRenderElement] = useState<React.ReactElement>();

  const { getClient, client } = useTenant();
  useEffect(() => {
    if (client && client.tenant == tenant) {
      setRenderElement(<TenantRoutes />);
    } else {
      setRenderElement(<Redirect to='/client' />);
    }
  }, [tenant]);

  return RenderElement ?? <div>Loading...</div>;
};
