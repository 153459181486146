import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Label } from '../../Label';
import style from './Time.module.css';
interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'value'
  > {
  id: string;
  grow?: boolean;
  label: string;
  value: string;
  inverted?: boolean;
  onChange: (value: string) => void;
}
export const Time = ({
  required,
  label,
  grow = false,
  id,
  inverted,
  value,
  onChange,
}: Props): React.ReactElement => {
  return (
    <div className={`${style.container} ${grow ? style.containerGrow : ''}`}>
      <Label value={label} htmlFor={`time-${id}`} inverted={inverted} />
      <div className={style.selectOutline}>
        <input
          id={`time-${id}`}
          value={value}
          required={required}
          type='time'
          className={style.select}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
        ></input>
      </div>
    </div>
  );
};
