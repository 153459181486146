import React, { useMemo } from 'react';
import {
  Equipment,
  EquipmentStatus,
  ProductionSummary,
} from '../../Controls/DataControl/models';
import style from './EquipmentSummaryPage.module.css';
import andonStyle from '../AndonPages.module.css';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { useTranslation } from 'react-i18next';

interface Data {
  name: string;
  status: string;
  validsAmount: number;
  belowAmount: number;
  aboveAmount: number;
  totalAmount: number;
  downtime:
  | {
    inDowntime: boolean;
    description: string;
    duration: string;
  }
  | undefined;
  product?: {
    name: string;
    brand: string;
    netWeight: number;
    imagePath?: string;
    unit?: string;
  };
}

interface EquipmentProductionStatus {
  equipment: { id: string; name: string };
  inDowntime: boolean;
  downtimeDuration?: string;
  downtimeJustificative?: string;
}

interface Props {
  id: string | number;
  equipments: Equipment[];
  equipmentIds: string[];
  equipmentsStatus: EquipmentStatus[];
  equipmentSummary: ProductionSummary[];
  equipmentsProductionStatus: EquipmentProductionStatus[];
}

export const EquipmentSummaryPage = ({
  equipments,
  equipmentIds,
  equipmentsStatus,
  equipmentSummary,
  equipmentsProductionStatus,
  id,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const data = useMemo(
    (): Data[] =>
      equipments
        ?.filter(({ id }) => equipmentIds.includes(id))
        .sort((a, b) => a.name.localeCompare(b.name))
        ?.map((equipment) => {
          const foundStatus = equipmentsStatus?.find(
            ({ equipment: { id } }) => equipment.id === id
          );
          const foundSummary = equipmentSummary
            .filter(({ equipment: { id } }) => equipment.id === id)
            .reduce<ProductionSummary | undefined>(
              (acc, curr) =>
                curr.latestProduction > (acc?.latestProduction ?? 0)
                  ? curr
                  : acc,
              undefined
            );
          const foundDowntime = equipmentsProductionStatus.find(
            ({ equipment: { id } }) => equipment.id === id
          );


          return {
            status: foundStatus?.status ?? t('andonPage:disconnected', { defaultValue: 'Desconectado' }),
            name: equipment.name,
            validsAmount: foundSummary?.approvedCount ?? 0,
            aboveAmount: foundSummary?.rejectedSuperiorCount ?? 0,
            belowAmount: foundSummary?.rejectedInferiorCount ?? 0,
            totalAmount: foundSummary?.count ?? 0,
            downtime: foundDowntime
              ? {
                inDowntime: foundDowntime.inDowntime ?? false,
                description: foundDowntime.downtimeJustificative ?? '',
                duration: foundDowntime.downtimeDuration ?? '',
              }
              : undefined,
            product: {
              brand: foundSummary?.product?.brand ?? t('andonPage:notFound', { defaultValue: 'Não encontrado' }),
              imagePath: foundSummary?.product.imagePath,
              name: foundSummary?.product.name ?? t('andonPage:notFound', { defaultValue: 'Não encontrado' }),
              netWeight:
                (foundSummary?.product.newWeight ?? 0) /
                ((foundSummary?.product.resolution ?? 0) + 1),
              unit: foundSummary?.product.unit ?? '',
            },
          };
        }) ?? [],
    [equipments, equipmentsStatus, equipmentsProductionStatus, equipmentSummary]
  );

  return (
    <>
      <div className={andonStyle.pageHeader} key={id}>
        <div>
          <img src='/supervis_logo_andon.png' alt='supervis' />
        </div>
        <div className={style.cardTitle}>
          <h3>{t('andonPage:machinesUpperCase', { defaultValue: 'MÁQUINAS' })}</h3>
        </div>
      </div>
      <div className={style.equipmentCardsContainer}>
        {data.map((equipment) => (
          <div
            className={style.equipmentCard}
            data-warning={equipment.status.toLowerCase() !== 'conectado'}
            data-error={equipment.status.toLowerCase() === 'erro'}
            data-downtime={
              !!equipment.downtime?.inDowntime &&
              equipment.downtime.description.toLowerCase() !== 'desconectado'
            }
          >
            <div className={style.data}>
              <div className={style.cardDetails}>
                <h3 className={style.equipmentNameField}>{equipment.name}</h3>
                <div
                  className={`${style.productionData} ${style.productField}`}
                >
                  <small>{t('andonPage:product', { defaultValue: 'Produto' })}</small>
                  <strong>{equipment.product?.name}</strong>
                </div>
                <div className={`${style.productionData} ${style.brandField}`}>
                  <small>{t('andonPage:brand', { defaultValue: 'Marca' })}</small>
                  <strong>{equipment.product?.brand}</strong>
                </div>
                <img
                  className={style.imageField}
                  src={equipment.product?.imagePath ?? '/icone_produto.png'}
                  alt={`imagem ${equipment.name}`}
                />
                <div
                  className={`${style.productionData} ${style.netWeightField}`}
                >
                  <small>{t('andonPage:netWeight', { defaultValue: 'Peso líq.' })}</small>
                  <strong>{`${formatNumberToNotation(
                    equipment.product?.netWeight ?? 0
                  )} ${equipment.product?.unit}`}</strong>
                </div>

                <div
                  className={`${style.productionData} ${style.productionField}`}
                >
                  <small>{t('andonPage:totalProdUn', { defaultValue: 'Total prod. (un)' })}</small>
                  <strong>
                    {formatNumberToNotation(equipment.totalAmount ?? 0)}
                  </strong>
                </div>
                <div
                  className={`${style.productionData} ${style.usefulProdField}`}
                >
                  <small>{t('andonPage:validsUn', { defaultValue: 'Úteis (un)' })}</small>
                  <strong>
                    {formatNumberToNotation(equipment.validsAmount ?? 0)}
                  </strong>
                </div>
                <div className={`${style.productionData} ${style.infRejField}`}>
                  <small>{t('andonPage:rejInfUn', { defaultValue: 'Rej. Inf. (un)' })}</small>
                  <strong>
                    {formatNumberToNotation(equipment.belowAmount ?? 0)}
                  </strong>
                </div>
                <div className={`${style.productionData} ${style.supRejField}`}>
                  <small>{t('andonPage:rejSupUn', { defaultValue: 'Rej. Sup. (un)' })}</small>
                  <strong>
                    {formatNumberToNotation(equipment.aboveAmount ?? 0)}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
