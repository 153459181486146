import React, { useEffect, useState } from 'react'
import { Order, statusKey } from '../../Controls/DataControl/models';
import { useTenant } from '../../../../TenantControl';
import { useAuth } from '../../../../AuthControl';
import style from './OrdersList.module.css'
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { Link, useHistory } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFileExcel } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../Components/Atoms/Button';
import { FiPlus } from 'react-icons/fi';
import { ConfirmDeleteOrderModal } from './ConfirmDeleteOrderModal';

export const OrdersListPage = (): React.ReactElement => {
  const { api } = useAuth();
  const { client } = useTenant();
  const [orders, setOrders] = useState<Order[]>([]);
  const { t } = useTranslation();
  const { push, location } = useHistory();
  const [orderToDelete, setOrderToDelete] = useState<Order>();

  const fetchOrders = async () => {
    const result = await api.get<Order[]>(
      `/api/client/${client?.tenant}/registrations/orders`
    );
    setOrders(result);
  };

  useEffect(() => {
    fetchOrders();
    const pollingOrders = setInterval(() => {
      fetchOrders();
    }, 10000);
    return () => {
      clearInterval(pollingOrders);
    };
  }, []);

  return (
    <div className={style.container}>
      {orderToDelete && (
        <ConfirmDeleteOrderModal
          tenant={client?.tenant || ''}
          orderId={orderToDelete.id}
          handleClose={(success) => {
            setOrderToDelete(undefined);
            if (success) {
              fetchOrders();
            }
          }}
        />
      )}
      <Button
        onClick={() => push(`${location.pathname}/novo`)}
        icon={<FiPlus />}
      >
        {t('orderPage:orders', { defaultValue: 'Ordem de Produção' })}
      </Button>
      <ul className={`${style.ordersList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.idColumn}`}>{t('orderPage:id', { defaultValue: 'Id' })}</div>
          <div className={`${style.shortColumn}`}>{t('orderPage:product', { defaultValue: 'Produto' })}</div>
          <div className={`${style.quantityColumn}`}>{t('orderPage:count', { defaultValue: `Quantidade de Fardos` })}</div>
          <div className={`${style.groupColumn}`}>{t('orderPage:group', { defaultValue: 'Grupo' })}</div>
          <div className={`${style.quantityColumn}`}>{t('orderPage:quantityProduced', { defaultValue: 'Quantidade Produzida' })}</div>
          <div className={`${style.shortColumn}`}>{t('orderPage:status', { defaultValue: 'Status' })}</div>
          <div className={`${style.actionsColumn}`}>Ações</div>
        </li>
        {orders.sort((a, b) => a.id.localeCompare(b.id)).map((order) => (
          <li key={order.id} className={style.row}>
            <div className={`${style.idColumn}`}>
              {order.codeText ?? order.id}
            </div>
            <div className={`${style.shortColumn}`}>
              {order.productName ?? order.product}
            </div>
            <div className={`${style.quantityColumn}`}>
              {order.count}
            </div>
            <div className={`${style.groupColumn}`}>
              {order.groupName ?? order.group}
            </div>
            <div className={`${style.quantityColumn}`}>
              {order.productsQuantity}
            </div>
            <div className={`${style.shortColumn}`}>
              {statusKey[order.status]}
            </div>
            <div className={`${style.actionsColumn}`}>
              <Tooltip tooltip={t('productPage:edit', { defaultValue: 'Editar' })}>
                <Link
                  to={`${location.pathname}/${encodeURI(
                    order.id
                  )}`}
                >
                  <AiOutlineEdit />
                </Link>
              </Tooltip>
              <Tooltip tooltip={t('productPage:delete', { defaultValue: 'Excluir' })}>
                <div>
                  <AiOutlineDelete
                    onClick={() => setOrderToDelete(order)}
                  />
                </div>
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
