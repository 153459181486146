import { createContext } from 'react';
import Api from '../../helper/Api';
import { Credentials, UpdateUserRequest, User } from './models';

export interface AuthContextState {
  user?: User;
  token?: string;
  signIn: (credentials: Credentials) => Promise<User>;
  signOut: () => void;
  updateUser: (user: UpdateUserRequest) => void;
  isAdmin: () => boolean;
  isOperator: () => boolean;
  api: Api;
}

export const AuthContext = createContext<AuthContextState | undefined>(
  undefined
);
