import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import uniqueStyle from './ProductionHistogramPage.module.css';
import style from '../SpecificChartPage.module.css';
import Chart from 'react-apexcharts';
import { useFilter } from '../../Controls/FilterControl';
import { useData } from '../../Controls/DataControl/UseData';
import { ProductionHistogram } from '../../Controls/DataControl/models';
import { ApexOptions } from 'apexcharts';
import { EmptyState } from '../../Components/Cards/EmptyState';
import convert from 'convert-units';
import { Filter } from '../../Controls/FilterControl/models';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { ProductionHistogramTable } from './ProductionHistogramTable';
import { useLocale } from '../../../../LocaleControl';
import { useTranslation } from 'react-i18next';
import { ProductionFilterModal } from '../../Components/ProductionFilterModal';
import { getProductionBlacklist } from '../../Components/ProductionFilterModal/ProductionFilterService';
import LegendDot from '../../../../../Components/Atoms/LegendDot';
import { format } from 'date-fns';

interface Props {
  containerHeight: number;
  containerWidth: number;
}

const unit = (unitString: string) => {
  switch (unitString) {
    case 'g':
    case 'kg':
    case 'lb':
      return unitString;
    default:
      return 'g';
  }
};

export const ProductionHistogramPage = ({
  containerWidth,
  containerHeight,
}: Props): React.ReactElement => {
  const { filter, resetFilterRules } = useFilter();
  const { fetchProductionHistogram } = useData();
  const [unitOfSeries, setUnitOfSeries] = useState<{ [key: string]: string }>({});
  const [series, setSeries] = useState<{ [key: string]: ApexAxisChartSeries }>({});
  const [histogramData, setHistogramData] = useState<ProductionHistogram>();
  const abortFetchRef = useRef<() => void>();
  const [loading, setLoading] = useState(true);
  const { chartOptionsLocale } = useLocale();
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [productionBlacklist, setProductionBlacklist] = useState<string[]>(getProductionBlacklist());

  const localFetch = useCallback(
    async (filter: Filter, localProductionBlackList: string[]) => {
      setLoading(true);
      try {
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }
        const { abort, fetch } = fetchProductionHistogram(localProductionBlackList);
        abortFetchRef.current = abort;
        const response = await fetch(filter);
        setHistogramData(response);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [fetchProductionHistogram, setHistogramData, setLoading]
  );
  useEffect(() => {
    return () => {
      setSeries({});
      setHistogramData(undefined);
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
    };
  }, []);
  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    localFetch(filter, productionBlacklist);
  }, [filter, productionBlacklist]);

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        offsetX: 20,
        animations: {
          enabled: false,
        },

        type: 'bar',
        ...chartOptionsLocale,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: true,
        },
      },
      fill: {
        opacity: 1,
        colors: [
          ({
            seriesIndex,
            w,
            dataPointIndex,
          }: {
            w: any;
            seriesIndex: any;
            dataPointIndex: any;
          }) => {
            const dataPoint =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return dataPoint.meta.ok ? '#18a9f2' : dataPoint.meta.invalid ? '#ec5959' : '#ec5';
          },
        ],
      },

      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          dataLabels: {
            hideOverflowingLabels: true,
          },
        },
      },
      ...ChartOptionsLegend,
      dataLabels: {
        offsetX: 30,
        enabled: false,
      },
      xaxis: {
        title: { ...AxisTitleConfiguration(t('specificChartPage:weight', { defaultValue: 'Peso' })), offsetY: 20 },
        labels: {
          rotateAlways: false,
          rotate: 0,
          hideOverlappingLabels: false,
          style: { fontSize: '1em' },
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
        followCursor: true,
        x: {
          formatter: (_, { w, seriesIndex, dataPointIndex }) => {
            const dataPoint =
              w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
            return `${formatNumberToNotation(dataPoint.x ?? 0)} ${dataPoint?.meta?.unit ?? 'g'
              }`;
          },
        },
        y: {
          formatter: (val) => {
            return `${formatNumberToNotation(val ?? 0)} un`;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => val.toFixed(0),
          style: { fontSize: '1em' },
        },
      },
    }),
    [histogramData]
  );
  useEffect(() => {
    if (!histogramData) {
      return;
    }
    setSeries(
      histogramData
        ? histogramData
        .sort((a, b) => {
          const equipmentNameA = a.equipment?.name || '';
          const equipmentNameB = b.equipment?.name || '';
          const productNameA = a.product?.name || '';
          const productNameB = b.product?.name || '';
        
          return (
            equipmentNameA.localeCompare(equipmentNameB) ||
            productNameA.localeCompare(productNameB)
          );
        })
          .map(({ data, product, equipment }) => {
            const formatedStartDate = format(new Date(filter.dates[0].startTimestamp), 'dd/MM/yyyy HH:mm');
            const formatedEndDate = format(new Date(filter.dates[0].endTimestamp), 'dd/MM/yyyy HH:mm');

            const name = `${equipment.name} - ${product?.codeText ?? ''} ${product?.name ?? ''
              } - ${t('specificChartPage:quant', { defaultValue: 'Quant.' })} (${formatedStartDate} - ${formatedEndDate})`;
            const nameWithId = `${equipment.name} - ${product?.id ?? ''} - ${t('specificChartPage:quant', { defaultValue: 'Quant.' })}`;
            const parsedData = data.map(({ amount, invalid, rejected, weight }) => ({
              x:
                product?.unit === 'g'
                  ? weight
                  : convert(weight).from('g').to(unit(product.unit)),
              y: amount,
              meta: {
                ok: !invalid && !rejected,
                rejected: !invalid && rejected,
                invalid: invalid,
                unit: [undefined, null, ''].includes(product?.unit)
                  ? 'g'
                  : product?.unit,
              },
            }));
            setUnitOfSeries((prev) => ({
              ...prev,
              [`${equipment.name} - ${product.id ?? ''} ${product?.name ?? ''
                } - ${t('specificChartPage:quant', { defaultValue: 'Quant.' })}`]: product?.unit,
            }));
            return {
              name: name,
              data: parsedData,
              nameWithId
            };
          })
          .reduce((acc, curr) => ({ ...acc, [curr.nameWithId]: [curr] }), {})
        : {}
    );
    setLoading(false);
    return () => setSeries({});
  }, [histogramData]);

  const seriesTitle = (key: string): string => {
    const name = series[key][0].name;
    const cut = name?.indexOf('(');
    return name?.substring(0, cut) || key;
  }

  return (
    <>
      {showFilter && (
        <ProductionFilterModal
          handleClose={() => {
            setProductionBlacklist(getProductionBlacklist());
            setShowFilter(false);
          }}
        />
      )}
      <div className={style.container}>
        <SpecificChartPageTitle text={t('specificChartPage:productionHistogram', { defaultValue: 'Histograma da Produção' })}
          onFilterClick={() => {
            setShowFilter(true);
          }}
        />
        <div className={uniqueStyle.chartsArea}>
          {loading ? (
            <LoadingState />
          ) : histogramData && Object.keys(series).length > 0 ? (
            Object.keys(series)
              .sort((a, b) => a.localeCompare(b))
              .map((key) => {
                return (
                  <div key={`${key}`} className={uniqueStyle.equipmentArea}>
                    <ProductionHistogramTable
                      serie={series[key]}
                      key={key}
                      unit={unitOfSeries[key]}
                    />
                    <div style={{ display: 'flex', margin: '10px' }}>
                      <div style={{ marginRight: '10px' }}>
                        <LegendDot color="rgba(var(--clr-blue), 0.3)" width='10px' height='10px' />
                        {t('specificChartPage:labelUseful', { defaultValue: 'Úteis' })}
                      </div>
                      <div style={{ marginRight: '10px' }}>
                        <LegendDot color="#ec5" width='10px' height='10px' />
                        {t('specificChartPage:labelRejected', { defaultValue: 'Rejeitados' })}
                      </div>
                      <div style={{ marginRight: '10px' }}>
                        <LegendDot color="rgba(var(--clr-error), 0.3)" width='10px' height='10px' />
                        {t('specificChartPage:labelInvalid', { defaultValue: 'Inválidos' })}
                      </div>
                    </div>
                    <Chart
                      type='bar'
                      options={{
                        ...options,
                        yaxis: {
                          ...options.yaxis,
                          title: {
                            ...AxisTitleConfiguration(seriesTitle(key)),
                            offsetX: -10,
                          },
                        },
                      }}
                      width={containerWidth - 120}
                      series={series[key]}
                      height={containerHeight - 280}
                    />
                  </div>
                );
              })
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
    </>
  );
};
