import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

const FALLBACK_LANGUAGE = 'pt_BR';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: true
    },
    fallbackLng: localStorage.getItem('lng') || FALLBACK_LANGUAGE,
    ns: [
      'userConfigPage',
      'userConfigTenantPage',
      'coilPage',
      'devicesPage',
      'equipmentPage',
      'eventPage',
      'operatorPage',
      'productPage',
      'tenantPage',
      'userPage',
      'andonPage',
      'advancedFilterPage',
      'cardsPage',
      'shared',
      'headerPage',
      'otherComponentsPage',
      'specificChartPage',
      'packagesPage'
    ],
    backend: {
      loadPath: `${process.env.REACT_APP_BACK_END_URL}/api/management/languages/{{lng}}/{{ns}}`,
    },
    load: 'currentOnly'
  });

export default i18n;