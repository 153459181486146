import { useTranslation } from "react-i18next";
import { Input } from "../../../../../Components/Atoms/Input";
import { useEffect, useState } from "react";
import { Select } from "../../../../../Components/Atoms/Input/Select";
import { EquipmentNameModel, EquipmentToAddModel } from "./models";
import { Button } from "../../../../../Components/Atoms/Button";
import { useToast } from "../../../../../hooks/toast";
import { useAuth } from "../../../../AuthControl";
import { useHistory } from "react-router-dom";
import { useTenant } from "../../../../TenantControl";
import style from './EquipmentGroupCreationPage.module.css';
import React from "react";

export const EquipmentGroupCreationPage = (): React.ReactElement => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState<string>('');
  const [availableEquipments, setAvailableEquipments] = useState<EquipmentNameModel[]>([]);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([]);
  const [equipmentSelectInput, setEquipmentSelectInput] = useState<number[]>([0]);
  const { api, user } = useAuth();
  const { addToast } = useToast();
  const { client } = useTenant();
  const { push } = useHistory();

  useEffect(() => {
    api.get<EquipmentNameModel[]>(`/api/client/${user?.tenant}/registrations/equipments/groups/available`)
      .then((response) => {
        setAvailableEquipments(response)
      });
  }, []);

  const handleAddEquipmentToGroup = async () => {
    const equipmentToAdd = selectedEquipments.filter((eq) => eq !== '');
    if (!groupName) {
      addToast({ title: t('equipmentPage:enterGroupName', { defaultValue: 'Digite o nome do grupo.' }), type: 'info' });
      return;
    }
    if (selectedEquipments.length === 0) {
      addToast({ title: t('equipmentPage:selectEquipment', { defaultValue: 'Selecione um equipamento.' }), type: 'info' });
      return;
    }

    try {
      await api.post(`/api/client/${user?.tenant}/registrations/equipments/groups/${groupName}`, { equipments: equipmentToAdd })
        .then(() => {
          push(`/client/${client?.tenant}/admin/grupos`);
        });
      addToast({ title: t('equipmentPage:equipmentAddedSuccessfully', { defaultValue: 'Grupo criado com sucesso.' }), type: 'success' });
    } catch (err) {
      addToast({ title: t('equipmentPage:errorAddingEquipmentToGroup', { defaultValue: 'Erro ao adicionar equipamento ao grupo.' }), type: 'error' });
    }
  }

  const handleAddSelect = () => {
    if (equipmentSelectInput.length === availableEquipments.length) {
      addToast({ title: t('equipmentPage:allEquipmentsAdded', { defaultValue: 'Número máximo de equipamentos já foram adicionados.' }), type: 'info' });
      return;
    };
    setEquipmentSelectInput([...equipmentSelectInput, equipmentSelectInput.length]);
  }

  const handleSelectChange = (index: number, event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedEquipments = [...selectedEquipments];
    newSelectedEquipments[index] = event.target.value;
    setSelectedEquipments(newSelectedEquipments);
  };

  const filteredEquipments = (index: number) => {
    return availableEquipments.filter(equip => !selectedEquipments.includes(equip.id) || selectedEquipments[index] === equip.id);
  };

  return (
    <div style={{
      maxWidth: '900px',
      margin: '0 auto',
      padding: '1rem 0'
    }}>
      <div>
        <div
          style={{
            margin: '20px 0px',
            fontSize: '20px',
            fontWeight: 'bolder'
          }}>
          {t('equipmentPage:enterTheGroupName', { defaultValue: 'Digite o nome do Grupo.' })}
        </div>
        <div style={{
          margin: '20px 0px'
        }}>
          <Input
            id='groupName'
            grow
            label={t('equipmentPage:groupName', { defaultValue: 'Nome do Grupo' })}
            type='text'
            value={groupName}
            onChange={setGroupName}
          />
        </div>
      </div>
      <>
        <div style={{ margin: '20px 0px' }}>
          <div className={style.container} style={{ gap: '0.75rem' }}>
            {equipmentSelectInput.map((_, index) => (
              <React.Fragment key={index}>
                <label htmlFor={`select-${index}`} key={`label-${index}`}>
                  {t('equipmentPage:selectOneEquipment', { defaultValue: 'Selecione um equipamento' })}
                </label>
                <div className={style.selectOutline} key={`div-${index}`}>
                  <select
                    className={style.select}
                    key={`select-${index}`}
                    name="selectEq"
                    id=""
                    value={selectedEquipments[index]}
                    onChange={(event) => handleSelectChange(index, event)}
                  >
                    <option key={`option-${index}`}>{t('equipmentPage:selectOneEquipment', { defaultValue: 'Selecione um equipamento' })}</option>
                    {filteredEquipments(index).map((equip) => (
                      <option key={equip.id} value={equip.id}>{equip.name}</option>
                    ))}
                  </select>
                </div>
              </React.Fragment>
            )
            )}
          </div>
        </div>
        <div style={{ margin: '20px 0px' }}>
          <Button
            onClick={handleAddSelect}
          >
            {t('equipmentPage:add', { defaultValue: 'Adicionar Equipamento' })}
          </Button>
        </div>
      </>
      <div style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1rem'
      }}>
        <Button
          secondary
          danger
          onClick={() => push(`/client/${client?.tenant}/admin/grupos`)}>
          {t('equipmentPage:return', { defaultValue: 'Voltar' })}
        </Button>
        <Button
          onClick={handleAddEquipmentToGroup}>
          {t('equipmentPage:confirm', { defaultValue: 'Confirmar' })}
        </Button>
      </div>
    </div>
  );
}