export const AvailableUnits = [
  {
    code: 'unitg0',
    unitName: 'g',
    resolution: 0,
    text: 'grama (res. 1g)',
  },
  {
    code: 'unitg1',
    unitName: 'g',
    resolution: 1,
    text: 'grama (res. 0.1g)',
  },
  {
    code: 'unitg2',
    unitName: 'g',
    resolution: 2,
    text: 'grama (res. 0.01g)',
  },
  {
    code: 'unitg3',
    unitName: 'g',
    resolution: 3,
    text: 'grama (res. 0.001g)',
  },
  {
    code: 'unitkg1',
    unitName: 'kg',
    resolution: 1,
    text: 'quilograma (res. 0.1kg)',
  },
  {
    code: 'unitkg2',
    unitName: 'kg',
    resolution: 2,
    text: 'quilograma (res. 0.01kg)',
  },
  {
    code: 'unitlb1',
    unitName: 'lb',
    resolution: 1,
    text: 'libra (res. 0.1lb)',
  },
  {
    code: 'unitlb2',
    unitName: 'lb',
    resolution: 2,
    text: 'libra (res. 0.01lb)',
  },
  {
    code: 'unitlb3',
    unitName: 'lb',
    resolution: 3,
    text: 'libra (res. 0.001lb)',
  },
];
