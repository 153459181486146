import { Link } from 'react-router-dom';
import style from './SignedInHeader.module.css';
import { useTranslation } from 'react-i18next';

export const AdministrativeMenuItems = (
  tenant: string | undefined
): React.ReactElement[] => {
  const { t } = useTranslation();
  const basePath = `/client/${tenant}`;

  return [
    <Link to={`${basePath}/admin/usuarios`}>
      <div className={style.menuItem}>{t('headerPage:users', { defaultValue: 'Usuários' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/ordens`} >
      <div className={style.menuItem}>{t('headerPage:orders', { defaultValue: 'Ordens de Produção' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/produtos`}>
      <div className={style.menuItem}>{t('headerPage:products', { defaultValue: 'Produtos' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/grupos`}>
      <div className={style.menuItem}>{t('headerPage:equipmentGroups', { defaultValue: 'Grupos de Equipamentos' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/equipamentos`}>
      <div className={style.menuItem}>{t('headerPage:equipments', { defaultValue: 'Equipamentos' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/operadores`}>
      <div className={style.menuItem}>{t('headerPage:operators', { defaultValue: 'Operadores' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/eventos`}>
      <div className={style.menuItem}>{t('headerPage:occurrences', { defaultValue: 'Ocorrências' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/bobinas`}>
      <div className={style.menuItem}>{t('headerPage:coils', { defaultValue: 'Bobinas' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/dispositivo`}>
      <div className={style.menuItem}>{t('headerPage:device', { defaultValue: 'Dispositivo' })}</div>
    </Link>,
    <Link to={`${basePath}/admin/informacao`}>
      <div className={style.menuItem}>{t('headerPage:informations', { defaultValue: 'Informações' })}</div>
    </Link>,
  ];
};
