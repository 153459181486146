import { Link } from 'react-router-dom';
import React from 'react';
import { FiLogOut, FiUser } from 'react-icons/fi';
import './style.css';
import { Manager } from '../../../AuthControl';
const Profile: React.FC<{ manager?: Manager; signOut: VoidFunction }> = ({
  manager,
  signOut,
}) => {
  return (
    <div id='manager-profile-info'>
      <Link to='/management/s/me'>
        {manager?.profilePicPath ? (
          <img src={manager.profilePicPath} />
        ) : (
          <FiUser size={96} />
        )}
      </Link>
      <div>
        <span className='profile-name'>{manager?.name}</span>{' '}
        <FiLogOut onClick={signOut} />
      </div>
    </div>
  );
};

export default Profile;
