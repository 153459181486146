export const saveOccurrencesBlacklist = (occurrenceCodes: string[]) => {
  localStorage.setItem(
    '@medianeira:filter:occurrences',
    JSON.stringify(occurrenceCodes)
  );
};

export const getOccurrencesBlacklist = (): string[] => {
  const data = localStorage.getItem('@medianeira:filter:occurrences');
  if (!data) return [];
  return JSON.parse(data);
};
