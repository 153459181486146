import { format, formatDistance } from 'date-fns';
import React from 'react';
import style from './LastOccurrencesCard.module.css';
import { getDateFnsLocation } from '../../../../../../helper/GetDateFnsLocation';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
  equipment: string;
  startDate: Date;
  endDate?: Date;
  code: string;
}
export const OccurrencesTile = ({
  label,
  equipment,
  startDate,
  endDate,
  code
}: Props): React.ReactElement => {
  const FALLBACK_LANGUAGE = 'pt_BR';
  const { t } = useTranslation();
  const labels: any = {
    Desconectado: t('cardsPage:disconnected', { defaultValue: 'Desconectado' }),
    ['Ocorrência não informada']: t('cardsPage:occurrenceNotInformed', { defaultValue: 'Ocorrência não informada' }),
    Conectado: t('cardsPage:connected', { defaultValue: 'Conectado' }),
  };
  console.log('labels', label);

  return (
    <div className={style.eventTile}>
      <strong className={style.eventTileDescription}>{labels[label] ?? label}</strong>
      <div className={style.content}>
        <div className={style.eventTileInfo}>
          <small>{code}</small>
          <small>{equipment}</small>
        </div>
        <div className={style.eventDuration}>
          <small className={endDate ? '' : style.runningEvent}>
            {endDate
              ? format(endDate, 'dd/MM HH:mm:ss')
              : formatDistance(startDate, new Date(), { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) })}
          </small>
          <small>{format(startDate, 'dd/MM HH:mm:ss', { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) })}</small>
        </div>
      </div>
    </div>
  );
};
