import React, { useEffect, useState } from 'react';
import { Button } from '../../../Components/Atoms/Button';
import ClientModel from './ClientModel';
import { formatDistanceToNow, format, isFuture } from 'date-fns';
import { useToast } from '../../../hooks/toast';
import { FiPlus } from 'react-icons/fi';
import { TbFileInvoice } from 'react-icons/tb';
import style from './ClientsList.module.css';
import { Link, useHistory } from 'react-router-dom';
import { useManagementAuth } from '../../AuthControl';
import { getDateFnsLocation } from '../../../helper/GetDateFnsLocation';

const ClientsList: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [clients, setClients] = useState<ClientModel[]>([]);
  const { token, api, signOut } = useManagementAuth();
  const FALLBACK_LANGUAGE = 'pt_BR';

  const subscriptionStatus = ({
    status,
    dueDate,
    currentPeriodEnd,
  }: {
    status?: string;
    dueDate: Date;
    currentPeriodEnd: Date;
  }) => {
    if (!status) {
      return 'Sem assinatura';
    }
    if (status === 'paid') {
      return `Próximo pagamento em ${format(currentPeriodEnd, 'dd/MM/yyyy')}`;
    }
    if (isFuture(dueDate)) {
      return `Próximo pagamento em ${format(dueDate, 'dd/MM/yyyy')}`;
    }
    return `Pagamento atrasado ${formatDistanceToNow(dueDate, {
      locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE),
      addSuffix: true,
    })}`;
  };

  const clientStatusMap = (status: string) =>
  ({
    Active: 'Ativo',
    Blocked: 'Bloqueado',
  }[status]);

  useEffect(() => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    api
      .get<{ clients: ClientModel[] }>(`/api/management/clients/account`)
      .then(({ clients }) => {
        setClients([]);
        clients.forEach((client) => {
          setClients((prev) => [...prev, client]);
        });
      })
      .catch((error) => {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        }
        throw 'error';
      });
  }, []);

  return (
    <div id='list-area'>
      <div style={{ color: 'rgb(var(--clr-neutral-50))' }}>
        <Link to='/management/s/clients/new'>
          <Button icon={<FiPlus />}>Cliente</Button>
        </Link>
      </div>
      <ul className={`${style.clientsList}`}>
        <li className={`${style.header}`} key='header'>
          <div className={`${style.column} ${style.nameColumn}'`}>Nome</div>
          <div className={`${style.column} ${style.idColumn}'`}>ID</div>
          <div className={`${style.column} ${style.statusColumn}'`}>Status</div>
          <div className={`${style.column} ${style.subscriptionStatusColumn}'`}>
            Assinatura
          </div>
        </li>
        {clients.map((client) => (
          <li
            className={`${style.row}`}
            key={client._id}
            onClick={() => {
              history.push(`/management/s/clients/${client.tenant}`);
            }}
          >
            <div className={`${style.column} ${style.nameColumn}`}>
              {client.name}
            </div>
            <div className={`${style.column} ${style.idColumn}`}>
              {client.tenant}
            </div>
            <div className={`${style.column} ${style.statusColumn}`}>
              {clientStatusMap(client.status)}
            </div>
            <div
              className={`${style.column} ${style.subscriptionStatusColumn}`}
            >
              <a
                data-visible={!!client.nextInvoice}
                href={client.nextInvoice}
                target='_blank'
                rel='noopener noreferrer'
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <TbFileInvoice />
              </a>
              {subscriptionStatus({
                status: client.currentPeriodStatus,
                dueDate: client.currentPeriodDueDate,
                currentPeriodEnd: client.currentPeriodEnd,
              })}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClientsList;
