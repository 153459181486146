import React from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import Modal from '../../../../../Components/Modal';
import { useAuth } from '../../../../../Client/AuthControl';
import { useToast } from '../../../../../hooks/toast';
import style from './ConfirmDeleteOperatorModal.module.css';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteOperatorModal = ({
  tenant,
  operatorId,
  operatorName,
  handleClose,
}: {
  tenant: string;
  operatorId: string;
  operatorName: string;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { addToast } = useToast();
  const { api } = useAuth();
  const { t } = useTranslation();

  const handleDeleteProduct = async () => {
    await api.delete(
      `/api/client/${tenant}/registrations/operators/${operatorId}`
    );
    addToast({ title: t('operatorPage:operatorSuccessfullyDeleted', { defaultValue: 'Operador excluído com sucesso!' }), type: 'success' });
    handleClose(true);
  };

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('operatorPage:deleteOperatorName', { operatorName: operatorName, defaultValue: `Excluir ${operatorName} ?` })}</h1>
        <p>
          {t('operatorPage:deleteOperatorText1', { defaultValue: 'Dados existentes não serão perdidos,' })}
          <br />
          {t('operatorPage:deleteOperatorText2', { defaultValue: 'mas não serão geradas novas medições com este operador.' })}
        </p>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('operatorPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteProduct()}>
            {t('operatorPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
