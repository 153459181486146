import { format } from 'date-fns';
import React from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import Modal from '../../../../../Components/Modal';
import style from './ProductionConformityPage.module.css';
import { getDateFnsLocation } from '../../../../../helper/GetDateFnsLocation';
import { useTranslation } from 'react-i18next';

export const SamplesModal = ({
  handleClose,
  samples,
}: {
  handleClose: () => void;
  samples: { weight: number; unit: string; date: Date }[];
}): React.ReactElement => {

  const { t } = useTranslation();
  const FALLBACK_LANGUAGE = 'pt_BR';

  return (
    <Modal handleClose={handleClose}>
      <div className={style.modalContainer}>
        <h3>{t('specificChartPage:samples', { defaultValue: 'Amostras' })}</h3>
        <ul className={style.modalSampleList}>
          {samples.map(({ date, weight, unit }, index) => (
            <li key={`${date}-${weight}`} className={style.modalSampleItem}>
              <div>{index + 1}</div>
              <div className={style.modalSampleDate}>
                {format(date, 'dd/MM/yy HH:mm:ss', { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) })}
              </div>
              <div
                className={style.modalSampleValue}
              >{`${weight} ${unit}`}</div>
            </li>
          ))}
        </ul>
        <Button danger onClick={() => handleClose()}>
          {t('specificChartPage:close', { defaultValue: 'Fechar' })}
        </Button>
      </div>
    </Modal>
  );
};
