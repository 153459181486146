import { Redirect, Route, Switch } from 'react-router';
import Menu from './Components/Menu';
import ClientDetails from './ClientDetailsPages';
import ClientsList from './ClientsListPages';
import ManagerDetails from './ManagerDetailsPages';
import ManagerList from './ManagerListPages';
import { ManagementRoute } from './Components/ManagementRoute';
import style from './SignedInPages.module.css';
import { LanguageList } from './LanguageListPages';
import { LanguageCreation } from './LanguageCreationPage';
import { LanguageDetails } from './LanguageDetailsPage';
import { LanguageTextEdit } from './LanguageTextEditPage';

export const SignedInPages: React.FC = () => {
  return (
    <div className={style.managementRoot}>
      <Menu />
      <section className={style.managementContainer}>
        <div className={style.managementContent}>
          <Switch>
            <ManagementRoute
              isPrivate
              path='/management/s/home'
              component={ClientsList}
            />
            <ManagementRoute
              path='/management/s/clients/:clientId'
              component={ClientDetails}
              isPrivate
            />
            <ManagementRoute
              isPrivate
              path='/management/s/me'
              component={ManagerDetails}
            />
            <ManagementRoute
              isPrivate
              path='/management/s/users'
              component={ManagerList}
            />
            <ManagementRoute
              isPrivate
              path='/management/s/languages/new'
              component={LanguageCreation}
            />
            <ManagementRoute
              path='/management/s/languages/:language/:code'
              component={LanguageTextEdit}
              isPrivate
            />
            <ManagementRoute
              path='/management/s/languages/:language'
              component={LanguageDetails}
              isPrivate
            />
            <ManagementRoute
              isPrivate
              path='/management/s/languages'
              component={LanguageList}
            />
            <Route path='*'>
              <Redirect to='/management/login' />
            </Route>
          </Switch>
        </div>
      </section>
    </div>
  );
};
