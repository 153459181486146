import { ApexOptions } from 'apexcharts';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { EmptyState } from '../../Components/Cards/EmptyState';
import uniqueStyle from './CoilConsumptionPage.module.css';
import { useData } from '../../Controls/DataControl/UseData';
import { useFilter } from '../../Controls/FilterControl';
import style from '../SpecificChartPage.module.css';
import { CoilConsumption } from '../../Controls/DataControl/models';
import { Filter } from '../../Controls/FilterControl/models';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { MultiSeriesChartPalette } from '../../../../../helper/ChartsColorPalette';
import convert from 'convert-units';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { formatNumberToNotation } from '../../../../../helper/FormatNumberToNotation';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { useLocale } from '../../../../LocaleControl';
import { useTranslation } from 'react-i18next';

interface CoilConsumptionSerie {
  name: string;
  data: { x: string; y: number; metadata: any }[];
}

export const CoilConsumptionPage = ({
  containerHeight,
}: {
  containerWidth: number;
  containerHeight: number;
}): React.ReactElement => {
  const [coilData, setCoilData] = useState<CoilConsumption[]>([]);
  const [loading, setLoading] = useState(true);
  const { filter, resetFilterRules } = useFilter();
  const { fetchCoilConsumption } = useData();
  const abortFetchRef = useRef<() => void>();
  const { chartOptionsLocale } = useLocale();
  const { t } = useTranslation();

  const localFetch = useCallback(
    async (filter: Filter) => {
      setLoading(true);
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      try {
        const { abort, fetch } = fetchCoilConsumption();
        abortFetchRef.current = abort;
        const response = await fetch(filter);
        setCoilData(response);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [fetchCoilConsumption, setCoilData, setLoading]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    localFetch(filter);
  }, [filter]);

  useEffect(() => {
    return () => {
      setCoilData([]);
    };
  }, [setCoilData]);

  const tableData = useMemo(() => {
    if (!coilData) {
      return { equipments: [], coils: [], data: undefined };
    }
    const equipments = Array.from(
      new Set(coilData.map(({ equipment }) => equipment.id).flat())
    );
    const coils = Array.from(
      new Set(coilData.map(({ coil }) => coil.id).flat())
    );

    const data = {
      equipments,
      coils,
      data: coilData.map(
        ({ coil, weightStart, weightEnd, count, equipment, product }) => ({
          coil,
          consume: (weightStart - weightEnd) / 1000,
          count,
          avg: (weightStart - weightEnd) / count,
          estimate: coil.weight / coil.estimate,
          equipment,
          product,
        })
      ),
    };
    console.log(' data: ', data);
    return data;
  }, [coilData]);

  const series = useMemo(() => {
    const returnSeries: CoilConsumptionSerie[] = [
      {
        name: t('specificChartPage:consumption', { defaultValue: 'Consumo' }),
        data: tableData.coils.map((coilId) => {
          const dataFromCoilId = tableData.data?.filter(
            ({ coil }) => coil.id === coilId
          );
          if (!dataFromCoilId || dataFromCoilId.length === 0) {
            return { x: coilId, y: 0, metadata: {} };
          }
          return {
            x: dataFromCoilId[0].coil.name,
            y: dataFromCoilId.reduce((acc, curr) => acc + curr.consume, 0),
            metadata: dataFromCoilId.map(({ equipment, consume }) => ({
              equipment,
              consume,
            })),
          };
        }),
      },
    ];

    return returnSeries;
  }, [tableData]);

  const chartHeight = useMemo(() => {
    let minHeight = Math.max(containerHeight - 90, 0);
    if (series.length) {
      const amountOfData = Math.max(...series.map(({ data }) => data.length));
      return Math.max(minHeight, amountOfData);
    }
    return minHeight;
  }, [series]);

  const options: ApexOptions = {
    responsive: [
      {
        breakpoint: 900,
        options: {
          xaxis: {
            tickAmount: 3,
            labels: { offsetY: 0 },
          },
          yaxis: {
            labels: {
              minWidth: 30,
              maxWidth: 450,
              align: 'left',
              title: {
                ...AxisTitleConfiguration(t('specificChartPage:coilConsumption', { defaultValue: 'Consumo das Bobinas' })),
                offsetX: 10,
              },
              style: {
                fontSize: '1em',
              },
            },
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          yaxis: {
            labels: {
              minWidth: 90,
              maxWidth: 700,
              align: 'left',
              title: {
                ...AxisTitleConfiguration(t('specificChartPage:coilConsumption', { defaultValue: 'Consumo das Bobinas' })),
                offsetX: 10,
              },
              style: {
                fontSize: '1em',
              },
            },
          },
        },
      },
    ],
    chart: {
      animations: {
        enabled: false,
      },
      type: 'bar',
      ...chartOptionsLocale,
      stacked: false,
      height: 400,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        barHeight: '90%',
        dataLabels: {
          position: 'bottom',

          hideOverflowingLabels: false,
        },
      },
    },

    colors: MultiSeriesChartPalette,
    fill: {
      type: 'solid',
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
      style: { fontSize: '1.175em', colors: ['#222'] },
      offsetX: 10,
      textAnchor: 'start',
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 4,
        borderColor: 'transparent',
        opacity: 1,
        dropShadow: {
          enabled: false,
        },
      },
      formatter: (val, { w, seriesIndex, dataPointIndex }) => {
        const dataPoint =
          w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
        if (typeof val === 'number') {
          return `${w.globals.initialSeries[seriesIndex]?.name
            } - ${formatNumberToNotation(val)} kg`;
        } else return formatNumberToNotation(parseFloat(val.toString()));
      },
    },
    xaxis: {
      type: 'category',
      tickPlacement: 'on',
      title: { ...AxisTitleConfiguration(t('specificChartPage:consumption', { defaultValue: 'Consumo' })), offsetY: 40 },
      labels: {
        rotateAlways: false,
        rotate: 0,
        show: true,
        style: { fontSize: '1em' },
        formatter: (val) => formatNumberToNotation(parseFloat(val)),
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      title: {
        ...AxisTitleConfiguration(t('specificChartPage:coils', { defaultValue: 'Bobinas' })),
        offsetX: 10,
      },
      labels: {
        minWidth: 120,
        maxWidth: 1200,
        align: 'left',
        style: {
          fontSize: '1em',
        },
      },
    },
    tooltip: {
      custom: ({ w, seriesIndex, dataPointIndex }) => {
        const dataPoint =
          w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
        return `<div style="padding: 0.5rem; border-radius: var(--rounded);">
          <h2>${w.globals.initialSeries[seriesIndex].name}</h2>
            <table style="border-collapse: collapse; padding: 0.5rem;">
            <thead style="padding: 0.25rem 0.5rem;">
              <th style="padding: 0.25rem 0.5rem;">${t('specificChartPage:equipment', { defaultValue: 'Equipamento' })}</th>
              <th style="padding: 0.25rem 0.5rem;">kg</th>
            </thead>
            <tbody>
            ${dataPoint.metadata.reduce((acc: any, curr: any) => {
          return `${acc}<tr style="padding: 0.25rem 0.5rem;">
                    <td style="padding: 0.25rem 0.5rem;">${curr.equipment.name
            }</td>
                    <td style="padding: 0.25rem 0.5rem;">${formatNumberToNotation(
              curr.consume
            )}</td>
                  </tr>`;
        }, '')}
              </tbody>
          </table>
        </div>`;
      },
    },
    ...ChartOptionsLegend,
  };

  return (
    <div className={style.container}>
      <SpecificChartPageTitle text={t('specificChartPage:coilConsumption', { defaultValue: 'Consumo das Bobinas' })} />
      <div className={uniqueStyle.chartsArea}>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            {!!coilData.length && (
              <>
                <div className={uniqueStyle.tableContainer}>
                  <table>
                    <tr>
                      <th rowSpan={2}>{t('specificChartPage:machine', { defaultValue: 'Máquina' })}</th>
                      {tableData.coils.map((coilId) => {
                        const foundCoil = coilData.find(
                          ({ coil }) => coil.id === coilId
                        );
                        return (
                          <td
                            className={uniqueStyle.classificationTitle}
                            colSpan={4}
                          >
                            {foundCoil?.coil.name}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      {tableData.coils
                        .map(() => [
                          <td className={uniqueStyle.classificationTitle}>
                            {t('specificChartPage:consumption', { defaultValue: 'Consumo' })} (kg)
                          </td>,
                          <td className={uniqueStyle.classificationTitle}>
                            {t('specificChartPage:packagesProduced', { defaultValue: 'Pacotes produzidos' })}
                          </td>,
                          <td className={uniqueStyle.classificationTitle}>
                            {t('specificChartPage:averageConsumption', { defaultValue: 'Consumo médio' })} (g/pct.)
                          </td>,
                          <td className={uniqueStyle.classificationTitle}>
                            {t('specificChartPage:expectedAverageConsumption', { defaultValue: 'Consumo médio esperado' })}
                          </td>,
                        ])
                        .flat()}
                    </tr>
                    {tableData.equipments.map((equipmentId) => {
                      const foundEquipment = coilData.find(
                        ({ equipment }) => equipment.id === equipmentId
                      );
                      const filteredByEquipment = tableData?.data?.filter(
                        ({ equipment }) => equipment.id === equipmentId
                      );
                      return tableData.coils.map((coilId) => {
                        debugger;
                        console.log(equipmentId, coilId);
                        const filteredByCoil = filteredByEquipment?.filter(
                          ({ coil }) => coil.id === coilId
                        );
                        if (!filteredByCoil) {
                          return (
                            <tr>
                              <td>{foundEquipment?.equipment.name}</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          );
                        } else if (filteredByCoil.length === 1) {
                          return (
                            <tr>
                              <td>{filteredByCoil[0].equipment.name}</td>
                              <td>
                                {formatNumberToNotation(
                                  filteredByCoil[0].consume,
                                  3
                                )}
                              </td>
                              <td>
                                {formatNumberToNotation(
                                  filteredByCoil[0].count
                                )}
                              </td>
                              <td>
                                {formatNumberToNotation(
                                  filteredByCoil[0].avg,
                                  3
                                )}
                              </td>
                              <td>
                                {formatNumberToNotation(
                                  filteredByCoil[0].estimate,
                                  3
                                )}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr>
                              <td>{filteredByCoil[0].equipment.name}</td>
                              <td>
                                {formatNumberToNotation(
                                  filteredByCoil.reduce(
                                    (acc, curr) => acc + curr.consume,
                                    0
                                  ),
                                  3
                                )}
                              </td>
                              <td>
                                {formatNumberToNotation(
                                  filteredByCoil.reduce(
                                    (acc, curr) => acc + curr.count,
                                    0
                                  ),
                                  3
                                )}
                              </td>
                              <td>
                                {formatNumberToNotation(
                                  filteredByCoil.reduce(
                                    (acc, curr) => acc + curr.avg * curr.count,
                                    0
                                  ) /
                                  filteredByCoil.reduce(
                                    (acc, curr) => acc + curr.count,
                                    0
                                  ),
                                  3
                                )}
                              </td>
                              <td>{filteredByCoil[0].estimate}</td>
                            </tr>
                          );
                        }
                      });
                    })}
                  </table>
                </div>
                <div className={uniqueStyle.chartContent}>
                  <Chart
                    type='bar'
                    options={options}
                    series={series}
                    height={chartHeight}
                  />
                </div>
              </>
            )}
            {!coilData.length && <EmptyState />}
          </>
        )}
      </div>
    </div>
  );
};
