import { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../../../../Components/Atoms/Button';
import { Tooltip } from '../../../../../Components/Atoms/Tooltip';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import { useTenant } from '../../../../TenantControl';
import style from './CoilsListPage.module.css';
import { ConfirmDeleteCoilModal } from './ConfirmDeleteCoilModal';
import { useTranslation } from 'react-i18next';

export interface Coil {
  id: string;
  name: string;
  code: number;
  weight: number;
  estimate: number;
  enabled: boolean;
}

export const CoilsListPage = () => {
  const { api } = useAuth();
  const { client } = useTenant();
  const { addToast } = useToast();
  const { push } = useHistory();
  const [coils, setCoils] = useState<Coil[]>();
  const [coilToDelete, setCoilToDelete] =
    useState<Pick<Coil, 'id' | 'name' | 'code'>>();
  const { t } = useTranslation();

  const fetchCoils = async (): Promise<void> => {
    try {
      const result = await api.get<Coil[]>(
        `/api/client/${client?.tenant}/registrations/coils`
      );
      setCoils(result);
    } catch (_) {
      addToast({ title: t('coilPage:problemListingEvents', { defaultValue: 'Problema ao listar eventos.' }), type: 'error' });
    }
  };

  useEffect(() => {
    fetchCoils();
  }, []);

  return (
    <div className={style.container}>
      {coilToDelete && (
        <ConfirmDeleteCoilModal
          tenant={client?.tenant || ''}
          id={coilToDelete.id}
          name={coilToDelete.name}
          handleClose={(success) => {
            setCoilToDelete(undefined);
            if (success) {
              fetchCoils();
            }
          }}
        />
      )}
      <Button
        onClick={() => push(`${location.pathname}/novo`)}
        icon={<FiPlus />}
      >
        {t('coilPage:coil', { defaultValue: 'Bobina' })}
      </Button>
      {coils && (
        <ul className={style.coilsList}>
          <li className={style.header} key='header'>
            <div className={`${style.column} ${style.nameColumn}`}>{t('coilPage:name', { defaultValue: 'Nome' })}</div>
            <div className={`${style.column} ${style.codeColumn}`}>{t('coilPage:code', { defaultValue: 'Código' })}</div>
            <div className={`${style.column} ${style.weightColumn}`}>{t('coilPage:weight', { defaultValue: 'Peso' })}</div>
            <div className={`${style.column} ${style.estimateColumn}`}>
              {t('coilPage:estimation', { defaultValue: 'Estimativa' })}
            </div>
            <div className={`${style.column} ${style.actionsColumn}`}></div>
          </li>
          {coils.map(({ name, code, estimate, weight, enabled, id }) => (
            <li className={enabled ? style.row : style.disabledRow}>
              <div className={`${style.column} ${style.nameColumn}`}>
                {name}
              </div>
              <div className={`${style.column} ${style.codeColumn}`}>
                {code}
              </div>
              <div className={`${style.column} ${style.weightColumn}`}>
                {weight}
              </div>
              <div className={`${style.column} ${style.estimateColumn}`}>
                {estimate}
              </div>
              <div className={`${style.column} ${style.actionsColumn}`}>
                <Tooltip tooltip={t('coilPage:edit', { defaultValue: 'Editar' })}>
                  <Link to={`${location.pathname}/${id}`}>
                    <AiOutlineEdit />
                  </Link>
                </Tooltip>
                {enabled && (
                  <Tooltip tooltip={t('coilPage:delete', { defaultValue: 'Excluir' })}>
                    <div>
                      <AiOutlineDelete
                        onClick={() => setCoilToDelete({ id, name, code })}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
