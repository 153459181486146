import { Link, useHistory } from "react-router-dom";
import { Button } from "../../../Components/Atoms/Button";
import { FiPlus } from "react-icons/fi";
import style from './LanguageList.module.css';
import { LanguageListItemModel } from "./LanguageModel";
import { useEffect, useState } from "react";
import { useManagementAuth } from "../../AuthControl";
import { useToast } from "../../../hooks/toast";

export const LanguageList = (): React.ReactElement => {
  const [languages, setLanguages] = useState<LanguageListItemModel[]>([]);
  const { token, api, signOut } = useManagementAuth();
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    const controller = new AbortController();
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    api.get<LanguageListItemModel[]>(`/api/management/languages`, controller.signal)
      .then((languages: LanguageListItemModel[]) => {
        setLanguages(languages);
      })
      .catch((error) => {
        if (error.statusCode === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada',
            description: 'Faça login novamente.',
          });
          signOut();
        }
        throw 'error';
      });
    return () => controller.abort();
  }, []);

  return (
    <div id="list-area">
      <div style={{ color: 'rgb(var(--clr-neutral-50))' }}>
        <Link to='/management/s/languages/new'>
          <Button icon={<FiPlus />}>Idioma</Button>
        </Link>
      </div>
      <ul className={style.list}>
        <li className={style.header} key='header'>
          <div className={style.column}>Código</div>
          <div className={style.column}>Idioma</div>
        </li>
        {languages.map(({ language, description }) => (
          <li className={style.row} key={language}
            onClick={() => {
              history.push(`/management/s/languages/${language}`);
            }}>
            <div className={style.column}>{language}</div>
            <div className={style.column}>{description}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}