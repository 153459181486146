import { ApexOptions } from 'apexcharts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { formatSecondsToHumanReadableTime } from '../../../../../helper/FormatSecondsToHumanReadableTime';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { EmptyState } from '../../Components/Cards/EmptyState';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { OccurrencesFilterModal } from '../../Components/OccurrencesFilterModal';
import { getOccurrencesBlacklist } from '../../Components/OccurrencesFilterModal/FilterOccurrencesService';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { EventsHistogram } from '../../Controls/DataControl/models';
import { useData } from '../../Controls/DataControl/UseData';
import { useFilter } from '../../Controls/FilterControl';
import { Filter } from '../../Controls/FilterControl/models';
import uniqueStyle from './OccurrencesHistogramPage.module.css';
import { useLocale } from '../../../../LocaleControl';
import { useTranslation } from 'react-i18next';

interface Props {
  containerWidth: number;
  containerHeight: number;
}
export const OccurrencesHistogramPage = ({
  containerHeight,
  containerWidth,
}: Props): React.ReactElement => {
  const { filter, resetFilterRules } = useFilter();
  const { fetchEventsHistogram } = useData();
  const [histogramData, setHistogramData] = useState<EventsHistogram[]>();
  const abortFetchRef = useRef<() => void>();
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState<{ [key: string]: ApexAxisChartSeries }>();
  const [showFilter, setShowFilter] = useState(false);
  const [occurrencesBlacklist, setOccurrencesBlacklist] = useState<string[]>(getOccurrencesBlacklist());
  const { t } = useTranslation();
  const labels: any = {
    Desconectado: t('cardsPage:disconnected', { defaultValue: 'Desconectado' }),
    ['Ocorrência não informada']: t('cardsPage:occurrenceNotInformed', { defaultValue: 'Ocorrência não informada' }),
    Conectado: t('cardsPage:connected', { defaultValue: 'Conectado' }),
  };
  const localFetch = useCallback(
    async (localFilter: Filter, localOccurrencesBlacklist: string[]) => {
      try {
        setSeries(undefined);
        setLoading(true);
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }
        const { abort, fetch } = fetchEventsHistogram(
          localOccurrencesBlacklist
        );
        abortFetchRef.current = abort;
        const response = await fetch(localFilter);
        setHistogramData(response.filter(({ equipment }) => !!equipment));
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [fetchEventsHistogram, setHistogramData]
  );
  const { chartOptionsLocale } = useLocale();

  useEffect(() => {
    return () => {
      setHistogramData(undefined);
      setSeries(undefined);
    };
  }, [setHistogramData, setSeries]);

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    localFetch(filter, occurrencesBlacklist);
  }, [filter, occurrencesBlacklist]);

  const options: ApexOptions = {
    chart: {
      stacked: false,
      animations: {
        enabled: false,
      },
      type: 'bar',
      ...chartOptionsLocale,
      toolbar: {
        show: true,
        tools: {
          selection: true,
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'bottom',
          orientation: 'vertical',
          hideOverflowingLabels: false,
        },
      },
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    ...ChartOptionsLegend,
    xaxis: {
      tickPlacement: 'between',
      labels: { style: { fontSize: '1em' },
      formatter: (value: string) => labels[value] ?? value,
    },
      title: { ...AxisTitleConfiguration(t('specificChartPage:occurrence', { defaultValue: 'Ocorrência' })), offsetY: 20 },
    },
    tooltip: {
      shared: false,
      intersect: false,
      y: {
        formatter: (val, { seriesIndex, w }) => {
          const serieName = w.globals.initialSeries[seriesIndex].name;
          if (serieName === t('specificChartPage:duration', { defaultValue: 'Duração' })) {
            return formatSecondsToHumanReadableTime(val);
          }
          return val.toString();
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: { fontSize: '1.175em', colors: ['#222'] },
      textAnchor: 'start',
      offsetY: 14,
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 6,
        borderColor: 'transparent',
        opacity: 1,
        dropShadow: {
          enabled: false,
        },
      },
      formatter: (val, { seriesIndex, w }) => {
        const serieName = w.globals.initialSeries[seriesIndex].name;
        if (typeof val === 'number') {
          if (serieName === t('specificChartPage:duration', { defaultValue: 'Duração' })) {
            return formatSecondsToHumanReadableTime(val);
          }
        }
        return val.toString();
      },
    },
  };

  useEffect(() => {
    if (!histogramData) {
      setLoading(false);
      return;
    }
    if (histogramData.length === 0) {
      setSeries(undefined);
      setLoading(false);
      return;
    }
    setSeries(
      (histogramData.length === 1
        ? histogramData
        : histogramData.sort((a, b) =>
          a.equipment.n.localeCompare(b.equipment.n)
        )
      )?.reduce<any>((acc, curr) => {
        return {
          ...acc,
          [curr.equipment.mid]: [
            {
              name: t('specificChartPage:count', { defaultValue: 'Contagem' }),
              type: 'column',
              data: curr.events
                .sort((a, b) => a?.description?.localeCompare(b.description))
                .map((event) => ({
                  x: event.description ?? t('specificChartPage:eventNotFound', { defaultValue: 'evento não encontrado' }),
                  y: event.count,
                })),
            },
            {
              name: t('specificChartPage:duration', { defaultValue: 'Duração' }),
              type: 'column',
              data: curr.events.map((event) => ({
                x: event.description ?? t('specificChartPage:eventNotFound', { defaultValue: 'evento não encontrado' }),
                y: event.duration,
              })),
            },
          ],
        };
      }, {})
    );
    setLoading(false);
  }, [histogramData]);
  return (
    <>
      {showFilter && (
        <OccurrencesFilterModal
          handleClose={() => {
            setOccurrencesBlacklist(getOccurrencesBlacklist());
            setShowFilter(false);
          }}
        />
      )}
      <div className={uniqueStyle.container}>
        <SpecificChartPageTitle
          text={t('specificChartPage:occurrencesHistogram', { defaultValue: 'Histograma de Ocorrências' })}
          onFilterClick={() => {
            setShowFilter(true);
          }}
        />
        <div className={uniqueStyle.chartsArea}>
          {loading ? (
            <LoadingState />
          ) : histogramData && series ? (
            (histogramData ?? [])
              .filter(
                ({ equipment, events }) =>
                  !!equipment &&
                  events.some(
                    ({ code }) =>
                      !occurrencesBlacklist.includes(code.toString())
                  )
              )
              .map(({ equipment }) => (
                <div key={equipment.n} className={uniqueStyle.equipmentArea}>
                  <Chart
                    type='bar'
                    options={{
                      ...options,
                      yaxis: [
                        {
                          seriesName: t('specificChartPage:count', { defaultValue: 'Contagem' }),
                          labels: {
                            minWidth: 0,
                            maxWidth: 250,
                            show: true,
                            style: { fontSize: '1em' },
                          },
                          title: AxisTitleConfiguration(
                            `${equipment.n} - ${t('specificChartPage:count', { defaultValue: 'Contagem' })}`
                          ),
                        },
                        {
                          seriesName: t('specificChartPage:duration', { defaultValue: 'Duração' }),
                          labels: {
                            show: true,
                            style: { fontSize: '1em' },
                            formatter: (val) => {
                              const threeHours = 60 * 60 * 3;
                              return formatSecondsToHumanReadableTime(
                                Math.ceil(val / threeHours) * threeHours
                              );
                            },
                          },
                          opposite: true,
                          title: AxisTitleConfiguration(
                            `${equipment.n} - ${t('specificChartPage:duration', { defaultValue: 'Duração' })}`
                          ),
                        },
                      ],
                    }}
                    series={series[equipment.mid]}
                    height={containerHeight - 190}
                    width={containerWidth - 100}
                  />
                </div>
              ))
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
    </>
  );
};
