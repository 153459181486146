import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { useFilter } from '../../Controls/FilterControl';
import { useData } from '../../Controls/DataControl/UseData';
import { Event } from '../../Controls/DataControl/models';
import style from '../SpecificChartPage.module.css';
import operationalFactorPageStyle from './OperationalFactorPage.module.css';
import { ChartOptionsLegend } from '../../Components/Cards/ChartOptionsLegend';
import { EmptyState } from '../../Components/Cards/EmptyState';
import { formatSecondsToHumanReadableTime } from '../../../../../helper/FormatSecondsToHumanReadableTime';
import { Filter } from '../../Controls/FilterControl/models';
import { LoadingState } from '../../Components/Cards/LoadingState';
import { getOccurrencesBlacklist } from '../../Components/OccurrencesFilterModal/FilterOccurrencesService';
import { OccurrencesFilterModal } from '../../Components/OccurrencesFilterModal';
import { ChartOptionsDatetimeXaxis } from '../../Components/Cards/ChartOptionsDatetimeXaxis';
import { SpecificChartPageTitle } from '../../Components/SpecificChartPageTitle';
import { AxisTitleConfiguration } from '../../../../../helper/AxisTitleConfiguration';
import { useLocale } from '../../../../LocaleControl';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocation } from '../../../../../helper/GetDateFnsLocation';

interface Props {
  containerWidth: number;
  containerHeight: number;
}

interface ChartData {
  name?: string;
  color?: string;
  data?: any[];
}[]

export const OperationalFactorPage = ({ }: Props): React.ReactElement => {
  const { filter, resetFilterRules } = useFilter();
  const { fetchEvents } = useData();
  const [events, setEvents] = useState<Event[]>();
  const [series, setSeries] = useState<ApexAxisChartSeries>();
  const [chartSeries, setChartSeries] = useState<ChartData | any>();
  const [isZooming, setIsZooming] = useState(false);
  const [loading, setLoading] = useState(true);
  const [occurrencesBlacklist, setOccurrencesBlacklist] = useState<string[]>(
    getOccurrencesBlacklist()
  );
  const [showFilter, setShowFilter] = useState(false);
  const abortFetchRef = useRef<() => void>();
  const { chartOptionsLocale } = useLocale();
  const { t } = useTranslation();
  const FALLBACK_LANGUAGE = 'pt_BR';

  const localFetch = useCallback(
    async (localFilter: Filter) => {
      setLoading(true);
      try {
        if (abortFetchRef.current) {
          abortFetchRef.current();
          abortFetchRef.current = undefined;
        }
        const { abort, fetch } = fetchEvents();
        abortFetchRef.current = abort;
        const result = await fetch(localFilter);
        setEvents(
          result.map((equipment) => ({
            ...equipment,
            events: equipment.events.filter((event) => event.duration > 0),
          }))
        );
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [abortFetchRef, fetchEvents]
  );

  useEffect(() => {
    resetFilterRules();
  }, []);

  useEffect(() => {
    return () => {
      setEvents(undefined);
      setSeries(undefined);
    };
  }, [setEvents, setSeries]);

  useEffect(() => {
    if (isZooming) {
      return;
    }
    localFetch(filter);
  }, [filter, isZooming, occurrencesBlacklist]);

  const computeEventSeries = useCallback((): ApexAxisChartSeries => {
    if (!events) {
      throw 'undefined events list';
    }
    const downtimeSeries = events
      .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
      .map(({ equipment, events }) => ({
        name: t('specificChartPage:stopped', { defaultValue: 'Parado' }),
        color: '#ec5959',
        data:
          events
            .filter(
              ({ code }) => !occurrencesBlacklist.includes(code?.toString())
            )
            .map(({ startDate, endDate, windowStart, windowEnd }) => ({
              x: equipment.name,
              y: [
                Math.max(startDate.getTime(), windowStart.getTime()),
                Math.min(
                  (endDate ?? new Date()).getTime(),
                  windowEnd.getTime()
                ),
              ],
            })) ?? [],
      }));
    return downtimeSeries;
  }, [events, occurrencesBlacklist]);

  const computeNonEventsSeries = useCallback((): ApexAxisChartSeries => {
    if (!events) {
      throw 'undefined events list';
    }
    return events
      .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
      .map(({ equipment, events }) => {
        let data: { x: string; y: number[] }[] = [];
        const filteredEvents = events.filter(
          ({ windowStart, windowEnd }, index) => {
            const start = windowStart.getTime();
            const end = windowEnd.getTime();
            if (start >= end) {
              return false;
            }

            const eventInTheMiddleOfOther = events.some(
              (searchEvent, innerIndex) => {
                if (index === innerIndex) return false;
                const searchStart = searchEvent.windowStart.getTime();
                const searchEnd = searchEvent.windowEnd.getTime();
                if (start >= searchStart && end <= searchEnd) {
                  console.log('FOUND IT');
                }
                return start >= searchStart && end <= searchEnd;
              }
            );
            return !eventInTheMiddleOfOther;
          }
        );
        if (!filteredEvents || filteredEvents.length === 0) {
          return {
            name: t('specificChartPage:producing', { defaultValue: 'Produzindo' }),
            color: '#27dc94',
            data: filter.dates.map((timestampFilter) => ({
              x: equipment.name,
              y: [
                Math.max(
                  timestampFilter.startTimestamp,
                  equipment.createdAt?.getTime() ?? 0
                ),
                Math.min(
                  timestampFilter.endTimestamp,
                  equipment.removedAt?.getTime() ?? Number.POSITIVE_INFINITY
                ),
              ],
            })),
          };
        }
        const equipmentStart = Math.max(
          equipment.createdAt?.getTime() ?? 0,
          filter.dates.reduce(
            (acc, curr) => Math.min(acc, curr.startTimestamp),
            Number.POSITIVE_INFINITY
          )
        );

        const equipmentEnd = Math.min(
          equipment.removedAt?.getTime() ?? Number.POSITIVE_INFINITY,
          filter.dates.reduce(
            (acc, curr) => Math.max(acc, curr.endTimestamp),
            0
          )
        );
        const periodStartsProducing =
          filteredEvents.length > 0 &&
          equipmentStart < filteredEvents[0].startDate.getTime();

        const isLastEvent = (index: number) =>
          index === filteredEvents.length - 1;

        if (periodStartsProducing) {
          data = [
            {
              x: equipment.name,
              y: [equipmentStart, filteredEvents[0].windowStart.getTime()],
            },
          ];
        }

        for (let i = 0; i < filteredEvents.length; i++) {
          if (
            filteredEvents[i].startDate === new Date('2023-03-30T21:08:49.000Z')
          ) {
            console.log(' found it:', JSON.stringify(filteredEvents[i]));
          }
          if (isLastEvent(i)) {
            data = [
              ...data,
              {
                x: equipment.name,
                y: [
                  filteredEvents[i].windowEnd.getTime(),
                  Math.min(
                    filteredEvents.length - 1 >= i + 1
                      ? filteredEvents[i + 1].windowStart.getTime()
                      : filter.dates.reduce(
                        (_acc, curr) =>
                          curr.endTimestamp <
                            filteredEvents[i].windowEnd.getTime()
                            ? filteredEvents[i].windowEnd.getTime()
                            : curr.endTimestamp,
                        0
                      ),
                    equipmentEnd
                  ),
                ],
              },
            ];
            break;
          }
          data = [
            ...data,
            {
              x: equipment.name,
              y: [
                Math.min(
                  (filteredEvents[i].endDate ?? new Date()).getTime(),
                  filteredEvents[i].windowEnd.getTime()
                ),
                Math.max(
                  filteredEvents[i + 1].startDate.getTime(),
                  filteredEvents[i + 1].windowStart.getTime()
                ),
              ],
            },
          ];
        }
        return {
          name: t('specificChartPage:producing', { defaultValue: 'Produzindo' }),
          color: '#27dc94',
          data: data.filter(
            (item) =>
              !filter.dates.find(
                ({ endTimestamp }) => endTimestamp === item.y[0]
              )
          ),
        };
      });
  }, [events]);

  const aggregateData = useCallback((newSeries: ApexAxisChartSeries) => {
    let stoppedData: any[] = [];
    let producingData: any[] = [];

    newSeries.forEach((item) => {
      if (item.name === t('specificChartPage:stopped', { defaultValue: 'Parado' })) {
        stoppedData.push(...item.data);
      } else if (item.name === t('specificChartPage:producing', { defaultValue: 'Produzindo' })) {
        producingData.push(...item.data);
      }
    });

    let aggArr = [{
      name: t('specificChartPage:stopped', { defaultValue: 'Parado' }),
      color: "#ec5959",
      data: [...stoppedData]
    }, {
      name: t('specificChartPage:producing', { defaultValue: 'Produzindo' }),
      color: "#27dc94",
      data: [...producingData]
    }]

    return aggArr
  }, [])

  useEffect(() => {
    try {
      const newSeries = [...computeEventSeries(), ...computeNonEventsSeries()];
      setLoading(false);
      setSeries(newSeries);
      const aggregatedData = aggregateData(newSeries);
      setChartSeries(aggregatedData)

    } catch (err) {
      console.info(err);
    }
  }, [setLoading, setSeries, computeEventSeries]);

  const options: ApexOptions = useMemo(
    () => ({
      responsive: [
        {
          breakpoint: 900,
          options: {
            xaxis: {
              tickAmount: 3,
              labels: { rotate: -22.5, rotateAlways: true, offsetY: -15 },
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            xaxis: {
              tickAmount: 7,
              labels: { rotateAlways: false, offsetY: 0 },
            },
          },
        },
      ],
      ...ChartOptionsLegend,
      chart: {
        events: {
          zoomed: () => {
            setIsZooming(true);
          },
          beforeResetZoom: () => {
            setIsZooming(false);
          },
        },
        offsetY: 0,
        type: 'rangeBar',
        animations: {
          enabled: false,
        },
        ...chartOptionsLocale,
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '60%',
          columnWidth: '20%',
          dataLabels: {
            position: 'bottom',
            hideOverflowingLabels: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          right: 50,
        },
      },
      ...ChartOptionsDatetimeXaxis(14),
      legend: {
        show: false,
      },
      yaxis: {
        title: { ...AxisTitleConfiguration(t('specificChartPage:equipment', { defaultValue: 'Equipamento' })), offsetX: 10 },
        labels: {
          style: { fontSize: '1em' },
          minWidth: 50,
          maxWidth: 1000,
          align: 'left',
        },
      },
      tooltip: {
        shared: false,
        intersect: false,
        followCursor: true,
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const dataPoint =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return `<div style="padding: 0.5rem 1rem">
          <div style="padding: 0.25rem 0; border-bottom: 1px solid #ddd; display:flex; flex-direction: column">
          <span>${w.globals.initialSeries[seriesIndex].name}</span>
          <span>${dataPoint.x}</span>
          <span>${formatSecondsToHumanReadableTime(
            Math.abs(dataPoint.y[1] - dataPoint.y[0]) / 1000
          )}</span></div>
          <div style="display: flex; flex-direction: column; line-height: 1.25rem"><span>${t('specificChartPage:startColon', { defaultValue: 'inicio:' })} ${format(
            new Date(dataPoint.y[0]),
            'HH:mm:ss dd/MMM',
            { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) }
          )}</span><span>${t('specificChartPage:endColon', { defaultValue: 'fim:' })} ${format(
            new Date(dataPoint.y[1]),
            'HH:mm:ss dd/MMM',
            { locale: getDateFnsLocation(localStorage.getItem('lng') || FALLBACK_LANGUAGE) }
          )}</span></div>
          </div>`;
        },
      },
    }),
    [series]
  );

  return (
    <div className={style.container}>
      {showFilter && (
        <OccurrencesFilterModal
          handleClose={() => {
            setOccurrencesBlacklist(getOccurrencesBlacklist());
            setShowFilter(false);
          }}
        />
      )}
      <SpecificChartPageTitle
        text={t('specificChartPage:operationalFactor', { defaultValue: 'Fator Operacional' })}
        onFilterClick={() => setShowFilter(true)}
      />
      <div className={operationalFactorPageStyle.chartsArea}>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <div className={operationalFactorPageStyle.tableSection}>
              <table className={operationalFactorPageStyle.table}>
                <thead>
                  <tr>
                    <th>{t('specificChartPage:equipment', { defaultValue: 'Equipamento' })}</th>
                    <th>{t('specificChartPage:availableTime', { defaultValue: 'Tempo Disponível' })}</th>
                    <th>{t('specificChartPage:occurrencesDuration', { defaultValue: 'Duração Ocorrências' })}</th>
                    <th>{t('specificChartPage:operationalFactor', { defaultValue: 'Fator Operacional' })} (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {(events ?? [])
                    .map(({ equipment }) => equipment)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((equipment) => {
                      const foundEvent = (events ?? []).find(
                        ({ equipment: { id } }) => id === equipment.id
                      );
                      const data = {
                        name: equipment.name,
                        timeAvailable: foundEvent?.timeAvailable
                          ? foundEvent.timeAvailable -
                          foundEvent.events
                            .filter(({ code }) =>
                              occurrencesBlacklist.includes(code?.toString())
                            )
                            .reduce((acc, curr) => acc + curr.duration, 0)
                          : filter.dates.reduce(
                            (acc, curr) =>
                              acc + curr.endTimestamp - curr.startTimestamp,
                            0
                          ) / 1000,
                        duration: foundEvent?.duration
                          ? foundEvent?.duration -
                          foundEvent.events
                            .filter(({ code }) =>
                              occurrencesBlacklist.includes(code?.toString())
                            )
                            .reduce((acc, curr) => acc + curr.duration, 0)
                          : 0,
                        factor:
                          (foundEvent?.duration
                            ? foundEvent?.duration -
                            foundEvent.events
                              .filter(({ code }) =>
                                occurrencesBlacklist.includes(code?.toString())
                              )
                              .reduce((acc, curr) => acc + curr.duration, 0)
                            : 0) /
                          (foundEvent?.timeAvailable
                            ? foundEvent.timeAvailable -
                            foundEvent.events
                              .filter(({ code }) =>
                                occurrencesBlacklist.includes(code?.toString())
                              )
                              .reduce((acc, curr) => acc + curr.duration, 0)
                            : filter.dates.reduce(
                              (acc, curr) =>
                                acc + curr.endTimestamp - curr.startTimestamp,
                              0
                            ) / 1000),
                      };
                      return (
                        <tr key={data.name}>
                          <td style={{ fontWeight: 600 }}>{data.name}</td>
                          <td>
                            {formatSecondsToHumanReadableTime(data.timeAvailable)}
                          </td>
                          <td>
                            {formatSecondsToHumanReadableTime(data.duration) ??
                              '-'}
                          </td>
                          <td>
                            {(
                              (data.factor === Number.POSITIVE_INFINITY ||
                                isNaN(data.factor)
                                ? 0
                                : 1 - data.factor) * 100
                            ).toFixed(2).replace('.', ',')}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {series && series.length ? (
              <div className={operationalFactorPageStyle.chartContent}>
                <Chart
                  type='rangeBar'
                  height={Math.max(
                    (events ?? []).map(({ equipment }) => equipment).length *
                    150,
                    300
                  )}
                  options={options}
                  series={chartSeries}
                />
              </div>
            ) : (
              <EmptyState />
            )}
          </>
        )}
      </div>
    </div>
  );
};
