import React from 'react';
import { Button } from '../../../../../Components/Atoms/Button';
import Modal from '../../../../../Components/Modal';
import { useToast } from '../../../../../hooks/toast';
import { useAuth } from '../../../../AuthControl';
import style from './ConfirmDeleteCoilModal.module.css';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteCoilModal = ({
  tenant,
  id,
  name,
  handleClose,
}: {
  tenant: string;
  id: string;
  name: string;
  handleClose: (refresh?: boolean) => void;
}): React.ReactElement => {
  const { addToast } = useToast();
  const { api } = useAuth();
  const { t } = useTranslation();

  const handleDeleteCoil = async () => {
    await api.delete(`/api/client/${tenant}/registrations/coils/${id}`);
    addToast({ title: t('coilPage:coilDeletedSuccessfully', { defaultValue: 'Bobina excluída com sucesso.' }), type: 'success' });
    handleClose(true);
  };

  return (
    <Modal handleClose={handleClose}>
      <div className={style.container}>
        <h1>{t('coilPage:delete', { defaultValue: 'Excluir' })} {name} ?</h1>
        <p className={style.body}>
          {t('coilPage:coilDeleteText1', { defaultValue: 'Dados existentes não serão perdidos,' })}
          <br />
          {t('coilPage:coilDeleteText2', { defaultValue: 'mas não serão geradas novos registros para essa bobina.' })}
        </p>
        <div className={style.buttons}>
          <Button secondary onClick={() => handleClose(false)}>
            {t('coilPage:cancel', { defaultValue: 'Cancelar' })}
          </Button>
          <Button danger onClick={() => handleDeleteCoil()}>
            {t('coilPage:delete', { defaultValue: 'Excluir' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
