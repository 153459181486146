import React, { useState } from 'react';
import { useAuth } from '../../AuthControl';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { Button } from '../../../Components/Atoms/Button';
import { Input } from '../../../Components/Atoms/Input';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import AppError from '../../../helper/AppError';
import style from './TenantLogin.module.css';
import { useTenant } from '../../TenantControl';
import { useTranslation } from 'react-i18next';

export const TenantLogin = ({
  tenant,
}: {
  tenant: string;
}): React.ReactElement => {
  const { signIn, user } = useAuth();
  const { client, clearClient, forgotPassword } = useTenant();
  const { push } = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({});
    const schema = Yup.object().shape({
      email: Yup.string()
        .email(t('userConfigTenantPage:userEmailMustBeValid', { defaultValue: 'usuário deve ser um email válido' }))
        .required(t('userConfigTenantPage:emailIsMandatory', { defaultValue: 'Email é obrigatório' })),
      password: Yup.string().required(t('userConfigTenantPage:passwordIsMandatory', { defaultValue: 'Senha é obrigatório' })),
    });
    try {
      await schema.validate({ email, password }, { abortEarly: false });
      const user = await signIn({ tenant, email, password });
      push('inicio');
      addToast({ type: 'success', title: t('userConfigTenantPage:welcome', { defaultValue: 'Bem-vindo' }), description: user.name });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors(
          error.inner.reduce((acc, curr) => {
            if (curr.path) {
              return { ...acc, [curr.path]: curr.errors };
            } else return acc;
          }, {})
        );
      } else if (error instanceof AppError) {
        addToast({
          title: t('userConfigTenantPage:failedToAuthenticate', { defaultValue: 'Falha ao autenticar.' }),
          type: 'error',
          description:
            error.statusCode === 401
              ? t('userConfigTenantPage:invalidCredentials', { defaultValue: 'Credenciais inválidas' })
              : t('userConfigTenantPage:unexpectedError', { defaultValue: 'Erro inesperado' }),
        });
      }
    }
  };

  const handleResetPassword = async () => {
    try {
      if (!client?.tenant || !email) {
        addToast({
          title: t('userConfigTenantPage:error', { defaultValue: 'Erro' }),
          description: t('userConfigTenantPage:emailFieldMustBeFilled', { defaultValue: 'Campo email deve ser preenchido primeiro.' }),
          type: 'info',
        });
        return;
      }
      forgotPassword({ tenant: client?.tenant ?? '', email });
      addToast({
        title: t('userConfigTenantPage:requestedSuccessfully', { defaultValue: 'Solicitado com sucesso' }),
        description: t('userConfigTenantPage:emailSentToResetPassword', {
          email: email,
          defaultValue: `Um email foi enviado a ${email} para redefinir a senha.`
        }),
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: t('userConfigTenantPage:error', { defaultValue: 'Erro' }),
        description: t('userConfigTenantPage:problemRequestingPasswordReset', {
          defaultValue: 'Houve um problema ao solicitar a redefinição da senha.'
        }),
        type: 'error',
      });
    }
  };
  return user && user.tenant === tenant ? (
    <Redirect to='inicio' />
  ) : (
    <div className={style.container}>
      <section className={style.background}>
        <img src='/background.png' />
      </section>
      <section className={style.body}>
        <div>
          <img
            src={
              client?.logoPath ? client?.logoPath : '/logo_cliente_supervis.png'
            }
          />
          <h1>{client?.name}</h1>
          <h3>{t('userConfigTenantPage:welcome', { defaultValue: 'Bem-vindo' })}</h3>
          <form onSubmit={handleSubmit} className=''>
            <Input
              id='email'
              grow
              label={t('userConfigTenantPage:email', { defaultValue: 'E-mail' })}
              type='text'
              value={email}
              onChange={setEmail}
              errors={errors && errors['email']}
            />
            <Input
              id='password'
              grow
              label={t('userConfigTenantPage:password', { defaultValue: 'Senha' })}
              type='password'
              value={password}
              onChange={setPassword}
              errors={errors && errors['password']}
            />
            <div>
              <Button type='submit'>{t('userConfigTenantPage:signIn', { defaultValue: 'Entrar' })}</Button>
            </div>
            <div
              role='button'
              className={style.changeClientLink}
              onClick={handleResetPassword}
            >
              {t('userConfigTenantPage:resetPassword', { defaultValue: 'Redefinir senha' })}

            </div>
            <div
              role='button'
              className={style.changeClientLink}
              onClick={() => {
                clearClient();
              }}
            >{t('userConfigTenantPage:imNotFromClient', { clientName: client?.name, defaultValue: `Não sou da ${client?.name}` })}</div>
          </form>
        </div>
      </section>
    </div>
  );
};