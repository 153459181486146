import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import style from './ScrapsCard.module.css';
import convert from 'convert-units';
import { Filter } from '../../../Controls/FilterControl/models';
import { ConvertValueToBestUnit } from '../../../../../../helper/ConvertValueToBestUnit';
import { formatNumberToNotation } from '../../../../../../helper/FormatNumberToNotation';
import { useTranslation } from 'react-i18next';

export const ScrapsCard = (): React.ReactElement => {
  const [scraps, setScraps] = useState<{
    count: number;
    reprocess: number;
    scraps: number;
  }>({ count: 0, reprocess: 0, scraps: 0 });
  const { fetchProductionScraps } = useData();
  const { filter } = useFilter();
  const abortFetchRef = useRef<() => void>();
  const { t } = useTranslation();

  const localFetch = useCallback(
    (filter: Filter) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      const { abort, fetch } = fetchProductionScraps();
      abortFetchRef.current = abort;
      fetch(filter).then((result) => {
        setScraps(
          result.reduce(
            (acc, curr) => ({
              count: acc.count + curr.c,
              reprocess: acc.reprocess + curr.r,
              scraps: acc.scraps + curr.n,
            }),
            { count: 0, reprocess: 0, scraps: 0 }
          )
        );
      });
    },
    [fetchProductionScraps, setScraps]
  );

  useEffect(() => {
    localFetch(filter);
  }, [filter]);

  useEffect(() => {
    return () => {
      setScraps({ count: 0, reprocess: 0, scraps: 0 });
    };
  }, [setScraps]);
  const scrapConverted = useMemo(
    () => ConvertValueToBestUnit(scraps.scraps),
    [scraps.scraps]
  );
  const reprocessConverted = useMemo(
    () => ConvertValueToBestUnit(scraps.reprocess),
    [scraps.scraps]
  );

  return (
    <div className={style.container}>
      <h2>{t('cardsPage:leftovers', { defaultValue: 'Sobras' })}</h2>
      <div className={style.dataContainer}>
        <div className={style.data}>
          <h4>{t('cardsPage:scrapsColon', { defaultValue: 'Aparas:' })}</h4>
          <strong>{`${scrapConverted.val} ${scrapConverted.unit}`}</strong>
        </div>
        <div className={style.data}>
          <h4>{t('cardsPage:reprocessColon', { defaultValue: 'Reprocesso:' })}</h4>
          <strong>{`${reprocessConverted.val} ${reprocessConverted.unit}`}</strong>
        </div>
        <div className={style.data}>
          <h4>{t('cardsPage:refundColon', { defaultValue: 'Estorno:' })}</h4>
          <strong>{formatNumberToNotation(scraps.count)} un</strong>
        </div>
      </div>
    </div>
  );
};
