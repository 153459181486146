class AppError {
  message: string;

  statusCode: number;
  code: string | undefined;
  constructor(message: string, statusCode = 500, code?: string) {
    this.message = message;
    this.statusCode = statusCode;
    this.code = code;
  }
}

export default AppError;
