import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useData } from '../../../Controls/DataControl/UseData';
import { useFilter } from '../../../Controls/FilterControl';
import style from './ProductionChart.module.css';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useResponsiveContainer } from '../../../Controls/ResponsiveContainerControl/UserResponsiveContainer';
import { ChartOptionsLegend } from '../ChartOptionsLegend';
import { EmptyState } from '../EmptyState';
import { LoadingState } from '../LoadingState';
import { Filter } from '../../../Controls/FilterControl/models';
import { Production } from '../../../Controls/DataControl/models';
import { MultiSeriesChartPalette } from '../../../../../../helper/ChartsColorPalette';
import convert from 'convert-units';
import { formatNumberToNotation } from '../../../../../../helper/FormatNumberToNotation';
import { AxisTitleConfiguration } from '../../../../../../helper/AxisTitleConfiguration';
import { ConvertValueToBestUnit } from '../../../../../../helper/ConvertValueToBestUnit';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../LocaleControl';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';

const normalizedUnit = (unitString: string) => {
  switch (unitString) {
    case 'g':
    case 'kg':
    case 'lb':
      return unitString;
    default:
      return 'g';
  }
};

const itemsPerPage = 8;

export const ProductionChart = (): React.ReactElement => {
  const { filter } = useFilter();
  const { fetchProduction } = useData();
  const { containerWidth } = useResponsiveContainer();
  const abortFetchRef = useRef<() => void>();
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<{ data: { x: string; y: number; meta: any }[] }[]>([]);
  const [paginatedSeries, setPaginatedSeries] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [localProduction, setLocalProduction] = useState<Production[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { chartOptionsLocale } = useLocale();

  const localFetch = useCallback(
    async (filter: Filter) => {
      if (abortFetchRef.current) {
        abortFetchRef.current();
        abortFetchRef.current = undefined;
      }
      try {
        const { abort, fetch } = fetchProduction();
        abortFetchRef.current = abort;
        const response = await fetch({
          ...filter,
          interval: '5m',
        });
        setLocalProduction(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    [abortFetchRef, fetchProduction, setLocalProduction]
  );

  useEffect(() => {
    if (localProduction) {
      if (localProduction.length) {
        const productionData = [
          {
            data: localProduction
              .reduce<Production[]>((acc, curr) => {
                if (
                  !acc.some(
                    ({ equipment, unit }) =>
                      equipment.name === curr.equipment.name &&
                      unit === curr.unit
                  )
                ) {
                  return [...acc, curr];
                } else {
                  const found = acc.find(
                    ({ equipment, unit }) =>
                      equipment.name === curr.equipment.name &&
                      unit === curr.unit
                  );
                  if (found) {
                    return [
                      ...acc.filter(
                        ({ equipment, unit }) =>
                          equipment.name !== curr.equipment.name ||
                          unit !== curr.unit
                      ),
                      {
                        ...found,
                        sum: found.sum + curr.sum,
                        entries: [...found.entries, ...curr.entries],
                      },
                    ];
                  }
                  return [...acc, curr];
                }
              }, [])
              .sort((a, b) => a.equipment.name.localeCompare(b.equipment.name))
              .sort((a, b) => b.sum - a.sum)
              .map(({ sum, equipment, unit }) => {
                const val = convert(sum).from('g').to(normalizedUnit(unit));
                return {
                  x: `${equipment.name}`,
                  y: val,
                  meta: {
                    unit: normalizedUnit(unit),
                  },
                };
              }),
          },
        ];

        let sortedPages: any = [];
        let data = productionData[0].data;

        for (let i = 0; i < data.length; i += itemsPerPage) {
          sortedPages.push(data.slice(i, i + itemsPerPage));
        }
        setPaginatedSeries(sortedPages);

        productionData[0].data = sortedPages[currentPage]
        setSeries(productionData)

      } else {
        setSeries([]);
      }
    }
  }, [localProduction]);

  useEffect(() => {
    setSeries(prev => {
      if (prev.length > 0) {
        prev[0].data = paginatedSeries[currentPage]
        return [...prev]
      }
      return prev
    })
  }, [currentPage])

  useEffect(() => {
    if (series.length === 0) return;
    setOptions({
      responsive: [
        {
          breakpoint: 1400,
          options: {
            xaxis: {
              tickAmount: 2,
            },
          },
        },
      ],
      ...ChartOptionsLegend,
      chart: {
        type: 'bar',
        toolbar: { show: false },
        animations: {
          enabled: false,
        },
        ...chartOptionsLocale,
      },
      xaxis: {
        type: 'category',
        tickPlacement: 'on',
        tickAmount: 4,
        categories: series[0].data.map((item) => item?.x),
        title: { ...AxisTitleConfiguration(t('cardsPage:weight', { defaultValue: 'Peso' })), offsetY: 10 },
        labels: {
          formatter: (val) => formatNumberToNotation(parseFloat(val) / 1000),
        },
      },
      colors: MultiSeriesChartPalette,
      yaxis: {
        show: false,
        labels: {
          formatter: formatNumberToNotation,
        },
      },
      dataLabels: {
        enabled: true,
        style: { fontSize: '0.875em', colors: ['#222'] },
        textAnchor: 'start',

        formatter: function (val, { w, dataPointIndex }) {
          const convertedValue = ConvertValueToBestUnit(
            parseFloat(val.toString())
          );
          return `${w.globals.labels[dataPointIndex]}: ${formatNumberToNotation(
            convertedValue.val
          )} ${convertedValue.unit}`;
        },

        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderColor: 'transparent',
          opacity: 0.5,
          dropShadow: {
            enabled: false,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          borderRadius: 4,
          dataLabels: {
            position: 'bottom',
            orientation: 'horizontal',
            hideOverflowingLabels: false,
          },
        },
      },
    });
  }, [series]);

  useEffect(() => {
    setIsLoading(true);
    localFetch(filter);
  }, [filter]);

  useEffect(() => {
    return () => {
      setOptions({});
      setSeries([]);
      setLocalProduction(undefined);
    };
  }, [setOptions, setSeries, setLocalProduction]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={style.chartContainer}>
      <div className={style.chartTitle}>
        <h2>{t('cardsPage:production', { defaultValue: 'Produção' })}</h2>
        <div className={style.pagination} >
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
            <GrLinkPrevious />
          </button>
          <button onClick={() => setCurrentPage(prev => prev + 1)} disabled={currentPage + 1 === paginatedSeries.length || paginatedSeries.length === 0} >
            <GrLinkNext />
          </button>
        </div>
      </div>
      {isLoading ? (
        <LoadingState />
      ) : options && series.length > 0 ? (
        <Chart
          type='bar'
          id='productionchart'
          options={options}
          series={series}
          height={containerWidth > 767 ? 360 : 330}
        />
      ) : (
        <EmptyState />
      )}
    </div>
  );
};
